import {Component, OnInit} from '@angular/core';
import {Product} from '../../model/Product';
import {BusinessServiceService} from '../../shared/services/business/business-service.service';
import {Location} from '@angular/common';
import {of} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {delay} from 'rxjs/operators';
import {ClothingColour} from '../../model/ClothingColour';
import {Utils} from '../../shared/Utils';
import {ProductService} from '../../services/product.service';
import {Router} from '@angular/router';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {ClothingSizeCode} from '../../model/ClothingSizeCode';
import {Ng2ImgMaxService} from 'ng2-img-max';
import {faArrowRight, faInfoCircle, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../services/notifier.service';
import Swal from 'sweetalert2';
import {HttpResponse} from '@angular/common/http';
import { ColorPickerService } from 'ngx-color-picker';
import {Business} from '../../model/Business';


@Component({
  selector: 'myafrica-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

    clothingSizeCodes: ClothingSizeCode[];
    selectedProduct: Product;
    activatedImage: string;

    selectedProductPrice: number;

    selectedProductName: string;
    color : string;

    url: ArrayBuffer | string;
    defaultUrl: ArrayBuffer | string;
    previousUrl = null;
    imageWidth: number;
    imageHeight: number;
    selectedBusiness: Business;

    selectedFile: File;
    defaultUrlImage: File;
    sizesElements: { name, count }[];

    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    // message displayed on the modal window
    displayModalMessage = '';
    displayModalErrorMessage: boolean;
    displayModalSuccessMessage: boolean;
    displayDeleteMessage: string;
    displayDeleteErrorMessage: boolean;
    displayDeleteSuccessMessage: boolean;
    loading: boolean;
    multiColourProduct: boolean;
    disableColorPicker: boolean;
    uploadedImage: Blob;

    iconSearch = faSearch;
    iconPlus = faPlus;
    iconArrowRight = faArrowRight;
    iconInfoCircle = faInfoCircle;
	
	breadCrumbDisplayManageProducts: boolean;

    constructor(private businessService: BusinessServiceService,
                private location: Location,
                private modalService: NgbModal,
                private appService: ApplicationStore,
                private notifier: NotifierService, private cpService: ColorPickerService,
                private productService: ProductService, private ng2ImgMax: Ng2ImgMaxService,
                private router: Router, private tableService: CodeTablesServiceService) {
    }

    ngOnInit() {
        this.sizesElements = [];
        this.activatedImage = undefined;
        this.selectedProduct = new Product();
		this.appService.storeStringItem('currentProduct', this.selectedProduct);

		this.selectedBusiness = this.appService.getJsonItem('selectedBusiness');
      
	    if(!sessionStorage.getItem('dashboard-path') !== undefined && sessionStorage.getItem('dashboard-path') === 'manage-products') {
		    this.breadCrumbDisplayManageProducts = true;
		}
	  
        this.getClothingSizeCodes();

        this.selectedProductPrice = 0.00;
        this.selectedProductName = 'Add Product';

        this.resetValues();
    }

    updateProductDetails(updatedPrice: number, productName: string) {
        this.selectedProductPrice = updatedPrice;
        this.selectedProductName = productName;
    }

   editBusiness() {

	this.navigate('edit-business');
   }


    getClothingSizeCodes() {
        this.tableService.getClothingSizeCodes().subscribe((value: ClothingSizeCode[]) => {
                this.clothingSizeCodes = value;
            },
        );
    }

    confirm() {
        const titleText = 'Are sure you want to delete the product?';

        Swal.fire({
            title: titleText,
            showDenyButton: true,
            confirmButtonText: `Continue`,
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteProduct(true);
            } else if (result.isDenied) {
                return false;
            }
        });

    }

    deleteProduct(userConfirmed: boolean) {

        if (!userConfirmed) {
            return;
        }

        this.businessService.deleteProduct(
            JSON.parse(sessionStorage.getItem('currentProduct')).businessId, JSON.parse(sessionStorage.getItem('currentProduct'))
                .productId).subscribe(value => {
            // this.getDefaultBusinessProducts();
            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Product deleted successfully!!';
            this.selectedProduct.deleted = true;
            this.notifier.notify(this.displayMessage, 'success');


        }, error1 => {
            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Failed to delete product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');

        });

    }


    undeleteConfirm() {

        const titleText = 'Are sure you want to undelete the product?';

        Swal.fire({
            title: titleText,
            showDenyButton: true,
            confirmButtonText: `Continue`,
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.undeleteProduct(true);
            } else if (result.isDenied) {
                return false;
            }
        });

    }

    undeleteProduct(userConfirmed: boolean) {

        if (!userConfirmed) {
            return;
        }

        this.businessService
            .undeleteProduct(JSON.parse(sessionStorage.getItem('currentProduct')).businessId,
                JSON.parse(sessionStorage.getItem('currentProduct')).productId).subscribe(() => {
            // this.getDefaultBusinessProducts();
            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Product undeleted successfully!!';
            this.selectedProduct.deleted = false;
            this.notifier.notify(this.displayMessage, 'success');

        }, () => {
            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Failed to undelete product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');

        });

    }

    goBack() {
        this.location.back();
    }

    addColor(content): void {
        this.resetValues();
        this.sizesElements.push({name: 'All', count: 0});

        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then(console.log);
    }

    adMoreElement() {
        this.sizesElements.push({name: 'All', count: 0});

    }

    open(content) {

        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            },
        );
    }

    sizesCaptured(): boolean {

        let elementEmpty = false;
        this.sizesElements.forEach((element) => {
            if (element.count === 0) {
                elementEmpty = true;
            }
        });
        if (elementEmpty) {
            this.showErrorMessage('Size cannot be empty. Please fill in the size of one of the color(s)!! ');
            return false;
        }


        return true;

    }

    showErrorMessage(errorMessage: string) {
        this.displayModalErrorMessage = true;
        this.displayModalSuccessMessage = false;
        this.displayModalMessage = errorMessage;

    }


    submit(): void {
        this.displayModalErrorMessage = false;
        this.displayModalSuccessMessage = false;

        if (this.color === undefined || this.color === null) {
            this.displayMessage = 'Please select colour for the product!!';
            this.notifier.notify(this.displayMessage, 'error');

            return;
        }


        this.loading = true;

        const payLoad = {
            colourName: this.color,
            clothingSizes: this.sizesElements,
        };

        this.businessService.addProductImage(this.selectedFile,
            this.selectedProduct.id, JSON.stringify(payLoad)).subscribe(() => {

            this.loading = false;

            this.displayModalMessage = 'Product color added successfullly!!';
            this.displayModalErrorMessage = false;
            this.displayModalSuccessMessage = true;
            this.notifier.notify(this.displayModalMessage, 'success');
            Utils.delay(Utils.getDelayTime()).then(() => {
                this.resetValues();

                this.modalService.dismissAll();

                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.navigate('edit-product');
                });


            });
        }, error1 => {
            this.loading = false;

            this.displayModalMessage = 'Error adding product color!! Please try again later!!';
            this.displayModalErrorMessage = true;
            this.displayModalSuccessMessage = false;
            this.notifier.notify(this.displayModalMessage, 'error');


            Utils.delay(Utils.getDelayTime()).then(() => {
                this.modalService.dismissAll();
            });

        });
    }

    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

    validateImage() {
        this.loading = true;
        this.displayErrorMessage = false;
        this.displayMessage = '';
        const image = this.defaultUrlImage;
        const reader = new FileReader();
        let img = null;
        img = new Image();
	    this.url = reader.result;
		let messagePrefix = "We have encountered a problem resizing the image.";

	    this.ng2ImgMax.resizeImage(image, 300, 300).subscribe(
            result => {
                this.uploadedImage = result;
                // this.selectedFile = this.uploadedImage;
                reader.readAsDataURL(this.uploadedImage); // read file as data billboardUrl
                reader.onload = () => { // called once readAsDataURL is completed
                    this.url = reader.result;
                    img.src = reader.result; // The data URL

                    img.onload = () => {
                        this.imageWidth = img.width;
                        this.imageHeight = img.height;
                        this.loading = false;
                        if (this.imageWidth < 160) {
                            this.displayErrorMessage = true;
                            this.displayMessage = messagePrefix + ' Required minimum image width is 200px!!!';
							this.notifier.notify(this.displayMessage, 'error');
							this.defaultUrl = undefined;
                            this.defaultUrlImage = undefined;
                            this.url = this.previousUrl;
                            return;
                        }
                    };

                    this.defaultUrlImage = new File([this.uploadedImage], 'untitled.png', {type: 'image/png'});
                };
            },
            () => {
                this.displayErrorMessage = true;
                this.displayMessage = messagePrefix +' Please resize the images. Required minimum image width is 200px!!!';
                this.notifier.notify(this.displayMessage, 'error');
				this.defaultUrl = undefined;
                this.loading = false;
                this.defaultUrlImage = undefined;
                this.url = this.previousUrl;
            },
        );


    }


    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.selectedFile = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            };
        }

    }

    updateDefaultUrl(event) {
	    if (event.target.files && event.target.files[0]) {
            this.defaultUrlImage = event.target.files[0];
            this.validateImage();
	
			const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
			
            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.defaultUrl = event.target.result;
		
            };
        }

    }

    updateDefaultImage() {
        this.loading = true;
        this.businessService.updateDefaultImage(this.defaultUrlImage, this.selectedProduct.id)
            .subscribe((response: HttpResponse<Product>) => {
                this.loading = false;
                this.selectedProduct.defaultImageUrl = response.body.defaultImageUrl;
				this.notifier.notify('Image updated successfully!!!', 'success');
                  
			}, () => {
                this.loading = false;
				this.notifier.notify('Failed to update image. Please try again later!!', 'error');
    		});
    }

    resetValues(): void {
      //  this.color = undefined;
        this.url = null;
        this.selectedFile = undefined;
        this.sizesElements = [];
        this.multiColourProduct = false;
        this.disableColorPicker = false;
    }

    getProductImage(selectedProduct: Product): string {
        return this.activatedImage ? this.activatedImage : selectedProduct.defaultImageUrl;
    }


    activateColorImage(color: ClothingColour) {
        of(color.imageUrl).pipe(
            delay(600),
        ).subscribe(() => {
            this.activatedImage = color.imageUrl;
        });

    }

    onChangeColor(color: string): void {
      this.color = color;
    }


    setMultiColourProduct() {
        if (this.multiColourProduct) {
            this.disableColorPicker = true;
            this.color = '#999999A';
        } else {
            this.color = '';
            this.disableColorPicker = false;

        }
    }
}