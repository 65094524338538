import {Component, OnInit} from '@angular/core';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CustomerOrder} from '../../model/CustomerOrder';
import {CustomerOrderInvoice} from '../../model/CustomerOrderInvoice';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../services/notifier.service';
import {CustomerOrderItem} from '../../model/CustomerOrderItem';

@Component({
  selector: 'myafrica-admin-manage-deliveries',
  templateUrl: './admin-manage-deliveries.component.html',
  styleUrls: ['./admin-manage-deliveries.component.scss']
})
export class AdminManageDeliveriesComponent implements OnInit {

    rowsControls = [];
    rows: CustomerOrder[];
    // contains the list of product item statuses that have been changed. saved when user selects the save button
    changedStatusTrackers = [];

    displayMessage = false;
    message = 'Data has been modified. Remember to save when done!!';
    displaySaveButton = false;
    displayErrorMessage = false;
    displayPrintMessage = false;
    displayPrintErrorMessage = false;

    orderItemStatuses: string[];

    orderStatuses: string[];

    profileId: string;

    iconSearch = faSearch;

    pageTitle = 'Manage Deliveries';

    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    conditionValue = 'outstanding';

    oustandingCheckBoxChecked = true;
    deliveredCheckBoxChecked = false;

    dateFormat: string;

    periodValue = 10;

    orderNo: string;
	deliveryNo: string;

    startDate = new Date();
    endDate: Date;

    startStringDate: string;
    endStringDate: string;
    closeResult: string;

    businessName: string;
    contactNo: string;
    specialInstructions: string;
    loading = false;
    selectedCustomerOrder: CustomerOrder;
    displayPrint = false;
    modalReference: any;

    changeCount: number;
	
	currentOrderStatus: string;

	statusIndex: number;

    constructor(private router: Router,
                private ordersService: OrdersServiceService,
                private appStore: ApplicationStore,
                private modalService: NgbModal,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        this.loading = true;

        this.changeCount = 0;
        this.dateFormat = environment.dateFormat;
        this.defaultDates();
 

    }

    defaultDates() {
        this.startDate = new Date(new Date().setDate(new Date().getDate() - 10));

        this.endDate = new Date();

    }

    setRadioValue(value: number) {
        this.periodValue = value;
    }


 
    clearValues() {
        this.displayPrintMessage = false;
        this.message = '';
        this.displayPrintErrorMessage = false;
    }

    open(content, businessName: any, businessContactNumber: string) {
        this.clearValues();
        this.specialInstructions = '';
        this.businessName = businessName;
        this.contactNo = businessContactNumber;

        this.modalReference = this.modalService.open(content, {
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );

    }

    getWindowSize(): string {
        if (window.matchMedia('(max-width: 767px)').matches) {
            return 'sm';
        } else if (window.matchMedia('(max-width: 889px)').matches) {
            return 'md';
        } else {
            return 'lg';
        }
    }

    openOrderItems(content, selectedOrder: any) {
        this.clearValues();
        this.selectedCustomerOrder = selectedOrder;


        this.modalReference = this.modalService.open(content, {
            size: this.getWindowSize(),
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }


    hasLogo(customerOrder: CustomerOrderItem): boolean {
        return customerOrder.product.defaultImageUrl !== undefined;
    }


    closeModal() {
        this.clearValues();
        this.specialInstructions = '';
        this.modalService.dismissAll();
    }

    setCartItem(object: any): void {

    }

}