import {Component, OnInit} from '@angular/core';
import {PaginationProduct} from '../model/PaginationProduct';
import {BusinessCategory} from '../model/BusinessCategory';
import {CategoryModel} from '../model/CategoryModel';
import {ApplicationStoreService} from '../services/application-store.service';
import {ProductService} from '../services/product.service';
import {TableService} from '../services/table.service';

@Component({
  selector: 'myafrica-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

 items: Array<CategoryModel>;

    allProducts = [];
    loading = false;


    searchedProductName = 'all';

    entryLimitCount = 12; // entries per page
    displayEntryLimitCount = 12; // entries per page
    currentPage = 1;
    totalProducts = 0;


    displayRangeLimit: number;
    private businessCategories: BusinessCategory[];

    orderBy = ' ';
    productCategory = 'all';

    activeCategory: { key: string, value: string };

       itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];

    selectedItem = '';

    orderByList = [{
        id: 0,
        name: 'Sort By',
        sortValue: ' ',
    }, {
        id: 1,
        name: 'Price:High to Low',
        sortValue: 'desc',
    }, {
        id: 2,
        name: 'Price:Low to High',
        sortValue: 'asc',
    }];

  constructor(private appStore: ApplicationStoreService,
                private productService: ProductService,
                private tableService: TableService) { }

  ngOnInit() {
        this.loading = true;
        this.productCategory = 'all';
        this.activeCategory = {
            key: 'all',
            value: 'All',
        };


        this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
            this.businessCategories = value;
            this.populateData();

        });

		if(sessionStorage.getItem('searchProductName')) {
	       this.searchedProductName = sessionStorage.getItem('searchProductName');
		}

		if(sessionStorage.getItem('categoryId')) {
		     this.productCategory = sessionStorage.getItem('categoryId');
		
		}		
	   
        this.currentPage = this.currentPage - 1;
        this.orderBy = 'all';
        this.productService.getPaginationProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy).subscribe((value: any) => {
				this.allProducts = value.content;
                this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
				
			
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, error => {
                this.loading = false;
            },
        );


    }

    setActiveCategory(category, displayText) {
        this.activeCategory = {
            key: category,
            value: displayText,
        };

    }


    searchForProduct() {

        this.loading = true;
        this.currentPage = 0;
        if (this.searchedProductName === '' || this.searchedProductName === undefined || this.searchedProductName === null) {
            this.searchedProductName = 'All';
        }

        this.productService.getPaginationProductsByName(this.searchedProductName.toLowerCase(),
            this.entryLimitCount, this.currentPage,
            this.activeCategory.key, this.orderBy).subscribe((value: any) => {

				this.allProducts = value.content;
                this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );

    }

    previous() {

        this.loading = true;
        this.currentPage = this.currentPage - 1;

        if (this.searchedProductName === '' || this.searchedProductName === undefined || this.searchedProductName === null) {
            this.searchedProductName = 'all';
        }

        this.productService.getPaginationProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy).subscribe((value: any) => {

                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    next() {

        this.loading = true;
        this.currentPage = this.currentPage + 1;
        if (this.searchedProductName === '' || this.searchedProductName === undefined || this.searchedProductName === null) {
            this.searchedProductName = 'all';
        }

        this.productService.getPaginationProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy).subscribe((value: any) => {
				this.allProducts = value.content;
                this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }


    populateData() {
        this.items = [];
        this.businessCategories.forEach(element => {
            this.items.push(new CategoryModel(element.key, element.description));
        });


    }

    setProductsByCategory(key: string, value: string) {
        this.productCategory = key;
    }

    setNumberOfItemsperPage() {
        this.displayEntryLimitCount = this.entryLimitCount;
        this.searchForProduct();
    }
}
