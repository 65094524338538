import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'myafrica-activation-failed',
  templateUrl: './activation-failed.component.html',
  styleUrls: ['./activation-failed.component.scss']
})
export class ActivationFailedComponent implements OnInit {

  supportEmail: string;

  constructor() { }

  ngOnInit() {

     this.supportEmail =  environment.supportEmail;
  }

}
