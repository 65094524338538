import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {LoginResponse} from '../../model/LoginResponse';
import {Observable, of} from 'rxjs';
import {ApplicationStore} from '../../interceptors/application-store.service';

@Injectable()
export class DashboardResolver implements Resolve<LoginResponse> {

    constructor(private appStore: ApplicationStore,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoginResponse> {
        const loginResponse = this.appStore.getJsonItem('loginResponse');
        if (!loginResponse) {
            this.router.navigate(['login']).then();
        }
        return of(loginResponse);
    }

}
