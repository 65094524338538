import {Component, OnInit} from '@angular/core';
import {Product} from '../../../model/Product';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {Utils} from '../../../shared/Utils';
import {ProductService} from '../../../services/product.service';
import {Router} from '@angular/router';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';
import {ClothingSizeCode} from '../../../model/ClothingSizeCode';
import {NotifierService} from '../../../services/notifier.service';
import {ClothingColour} from '../../../model/ClothingColour';


@Component({
    selector: 'myafrica-product-colour-edit',
    templateUrl: './product-colour-edit.component.html',
    styleUrls: ['./product-colour-edit.component.scss'],
})
export class ProductColourEditComponent implements OnInit {


    clothingSizeCodes: ClothingSizeCode[];
    activatedImage: string;
    subs1: Subscription;
    subs2: Subscription;

    url: string;
    selectedProduct: Product;

    selectedFile: File;
    sizesElements: { name, count, id }[];

    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    // message displayed on the modal window
    displayModalMessage = '';
    displayModalErrorMessage: boolean;
    displayModalSuccessMessage: boolean;
    displayDeleteMessage: string;
    displayDeleteErrorMessage: boolean;
    displayDeleteSuccessMessage: boolean;
    loading: boolean;
    multiColourProduct = false;
    disableColorPicker = false;

    myColor: any;
	colours: ClothingColour[];


    constructor(private businessService: BusinessServiceService,
                private location: Location,
                private modalService: NgbModal,
                private appService: ApplicationStore,
                private productService: ProductService, private notifier: NotifierService,
                private router: Router, private tableService: CodeTablesServiceService) {
    }

    ngOnInit() {
        this.sizesElements = [];
        this.activatedImage = undefined;
        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
	    this.subs1 = this.productService.getProductClothingColours(this.selectedProduct
            .id).subscribe(value => {
			this.colours = value.body;
        }, error1 => {
        });

        this.getClothingSizeCodes();
    }

    getClothingSizeCodes() {

        this.subs1 = this.tableService.getClothingSizeCodes().subscribe((value: ClothingSizeCode[]) => {
                this.clothingSizeCodes = value;
            },
        );
    }

    editColor(content, itemColor: any): void {
        this.myColor = itemColor;
        this.sizesElements = [];
        this.myColor.clothingSizes.forEach(element => {

            this.sizesElements.push({name: element.name, count: element.count, id: element.id});

        });

        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then(console.log);
    }

    adMoreElement() {
        this.sizesElements.push({name: 'All', count: 0, id: ""});

    }


    submit(): void {
        this.displayModalErrorMessage = false;
        this.displayModalSuccessMessage = false;

        let elementEmpty = false;
        this.sizesElements.forEach((element) => {
            if (element.name === '' || element.name === ' ') {
                elementEmpty = true;
            }
        });

        if (elementEmpty) {
            this.displayModalMessage = 'Size cannot be empty. Please fill in the size of one of the color(s)!! ';
            this.displayModalErrorMessage = true;
            this.displayModalSuccessMessage = false;
            this.notifier.notify(this.displayModalMessage, 'error');
            return;
        }


        this.loading = true;

        const payLoad = {
            colourName: '',
            clothingSizes: this.sizesElements,
        };

        this.businessService.updateProductClothingColours(
            this.myColor.id, JSON.stringify(payLoad)).subscribe(() => {

            this.loading = false;

            this.displayModalMessage = 'Product sizes counts updated successfullly!!';
            this.displayModalErrorMessage = false;
            this.displayModalSuccessMessage = true;
            this.notifier.notify(this.displayModalMessage, 'success');

            Utils.delay(Utils.getDelayTime()).then(() => {

                this.modalService.dismissAll();

                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.navigate('edit-product');
                });

            });
        }, error1 => {
            console.log(error1);
            this.loading = false;

            this.displayModalMessage = 'Error updating product sizes counts!! Please try again later!!';
            this.displayModalErrorMessage = true;
            this.displayModalSuccessMessage = false;
            this.notifier.notify(this.displayModalMessage, 'error');

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.modalService.dismissAll();
            });

        });
    }

    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

}
