import {Component, Input, OnInit} from '@angular/core';
import {Business} from '../model/Business';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessService} from '../services/business.service';

@Component({
    selector: 'myafrica-about-business',
    templateUrl: './about-business.component.html',
    styleUrls: ['./about-business.component.scss'],
})
export class AboutBusinessComponent implements OnInit {

    @Input() business: Business;
    closeResult: string;
    loading = false;

    currentBusiness: Business;


    constructor(private modalService: NgbModal, private businessService: BusinessService) {
    }

    ngOnInit() {

        this.businessService.getBusinessBankingDetails(this.business.businessId).subscribe((value: Business) => {
                this.currentBusiness = value;
            },
        );

    }

    open(content) {
        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


}
