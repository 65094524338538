<div class="container mb-5">
    <myafrica-page-title pageTitle="{{pageTitle}}"></myafrica-page-title>

    <div class="row ">
        <div class="col-12">
            <form #searchForm="ngForm" (ngSubmit)="searchOrders()" class="border border-light p-2 shadow rounded">
                <div class="form-row p-1 m-4">
                    <div class="col">
                        <label for="OrderNumberInput">Order Number:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="form-control" id="OrderNumberInput"
                               name="orderNumber" [(ngModel)]="orderNo">
                    </div>
                    <div class="col">
                        <button type="submit" class="btn btn-success btn-block">
                            <i  class="fa fa-search" aria-hidden="true"></i>&nbsp;Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="container mb-5">
    <div class="row">
        <div class="col-1 ">
            <p></p>
        </div>
        <div class="col-5 col-md-3 col-lg-3 div-cell">Order No</div>
        <div class="col-2 div-cell d-none d-md-block">Date</div>
        <div class="col-3 div-cell d-none d-md-block">Total</div>

        <div class="col-6 col-md-3 col-lg-3 div-cell">Status</div>
    </div>
</div>

<div class="container" *ngFor="let row of rows; let index = index">
    <div class="row">
        <div class="col-1 div-cell">
            <p>

                <a
                        (click)="rowsControls[index].isCollapsed = !rowsControls[index].isCollapsed"
                        [attr.aria-controls]="'collapse_' + row.id"><i
                        [hidden]="!rowsControls[index].isCollapsed" class="fa fa-plus fa-2"></i>
                    <i [hidden]="rowsControls[index].isCollapsed"
                       class="fa fa-minus fa-2"></i>&nbsp;</a>
            </p>
        </div>
        <div class="col-5 col-md-3 col-lg-3 div-cell">{{row.orderId}}</div>
        <div class="col-2 div-cell d-none d-md-block">{{row.created_Date | date:
            dateFormat}}</div>
        <div class="col-3 div-cell d-none d-md-block">{{row.orderAmount | currency :
            'ZAR'}}</div>

        <div class="col-6 col-md-3 col-lg-3 div-cell">{{row.orderStatus}}</div>
    </div>


    <div id="row collapse_{{ row.id }}"
         [ngbCollapse]="rowsControls[index].isCollapsed">
        <div class="row product-items-div hide-div">
            <div class="card-body col-5 col-md-5 col-lg-5 div-cell">Product Name</div>
            <div class="card-body col-1 col-md-1  col-lg-1 div-cell ">Quantity</div>
            <div class="card-body col-2 col-md-2 col-lg-2 div-cell">Total</div>

            <div class="card-body col-3 col-md-3 col-lg-3 div-cell">Status</div>
            <div class="card-body col-1 col-md-1 col-lg-1 div-cell">Business</div>
        </div>

        <div class="row product-items-div form-group hide-div"
             *ngFor="let itemrow of row.customerOrderItems">
            <div class="card-body col-6 col-md-5 div-cell">{{
                itemrow.product.productName }}</div>
            <div class="card-body col-4 col-md-1 div-cell">{{
                itemrow.quantity }}</div>
            <div class="card-body col-6  col-md-2 div-cell">{{
                itemrow.itemPrice * itemrow.quantity | currency :
                    itemrow.product.currency}}</div>

            <div *ngIf="itemrow.orderItemStatusTracker[0]; else elseBlock" class="card-body col-7 col-md-3 div-cell">
                {{itemrow.orderItemStatusTracker[0].itemStatus}}

            </div>

            <ng-template #elseBlock>
                <div class="card-body col-7 col-md-3 div-cell">
                    N/A
                </div>

            </ng-template>
            <div class="card-body col-1 col-md-1 div-cell">
                <i style="margin-top: -2px;color: #f15a22; cursor: pointer"
                   class="fa fa-info-circle fa-2x" (click)="open(content, itemrow.product.productBusiness.businessId)">&nbsp;</i>
            </div>
        </div>
        <!-- DISPLAYED ON SMALL DEVICES -->
        <div class="container product-items-div d-md-none d-lg-none"
             style="margin-left: 0px" *ngFor="let itemrow of row.customerOrderItems">

            <div class="row d-md-none d-lg-none">
                <div class="col-6 div-cell">
                    <span>Product Name</span>
                </div>
                <div class="col-6 div-cell d-md-none d-lg-none text-center">
                    <img *ngIf="itemrow.product.defaultImageUrl; else elseBlock"
                         class="img-thumbnail" style="height: 80px; width: 80px"
                         src="{{itemrow.product.defaultImageUrl}}"/>
                    <ng-template #elseBlock>
                        <img src="https://myafricaconnect.com/images/image-not-available.jpg"
                             alt="business logo" style="height: 80px; width: 80px"
                             class="img-thumbnail"/></ng-template>
                    {{itemrow.product.productName}}
                </div>
            </div>

            <div class="row d-md-none d-lg-none">
                <div class="col-6 div-cell">
                    <span>Quantity</span>
                </div>
                <div class="col-6 div-cell">{{itemrow.quantity}}</div>
            </div>

            <div class="row d-md-none d-lg-none">
                <div class="col-6 div-cell">
                    <span>Total</span>
                </div>
                <div class="col-6 div-cell">{{itemrow.itemPrice * itemrow.quantity | currency :    itemrow.product.currency}}</div>
            </div>

            <div class="row d-md-none d-lg-none">
                <div class="col-6 div-cell">
                    <span>Status</span>
                </div>
                <div class="col-6 div-cell">{{itemrow.orderItemStatusTracker[0].itemStatus}}</div>
            </div>

            <div class="row d-md-none d-lg-none">
                <div class="col-6 div-cell">
                    <span>Business</span>
                </div>
                <div class="col-6 div-cell"><i style="margin-top: -2px;color: #f15a22; cursor: pointer"
                                               class="fa fa-info-circle fa-2x"
                                               (click)="open(content, itemrow.product.productBusiness.businessId)">&nbsp;</i>
                </div>
            </div>

        </div>

    </div>
</div>
<ng-template #content let-modal>

    <div class="row modal-header m-0">
        <h2 class="form-heading">Business Details</h2>
    </div>
    <div class="modal-body">
        <form class="form-horizontal style-form" #messageform="ngForm"
              name="messageform">
            <div class="row">
                <div class="col-3"><label class="control-label">Name:</label></div>
                <div class="col-9"><label
                        *ngIf="selectedBusiness.businessDetails">{{selectedBusiness.businessName}}</label></div>
            </div>
            <div class="row">
                <div class="col-3"><label class="control-label">Facebook:</label></div>
                <div class="col-9"><label
                        *ngIf="selectedBusiness.businessDetails">{{selectedBusiness.businessDetails.businessFacebookURL}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-3"><label class="control-label">Twitter:</label></div>
                <div class="col-9"><label
                        *ngIf="selectedBusiness.businessDetails">{{selectedBusiness.businessDetails.businessTwitterURL}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-3"><label class="control-label">About Business:</label></div>
                <div class="col-9"><label
                        *ngIf="selectedBusiness.businessDetails">{{selectedBusiness.businessDetails.aboutBusiness}}</label>
                </div>
            </div>

            <button type="button" (click)="modal.close('Save click')"
                    class="btn btn-danger">Cancel
            </button>
        </form>
    </div>
</ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
