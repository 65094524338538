<myafrica-page-title pageTitle="My Cart" tileImage="fa fa-shopping-cart text-warning"></myafrica-page-title>
<div class="container">
    <table class="table table-responsive-sm">
        <thead class="thead-dark">
        <tr>
            <th scope="col">Product Image</th>
            <th scope="col">Product Name</th>
            <th scope="col">Price</th>
            <th scope="col">Colour/Size</th>
            <th scope="col">Quantity</th>
            <th scope="col">
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let cartItem of (shoppingCartItems$ | async) as cartItems">
            <th scope="row">

                <span data-toggle="tooltip" data-placement="right" title="Click to view a bigger image">
                    <img [src]="cartItem.image" class="d-inline img-fluid mx-3 p-2 border border-primary"
                         [ngStyle]="{'max-height':'80px'}" data-toggle="modal"
                         data-target="#viewBigImageModal" (click)="selectCartItem(cartItem)">
                </span>

            </th>
            <td>
                <span class="d-inline h6 cursor-pointer" data-toggle="modal"
                      data-target="#editCartItem"
                      (click)="selectCartItem(cartItem)">{{cartItem.product.productName}}
                    <i class="fa fa-edit text-primary"></i></span>
            </td>
            <td>
                <myafrica-product-price [product]="cartItem.product"></myafrica-product-price>
            </td>
            <td>
                <div>
                    <span class="badge  mr-3 d-inline border border-primary"
                          *ngIf="cartItem.productColour"
                          [ngStyle]="{'background-color': cartItem.productColour}"
                          [ngClass]="cartItem.productColour === '#999999A'?'multicolourdiv':''">&nbsp;&nbsp;&nbsp;</span>

                    <span class="badge border border-dark px-1 ml-2"
                          *ngIf="cartItem.productSize">{{cartItem.productSize}}</span>
                </div>
            </td>
            <td>
                <input class="form-control-sm" type="number" [(ngModel)]="cartItem.quantity"
                       (ngModelChange)="updateCart(cartItem)">
            </td>
            <td>
                <span data-toggle="tooltip" data-placement="right" title="Click to remove item from cart"
                      (click)="removeItem(cartItem)">
                <i class="fa fa-trash fa-1x text-danger cursor-pointer float-right" aria-hidden="true"
                ></i></span>

            </td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
                Total &nbsp;<span
                    class="h6 text-warning font-weight-bold">{{cartTotal | currency:cartCurrency }}</span>

            </td>
            <td>&nbsp;</td>
            <td>&nbsp;<button class="btn btn-info text-white float-right"
                              *ngIf="(shoppingCartItems$ | async).length > 0"
                              (click)="clearCart()"><i
                    class="fa fa-trash text-danger"></i> Clear Cart
            </button>
            </td>
        </tr>
        </tbody>
    </table>

</div>
<hr class="bg-primary">
<div class="container">
    <div class="row">
        <div class="col-12  col-md-5">
            <label class="search-label">How would you like to
                receive your items? &nbsp;</label>
            <i class="fa fa-info-circle text-warning d-inline" data-toggle="modal"
               data-target="#deliveryOptionsModal"></i>

        </div>
        <div class="col-12 col-md-7">
            <div class="row">
                <div class="col-12  px-2 pt-1 rounded my-2 text-white text-justify"
                     [ngStyle]="{'background-color':'green'}">
                    <input id="tab2" type="radio" class="form-radio" (change)="setRadioValue('delivery')"
                           id="last7DaysRadio" checked>
                    <label for="tab2">&nbsp;Have it delivered<i><small>
                        N.B. (Standard Delivery fee is R100.00)</small></i></label>

                </div>
                <div class="col-12  rounded px-2 pt-1 bg-light my-2 text-justify">

                    <input id="tab1" type="radio" class="form-radio" (change)="setRadioValue('collect')"
                           disabled>
                    <label for="tab1">&nbsp;I will collect from the business <span
                            class="text-danger">(Disabled due to lockdown)</span></label>
                </div>
            </div>
        </div>
    </div>
</div>
<hr class="bg-primary">

<div class="container mb-5">
    <div class="row">
        <div class="col-12 col-md-6 my-2">
            <button class="btn btn-info btn-block" (click)="goBack()">
                <span>
                    <img height="15"
                         src="https://myafricaconnect.com/images/add-to-cart36x36.png">&nbsp;Continue Shopping</span>
            </button>
        </div>
        <div class="col-12 col-md-6 my-2">
            <button class="btn btn-info btn-block" (click)="goToDeliveryOptions()"
                    [disabled]="(shoppingCartItems$ | async).length <= 0">
                <span>
                    <img height="15"
                         src="https://myafricaconnect.com/images/add-to-cart36x36.png">&nbsp;Proceed to Checkout</span>
            </button>
        </div>
    </div>
</div>


<myafrica-shopping-cart-modals [cartItem]="selectedCartItem"></myafrica-shopping-cart-modals>

<div class="modal" tabindex="-1" id="editCartItem">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{selectedCartItem?.product.productName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="text-danger">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <img [src]="selectedCartItem?.image" class="img-fluid" style="max-height: 300px">
                <div class="row">
                    <div class="col-12">
                    <span *ngIf="selectedCartItem &&
                    selectedCartItem.product && selectedCartItem.product.colours && selectedCartItem.product.colours.length > 0"
                          class="text-dark h6 ">COLOR: </span>
                        <span *ngFor="let clothingColour of selectedCartItem?.product.colours">
                    <myafrica-clothing-colour [clothingColour]="clothingColour"
                                              (click)="filterSizes(clothingColour)">
                    </myafrica-clothing-colour>

                    </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 py-2">
                        <span *ngIf="clothingSizes && clothingSizes.length > 0" class="text-dark h6 ">SIZES: </span>
                        <span class="d-inline cursor-pointer" (click)="setSizeSelected(productSize)"
                              *ngFor="let productSize of clothingSizes;">
                    <span class="badge"
                          [ngStyle]="{'background-color': productSize.active ? '#00b300':'white'}">
                        {{productSize.name}}
                    </span>
                </span>
                    </div>
                </div>
                <myafrica-increase-cart-items *ngIf="selectedCartItem"
                                              [cartItem]="selectedCartItem"></myafrica-increase-cart-items>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-block" data-dismiss="modal"
                        (click)="updateCartItem()">Update Item
                </button>
                <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
