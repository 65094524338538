import {Component, OnInit} from '@angular/core';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CustomerOrder} from '../../model/CustomerOrder';
import {CustomerOrderInvoice} from '../../model/CustomerOrderInvoice';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../services/notifier.service';
import {CustomerOrderItem} from '../../model/CustomerOrderItem';


@Component({
    selector: 'myafrica-admin-manage-orders',
    templateUrl: './admin-manage-orders.component.html',
    styleUrls: ['./admin-manage-orders.component.scss'],
})
export class AdminManageOrdersComponent implements OnInit {

    rowsControls = [];
    rows: CustomerOrder[];
    // contains the list of product item statuses that have been changed. saved when user selects the save button
    changedStatusTrackers = [];

    displayMessage = false;
    message = 'Data has been modified. Remember to save when done!!';
    displaySaveButton = false;
    displayErrorMessage = false;
    displayPrintMessage = false;
    displayPrintErrorMessage = false;

    orderItemStatuses: string[];

    orderStatuses: string[];

    profileId: string;

    iconSearch = faSearch;

    pageTitle = 'Manage Client Orders';

    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    conditionValue = 'outstanding';

    oustandingCheckBoxChecked = true;
    deliveredCheckBoxChecked = false;

    dateFormat: string;

    periodValue = 10;

    orderNo: string;

    startDate = new Date();
    endDate: Date;
	
	bookingDate = new Date();

    startStringDate: string;
    endStringDate: string;
    closeResult: string;

    businessName: string;
    contactNo: string;
    specialInstructions: string;
    loading = false;
    selectedCustomerOrder: CustomerOrder;
    displayPrint = false;
    modalReference: any;

    changeCount: number;
	
	currentOrderStatus: string;

	statusIndex: number;
	

    constructor(private router: Router,
                private ordersService: OrdersServiceService,
                private appStore: ApplicationStore,
                private modalService: NgbModal,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        this.loading = true;

        this.changeCount = 0;
        this.dateFormat = environment.dateFormat;
        this.defaultDates();
        this.ordersService.getOrderItemStatuses().subscribe((value: string[]) => {
            this.orderItemStatuses = value;
        });

        this.loadDefaultData();

    }

    defaultDates() {
        this.startDate = new Date(new Date().setDate(new Date().getDate() - 10));

        this.endDate = new Date();

    }

    setRadioValue(value: number) {
        this.periodValue = value;
        this.clearOrderNo();
    }


    loadDefaultData() {
        this.profileId = this.appStore.getJsonItem('profileId');

        this.ordersService.getOrderStatusesByProfile(Number(this.profileId)).subscribe((value: string[]) => {
            this.orderStatuses = value;
		    sessionStorage.setItem('orderStatuses', JSON.stringify(this.orderStatuses));

        });

        this.ordersService.getOrdersByProfile('0', this.conditionValue, this.periodValue)
            .subscribe((value: CustomerOrder[]) => {
                this.rows = value;
                this.loading = false;
                this.rows.forEach(() => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });


            }, error1 => {
                this.loading = false;
            });
        //
    }

    clearOrderNo() {
        this.orderNo = '';
    }

    searchOrders() {
        this.displayMessage = false;
        this.displayErrorMessage = false;
        this.displaySaveButton = false;
        this.startStringDate = Utils.getDateString(this.startDate);
        this.endStringDate = Utils.getDateString(this.endDate);

        this.loading = true;
        if (this.orderNo === '' || this.orderNo === undefined) {
            this.ordersService
                .getOrdersByDates(this.startStringDate, this.endStringDate).subscribe((value: CustomerOrder[]) => {
                this.rows = value;
		        this.rows.forEach(() => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });
                this.loading = false;
            }, () => {
                this.loading = false;
                // this.orderNotProcessCount = "0";
            });
        } else {
            this.ordersService.getOrdersByOrderNo(this.orderNo).subscribe((value: CustomerOrder[]) => {
                //         this.rows = JSON.stringify(value.body);
                this.rows = value;
                this.loading = false;
                this.rows.forEach(() => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });


            }, () => {
                this.loading = false;
                // this.orderNotProcessCount = "0";
            });

        }


    }

     setCurrentStatus(orderStatus: string) {
	    this.currentOrderStatus = orderStatus;
	 }


    /**
     * Adds changed product item statuses to the status tracker array
     */
    changeOrderStatus(customerOrder: CustomerOrder) {
	
        this.displayMessage = false;
        this.displayErrorMessage = false;
        let index = 0;
        this.changedStatusTrackers.forEach(row => {
            // remove item from existing array to not have duplicates
            if (customerOrder.customerOrderId === row.customerOrderId) {
                this.changedStatusTrackers.splice(index, 1);
            }
            index++;
        });
        this.displayMessage = true;
        this.message = 'Data has been modified. Remember to save when done!!';
        if (this.changeCount === 0) {
            this.notifier.notify(this.message);
        }

        this.changeCount = this.changeCount + 1;

        this.displaySaveButton = true;
        this.changedStatusTrackers.push(customerOrder);
    }

    /**
     * Uploads the updated order item statuses
     */
    updateStatuses() {
        this.displayMessage = false;
        this.displayErrorMessage = false;
        this.ordersService.updateOrdersStatuses(this.changedStatusTrackers).subscribe(() => {
            this.displayMessage = true;

            this.loadDefaultData();
            this.displaySaveButton = false;
            this.message = 'Record(s) updated successfully!!';
            this.notifier.notify(this.message);

            this.searchOrders();

        }, error1 => {
            this.message = 'Error updating records. Please try again later!!';
            this.displayErrorMessage = true;
            this.notifier.notify(this.message, 'error');

        });

    }


    generateOrderInvoicesReport(modal) {
        this.loading = true;
        const customerInvoice = new CustomerOrderInvoice();
        customerInvoice.specialInstructions = this.specialInstructions;
        customerInvoice.customerOrder = this.selectedCustomerOrder;

        this.ordersService.generateOrderInvoicesReport(customerInvoice,
            this.selectedCustomerOrder.orderId).subscribe(value => {
            this.displayPrintMessage = true;
            this.message = 'Report successfully generated!!';
            this.notifier.notify(this.message);

            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([value], {type: 'application/pdf'});

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            this.loading = false;

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL = window.URL.createObjectURL(newBlob);

            const tab = window.open();
            tab.location.href = fileURL;

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.loading = false;

                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.router.navigate(['dashboard', {outlets: {dashboard: ['manage-orders']}}],
                        {skipLocationChange: true}).then();
                });

            });

        }, () => {
            this.loading = false;
            this.message = 'Error generating report Please try again!!';
            this.displayPrintErrorMessage = true;
            this.notifier.notify(this.message, 'error');

        });

    }


    regenerateOrderInvoicesReport(customerOrder: CustomerOrder) {
        this.clearValues();
        this.loading = true;
        this.selectedCustomerOrder = customerOrder;

        this.ordersService.regenerateOrderInvoicesReport(this.selectedCustomerOrder,
            this.selectedCustomerOrder.orderId).subscribe(value => {
            this.displayPrintMessage = true;
            this.message = 'Report successfully generated!!';
            this.notifier.notify(this.message, 'center');

            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([value], {type: 'application/pdf'});

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            this.loading = false;

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL = window.URL.createObjectURL(newBlob);

            const tab = window.open();
            tab.location.href = fileURL;

        }, () => {
            this.loading = false;
            this.message = 'Error generating report Please try again!!';
            this.displayPrintErrorMessage = true;
            this.notifier.notifyError(this.message);

        });

    }

    clearValues() {
        this.displayPrintMessage = false;
        this.message = '';
        this.displayPrintErrorMessage = false;
    }

   

    getWindowSize(): string {
        if (window.matchMedia('(max-width: 767px)').matches) {
            return 'sm';
        } else if (window.matchMedia('(max-width: 889px)').matches) {
            return 'md';
        } else {
            return 'lg';	
        }
    }

    
	 navigate(path: string) {

	   	 sessionStorage.setItem('navigationOutlet', 'adminclerkdashboard');

        this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}], {skipLocationChange: true}).then();
    }

    editOrder(selectedOrder: any) {
		this.clearValues();
        this.selectedCustomerOrder = selectedOrder;
		sessionStorage.setItem('selectedCustomerOrder', JSON.stringify(selectedOrder));
		
		 this.navigate('edit-order');

    }

    openInvoiceModal(content, customerOrder: CustomerOrder) {
        this.clearValues();
        this.specialInstructions = '';
        this.selectedCustomerOrder = customerOrder;
        this.displayPrint = customerOrder.customerOrderInvoices.length <= 0;

        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

  
    closeModal() {
        this.clearValues();
        this.specialInstructions = '';
        this.modalService.dismissAll();
    }

    setCartItem(object: any): void {

    }

}
