import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {CartItemsService} from '../services/cart-items.service';
import {ApplicationStoreService} from '../services/application-store.service';
import {UserDetails} from '../model/UserDetails';
import {Router} from '@angular/router';
import {faUserAlt} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../environments/environment';
import $ from 'jquery';
import {AppIdleService} from '../shared/services/app-idle.service';
import {ApplicationStore} from '../interceptors/application-store.service';
import {LoginResponse} from '../model/LoginResponse';


@Component({
    selector: 'myafrica-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterContentChecked {
    userIcon = faUserAlt;
    isUserLoggedIn: boolean;
    loggedInUser: UserDetails;
    loginResponse: LoginResponse;

    constructor(private cartService: CartItemsService,
                private appStore: ApplicationStoreService,
                private router: Router, private tokenStore: ApplicationStore,
                private idleAppService: AppIdleService) {
    }

    ngOnInit() {
        $(document).click((event) => {
            const isNavbarOpened = $('.navbar-toggler').attr('aria-expanded');
            if (isNavbarOpened) {
                $('.navbar-collapse').removeClass('show');
            }
        });
    }

    logout() {
        const cart = this.tokenStore.getJsonItem('cart');
        this.tokenStore.signOut();
        this.idleAppService.setUserLoggedIn(false);
        sessionStorage.clear();
        if (cart !== null && cart !== undefined) {
            sessionStorage.setItem('cart', JSON.stringify(cart));
        }

        this.router.navigate(['/']).then();
    }


    register() {
        if (environment.newregenabled) {
            this.router.navigate(['/register-option']).then();
        } else {
            this.router.navigate(['/register']).then();
        }
    }


    ngAfterContentChecked(): void {
        this.isUserLoggedIn = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
        this.loggedInUser = JSON.parse(sessionStorage.getItem('userDetails'));
        this.loginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));

    }

    goHome() {
        this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
            this.navigate('/');
        });
    }

    navigate(path: string) {
        const navigationOutlet = sessionStorage.getItem('navigationOutlet') + '';
        if (navigationOutlet === 'shopperdashboard') {
            this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: ['/shopperdashboard']}}]).then();
        } else if (navigationOutlet === 'adminclerkdashboard') {
            this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: ['/adminclerkdashboard']}}]).then();
        } else {
            this.router.navigate(['dashboard', {outlets: {dashboard: ['/dashboard']}}]).then();

        }
    }


}
