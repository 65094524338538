import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Subscription} from 'rxjs';
import {CartItemsService} from '../../services/cart-items.service';
import {CartItem} from '../../model/CartItem';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {md5} from '../../shared/services/md5generator';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'myafrica-eft-place-order-cancelled',
    templateUrl: './eft-place-order-cancelled.component.html',
    styleUrls: ['./eft-place-order-cancelled.component.scss'],
})
export class EftPlaceOrderCancelledComponent implements OnInit, OnDestroy {

    cartItems: CartItem[] = [];
    cartTotal = 0;
    subs: Subscription;
    subs2: Subscription;
    loading: boolean;
    displayErrorMessage: boolean;
    totalCost = 0;
    cartCurrency = 'ZAR';
    deliveryCost = 100;
    productFees: string;
    instantPayment = true;

    modalReference: any;
    closeResult: string;
    emailAddress = '';
    deliveryMethod = 'delivery';


    constructor(private appStore: ApplicationStore, private cartService: CartItemsService, private tableService: CodeTablesServiceService,
                private router: Router, private ordersService: OrdersServiceService, private modalService: NgbModal) {
    }


    ngOnInit() {
        this.cartTotal = 0;
        const cart = this.appStore.getJsonItem('cart');
        if (cart === null || cart === undefined) {

            this.router.navigate(['']);
            return;
        }
        this.cartItems = cart.map(cartItem => {
            const result = cartItem;
            this.cartTotal = this.cartTotal + (result.product.price * result.quantity);
            return result;
        });

        this.subs2 = this.tableService.getProductFees().subscribe((value: any) => {
                this.productFees = value;
                const productFeesObject = JSON.parse(JSON.stringify(this.productFees));

                this.deliveryCost = productFeesObject.courierFee;
                this.totalCost = this.cartTotal + this.deliveryCost;
            },
        );

    }


    updateOrderStatus() {
        this.loading = true;
        const orderNo = this.appStore.getItem('ORDER_NO');
        this.subs = this.ordersService.updateOrderStatusToPaid(orderNo).subscribe(value => {
                this.loading = false;
                this.displayErrorMessage = false;
            }, error1 => {
                this.loading = false;
                this.displayErrorMessage = true;

            },
        );

    }

    isCustomerCollecting() {
        if (this.appStore.getItem('DELIVERY_METHOD') === 'collect') {
            return true;
        }
        return false;

    }


    open(content) {
        this.modalReference = this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
        this.modalReference.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });

    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    setInstantPayment(option: boolean) {
        this.instantPayment = option;
    }


    calculatePrices() {

        this.cartTotal = 0;
        const cart = this.appStore.getJsonItem('cart');
        let totalNumerOfItems = 0;
        this.cartItems = cart.map(cartItem => {
            const result = cartItem;
            this.cartTotal = this.cartTotal + (result.product.price * result.quantity);
            totalNumerOfItems = totalNumerOfItems + result.quantity;
            return result;
        });

        this.cartService.updateCount(totalNumerOfItems);
        this.totalCost = this.cartTotal + this.deliveryCost;
    }

    instantEFTPayment() {

        const item_name_without_spaces = ('ORDER ' + this.appStore.getItem('ORDER_NO')).replace(/\s+/g, '+');

        const appAddress = environment.mainUrl;

        const merchant_id = environment.merchant_id;
        const merchant_key = environment.merchant_key;
        const payfast_url = environment.payfast_url;

        const return_url = appAddress + '/shopping/payment/sucesss';
        const cancel_url = appAddress + '/shopping/payment/cancelled';
        const notify_url = appAddress + '/payment/testnotify';

        const generatedId = this.generateRandomString() + '-' + this.generateDateTime();

        this.calculatePrices();

        const tempurlParameters = 'merchant_id=' + encodeURIComponent(merchant_id) + '&' +
            'merchant_key=' + encodeURIComponent(merchant_key) + '&' +
            'return_url=' + encodeURIComponent(return_url) + '&' +
            'cancel_url=' + encodeURIComponent(cancel_url) + '&' +
            'notify_url=' + encodeURIComponent(notify_url) + '&' +
            'email_address=' + encodeURIComponent(this.emailAddress) + '&' +
            'm_payment_id=' + encodeURIComponent(generatedId) + '&' +
            'amount=' + encodeURIComponent(this.totalCost) + '&' +
            'item_name=' + encodeURIComponent(item_name_without_spaces);

        const tempsignature = md5(tempurlParameters);

        const urlPostParameters = tempurlParameters + '&signature=' + tempsignature;

        window.location.href = payfast_url + urlPostParameters;

    }

    /**
     * Generate a time in the following format yyyyMMddHHMMSS
     @returns
     */
    generateDateTime() {
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        const hourMMSS = '' + d.getHours() + d.getMinutes() + d.getSeconds();


        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return year + month + day + hourMMSS;

    }

    placeOrder() {
        this.appStore.storeItem('DELIVERY_METHOD', this.deliveryMethod);

        if (this.deliveryMethod === 'delivery') {
            this.router.navigate(['/shopping/view-delivery-options']).then();
        } else {
            this.router.navigate(['view/collect-option']).then();
        }

    }

    /**
     * Generates random string with a length of 2
     * @returns
     */
    generateRandomString() {
        const STRING_LENGTH = 8;
        let text = '';
        const char_list = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = 0; i < STRING_LENGTH; i++) {
            text += char_list.charAt(Math.floor(Math.random() * char_list.length));
        }
        return text;
    }


    goBack() {
        this.appStore.deleteItem('cart');

        this.router.navigate(['']);

    }

    ngOnDestroy() {
        this.appStore.deleteItem('cart');
        this.cartService.updateCount(0);
    }


}
