<myafrica-breadcrumbs title="Shopper Agreement"></myafrica-breadcrumbs>


<div class="container mb-2">
	<div>
		<div class="form-group">
			<div class="col-12 field-control">

				<span>Welcome to MyAfricaConnect {{userName}}, before you proceed please read and accept  our terms and conditions, and tick the checkbox to agree</span>
			</div>
		</div>
		
			<div class="container my-5 pb-3 shadow">
			    <myafrica-page-title pageTitle="Terms & Conditions"></myafrica-page-title>
			
			    <div class="accordion" id="accordionTerms">
			        <div class="card">
			            <div class="card-header bg-primary text-white display-4" id="headingIntro">
			                <h2 class="mb-0">
			                    <button class="btn text-white display-4 btn-block text-center" type="button" data-toggle="collapse"
			                            data-target="#collapseIntro" aria-expanded="true" aria-controls="collapseIntro">
			                        Introduction
			                    </button>
			                </h2>
			            </div>
			
			            <div id="collapseIntro" class="collapse" aria-labelledby="headingIntro" data-parent="#accordionTerms">
			                <div class="card-body">
			                    <p class="text-justify text-uppercase">PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN
			                        IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
			                        OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, A
			                        CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND
			                        OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS. IN
			                        PARTICULAR, BUSINESS OWNERS SHOULD UNDERSTAND HOW THE LAWS WORK IN
			                        THEIR RESPECTIVE CITIES OR COUNTRIES AND FIELDS OF BUSINESS, SOME
			                        CITIES OR COUNTRIES HAVE LAWS THAT RESTRICT THEIR ABILITY TO RUN A
			                        BUSINESS WITHOUT A PERMIT. THESE LAWS ARE OFTEN PART OF A
			                        CITY&rsquo;S OR COUNTRIES ZONING OR ADMINISTRATIVE CODES IN MANY
			                        COUNTRIES BUSINESSES MUST REGISTER, GET A PERMIT, OR OBTAIN A
			                        LICENSE BEFORE OPERATING OR DOING BUSINESS, SOME BUSINESSES MAY BE
			                        PROHIBITED ALTOGETHER,GORVENMENTS DIFFER IN LAWS ON HOW THEY
			                        INFORCE THIS LAWS, PENALTIES MAY INCLUDE FINES OR OTHER
			                        ENFORCEMENTS,BUSINESSES SHOULD REVIEW LAW CONSTITUTIONS BEFORE
			                        ENLISTING ON MYAFRICACONNECT</p>
			                    <br>
			                    <ol>
			                        <li>
			                            This website can be accessed at www.myafricaconnect.com
			                            (&quot;the Website&quot;) and is made available by
			                            MyAfricaConnect(Proprietary) Limited, registration number &quot;2015/167845/07
			                            &quot; , a limited liability private company incorporated in the
			                            Republic of South Africa Gauteng (&quot;the Company&quot;,
			                            &quot;we&quot;, &quot;us&quot; and &quot;our&quot;)
			                            <ol type="i">
			                                <li>These Website Terms and Conditions (&quot;Terms and
			                                    Conditions&quot;) govern the sale of Goods (as defined below) and
			                                    the use of the Website.
			                                </li>
			                                <li>These Terms and Conditions are
			                                    binding and enforceable against every person (&quot;you&quot;;
			                                    &quot;your&quot; or &quot;user&quot;) that accesses or uses this
			                                    Website or registers as a user as contemplated below. By using the
			                                    Website and by clicking on the &quot;Register &quot; button on the
			                                    Website, as may be applicable, you acknowledge that you have read
			                                    and agree to be bound by these Terms and Conditions.
			                                </li>
			                                <li>The Website enables you to shop online for an extensive range of
			                                    goods including jewellery and beauty products, books, music, toys
			                                    and pet supplies ("Goods") etc..and not limited to the fore mentioned products.
			                                </li>
			                            </ol>
			                        </li>
			                    </ol>
			
			                </div>
			            </div>
			        </div>
			        <div class="card">
			            <div class="card-header bg-primary text-white display-4" id="headingGeneral">
			                <h2 class="mb-0">
			                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
			                            data-target="#collapseGeneral" aria-expanded="false" aria-controls="collapseGeneral">
			                        General
			                    </button>
			                </h2>
			            </div>
			            <div id="collapseGeneral" class="collapse" aria-labelledby="headingGeneral" data-parent="#accordionTerms">
			                <div class="card-body">
			                    <ol>
			                        <li>The Company may, in its sole discretion, at any time and for any
			                            reason and without prior written notice, suspend or terminate the
			                            operation of the Website or the user&rsquo;s right to use the
			                            Website or any of its contents subject to us processing any orders
			                            then already made by you.
			                        </li>
			                        <li>You may not cede, assign or
			                            otherwise transfer your rights and obligations in terms of these
			                            Terms and Conditions to any third party
			                        </li>
			                        <li>Any failure on
			                            the part of you or the Company to enforce any right in terms hereof
			                            shall not constitute a waiver of that right.
			                        </li>
			                        <li>If any
			                            term or condition contained herein is declared invalid, the
			                            remaining terms and conditions will remain in full force and
			                            effect.
			                        </li>
			                        <li>No variation, addition, deletion, or agreed
			                            cancellation of the Terms and Conditions will be of any force or
			                            effect unless in writing and accepted by or on behalf of the
			                            parties hereto.
			                        </li>
			                        <li>No indulgence, extension of time,
			                            relaxation or latitude which any party (&quot;the grantor&quot;)
			                            may show grant or allow to the other (&quot;the grantee&quot;)
			                            shall constitute a waiver by the grantor of any of the
			                            grantor&rsquo;s rights and the grantor shall not thereby be
			                            prejudiced or stopped from exercising any of its rights against the
			                            grantee which may have arisen in the past or which might arise in
			                            the future.
			                        </li>
			                        <li>These Terms and Conditions contain the
			                            whole agreement between you and the Company and no other warranty
			                            or undertaking is valid, unless contained in this document between
			                            the parties.
			                        </li>
			                    </ol>
			                </div>
			            </div>
			        </div>
			        <div class="card">
			            <div class="card-header bg-primary text-white display-4" id="headingDisclaimer">
			                <h2 class="mb-0">
			                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
			                            data-target="#collapseDisclaimer" aria-expanded="false" aria-controls="collapseDisclaimer">
			                        Disclaimer
			                    </button>
			                </h2>
			            </div>
			            <div id="collapseDisclaimer" class="collapse" aria-labelledby="headingDisclaimer"
			                 data-parent="#accordionTerms">
			                <div class="card-body">
			                    <ol type="A">
			                        <li>The use of the Website is entirely at your own risk and you
			                            assume full responsibility for any risk or loss resulting from use
			                            of the Website or reliance on any information on the Website.
			                        </li>
			                        <li>Whilst the Company takes reasonable measures to ensure that the
			                            content of the Website is accurate and complete, the Company makes
			                            no representations or warranties, whether express or implied, as to
			                            the quality, timeliness, operation, integrity, availability or
			                            functionality of the Website or as to the accuracy, completeness or
			                            reliability of any information on the Website.
			                        </li>
			                        <li>The
			                            Company disclaims liability for any damage, loss or liability,
			                            whether direct, indirect or consequential in nature, arising out of
			                            or in connection with your access to or use of the Website and/or
			                            any content therein unless otherwise provided by law.
			                        </li>
			                        <li>Although Goods sold from the Website may, under certain
			                            specifically defined circumstances, be under warranty, the Website
			                            itself and all information provided on the Website is provided
			                            &quot;as is&quot; without warranty of any kind, either express or
			                            implied, including, but not limited to, the implied warranties of
			                            merchantability, fitness for a particular purpose, completeness, or
			                            non-infringement, as may be allowed in law.
			                        </li>
			                        <li>Any views
			                            or statements made or expressed on the Website are not necessarily
			                            the views of the Company, its directors, employees and/or agents.
			                        </li>
			                        <li>In addition to the disclaimers contained elsewhere in these
			                            Terms and Conditions, the Company also makes no warranty or
			                            representation, whether express or implied, that the information or
			                            files available on the Website are free of viruses, spyware,
			                            malware, trojans, destructive materials or any other data or code
			                            which is able to corrupt, destroy, compromise, disrupt, disable,
			                            harm, jeopardise or otherwise impede in any manner the operation,
			                            stability, security functionality or content of your computer
			                            system, computer network, hardware or software in any way.
			                        </li>
			                        <li>You
			                            accept all risk associated with the existence of such viruses,
			                            destructive materials or any other data or code which is able to
			                            corrupt, compromise, jeopardise, disrupt, disable, harm or
			                            otherwise impede in any manner the operation or content of a
			                            computer system, computer network, any handset or mobile device, or
			                            your hardware or software, save where such risks arise due to the
			                            gross negligence or wilful misconduct of the Company, its
			                            employees, agents or authorised representatives. The Company thus
			                            disclaims all liability for any damage, loss or liability of any
			                            nature whatsoever arising out of or in in connection with your
			                            access to or use of the Website.
			                        </li>
			                    </ol>
			
			                </div>
			            </div>
			        </div>
			        <div class="card">
			            <div class="card-header bg-primary text-white display-4" id="headingPrivacy">
			                <h2 class="mb-0">
			                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
			                            data-target="#collapsePrivacy" aria-expanded="false" aria-controls="collapsePrivacy">
			                        Privacy Policy
			                    </button>
			                </h2>
			            </div>
			            <div id="collapsePrivacy" class="collapse" aria-labelledby="headingPrivacy" data-parent="#accordionTerms">
			                <div class="card-body">
			                    <ol>
			                        <li>We respect your privacy and will take reasonable measures to
			                            protect it, as more fully detailed below.
			                        </li>
			                        <li>Should you
			                            decide to register as a user on the Website, we may require you to
			                            provide us with personal information which includes but is not
			                            limited to -
			                            <ol type="i">
			                                <li>your name and surname;</li>
			                                <li>your
			                                    email address;
			                                </li>
			                                <li>your physical address;</li>
			                                <li>your
			                                    gender;
			                                </li>
			                                <li>your date
			                                    of birth
			                                </li>
			                                <li>your mobile number; and</li>
			                            </ol>
			                        </li>
			                        <li>Should your personal information change,
			                            please inform us and provide us with updates to your personal
			                            information as soon as reasonably possible to enable us to update
			                            your personal information.
			                        </li>
			                        <li>This Website makes use of
			                            &quot;cookies&quot; to automatically collect information and data
			                            through the standard operation of the Internet servers.<br>
			                            <h4>Cookies</h4>
			                            <p>these are small text files a website can use (and which we may use) to
			                                recognise repeat users, facilitate the user&rsquo;s on-going access
			                                to and use of a website and allow a website to track usage
			                                behaviour and compile aggregate data that will allow the website
			                                operator to improve the functionality of the website and its
			                                content. The type of information collected by cookies is not used
			                                to personally identify you. If you do not want information
			                                collected through the use of cookies, there is a simple procedure
			                                in most browsers that allows you to deny or accept the cookie
			                                feature. Please note that cookies may be necessary to provide you
			                                with certain features available on our Website, and thus if you
			                                disable the cookies on your browser you may not be able to use
			                                those features, and your access to our Website will therefore be
			                                limited.&nbsp;If you do accept a &quot;cookie&quot;, you thereby
			                                consent to our use of any personal information collected by us
			                                using that cookie subject to the provisions of clause 7.7 below.</p></li>
			                        <li>You may choose to provide additional personal information to us,
			                            in which event you agree to provide accurate and current
			                            information, and not to impersonate or misrepresent any person or
			                            entity or falsely state or otherwise misrepresent your affiliation
			                            with anyone or anything.
			                        </li>
			                        <li>The purposes for which we will
			                            use your personal information are as follows:
			                            <ol>
			                                <li>in
			                                    relation to the sale and delivery of Goods;
			                                </li>
			                                <li>to contact
			                                    you regarding current or new products or services (provided you
			                                    have consented to receiving such marketing material);
			                                </li>
			                                <li>o inform you of new features, special offers and promotional
			                                    competitions (provided you have consented to receiving such
			                                    marketing material); and
			                                </li>
			                                <li>to improve your experience on
			                                    our Website by, inter alia, monitoring statistical non-personal
			                                    browsing habits, and to transact with us.
			                                </li>
			                            </ol>
			                        </li>
			                        <li>The Company will
			                            not use your personal information for any purpose (other than as
			                            stated above) without your express consent. We will not use or
			                            disclose your personal information to third parties without your
			                            consent, unless the use or disclosure is -
			                            <ol type="i">
			                                <li>required in
			                                    order to comply with applicable law, order of court or legal
			                                    process served on the Company; and/or
			                                </li>
			                                <li>disclosure is
			                                    necessary to protect and defend the rights or property of the
			                                    Company.
			                                </li>
			                            </ol>
			                        </li>
			                        <li>We will be entitled to disclose your personal
			                            information to those of our employees and/or third party service
			                            providers who assist us to interact with you via our Website or
			                            email, for the sale of Goods or when delivering Goods to you, and
			                            thus need to know your personal information in order to assist us
			                            to communicate with you properly and efficiently. We will ensure
			                            that all such employees and/or third party service providers having
			                            access to your personal information are bound by appropriate and
			                            legally binding confidentiality and non-use obligations in relation
			                            to your personal information.
			                        </li>
			                        <li>We will -
			                            <ol type="i">
			                                <li>treat
			                                    your personal information as strictly confidential;
			                                </li>
			                                <li>take appropriate technical and organisational measures to ensure
			                                    that your personal information is kept secure and is protected
			                                    against unauthorised or unlawful processing, accidental
			                                    loss, destruction or damage, alteration, disclosure or access;
			                                </li>
			                                <li>provide you with access to your personal information to view
			                                    and/or update personal details;
			                                </li>
			                                <li>promptly notify you if
			                                    we become aware of any unauthorised use, disclosure or processing
			                                    of your personal information;
			                                </li>
			                                <li>provide you with
			                                    reasonable evidence of our compliance with our obligations under
			                                    this policy on reasonable notice and request; and
			                                </li>
			                                <li>upon
			                                    your request, promptly return or destroy any and all of your
			                                    personal information in our possession or control.
			                                </li>
			                                <li>We will
			                                    not retain your personal information longer than the period for
			                                    which it was originally needed, unless we are required by law to do
			                                    so, or you consent to us retaining such information for a longer
			                                    period.
			                                </li>
			                            </ol>
			                        </li>
			                        <li>The Company undertakes never to sell or make
			                            your personal information available to any third party unless we
			                            are compelled to do so by law. In particular, in the
			                            event of a fraudulent online payment, the Company reserves the
			                            right to disclose relevant personal information for criminal
			                            investigation purposes or in line with any other legal obligation
			                            for disclosure of the personal information which may be required of
			                            it. Whilst we will do all things reasonably necessary to protect
			                            your rights of privacy, we cannot guarantee or accept any liability
			                            whatsoever for unauthorised or unlawful disclosures of your
			                            personal information, whilst in our possession, made by third
			                            parties who are not subject to our control, unless such disclosure
			                            is as a result of our gross negligence. If you disclose
			                            your personal information to a third party, such as an entity which
			                            operates a website linked to this Website or anyone other than the
			                            Company, THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE,
			                            HOWSOEVER ARISING, SUFFERED BY YOU AS A RESULT OF THE DISCLOSURE OF
			                            SUCH INFORMATION TO THE THIRD PARTY. This is because we do not
			                            regulate or control how that third party uses your personal
			                            information. You should always ensure that you read the privacy
			                            policy of any third party.
			                        </li>
			                        <li value=""><h4>Limitation of liability</h4>
			                            <p>The Company cannot be held liable for any inaccurate information
			                                published on the Website and/or any incorrect prices displayed on
			                                the Website, save where such liability arises from the gross
			                                negligence or wilful misconduct of the Company, its employees,
			                                agents or authorised representatives. You are encouraged to contact
			                                us to report any possible malfunctions or errors by way of email to
			                                info@myafricaconnect.com.<br><br> THE COMPANY SHALL NOT BE
			                                LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
			                                CONSEQUENTIAL LOSS OR DAMAGES WHICH MIGHT ARISE FROM YOUR USE OF,
			                                OR RELIANCE UPON, THE WEBSITE OR THE CONTENT CONTAINED IN THE
			                                WEBSITE; OR YOUR INABILITY TO USE THE WEBSITE, AND/OR UNLAWFUL
			                                ACTIVITY ON THE WEBSITE AND/OR ANY LINKED THIRD PARTY WEBSITE.<br><br>
			                                YOU HEREBY INDEMNIFY THE COMPANY AGAINST ANY LOSS, CLAIM OR
			                                DAMAGE WHICH MAY BE SUFFERED BY YOURSELF OR ANY THIRD PARTY ARISING
			                                IN ANY WAY FROM YOUR USE OF THIS WEBSITE AND/OR ANY LINKED THIRD
			                                PARTY WEBSITE.</p></li>
			                    </ol>
			                </div>
			            </div>
			        </div>
			        <div class="card">
			            <div class="card-header bg-primary text-white display-4" id="headingNotices">
			                <h2 class="mb-0">
			                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
			                            data-target="#collapseNotices" aria-expanded="false" aria-controls="collapseNotices">
			                        Important Notice
			                    </button>
			                </h2>
			            </div>
			            <div id="collapseNotices" class="collapse" aria-labelledby="headingNotices" data-parent="#accordionTerms">
			                <div class="card-body">
			                    <ol>
			                        <li>These Terms and Conditions apply to users who are consumers for
			                            purposes of the Consumer Protection Act, 68 of 2008 (&quot;the
			                            CPA&quot;).
			                        </li>
			                        <li>These Terms and Conditions contain
			                            provisions that appear in similar text and style to this clause and
			                            which -
			                            <ol type="i">
			                                <li>may limit the risk or liability of the Company
			                                    or a third party; and/or
			                                </li>
			                                <li>may create risk or liability
			                                    for the user; and/or
			                                </li>
			                                <li> may compel the user to indemnify
			                                    the Company or a third party; and/or
			                                </li>
			                                <li>serves as an
			                                    acknowledgement, by the user, of a fact.
			                                </li>
			                            </ol>
			                        </li>
			                        <li>our attention
			                            is drawn to these Terms and Conditions because they are important
			                            and should be carefully noted.
			                        </li>
			                        <li>If there is any
			                            provision in these Terms and Conditions that you do not understand,
			                            it is your responsibility to ask the Company to explain it to you
			                            before you accept the Terms and Conditions or continue using the
			                            Website.
			                        </li>
			                        <li>Nothing in these Terms and Conditions is
			                            intended or must be understood to unlawfully restrict, limit or
			                            avoid any right or obligation, as the case may be, created for
			                            either you or the Company in terms of the CPA.
			                        </li>
			                        <li>The
			                            Company permits the use of this Website subject to the Terms and
			                            Conditions. By using this Website in any way, you shall be deemed
			                            to have accepted all the Terms and Conditions unconditionally. You
			                            must not use this Website if you do not agree to the Terms and
			                            Conditions.
			                        </li>
			
			                    </ol>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>

		<div class="form-group">
			<div class="col-12 field-control">
				<label> <input type="checkbox" name="terms" (click)="activateButton()"
					class="form-check-input" [(ngModel)]="termsAccepted">I
					agree that I have read and accept the <a
					class="mylink">Merchant
						Agreement Terms and conditions</a>
				</label>
			</div>
		</div>

		<button class="btn bg-success text-white text-center" type="submit"  [disabled]="!buttonDisabled"
			(click)="registerUser()">Submit</button>
	</div>
</div>