import {Component, OnDestroy, OnInit} from '@angular/core';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';
import {Business} from '../../../model/Business';
import {Bank} from '../../../model/Bank';
import {BankAccountType} from '../../../model/BankAccountType';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {HttpResponse} from '@angular/common/http';
import {BusinessBankingDetail} from '../../../model/BusinessBankingDetail';
import {Subscription} from 'rxjs';
import {NotifierService} from '../../../services/notifier.service';


@Component({
  selector: 'myafrica-business-banking-details',
  templateUrl: './business-banking-details.component.html',
  styleUrls: ['./business-banking-details.component.scss']
})
export class BusinessBankingDetailsComponent implements OnInit, OnDestroy {

  supportedBanks: Bank[];

  bankAccountTypes: BankAccountType[];

  businessBankingDetails: BusinessBankingDetail;

  bankAccountTypeId: string;

  bankId: string;

  accountNumber: number;

  selectedBusiness: Business;

  bankingDetailsId: string;

  displayMessage: string;
  displayErrorMessage: boolean;
  displaySuccessMessage: boolean;

  subs1: Subscription;
  subs2: Subscription;
  subs3: Subscription;
  subs4: Subscription;

  bankAccountTypeIdClass = true;

  bankIdClass  = true;

  accountNumberClass  = true;


  constructor(private tableService: CodeTablesServiceService, private businessService: BusinessServiceService,
			           private notifier: NotifierService) {
  }

  ngOnInit() {
       this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
	   this.supportedBanks = JSON.parse(sessionStorage.getItem('banks'));
		 if (this.supportedBanks.length > 0) {
			this.bankId = this.supportedBanks[0].id;
		 }
	 
    
    this.subs2 = this.tableService.getBankAccountTypes().subscribe((value: BankAccountType[]) => {
        this.bankAccountTypes = value;
		      if (this.bankAccountTypes.length > 0) {
				this.bankAccountTypeId = this.bankAccountTypes[0].id;
		      }
      }
    );

    this.getBusinessBankingDetails();
  }

  getBusinessBankingDetails() {
	this.subs3 = this.businessService.getBusinessBankingDetails(this.selectedBusiness.id).subscribe((value: any) => {
         if(value) {
	         this.businessBankingDetails = value;
			 this.defaultData();
		     this.dataChanged();
		 }
	   }
    );

  }

  defaultData() {
    if (this.businessBankingDetails != null && this.businessBankingDetails.id) {
      this.bankingDetailsId = this.businessBankingDetails.id;
      this.accountNumber = this.businessBankingDetails.accountNumber;
      this.bankId = this.businessBankingDetails.bank.id;
      this.bankAccountTypeId = this.businessBankingDetails.bankAccountType.id;

    }

  }


	dataChanged() {
	if (this.bankAccountTypeId) {
		this.bankAccountTypeIdClass = false;
	} else {
		this.bankAccountTypeIdClass = true;
	}

	if (this.bankId) {
		this.bankIdClass = false;
	} else {
		this.bankIdClass = true;
	}

	if (this.accountNumber === undefined || this.accountNumber === 0) {
		this.accountNumberClass = true;
	} else {
		this.accountNumberClass = false;
	}

	}


  updateBusinessBankingDetails() {
    const businessBankingDetails = {
      id: this.bankingDetailsId,
      accountNumber: this.accountNumber,
      bank: {
        id: this.bankId
      },
      bankAccountType: {
        id: this.bankAccountTypeId
      }
    };


    if (sessionStorage.getItem('userDetails')) {
      this.subs4 = this.businessService.updateBankingDetails(this.selectedBusiness.id + '', businessBankingDetails).subscribe((value: HttpResponse<any>) => {

        this.displayMessage = 'Successfully updated banking details!!!';
        this.notifier.notify(this.displayMessage, 'success');
	    if (value) {
			this.businessBankingDetails = value.body;
			this.defaultData();
        }


      }, error => {
	    this.displayMessage = 'Failed to update banking details. Please try again later!!!';
        this.notifier.notify(this.displayMessage, 'error');
      });
    } else {
        this.notifier.notify('Error saving record!! Please try again later!!', 'error');
    }

  }

  ngOnDestroy() {
    this.doSubs(this.subs1);
    this.doSubs(this.subs2);
    this.doSubs(this.subs3);
    this.doSubs(this.subs4);
  }

  doSubs(subs: Subscription) {
    if (subs) {
      subs.unsubscribe();
    }
  }
}

