import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {LoginDetails} from '../model/LoginDetails';
import {Observable} from 'rxjs';
import {LoginResponse} from '../model/LoginResponse';

@Injectable({
    providedIn: 'root',
})
export class LoginService {

    constructor(private http: HttpClient) {
    }

    public login(loginDetails: LoginDetails): Observable<LoginResponse> | Observable<any> {

        return this.http.post(`myafrica-api/login`, loginDetails,
            {
                observe: 'response',
            });
    }

    public refreshAccessToken(refreshToken: string): Observable<HttpResponse<any>> {
        const headersData = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + refreshToken,
        });

        return this.http.get(
            `myafrica-api/refresh/token`, {headers: headersData, observe: 'response'});

    }
}
