import { Component, OnInit, Input } from '@angular/core';
import {Business} from '../../../model/Business';

@Component({
  selector: 'myafrica-business-details-tabs',
  templateUrl: './business-details-tabs.component.html',
  styleUrls: ['./business-details-tabs.component.scss']
})
export class BusinessDetailsTabsComponent implements OnInit {

 @Input() selectedBusiness: Business;

  currentJustify = 'justify';

  constructor() { }

  ngOnInit() {
  }

}
