import {Component, OnDestroy, OnInit} from '@angular/core';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';
import {Province} from '../../../model/Province';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {HttpResponse} from '@angular/common/http';
import {Business} from '../../../model/Business';
import {BusinessAddress} from '../../../model/BusinessAddress';
import {Subscription} from 'rxjs';
import {NotifierService} from '../../../services/notifier.service';

@Component({
  selector: 'myafrica-business-address-details',
  templateUrl: './business-address-details.component.html',
  styleUrls: ['./business-address-details.component.scss']
})
export class BusinessAddressDetailsComponent implements OnInit, OnDestroy {

  provinces: Province[];
  selectedBusiness: Business;

  businessAddress: BusinessAddress;

  businessId: string;
  businessProvinceId: string;
  businessProvince: Province;

  addressId: string;
  addressline1: string;
  streetname: string;
  city: string;
  zipcode: string;

  displayMessage: string;
  displayErrorMessage: boolean;
  displaySuccessMessage: boolean;
  subs1: Subscription;
  subs2: Subscription;
  subs3: Subscription;

  addressline1Class = true;
  streetnameClass = true;
  cityClass = true;
  zipcodeClass = true;
  businessProvinceClass = true;

  constructor(private tableService: CodeTablesServiceService,
              private businessService: BusinessServiceService,
			           private notifier: NotifierService) {
  }

  ngOnInit() {
   	this.provinces = JSON.parse(sessionStorage.getItem('provinces'));
	this.provinces.forEach(element => {
		if (element.name.toLowerCase() === 'GAUTENG'.toLowerCase()) {
			this.businessProvince = element;
			this.businessProvinceId = element.id;
		}
	});

    this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
    this.businessId = this.selectedBusiness.businessId + '';
	this.defaultData();
	
  }

  getBusinessAddress() {
    this.subs2 = this.businessService.getBusinessAddress(this.businessId).subscribe((value: BusinessAddress) => {
        this.businessAddress = value;
        this.defaultData();
		      this.dataChanged();
      }
    );

  }

  defaultData() {
    if (this.selectedBusiness.businessAddress != null && this.selectedBusiness.businessAddress.id) {
      this.addressId = this.selectedBusiness.businessAddress.id;
      this.addressline1 = this.selectedBusiness.businessAddress.complex;
      this.streetname = this.selectedBusiness.businessAddress.streetname;
      this.city = this.selectedBusiness.businessAddress.city;
      this.zipcode = this.selectedBusiness.businessAddress.zipCode;
   	  
	   if(this.selectedBusiness.businessProvince) {
		  this.businessProvinceId =  this.selectedBusiness.businessProvince.id;
		
	   }
    }

  }

 dataChanged() {
	this.setBusinessProvince(this.businessProvinceId);
	if (this.addressline1 === undefined || this.addressline1 === '' || this.addressline1 === null) {
		this.addressline1Class = true;
	} else {
		this.addressline1Class = false;
	}
	if (this.streetname === undefined || this.streetname === '' || this.streetname === null) {
		this.streetnameClass = true;
	} else {
		this.streetnameClass = false;
	}

	if (this.city === undefined || this.city === '' || this.city === null) {
		this.cityClass = true;
	} else {
		this.cityClass = false;
	}

	if (this.zipcode === undefined || this.zipcode === '' || this.zipcode === null) {
		this.zipcodeClass = true;
	} else {
		this.zipcodeClass = false;
	}

	if (this.businessProvince) {
		this.businessProvinceClass = false;
	} else {
		this.businessProvinceClass = true;
	}

  }


	setBusinessProvince(provinceId: string) {
		if(this.provinces) {
			this.provinces.forEach(element => {
				if (element.id === provinceId) {
					this.businessProvince = element;
				}
		 });
		}
	}

  updateBusinessAddress() {
    const addressJSONObject = {
      id: this.addressId,
      complex: this.addressline1,
      streetName: this.streetname,
      city: this.city,
      province: this.businessProvince,
      zipCode: this.zipcode
    };
    if (sessionStorage.getItem('userDetails')) {
	   this.subs3 = this.businessService.updateBusinessAddress(this.selectedBusiness.id, addressJSONObject).subscribe((value: HttpResponse<any>) => {

        this.displayMessage = 'Successfully updated business address!!!';
      	 this.notifier.notify(this.displayMessage, 'success');

        if (this.addressId === '') {

          this.getBusinessAddress();
        }
      }, error1 => {
	     this.displayMessage = 'Failed to update business address. Please try again later!';
        this.notifier.notify(this.displayMessage, 'error');

      });
    } else {
		this.notifier.notify('Error saving record!! Please try again later!!', 'error');
    }
  }

  ngOnDestroy() {
    this.doUnsub(this.subs1);
    this.doUnsub(this.subs2);
    this.doUnsub(this.subs3);
  }

  doUnsub(subs: Subscription) {
    if (subs) {
      subs.unsubscribe();
    }
  }


}
