import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ProfileService} from '../../shared/services/profile.service';

@Component({
  selector: 'myafrica-vendor-agreement',
  templateUrl: './vendor-agreement.component.html',
  styleUrls: ['./vendor-agreement.component.scss']
})
export class VendorAgreementComponent implements  OnInit, OnDestroy {

  termsAccepted: boolean;
  userName: string;
  buttonDisabled: boolean;
  email: string;
  subscription: Subscription;

  displayErrorMessage: boolean;
  displaySuccessMessage: boolean;
  loading = false;
  loginErrorMessage: string;
  loginSuccessMessage: string;
  termsAlreadyAccepted: boolean;
  
  

  constructor( private profileService: ProfileService,
				           private router: Router, ) {
  }

  ngOnInit() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
	this.userName = userDetails.userName;
	this.email = userDetails.email;
	this.termsAlreadyAccepted = userDetails.termsAccepted;
	this.termsAccepted = true;
	this.buttonDisabled = false;

  }

  activateButton() {
	if (this.termsAccepted) {
		this.buttonDisabled = false;
	} else {
		this.buttonDisabled = true;
	}
  }

  registerUser() {

   const userDetails = {
      email: this.email
    };

   this.subscription = this.profileService.acceptSellerAgreement(userDetails).subscribe((value: HttpResponse<any>) => {
		const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
		
		userDetails.termsAccepted = true;
		sessionStorage.setItem('userDetails', JSON.stringify(userDetails));

        this.router.navigate(['/dashboard']);

    }, error1 => {


      this.loginErrorMessage = 'An error occurred activating the account. Please try again later!';

	     this.displayErrorMessage = true;
      this.displaySuccessMessage = false;

    });


  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
