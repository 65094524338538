import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Business} from '../model/Business';

@Injectable({
    providedIn: 'root',
})
export class BusinessItemService {

    constructor(private http: HttpClient) {
    }

    getBusinessDetails(businessId: number): Observable<Business> | Observable<any> {
        return this.http.get(`myafrica-api/views/${businessId}/viewbusiness`, {observe: 'response'});
    }


    sendBusinessMessage(message, businessId) {
        return this.http.post(
            `myafrica-api/public/${businessId}/sendmessage`, message,
            {observe: 'response'},
        );
    }
}
