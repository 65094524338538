<div class="container my-5">
    <myafrica-advert-slides class="mt-3"></myafrica-advert-slides>

</div>
<div class="container">
    <myafrica-page-title pageTitle="All Businesses"></myafrica-page-title>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4 mb-2" *ngFor="let business of businesses">
                <myafrica-business-item [business]="business"></myafrica-business-item>
            </div>
        </div>
    </div>
</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
