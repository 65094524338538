// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'https://localhost:8090',
  mainUrl: 'http://127.0.0.1:4200',
  supportEmail: 'support@myafricaconnect.com',
  dateFormat: 'yyyy-MM-dd',
  payfast_url: 'https://sandbox.payfast.co.za/eng/process?',
  merchant_id: '10007267',
  merchant_key: 'sgm71spqqy194',
  newregenabled: true,
  apiPrefix: 'myafrica-api',
  apiAdminPrefix: 'myafrica-api-admin',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
