import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Business, businessWithUpdatedImages} from '../../model/Business';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApplicationStore} from '../../interceptors/application-store.service';

@Injectable()
export class BusinessDetailsResolver implements Resolve<Business> {

    constructor(private appStore: ApplicationStore, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Business> {
        const business = this.appStore.getJsonItem('selectedBusiness');
        if (!business) {
            this.router.navigate(['home']).then();
        }
        return of(businessWithUpdatedImages(business));
    }

}
