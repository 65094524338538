<div class="container my-1 py-1">
    <hr class="bg-success shadow">
    <div class="row justify-content-center ">
        <h1 class="text-center  text-primary px-1 position-sticky">
            <i *ngIf="tileImage"
               class="{{tileImage}}"></i>&nbsp;{{pageTitle}}
        </h1>
    </div>
    <hr class="bg-warning shadow">
</div>
