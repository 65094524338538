import {AfterContentInit, Component, OnInit} from '@angular/core';
import {CartItem} from '../../model/CartItem';
import {ClothingSize} from '../../model/ClothingSize';
import {Product} from '../../model/Product';
import {Observable, of} from 'rxjs';
import {Business, businessWithUpdatedImages} from '../../model/Business';
import {ActivatedRoute, Router} from '@angular/router';
import {ClothingColour} from '../../model/ClothingColour';
import {delay, map} from 'rxjs/operators';
import {CartManagementInput} from '../../model/CartManagementInput';
import {ApplicationStoreService} from '../../services/application-store.service';
import {ProductService} from '../../services/product.service';
import {CartItemsService} from '../../services/cart-items.service';
import {HistoryService} from '../../services/history.service';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'myafrica-clothing',
    templateUrl: './clothing.component.html',
    styleUrls: ['./clothing.component.scss'],
})
export class ClothingComponent implements OnInit, AfterContentInit {
    displayErrorMessage: any;
    selectedImageUrl: string;
    defaultImageUrl: string;

    clothingSizes: ClothingSize[];
    selectedProduct: Product;
    cartInput: CartManagementInput;

    displaySuccessMessage: boolean;

    displayMessage = '';

    imageUrl: string;

    loading: boolean;
    similarProducts: Product[];
    productBusiness: Business;
    htmlContent: any;
    cartItem: CartItem;
    stockCount: Observable<number>;

    constructor(private cartService: CartItemsService,
                private router: Router,
                private productService: ProductService,
                private appStore: ApplicationStoreService,
                private historyService: HistoryService,
                private route: ActivatedRoute) {
    }


    ngOnInit() {
	this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
	
       this.clothingSizes = [];
       this.makeCalls();
		this.productService.getAppProduct(this.selectedProduct
            .id).subscribe((value: HttpResponse<Product>) => {
		    this.selectedProduct = value.body;
			sessionStorage.setItem('currentProduct', JSON.stringify(this.selectedProduct));
			this.productBusiness = this.selectedProduct.productBusiness;
			this.loading = false;
			this.setColoursData();
			this.setDefaultClothingSize();
			
	    }, error1 => {
	    });

    }


   setColoursData() {
	   if(this.selectedProduct.colours) {
			 if (this.selectedProduct.colours.length > 0) {
			   let count = 0;
				this.defaultImageUrl = this.selectedProduct.defaultImageUrl;
				this.selectedProduct.colours[0].clothingSizes.forEach(innerElement => {
					const newColour = new ClothingSize();
					newColour.id = innerElement.id;
					newColour.name = innerElement.name;
					newColour.count = innerElement.count;
					newColour.active = false;
					this.clothingSizes[count] = newColour;
					count++;
			
				});

			} else {
				this.imageUrl = this.selectedProduct.defaultImageUrl;
			}
	   }
		
   }

    setDefaultClothingSize() {
	    if (this.clothingSizes.length > 0) {
            this.clothingSizes = this.filterSizesWithNoStock(this.clothingSizes);
            if (this.clothingSizes[0]) {
                this.clothingSizes[0].active = true;
                this.cartItem.productSize = this.clothingSizes[0].name;
                this.cartItem.productSizeId = this.clothingSizes[0].id;
                this.stockCount = of(this.clothingSizes[0].count);
                this.cartItem.maxCount = this.clothingSizes[0].count;

            }
        }
    }

    filterSizes(colour: ClothingColour) {

        this.activateColorImage(colour);

        this.selectedProduct.colours.forEach(element => {
            if (element.id === colour.id) {
                this.clothingSizes = [];
                let count = 0;
                element.clothingSizes.forEach(innerElement => {

                    const newColour = new ClothingSize();

                    newColour.id = innerElement.id;
                    newColour.name = innerElement.name;
                    newColour.count = innerElement.count;
                    newColour.active = false;

                    this.clothingSizes[count] = newColour;
                    count++;
                });
            }
        });

        this.setDefaultClothingSize();
    }

    activateColorImage(color: ClothingColour) {
        this.selectedImageUrl = color.imageUrl;
        of(color.imageUrl).pipe(delay(1000)).subscribe(() => {
            this.imageUrl = color.imageUrl;
            this.cartItem.image = color.imageUrl;
            this.cartItem.productColour = color.colourName;
        });

    }


    // Filter the product sizes to return those that have stock available
    filterSizesWithNoStock(clothingSizes: ClothingSize[]) {
        return clothingSizes.filter(size => size.count > 0);
    }

    setSizeSelected(productSize: ClothingSize, index: number) {

        for (let i = 0; i < this.clothingSizes.length; i++) {
            if (i === index) {
                this.clothingSizes[i].active = true;
                this.cartItem.productSize = this.clothingSizes[i].name;
                this.cartItem.productSizeId = this.clothingSizes[i].id;
                this.cartItem.quantity = 1;
                this.stockCount = of(this.clothingSizes[i].count);
                this.cartItem.maxCount = this.clothingSizes[i].count;

            } else {
                this.clothingSizes[i].active = false;
            }
        }

    }


    checkOut() {

        const cart = JSON.parse(sessionStorage.getItem('cart'));

        if (cart === undefined || cart == null || cart.length <= 0) {
            this.displaySuccessMessage = true;
            this.displayMessage = 'No items added to the cart. Please add quantity to create cart!!';
            return;
        }

        this.router.navigate(['/shopping/view-cart']).then();
    }


    updateCartCount(): void {
        this.cartItem.quantity++;
    }


    reloadPage(product?: Product) {

        of(this.appStore.deleteItem('currentProduct')).subscribe(() => {
            this.appStore.storeStringItem('currentProduct', product);
            window.location.reload();
            this.selectedProduct = product ? product : this.appStore.getJsonItem('currentProduct');
            this.cartItem.product = this.selectedProduct;
            this.selectedImageUrl = this.selectedProduct.defaultImageUrl;
            this.stockCount = of(this.clothingSizes[0].count);
            this.cartItem.maxCount = this.clothingSizes[0].count;
            this.makeCalls();
        });

    }

    private makeCalls(): void {

        this.loading = true;
        this.productService.updateProductViewCount(this.selectedProduct.id);
      /*  this.route.data.pipe(map(data => data.allCalls)).subscribe((res: Array<any>) => {
            this.similarProducts = res[0].filter(prd => prd.id !== this.selectedProduct.id);
            this.htmlContent = res[1].body ? res[1].body.history : null;
            this.productBusiness = businessWithUpdatedImages(res[2]);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
	*/
    }


    ngAfterContentInit() {
        this.cartItem = new CartItem();
        this.cartItem.product = this.selectedProduct;
        this.cartItem.quantity = 1;
        this.cartItem.image = this.selectedImageUrl ? this.selectedImageUrl : this.selectedProduct.defaultImageUrl;
        this.cartItem.productColour = this.selectedProduct.colours[0].colourName;
       
    }

}
