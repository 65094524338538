import {Component, OnInit} from '@angular/core';
import {Business, businessWithUpdatedImages} from '../model/Business';
import {BusinessService} from '../services/business.service';
import {PaginationBusiness} from '../model/PaginationBusiness';
import {BusinessCategory} from '../model/BusinessCategory';
import {CategoryModel} from '../model/CategoryModel';
import {TableService} from '../services/table.service';
import {BusinessItemService} from '../services/business-item.service';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'myafrica-all-businesses',
    templateUrl: './all-businesses.component.html',
    styleUrls: ['./all-businesses.component.scss'],
})
export class AllBusinessesComponent implements OnInit {


    loading: boolean;
    businessNameSearch: string;

    businesses: Business[];

    paginationBusiness: PaginationBusiness[];

    totalNumberOfItems: number;
    businessCategory = 'all';

    private businessCategories: BusinessCategory[];
    items: Array<CategoryModel>;

    entryLimitCount = 30; // entries per page
    displayEntryLimitCount = 30; // entries per page
    productCategoryMap: Map<string, string>;

    productCategory: string;
    activeCategory: { key: string, value: string };

    currentPage = 1;

    constructor(private tableService: TableService,
                private businessItemService: BusinessItemService,
                private businessService: BusinessService,
                private router: Router) {
    }

    ngOnInit() {

        this.loading = true;
        this.activeCategory = {
            key: 'all',
            value: 'All',
        };

        if (this.businessNameSearch === '' || this.businessNameSearch === undefined || this.businessNameSearch === null) {
            this.businessNameSearch = 'All';
        }

        this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
                this.businessCategories = value;
                this.populateData();
            },
        );

        this.businessService.getAdminPaginationBusinessByName('all', this.entryLimitCount, this.currentPage - 1,
            '0', 'asc', '').subscribe((value: HttpResponse<PaginationBusiness[]>) => {

            this.paginationBusiness = value.body;
            this.businesses = this.paginationBusiness[0].businesses.map(businessWithUpdatedImages);
            this.loading = false;
        }, () => this.loading = false);
    }

    populateData() {
        this.items = [];
        this.businessCategories.forEach(element => {
            this.items.push(new CategoryModel(element.key, element.description));
        });

        this.productCategoryMap = new Map<string, string>();
        this.productCategoryMap.set('all', 'All');
        this.items.forEach(element => {
            this.productCategoryMap.set(element.key, element.value);

        });

    }

    setActiveCategory(category, displayText) {
        this.activeCategory = {
            key: category,
            value: displayText,
        };

    }

    getKeys(): Array<string> {
        if (!this.productCategoryMap) {
            this.productCategoryMap = new Map<string, string>();
        }
        return Array.from(this.productCategoryMap.keys());
    }

    getKeysSmall(): Array<string> {
        if (!this.productCategoryMap) {
            this.productCategoryMap = new Map<string, string>();
        }
        return Array.from(this.productCategoryMap.keys());
    }


    showBusinessDetails(item?, item2?) {
        this.businessItemService.getBusinessDetails(item).subscribe(value => {
                sessionStorage.setItem('selectedBusiness', JSON.stringify(value));
                this.router.navigate(['business-details']).then();
                window.scrollTo(0, 0);
            },
        );
    }

    searchForBusiness() {
        let categoryId = '0';
        if (this.businessNameSearch == null || this.businessNameSearch === '') {
            this.businessNameSearch = 'all';
        }

        if (this.activeCategory.key === undefined || this.activeCategory.key === null
            || this.activeCategory.key === 'all' || this.activeCategory.key === 'undefined') {
            categoryId = '0';
        } else {
            this.businessCategories.forEach(element => {
                if (element.key === this.activeCategory.key) {
                    categoryId = element.categoryId;
                }
            });
        }


        this.businessService.getAdminPaginationBusinessByName(this.businessNameSearch, this.entryLimitCount, this.currentPage - 1,
            categoryId, 'asc', 'filter').subscribe((value: PaginationBusiness[]) => {
                this.paginationBusiness = value;
                this.businesses = this.paginationBusiness[0].businesses;
            },
        );
    }

    searchBusinessByCategory() {

        this.businessService.getAdminPaginationBusinessByName('all', this.entryLimitCount, this.currentPage - 1,
            this.activeCategory.key, 'asc', 'filter').subscribe((value: PaginationBusiness[]) => {
                this.paginationBusiness = value;
                this.businesses = this.paginationBusiness[0].businesses;
            },
        );
    }

    setBusinessByCategory(key: string, value: string) {
        this.businessCategory = key;
    }


}
