<myafrica-breadcrumbs title="Addresses"></myafrica-breadcrumbs>

<myafrica-page-title pageTitle="Address Book"></myafrica-page-title>

<div class="container my-5">
	<div class="row mb-2">
		<div class="col-md-4">
			<button type="button" class="btn btn-primary w-100" id="addAddress"
				(click)="addAddress()">
				<i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;Add Address
			</button>

		</div>

		<div class="col-md-8"></div>
	</div>



	<div class="card bg-light  mb-3" *ngFor="let address of userAddresses">
		<div class="card-body cursor-pointer" (click)="editAddress(address)">
			<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
				<span>
					<h6 class="mb-1">{{address.addressline1}}</h6>
				</span>
				<h6>{{address.streetname}}, {{address.city}},
					{{address.zipcode}}</h6>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
				 <span data-toggle="tooltip" data-placement="right" title="Click to edit the address"
	                 >
                	<i class="fa fa-edit fa-2x cursor-pointer" aria-hidden="true"
                ></i></span>
			</div>	
			</div>
		</div>
	</div>

</div>

