import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'myafrica-reg-success',
  templateUrl: './reg-success.component.html',
  styleUrls: ['./reg-success.component.scss']
})
export class RegSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
