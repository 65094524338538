<div id="navWrap">
    <nav>
        <myafrica-mega-menu></myafrica-mega-menu>
    </nav>
</div>
<div class="container">
    <br>
    <myafrica-page-title pageTitle="Selected Product"></myafrica-page-title>
    <div class="container" *ngIf="productBusiness && selectedProduct">
        <myafrica-product-info [product]="selectedProduct"
                               [productBusiness]="productBusiness"></myafrica-product-info>
    </div>

    <br>
    <myafrica-product-history-view [htmlContent]="htmlContent"></myafrica-product-history-view>
    <br>
    <myafrica-page-title pageTitle="Similar Products"></myafrica-page-title>

    <div class="row" *ngIf="allProducts">
        <div class="col-12 col-md-3"
             *ngFor="let product of allProducts" (click)="reloadPage()">
            <myafrica-product-item [product]="product"></myafrica-product-item>
        </div>
    </div>
</div>
<br />
<myafrica-footer></myafrica-footer>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
