<div class="container col-12 form-group required ">
	<form class="form-horizontal " #additionalDetailsForm="ngForm">



		<div class="form-group row">
			<div class="col-12">
				<h3 class="form-heading">Business Details</h3>
			</div>
		</div>

       <myafrica-capture-required-fields-message></myafrica-capture-required-fields-message>
 		<div class="form-row">
			<div class="form-group col-md-6">
				<label class="control-label">Business Name</label> <input
					type="text"
					[ngClass]="[ businessNameClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="businessName" required [(ngModel)]="businessName"
					[maxlength]="100" (ngModelChange)="dataChanged()">
			</div>
			<div class="form-group col-md-6">
				<label class="control-label">Email</label> <input type="email"
					[ngClass]="[ businessEmailClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="businessEmail" required [(ngModel)]="businessEmail"
					[maxlength]="100" (ngModelChange)="dataChanged()">
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-md-4">
				<label class="control-label">Contact Number</label> <input
					type="text"
					[ngClass]="[ businessContactNumberClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="businessContactNumber" required
					[(ngModel)]="businessContactNumber" numbersOnly [maxlength]="10"
					(ngModelChange)="dataChanged()">
			</div>
			<div class="form-group col-md-4">
				<label class="control-label">CIPRO Reg. Number</label> <input
					type="text"
					[ngClass]="[ ciproRegNumberClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="ciproRegNumber" required [(ngModel)]="ciproRegNumber"
					[maxlength]="20" (ngModelChange)="dataChanged()">
			</div>
			<div class="form-group col-md-4">
				<label class="control-label">Years In Business</label> <input
					type="text"
					[ngClass]="[ yearsInBusinessClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="yearsInBusiness" [maxlength]="12" numbersOnly
					[(ngModel)]="yearsInBusiness" (ngModelChange)="dataChanged()"
					required>
			</div>

		</div>


<div class="form-row">
			<div class="form-group col-md-12">
			<label class="control-label">Describe your business</label>
				<textarea type="text" class="form-control input-lg"
					name="aboutBusiness" rows="6" [(ngModel)]="aboutBusiness"></textarea>
	
	</div></div>
	<div class="form-row">
                        <div class="form-group col">
                          <label>Monthly Revenue</label>
                             <div class="bg-light" *ngFor="let monthlyRevenue of businessMonthlyRevenues">
					            <label><input name="monthlyRevenue" type="radio" (click)='setMonthlyRevenueId(monthlyRevenue.id)'
					                     [checked]="monthlyRevenue.id === monthlyRevenueId"     value="monthlyRevenue.id">&nbsp;{{monthlyRevenue.description}}</label>
					          </div>
                            
                          
                    </div>
                  </div>
                 <div class="form-row" >
                        <div class="form-group col">
                            <label >Do you have a physical store?</label>
                         	 <div class="row bg-light">
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="physicalStore"  (change)="setPhysicalStore('Yes')"
					                       [(ngModel)]="physicalStore"  value="Yes" id="physicalStoreRadio" ><label
					                    class="search-label">&nbsp;Yes, I have a store </label>
					
					            </div>
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="physicalStore" 
					                [(ngModel)]="physicalStore" value="No" (change)="setPhysicalStore('No')">
					                <label class="search-label">&nbsp;No, I sell through other channels</label>
					            </div>
					
					        </div>
                        
                        </div>
                    </div>

                       
                <div class="form-row" style="display: flex;">
                        <div class="form-group col-12">
                            <label for="carryStock">Do you carry stock?</label>
                         	 <div class="row bg-light">
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="carryStock"  (change)="setCarryStock('Yes')"
					                       [(ngModel)]="carryStock" value="Yes" id="carryStockRadio"><label
					                    class="search-label">&nbsp;Yes</label>
					
					            </div>
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="carryStock" 
					                [(ngModel)]="carryStock" value="No" (change)="setCarryStock('No')">
					                <label class="search-label">&nbsp;No</label>
					            </div>
					
					        </div>
                        
                        </div>
                    </div>       
                       


		<div class="form-row">
			<div class="form-group col-md-6">
				<label class="control-label">Website URL</label> 
				<input type="url" class="form-control" name="businessWebsiteURL"
					[(ngModel)]="businessWebsiteURL" [maxlength]="255">
			</div>
			<div class="form-group col-md-6">
				<label class="control-label">Facebook URL</label> 
				<input type="url" class="form-control" name="businessFacebookURL"
					[(ngModel)]="businessFacebookURL" [maxlength]="255">
			</div>
		</div>

	<div class="form-row">
			<div class="form-group col-md-6">
				<label class="control-label">Instagram URL</label> 
				<input type="url" class="form-control" name="businessInstagramURL"
					[(ngModel)]="businessInstagramURL" [maxlength]="255">
			</div>
			<div class="form-group col-md-6">
				<label class="control-label">Twitter URL</label> 
				<input type="url" class="form-control" name="businessTwitterURL"
					[(ngModel)]="businessTwitterURL" [maxlength]="255">
			</div>
		</div>
		
		<div class="modal-footer">
			<button type="button" (click)="updateBusinessDetails()"
				[disabled]="!additionalDetailsForm.form.valid"
				class="btn btn-success">Update</button>
		</div>

	</form>
</div>
