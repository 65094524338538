<myafrica-page-title pageTitle="About Us"></myafrica-page-title>
<div class="container mt-md-5 text-justify">
    <ul class="nav nav-tabs justify-content-center " id="myTab" role="tablist">
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link active" id="philosophy-tab" data-toggle="tab" href="#philosophy" role="tab"
               aria-controls="philosophy" aria-selected="true">Why</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="vision-tab" data-toggle="tab" href="#vision" role="tab" aria-controls="vision"
               aria-selected="false">Purpose</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="mission-tab" data-toggle="tab" href="#mission" role="tab" aria-controls="mission"
               aria-selected="false">Mission</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="values-tab" data-toggle="tab" href="#values" role="tab" aria-controls="values"
               aria-selected="false">Values</a>
        </li>
    </ul>
    <div class="tab-content p-3" id="myTabContent">
        <div class="tab-pane fade show active" id="philosophy" role="tabpanel" aria-labelledby="philosophy-tab">
        <p class="tab-text">
         We believe in empowering & enabling individuals to access high quality products and services that tell a story, it is the reason we exist.
         </p></div>
        
        <div class="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision-tab">
        <p class="tab-text">We imagine a World & a future where people are connected to authentic brands, products & services that add value to the lives of any individual.</p></div>
        <div class="tab-pane fade" id="mission" role="tabpanel" aria-labelledby="mission-tab">
        <p class="tab-text">Enable African brands and service providers to be globally accessible.</p></div>
        <div class="tab-pane fade" id="values" role="tabpanel" aria-labelledby="values-tab">
            <ul class="">
                <li><span class="tab-text">Inclusive </span>
                </li>
                <li><span class="tab-text">Impactful</span>
                </li>
                <li><span class="tab-text">Transparent</span>
                </li>
                <li><span class="tab-text">Reliable</span>
                </li>
                <li><span class="tab-text">We listen</span>
                </li>
            	<li><span class="tab-text">We keep our promise</span>
                </li>
                <li><span class="tab-text">Trust</span>
                </li>
                <li><span class="tab-text"> Dependable </span>
                </li>
            </ul>
        </div>
    </div>
</div>

<myafrica-page-title pageTitle="Features"></myafrica-page-title>

<section class="section-div">
    <div class="container shadow">

        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
                <img class="img-fluid img-center " alt=""
                     src="https://myafricaconnect.com/images/list-business144x144.png">
                <h4>List your business</h4>
                <p class="feature-content">List multiple businesses through
                    registering.</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
                <img class="img-fluid img-center" alt=""
                     src="https://myafricaconnect.com/images/dash-manag144x144.png">
                <h4>Dashboard Management</h4>
                <p class="feature-content">Manage multiple businesses and Data
                    management.</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
                <img class="img-fluid img-center"
                     src="https://myafricaconnect.com/images/inventory-manag144x144.png" alt="">
                <h4>Inventory Management</h4>
                <p class="feature-content">Manage product inventory.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
                <img class="img-fluid img-center"
                     src="https://myafricaconnect.com/images/geo-locate144x144.png" alt="">
                <h4>GeoLocator</h4>
                <p class="feature-content">Add locations of multiple
                    businesses/stores.</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
                <img class="img-fluid img-center"
                     src="https://myafricaconnect.com/images/deliver144x144.png" alt="">
                <h4>Packaging and Deliveries</h4>
                <p class="feature-content">Guaranteed Delivery and nationwide courier service.</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
                <img class="img-fluid img-center"
                     src="https://myafricaconnect.com/images/stats144x144.png" alt="">
                <h4>Stats Reporting</h4>
                <p class="feature-content">Get instant statistics and assess
                    business daily operational statistics in the fly.</p>
            </div>
        </div>
    </div>
</section>

<myafrica-page-title pageTitle="Benefits"></myafrica-page-title>
<div class="container">
    <div class="row">
        <p class="p-3 m-3 bg-success text-justify text-white rounded shadow">Your business is always open to
            trade in the global market
            place 24/7.
            Find reliable African businesses and
            products online. Easily run and manage your business online with your mobile device. List products and
            connect to other businesses online. MyAfricaConnect offers Online SEO, Digital Marketing to a new market
            segment to increase your reach. </p>
    </div>
</div>
<myafrica-page-title pageTitle="How it works"></myafrica-page-title>
<div class="container mb-5">
    <section>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col col-md-6">
                        <div class="media">
                            <img class="text-center mr-3 rounded-circle shadow" src="https://myafricaconnect.com/images/1144x144.png">
                            <div class="media-body">
                                <h5 class="text-warning">Click on Register</h5>
                                <p class="text-muted">Provide business owner's/proxy's required information
                                    to be able
                                    to continue to
                                    list businesses on the application.</p>
                            </div>
                        </div>

                        <div class="media">
                            <img class="text-center mr-3 rounded-circle shadow" src="https://myafricaconnect.com/images/2144x144.png">
                            <div class="media-body">
                                <h5 class="text-warning">Activate Account</h5>
                                <p class="text-muted">You will receive an activation e-mail. Click on the
                                    link to
                                    activate.
                                    Once account activation is complete the application will automatically redirect you
                                    to the login
                                    page.</p>
                            </div>
                        </div>

                        <div class="media ">
                            <img class="text-center mr-3 rounded-circle shadow" src="https://myafricaconnect.com/images/3144x144.png"
                                 alt="Generic placeholder image">
                            <div class="media-body">
                                <h5 class="text-warning">Provide Login Details</h5>
                                <p class="text-muted">Sign-in with the e-mail that you used for
                                    registration and the
                                    same password you
                                    used when registering.
                                    Then click on SIGN-IN to access the Connected African online world.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 pull-right col-md-6 d-none d-md-inline">
                        <div class="col-12">
                            <img src="https://myafricaconnect.com/images/guy-on-pc.png" class="img-fluid text-center"
                                 alt="guy-on-chair">
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-12 pull-left col-md-6 d-none d-md-inline">
                        <div class="col-12">
                            <img src="https://myafricaconnect.com/images/how-it-works-laptop.png" class="img-fluid text-center"
                                 alt="laptop">
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                        <div class="media">
                            <div class="media-body">
                                <h5 class="mt-0 text-warning">Manage Businesses</h5>
                                <p class="text-muted">Click ADD Business, and provide required information. Also add
                                    products by
                                    clicking ADD Products on the same page. </p>
                            </div>
                            <img class="align-self-center mr-3 rounded-circle shadow"
                                 src="https://myafricaconnect.com/images/4144x144.png">
                        </div>

                        <div class="media ">
                            <div class="media-body">
                                <h5 class="mt-0 text-warning">Start Selling</h5>
                                <p class="text-muted">Congratulations we now welcome you to a world of limitless online
                                    African
                                    shopping experience.</p>
                            </div>
                            <img class="align-self-center mr-3 rounded-circle shadow"
                                 src="https://myafricaconnect.com/images/5144x144.png">
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </section>
</div>
