import {Component, OnInit} from '@angular/core';
import {forkJoin, Subscription} from 'rxjs';
import {ProductService} from '../services/product.service';
import {TableService} from '../services/table.service';
import {HomeService} from '../services/home.service';
import {SessionManagerService} from '../services/session-manager.service';
import {ApplicationStoreService} from '../services/application-store.service';
import {Carousel} from '../model/Carousel';
import {NotifierService} from '../services/notifier.service';
import {Business, businessWithUpdatedImages} from '../model/Business';
import {Product} from '../model/Product';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';


@Component({
    selector: 'myafrica-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

    activeCarousels = new Array<Carousel>();
    businesses: Business[];
    marketingSlides = new Array<any>();

    products = new Array<Product>();
    categories = new Array<any>();

    showNavigationArrows = false;
    showNavigationIndicators = false;
    loading = false;
    subscription: Subscription;

    constructor(private homeService: HomeService,
                private userSessionManager: SessionManagerService,
                private productsService: ProductService,
                private tableService: TableService,
                private appStore: ApplicationStoreService,
                private notifier: NotifierService,
                private meta: Meta, private router: Router,
                private title: Title) {

        this.meta.addTags([
            {name: 'description', content: 'Proudly african products'},
            {name: 'author', content: 'myafrica'},
            {
                name: 'keywords',
                content: 'Africa, Connect, MyAfrica, My Africa Connect, African products, Proudly Africa, African Market, Online African shop',
            },
        ]);
        this.title.setTitle('MyAfricaConnect');
    }

    ngOnInit() {
        this.loading = true;
        forkJoin([
           // this.homeService.getMarketingSlides(),
           // this.tableService.getClothingGenders(),
			this.tableService.getCodeTablesData(),
			this.productsService.getHomeProducts(),
		    this.homeService.getActiveCarousels(),
			this.homeService.getActiveBusinesses(),
		],
        ).subscribe((res: Array<any>) => {
               // this.marketingSlides = res[2];
                this.activeCarousels = [];
			    this.appStore.storeItem('wareHouseAddress', JSON.stringify(res[0].wareHouseAddress));
				this.appStore.storeItem('banks', JSON.stringify(res[0].banks));
				this.appStore.storeItem('provinces', JSON.stringify(res[0].provinces));
				this.appStore.storeItem('userRoles', JSON.stringify(res[0].userRoles));
				this.appStore.storeItem('orderStatuses', JSON.stringify(res[0].orderStatuses));
				this.appStore.storeItem('orderItemStatuses', JSON.stringify(res[0].orderItemStatuses));
				this.appStore.storeItem('categories', JSON.stringify(res[0].categories));
				this.appStore.storeItem('codeTablesData', JSON.stringify(res[0]));
			    this.products = res[1].body.content;
		   	    this.activeCarousels = res[2].body;
			    this.businesses = res[3].body.content;
		    
				this.loading = false;

             },
            error => {
                this.notifier.notify(error.message, 'error');
                this.loading = false;
            });
			this.homeService.setCategories();
			
	}		

    allProducts() {
		 sessionStorage.setItem('searchProductName', 'all');
		sessionStorage.setItem('category', 'all');

		this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
			this.router.navigate(['/all-products']).then();
		});
	
	}		


}
