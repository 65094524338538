import {CustomerOrder} from './CustomerOrder';

export class CustomerOrderInvoice {
  invoiceId: number;
  invoiceNumber: string;
  specialInstructions: string;
  created_Date: string;
  invoiceAmount: string;
  customerOrder: CustomerOrder;
}
