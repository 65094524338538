import {Product} from './Product';
import {BusinessAddress} from './BusinessAddress';
import {BusinessTradingHours} from './BusinessTradingHours';
import {BusinessMonthlyRevenue} from './BusinessMonthlyRevenue';

export class Business {
    id: string;
    products: Product[];
    logoPath: string;
    businessEmail: string;
    billboardPath: string;
    businessLogoPath: string;
	businessHasWebSite: boolean;
	businessWebsiteURL: string;
	businessStatus: string;
	businessHasFaceBookAccount: boolean;
	businessFacebookURL: string;
	businessContactNumber: string;
	businessIsFullyOnSocialMedia: boolean;
	businessHasInstagram: boolean;
	businessInstagramURL: string;
	businessHasTwitter: boolean;
	businessTwitterURL: string;
    businessId: number;
    businessName: string;
    businessAddress: BusinessAddress;
	yearsInBusiness: number;
    physicalStore: string;
    carryStock: string;
    monthlyRevenue: BusinessMonthlyRevenue;
    ciproRegNumber: string;
    aboutBusiness: string;
    businessLogo: {
        logo: {
            links: any[];
        }
    };
    businessBillBoard: {
        billBoard: {
            links: any[];
        }
    };
    businessCategory: {
        categoryId: string;
        description: string;
        key: string;
    };
    businessProvince: {
        id: string;
        name: string;
    };
    businessDetails: {
        detailsId: number;
        ciproRegNumber: string;
        aboutBusiness: string;
        businessWebsiteURL: string;
        businessEmail: string;
        businessContactNumber: string;
        businessFacebookURL: string;
        businessInstagramURL: string;
        businessTwitterURL: string;
        businessName: string;
        yearsInBusiness: number
    };
    businessBankingDetails: {
        bankingDetailsId: number;
        accountNumber: number;
        bank: {
            bankId: number;
            branchName: string;
            branchCode: string;
        },
        bankAccountType: {
            typeId: number;
        }
    };
    links: [{ href: string }];
    logoUrl: string;
    billboardUrl: string;
    businessTradingHours: Array<BusinessTradingHours>;
}

export function businessWithUpdatedImages(business: Business): Business {
    if (business.businessLogo) {
        const logoUrl = business.businessLogo.logo.links[0].href;
        business.logoUrl = logoUrl; // logoUrl.replace('rest', 'myafrica-api').replace('https', 'https');
    } else {
        business.logoUrl = business.businessLogoPath;
    }

    if (business.businessBillBoard) {
        const billboardUrl = business.businessBillBoard.billBoard.links[0].href;
        business.billboardUrl = billboardUrl ? billboardUrl : business.billboardPath;
        // billboardUrl.replace('rest', 'myafrica-api').replace('https', 'https');
    }
    return business;
}
