import { Component, OnInit } from '@angular/core';
import {CustomerOrderReturn} from '../../../model/CustomerOrderReturn';
import {OrdersServiceService} from '../../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {Utils} from '../../../shared/Utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerOrder} from '../../../model/CustomerOrder';
import {faSearch, faSave, faPlus} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../../services/notifier.service';
import {CustomerOrderReturnItem} from '../../../model/CustomerOrderReturnItem';



@Component({
  selector: 'myafrica-edit-customer-return',
  templateUrl: './edit-customer-return.component.html',
  styleUrls: ['./edit-customer-return.component.scss']
})
export class EditCustomerReturnComponent implements OnInit {


	 searchIcon = faSearch;
    plusIcon = faPlus;
    saveIcon = faSave;
    pageTitle = 'Edit Order Return';
    orderNo: string;
    refNumber: string; // ticket ref no
    customerName: string;
    description: string;
    customer_cell_no: string;
	returnStatusDescription: string;
	moneyBack: boolean;
	exchange: boolean;
    returnReason = 'Not Item Ordered';
    businessName: string;
    contactNo: string;
    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    displayDeleteMessage: string;
    displayDeleteErrorMessage: boolean;
    displayRefNumber: boolean;

    subs1: Subscription;
    subs2: Subscription;

    rowsControls = [];
    customerOrderReturns: CustomerOrderReturn[];

    customerOrders: CustomerOrder[];

    customerOrder: CustomerOrder;

    customerOrderReturn: CustomerOrderReturn;
	savedCustomerOrderReturn: CustomerOrderReturn;
    selecteItems = [];
    buttonDisabled: boolean;
	returnStatuses: string[];
	changedStatusTrackers = [];
	customerOrderReturnItems = [];
	customerOrderItems = [];
    closeResult: string;
	statusDropdownDisabled: boolean;

  constructor(private router: Router, private ordersService: OrdersServiceService, private appStore: ApplicationStore,
              private modalService: NgbModal, private notifier: NotifierService) { }

  ngOnInit() {

	   if(sessionStorage.getItem('userRole') === 'Shopper') {
		this.statusDropdownDisabled = true;
	   } else {
		this.statusDropdownDisabled = false;
	   
	   }

	   this.customerOrderReturn = JSON.parse(sessionStorage.getItem('selectedCustomerOrderReturn'));
	   this.orderNo = this.customerOrderReturn.customerOrder;
	   this.refNumber = this.customerOrderReturn.refNumber;
	   this.customer_cell_no = this.customerOrderReturn.customer_cell_no;
	   this.description = this.customerOrderReturn.description;
	   this.moneyBack = this.customerOrderReturn.moneyBack;
	   this.exchange = this.customerOrderReturn.exchange;  
	   this.returnReason = this.customerOrderReturn.returnReason;
	   this.returnStatusDescription = this.customerOrderReturn.returnStatusDescription;
	   this.buttonDisabled = true;

	   this.subs1 = this.ordersService.getReturnStatuses().subscribe((value: string[]) => {
		  this.returnStatuses = value;

		});

	   this.populateOrderReturnItems();

	   this.searchOrder();

  }

  populateOrderReturnItems() {
  	this.customerOrderReturn.customerOrderReturnItems.forEach(row => {
          this.customerOrderReturnItems.push(row);
          });
  }


  populateItemsToDisplay() {

		this.customerOrderReturnItems.forEach(row => {
           this.customerOrder.customerOrderItems.forEach(innerRow => {
				if (innerRow.orderItemId === row.customerOrderItem.id) {

				  this.customerOrderItems.push(innerRow);
				}
		   });
		});

  }

   searchOrder() {
      this.displayMessage = '';
      this.displayErrorMessage = false;

      this.subs2 = this.ordersService.getOrdersByOrderNo(this.orderNo).subscribe((value: CustomerOrder[]) => {

          this.customerOrders = value;
          if (this.customerOrders[0].customer_surname === null ||
                  this.customerOrders[0].customer_surname === '' ||
                  this.customerOrders[0].customer_surname === undefined) {
              this.customerName = this.customerOrders[0].customer_name;

          } else {
               this.customerName = this.customerOrders[0].customer_name + ' ' + this.customerOrders[0].customer_surname;
          }
          if (this.customerOrders[0].customer_cell_no != null) {
           this.customer_cell_no = this.customerOrders[0].customer_cell_no;
          }

          this.customerOrder = this.customerOrders[0];

			       this.customerOrders.forEach(row => {
            this.rowsControls.push({
              isCollapsed: true
            });
          });
			       this.populateItemsToDisplay();

        }, error1 => {
          console.log(error1);
          // this.orderNotProcessCount = "0";
        });

   }


	/**
   * Adds changed product item statuses to the status tracker array
   */
  changeOrderStatus() {
	this.buttonDisabled = false;
	this.displaySuccessMessage = false;
	this.displayErrorMessage = false;
 let index = 0;
 this.changedStatusTrackers.forEach(row => {
      // remove item from existing array to not have duplicates
        this.changedStatusTrackers.splice(index, 1);
        index++;
    });
 this.changedStatusTrackers.push(this.customerOrderReturn);
  }

   enableSubmitButton() {
   this.buttonDisabled = false;
   }

 /**
   * Uploads the updated order return status
   */
  updateReturn() {

	this.customerOrderReturn.description = this.description;
	this.customerOrderReturn.moneyBack = this.moneyBack;
    this.customerOrderReturn.exchange = this.exchange;
	 
	this.customerOrderReturn.returnStatusDescription = this.returnStatusDescription;
	this.changeOrderStatus();
	this.displaySuccessMessage = false;
	this.displayErrorMessage = false;
	this.subs2 = this.ordersService.updateReturnsStatuses(this.changedStatusTrackers).subscribe(value => {

      this.displayMessage = 'Record updated successfully!!';
	     this.notifier.notify(this.displayMessage, 'success');

    }, error1 => {
      this.displayMessage = 'Error updating records. Please try again later!!';
	     this.notifier.notify(this.displayMessage, 'error');

	     console.log(error1);
    });

  }

  getWindowSize(): string  {
		let modalSize = 'sm';

		if (window.matchMedia('(max-width: 767px)').matches) {
		   modalSize = 'sm';
		} if (window.matchMedia('(max-width: 889px)').matches) {
			modalSize = 'md';
		} else {
			modalSize = 'lg';
		}
		return modalSize;
  }

  open(content, customerOrder: any) {
	this.businessName = customerOrder.productBusiness.businessName;
 this.contactNo = customerOrder.productBusiness.businessContactNumber;

 this.modalService.open(content, {size: this.getWindowSize(), ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }
    );
  }

  goBack() {
	    this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
				  this.navigate('manage-returns');
	});

    }

     navigate(path: string) {
	    const navigationOutlet  = sessionStorage.getItem('navigationOutlet') + '';
		 if (navigationOutlet === 'shopperdashboard') {
		    this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: [path]}}], {skipLocationChange: true}).then();
		 } else if (navigationOutlet === 'adminclerkdashboard') {
		    this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}], {skipLocationChange: true}).then();
		} else {
		  this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();

		}
    }



}
