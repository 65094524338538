import { Component, OnInit } from '@angular/core';
import {
    faBuilding,
    faEnvelope,
    faFileInvoiceDollar,
    faHome,
    faLockOpen,
    faShoppingBasket,
    faShoppingCart,
    faStickyNote,
    faTruckLoading,
    faUser,
	faMoneyBill, faAddressCard,
} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'myafrica-shopper-dashboard',
  templateUrl: './shopper-dashboard.component.html',
  styleUrls: ['./shopper-dashboard.component.scss']
})
export class ShopperDashboardComponent implements OnInit {

    cartIcon = faShoppingCart;
    moneyIcon = faMoneyBill;
    fileInvoiceDollarIcon = faFileInvoiceDollar;
    messagesIcon = faEnvelope;
    profileIcon = faUser;
    homeIcon = faHome;
    businessesIcon = faBuilding;
    productIcon = faShoppingBasket;
    returnsIcon = faTruckLoading;
    passwordIcon = faLockOpen;
	addressIcon = faAddressCard;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

   navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

}
