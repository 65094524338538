import {Component, OnDestroy, OnInit} from '@angular/core';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {HttpResponse} from '@angular/common/http';
import {Business} from '../../../model/Business';
import {BusinessDetail} from '../../../model/BusinessDetail';
import {NotifierService} from '../../../services/notifier.service';
import {Subscription} from 'rxjs';
import {BusinessMonthlyRevenue} from '../../../model/BusinessMonthlyRevenue';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';


@Component({
    selector: 'myafrica-business-additional-details',
    templateUrl: './business-additional-details.component.html',
    styleUrls: ['./business-additional-details.component.scss'],
})
export class BusinessAdditionalDetailsComponent implements OnInit, OnDestroy {

    selectedBusiness: Business;
    businessId: string;

    businessDetails: BusinessDetail;
	businessMonthlyRevenues: BusinessMonthlyRevenue[];
    businessDetailsId: string;
    businessEmail: string;
    businessName: string;
    businessContactNumber: string;
    ciproRegNumber: string;
    yearsInBusiness: number;
    aboutBusiness: string;
    businessWebsiteURL: string;
    businessFacebookURL: string;
    businessInstagramURL: string;
    businessTwitterURL: string;

    displayMessage: string;
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    subscription: Subscription;
    updateSub: Subscription;
    businessNameClass = true;
    businessEmailClass = true;
    businessContactNumberClass = true;
    ciproRegNumberClass = true;
    yearsInBusinessClass = true;
	physicalStore: string = 'Yes';
    carryStock: string = "Yes";
	monthlyRevenueId: string;
	monthlyRevenue: BusinessMonthlyRevenue;
	
    constructor(private businessService: BusinessServiceService, private notifier: NotifierService, private tableService: CodeTablesServiceService,) {
    }

    ngOnInit() {
	    this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
	    this.businessId = this.selectedBusiness.id;
        this.businessName = this.selectedBusiness.businessName;
      
		this.businessMonthlyRevenues = JSON.parse(sessionStorage.getItem('businessMonthlyRevenues'));
		if (this.businessMonthlyRevenues === undefined || this.businessMonthlyRevenues === null) {
      
			this.subscription = this.tableService.getBusinessMonthlyRevenues().subscribe((value: BusinessMonthlyRevenue[]) => {
					this.businessMonthlyRevenues = value;
					sessionStorage.setItem('businessMonthlyRevenues', JSON.stringify(this.businessMonthlyRevenues));
          	},
			);
		}  	
		//this.businessDetails = this.selectedBusiness;
		this.defaultData();
		this.dataChanged();

    }

    getBusinessDetails() {
        this.subscription = this.businessService.getBusinessDetails(this.businessId).subscribe((value: BusinessDetail) => {
                this.businessDetails = value;
                this.defaultData();
                this.dataChanged();

            },
        );

    }

    defaultData() {
	
    if (this.selectedBusiness != null) {
            this.businessDetailsId = this.selectedBusiness.id;
            this.businessEmail = this.selectedBusiness.businessEmail;
            this.businessContactNumber = this.selectedBusiness.businessContactNumber;
            this.ciproRegNumber = this.selectedBusiness.ciproRegNumber;
            this.yearsInBusiness = this.selectedBusiness.yearsInBusiness;
            this.aboutBusiness = this.selectedBusiness.aboutBusiness;
            this.businessWebsiteURL = this.selectedBusiness.businessWebsiteURL;
            this.businessFacebookURL = this.selectedBusiness.businessFacebookURL;
            this.businessInstagramURL = this.selectedBusiness.businessInstagramURL;
            this.businessTwitterURL = this.selectedBusiness.businessTwitterURL;
		
			if(this.selectedBusiness.monthlyRevenue) {
			  this.setMonthlyRevenueId(this.selectedBusiness.monthlyRevenue.id);
			}
			
			if(this.selectedBusiness.carryStock === null) {
			   this.carryStock = "Yes";
			} else {	
			   this.carryStock = this.selectedBusiness.carryStock;
			}
			
			if(this.selectedBusiness.physicalStore === null) {
			   this.physicalStore = "Yes";
			} else {	
			   this.physicalStore = this.selectedBusiness.physicalStore;
    		}
		
	    }

    }

    dataChanged() {
        if (this.businessName !== '') {
            this.businessNameClass = false;
        } else {
            this.businessNameClass = true;
        }

        if (this.businessEmail === undefined || this.businessEmail === '' || this.businessEmail === null) {
            this.businessEmailClass = true;
        } else {
            this.businessEmailClass = false;
        }
        if (this.businessContactNumber === undefined || this.businessContactNumber === '' || this.businessContactNumber === null) {
            this.businessContactNumberClass = true;
        } else {
            this.businessContactNumberClass = false;
        }

        if (this.ciproRegNumber === undefined || this.ciproRegNumber === '' || this.ciproRegNumber === null) {
            this.ciproRegNumberClass = true;
        } else {
            this.ciproRegNumberClass = false;
        }

        if (this.yearsInBusiness !== 0) {
            this.yearsInBusinessClass = false;
        } else {
            this.yearsInBusinessClass = true;
        }

    }


    updateBusinessDetails() {
        const businessJSONObject = {
            id: this.selectedBusiness.id,
            businessEmail: this.businessEmail,
            businessContactNumber: this.businessContactNumber,
            ciproRegNumber: this.ciproRegNumber,
            aboutBusiness: this.aboutBusiness,
            businessWebsiteURL: this.businessWebsiteURL,
            businessFacebookURL: this.businessFacebookURL,
            businessInstagramURL: this.businessInstagramURL,
            businessTwitterURL: this.businessTwitterURL,
            yearsInBusiness: this.yearsInBusiness,
            businessName: this.businessName,
			monthlyRevenue: this.monthlyRevenue,
			carryStock: this.carryStock,
			physicalStore: this.physicalStore,

        };

		if(this.businessEmail === null || this.businessEmail === undefined || this.businessEmail.trim() === "") {
			this.displayMessage = 'Please enter business email address!!!';
            this.notifier.notifyError(this.displayMessage);
		    return;
		}

        if (sessionStorage.getItem('userDetails')) {

            this.updateSub = this.businessService
                .updateBusiness(this.businessId, businessJSONObject).subscribe((value: HttpResponse<any>) => {
                    this.displayMessage = 'Business details updated successfully!!!';
                    this.notifier.notify(this.displayMessage);

                    //if (this.businessDetailsId === 0) {
                     //   this.getBusinessDetails();
                    //}
                }, error1 => {
			        this.displayMessage = 'Error updating business details. Please try again later!!!';
                    this.notifier.notifyError(this.displayMessage);

                });
        } else {
            alert('Not logged in');
        }
    }

	setMonthlyRevenue(monthlyRevenueId: string) {
		if(this.businessMonthlyRevenues) {
			this.businessMonthlyRevenues.forEach(element => {
				if (element.id === monthlyRevenueId) {
					this.monthlyRevenue = element;
				}
		 });
		}
	}

	setMonthlyRevenueId(monthlyRevenueId: string) {
		this.monthlyRevenueId = monthlyRevenueId;
		this.setMonthlyRevenue(monthlyRevenueId);
	}

	setPhysicalStore(physicalStore: string) {
		this.physicalStore = physicalStore;
	}

	setCarryStock(carryStock: string) {
		this.carryStock = carryStock;
	}

    ngOnDestroy() {
        this.doUnsubscribe(this.subscription);
        this.doUnsubscribe(this.updateSub);
    }

    doUnsubscribe(subs: Subscription) {
        if (subs) {
            subs.unsubscribe();
        }
    }

}
