import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {Business} from '../model/Business';
import {CategoryModelRevamped} from '../model/CategoryModelRevamped';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
	
	categories: CategoryModelRevamped[];
     subs1: Subscription;
	 
	constructor(private http: HttpClient) {
    }
	
	
	public setCategories() {
	  this.subs1 = this.getCategories().subscribe((value: CategoryModelRevamped[]) => {
		   this.categories = value;
		}, error => {
			return;

		},
		);
	}
	
	public getDownloadedCategories() {
	   return  this.categories;
	}
	
	public getActiveCarousels(): Observable<any> {
        return this.http.get(`myafrica-api/carousel/getactive-carousels`, {observe: 'response'});
    }

    public getMarketingSlides(): Observable<any> {
        return this.http.post(`myafrica-api/marketing-slides/getactive-marketing-slides`, {observe: 'response'});
    }

    public getActiveBusinesses(): Observable<Business> | Observable<any> {
        return this.http.get(`myafrica-api/home/businesses`, {observe: 'response'});
    }
	
	 public getCategories(): Observable<CategoryModelRevamped> | Observable<any> {
        return this.http.get(`myafrica-api//all/active/categories/map`, {observe: 'response'});
    }
}

