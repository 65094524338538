import {environment as config} from '../../environments/environment';


export class RequestHeader {

  public config;

  constructor() {
    this.config = config;
  }
}
