<div class="container d-none d-md-block">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
        </ol>
        <div class="carousel-inner rounded shadow-lg" style="height: 15rem">
            <div class="carousel-item active">
                <img src="https://myafriconnect.dedicated.co.za/images/free-subscription-1.png" class="d-block w-100 h-15" alt="...">
            </div>
            <div class="carousel-item">
                <img src="https://myafriconnect.dedicated.co.za/images/proudly-african.png" class="d-block w-100 h-15" alt="...">
            </div>
            <div class="carousel-item">
                <img src="https://myafriconnect.dedicated.co.za/images/free-subscription-2.png" class="d-block w-100 h-15" alt="...">
            </div>
            <div class="carousel-item">
                <img src="https://myafriconnect.dedicated.co.za/images/weekly-deals.png" class="d-block w-100 h-15" alt="...">
            </div>
            <div class="carousel-item">
                <img src="https://myafriconnect.dedicated.co.za/images/free-subscription-3.png" class="d-block w-100 h-15" alt="...">
            </div>
            <div class="carousel-item">
                <img src="https://myafriconnect.dedicated.co.za/images/eft-friendly-market.png" class="d-block w-100 h-15" alt="...">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
