<div class="container pt-md-2 mt-5 mb-5  align-middle p-md-5 col-sm-12 col-md-8 text-dark shadow">
    <div class="row  mt-2">
        <div class="col-12 card-header text-center h3 bg-primary text-white">
            REGISTER
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-xs-12 col-sm-12  col-md-6 col-lg-6 text-center bg-light ">
            <h5 class="mt-2">MyAfriShop</h5>

            <h6 class="font-weight-bold mt-4"><strong>For online shoppers & customers</strong></h6>

            <button type="button" class="btn btn-success w-100 mt-4 mb-2" (click)="register('Shopper')"
                    id="registerCustomerButton">
                <i class="fa fa-shopping-cart fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;Register as shopper
            </button>

        </div>
        <div class="col-xs-12 col-sm-12  col-md-6 col-lg-6 text-center bg-light">
            <h5 class="mt-2">MyAfricaBiz</h5>
            <h6 class="font-weight-bold mt-4"><strong>For entrepreneurs and business owners, start 6 months free
                subscription today</strong></h6>
            <button type="button" class="btn btn-success w-100  mb-2 mt-1"
                    id="registerBusinessButton" (click)="register('Merchant')">
                <i class="fa fa-building fa-1x" aria-hidden="true"></i>&nbsp;&nbsp;Register as seller
            </button>

        </div>
    </div>

    <hr class="bg-warning">
    <div class="text-center pb-3">
        <a routerLink="/login" class="text-decoration-none text-dark">Already have a MyAfricaConnect account?? Login</a>
    </div>
    <div class="text-center pb-3">
        <a class="text-decoration-none text-dark"
           [routerLink]="'/reset-password'"> Reset password</a>
    </div>

    <div class="text-center pb-3">
        <a class="text-decoration-none text-dark"
           [routerLink]="'/forgot-password'"> Forgot password</a>
    </div>


</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>



