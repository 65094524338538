import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'myafrica-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

    @Input() isPageLoading: boolean;

    public loadingOptions = {
        backdropBorderRadius: '3px',
        fullScreenBackdrop: true,
        primaryColour: '#f15a22',
        secondaryColour: '#33a5c0',
        tertiaryColour: '#FFF',
        backdropBackgroundColour: 'rgb(61,51,86)',
    };

    constructor() {
    }

    ngOnInit() {
    }

}
