import {Component, OnDestroy, OnInit} from '@angular/core';
import {Business} from '../../model/Business';
import {Product} from '../../model/Product';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessServiceService} from '../../shared/services/business/business-service.service';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Utils} from '../../shared/Utils';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {CategoryModel} from '../../model/CategoryModel';
import {NotifierService} from '../../services/notifier.service';
import {BusinessCategory} from '../../model/BusinessCategory';
import {ClothingGender} from '../../model/ClothingGender';
import {ClothingGroup} from '../../model/ClothingGroup';
import {ClothingCategory} from '../../model/ClothingCategory';
import {PaginationProduct} from '../../model/PaginationProduct';
import {Ng2ImgMaxService} from 'ng2-img-max';
import {ClothingSizeCode} from '../../model/ClothingSizeCode';
import {faArrowRight, faInfoCircle, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

@Component({
    selector: 'myafrica-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
    currentJustify = 'justify';
    items: Array<CategoryModel>;
    closeResult: string;
    product: Product;

    selectedFile: File;
    url = null;
    imageWidth: number;
    imageHeight: number;
    previousUrl = null;
    label = 'Products';
    icon = 'fa fa-cog fa-3x fa-spin';

    buttonLabel = 'ADD Product';
    buttonIcon = 'fa fa-fw fa-plus';
    selectedBusiness: Business;

    searchedProducts: Product[] = [];
    businesses: Business[] = [];


    calculatedServiceFee = 0;
    calculatedMarkUpFee = 0;
    calculatedEftFee = 0;

    unitsInStock: number;
    currency = 'ZAR';
    productCategory: string;
    businessId: string;
    productName: string;
    price: number;
    productDescription: string;
	productLength: number;
	productHeight: number; 
	productBreadth: number;
	productMass: number;

    serviceFee = 0;
    markUpFee = 0;
    courierFee = 0;
    totalProductFee = 0;
    eftFee = 0;
    adminFee = 0;
    productFees: string;

    searchProductName: string;
    searchProductCategory: string;
    searchProductCategoryMap: Map<string, string>;
    businessIds: string;
    private businessCategories: BusinessCategory[];


    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    displayDeleteMessage: string;
    displayDeleteErrorMessage: boolean;
    displayDeleteSuccessMessage: boolean;
    searchBusinessId = '0';

    clothingGenders: ClothingGender[];
    clothingGroups: ClothingGroup[];
    clothingCategories: ClothingCategory[];
    displayGenders: boolean;
    displayGroups: boolean;
    displaySubCategory: boolean;

    genderId: number;
    groupId: number;
    subCategoryId: number;
    loading: boolean;

    entryLimitCount = 12; // entries per page
    displayEntryLimitCount = 12; // entries per page
    currentPage = 1;
    totalProducts = 0;

    orderBy = ' ';
    productCategoryMap: Map<string, string>;
    displayRangeLimit: number;

    itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];

    selectedItem = '';

    orderByList = [{
        id: 0,
        name: 'Sort By',
        sortValue: ' ',
    }, {
        id: 1,
        name: 'Price:High to Low',
        sortValue: 'desc',
    }, {
        id: 2,
        name: 'Price:Low to High',
        sortValue: 'asc',
    }];

    buttonDisabled = false;
    addProductButtonDisabled = false;
    uploadedImage: Blob;

    colorSelectedFile: File;
    colorUrl = null;
    colorPreviousUrl = null;
    sizesElements: { name, count }[];
    clothingSizeCodes: ClothingSizeCode[];
    multiColourProduct = false;
    disableColorPicker = false;
    color: string;

    displayColourTab = false;

    iconSearch = faSearch;
    iconPlus = faPlus;
    iconArrowRight = faArrowRight;
    iconInfoCircle = faInfoCircle;


    constructor(private modalService: NgbModal,
                private tableService: CodeTablesServiceService,
                private businessService: BusinessServiceService,
                private router: Router,
                private appStore: ApplicationStore, private ng2ImgMax: Ng2ImgMaxService,
                private notifier: NotifierService) {
    }


    ngOnInit() {
        this.loading = true;
        this.clothingGenders = JSON.parse(this.appStore.getItem('clothingGenders'));

        this.businessCategories = JSON.parse(sessionStorage.getItem('businessCategories'));
        this.sizesElements = [];
        this.sizesElements.push({name: 'All', count: 0});
    
       if (this.businessCategories === undefined || this.businessCategories === null) {
             this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
                    this.businessCategories = value;
                    sessionStorage.setItem('businessCategories', JSON.stringify(this.businessCategories));
                    this.populateData();
                },
            );

        } else {
            this.populateData();
        }
        this.getClothingSizeCodes();


        this.currentPage = this.currentPage - 1;
        this.searchProductCategory = 'all';
        this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
	
        const profileId = sessionStorage.getItem('profileId');

        this.businessService.getBusinessesByProfileId(profileId).subscribe((value: Business[]) => {
                this.businesses = value;

                if (this.businesses.length <= 0) {
                    this.addProductButtonDisabled = true;
                }

                this.businessIds = ',';
                let index = 1;
                this.businesses.forEach(element => {

                    if (index === this.businesses.length) {
                        this.businessIds += element.id;
                    } else {
                        this.businessIds += element.id + ',';

                    }
                    index++;
                });

                this.getDefaultBusinessProducts();
            },
        );
		
		this.productFees =  sessionStorage.getItem('codeTablesData');     
    
		const productFeesObject = JSON.parse(this.productFees).productPricingFee;
		this.serviceFee = productFeesObject[0].serviceFee;
		this.markUpFee =  productFeesObject[0].markUpFee;
		this.courierFee =  productFeesObject[0].courierFee;
		this.adminFee =  productFeesObject[0].serviceFee;
		this.eftFee =  productFeesObject[0].eftFee;
		this.loading = false;

    }


    getClothingSizeCodes() {

        this.tableService.getClothingSizeCodes().subscribe((value: ClothingSizeCode[]) => {
                this.clothingSizeCodes = value;
            },
        );
    }

    adMoreElement() {
        this.sizesElements.push({name: 'All', count: 0});

    }


    displayGendersDropdown(productCategory: string) {
        if (productCategory === 'clothing') {
            this.displayColourTab = true;
            this.displayGenders = true;
        } else {
            this.displayColourTab = false;
            this.displayGenders = false;
            this.displayGroups = false;
            this.displaySubCategory = false;
        }
    }

    displayGroupsDropDown(genderId: number) {
        this.displayGroups = true;

        this.clothingGenders.forEach(element => {
            if (Number(element.id) === Number(genderId)) {
                this.clothingGroups = element.clothingGroups;

            }
        });

    }


    displaySubCategoryDropDown(groupId: number) {
        this.displaySubCategory = true;

        this.clothingGroups.forEach(element => {
            if (Number(element.id) === Number(groupId)) {
                this.clothingCategories = element.clothingCategories;

            }
        });

    }


    populateData() {
        this.items = [];
        this.businessCategories.forEach(element => {
            this.items.push(new CategoryModel(element.key, element.description));
        });

        this.searchProductCategoryMap = new Map<string, string>();
        this.searchProductCategoryMap.set('all', 'All');
        this.items.forEach(element => {
            this.searchProductCategoryMap.set(element.key, element.value);

        });

    }

    getDefaultBusinessProducts() {
	    this.businessService.getPaginationBusinessProductsByName
        ('all', this.entryLimitCount, 0, this.searchProductCategory, [this.businessIds]).subscribe((value: any) => {
				this.searchedProducts = value.content;
			    this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;

            }, error => {
	            this.loading = false;

            },
        );
    }

    getProductsByNameByOrder() {
	    if (this.searchBusinessId === '0') {
            this.businessIds = ',';
            let index = 1;
	        this.businesses.forEach(element => {

                // element.products.filter(item => !item.deleted).forEach(entry => this.searchedProducts.push(entry));

                if (index === this.businesses.length) {
                    this.businessIds += element.id;
                } else {
                    this.businessIds += element.id + ',';

                }
                index++;
            });
        } else {
            this.businessIds = ',' + this.searchBusinessId;
        }
	    this.getProductsByName();

    }

    previous() {

        this.currentPage = this.currentPage - 1;

        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName,
            this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [this.businessIds]).subscribe((value: any) => {

            	this.searchedProducts = value.content;
			    this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            },
        );
    }

    next() {
        this.currentPage = this.currentPage + 1;
        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }
        this.businessService.getPaginationBusinessProductsByName(this.searchProductName,
            this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [this.businessIds]).subscribe((value: any) => {
				this.searchedProducts = value.content;
			    this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            },
        );
    }


    calculateProductPrice() {
        const productPrice = this.price;
        this.calculatedServiceFee = parseFloat((productPrice * (this.serviceFee / 100)).toFixed(2));

        this.totalProductFee = productPrice + this.calculatedServiceFee;

        this.calculatedMarkUpFee = parseFloat((this.totalProductFee * (this.markUpFee / 100)).toFixed(2));


        this.totalProductFee = this.totalProductFee + this.calculatedMarkUpFee;

        if (this.price === 0 || this.price === null || isNaN(this.price)) {
            this.totalProductFee = 0;
            //    this.adminFee = 0;
        }

        this.calculatedEftFee = parseFloat((this.totalProductFee * (this.eftFee / 100)).toFixed(2));

        this.totalProductFee = parseFloat((this.calculatedEftFee + this.totalProductFee).toFixed(2));


    }

    getProductsByName() {
        this.currentPage = 0;
	    if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'All';
        }

	    this.businessService.getPaginationBusinessProductsByName(
            this.searchProductName, this.entryLimitCount, 0,
            this.searchProductCategory, [this.businessIds]).subscribe((value: any) => {
				this.searchedProducts = value.content;
			    this.totalProducts = value.totalElements;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }

            },
        );
    }

    setProductsByCategory(key: string, description?: string) {
        this.searchProductCategory = key;
        this.searchProductName = '';
        this.getProductsByName();
    }


    confirm(businessId: string, productId: number) {

        const titleText = 'Are sure you want to delete the product?';
        alert('userConfirmed : ');

        Swal.fire({
            title: titleText,
            showDenyButton: true,
            confirmButtonText: `Continue`,
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteProduct(businessId, productId, true);
            } else if (result.isDenied) {
                return false;
            }
        });

    }

    deleteProduct(businessId: string, productId: number, userConfirmed: boolean) {
        if (!userConfirmed) {
            return;
        }


        this.businessService.deleteProduct(businessId, productId).subscribe(value => {
            this.getDefaultBusinessProducts();
            this.displayDeleteErrorMessage = false;
            this.displayDeleteSuccessMessage = true;
            this.displayDeleteMessage = 'Product deleted successfully!!';
            this.notifier.notify(this.displayDeleteMessage, 'success');

            setInterval(a => {
                this.displayDeleteMessage = '';
            }, Utils.getDelayTime(), []);

        }, () => {
            this.displayDeleteErrorMessage = true;
            this.displayDeleteSuccessMessage = false;

            this.displayDeleteMessage = 'Failed to delete product. Please try again later!';
            this.notifier.notify(this.displayDeleteMessage, 'error');
            setInterval(a => {
                this.displayDeleteMessage = '';
            }, Utils.getDelayTime(), []);

        });

    }

    onImageChange(event) {
        this.loading = true;
        this.selectedFile = event.target.files[0];
        this.displayErrorMessage = false;
        this.displayMessage = '';
        const image = event.target.files[0];
        const reader = new FileReader();
        let img = null;
        img = new Image();

        this.url = reader.result;
        this.ng2ImgMax.resizeImage(image, 400, 400).subscribe(
            result => {
                this.uploadedImage = result;
                // this.selectedFile = this.uploadedImage;
                reader.readAsDataURL(this.uploadedImage); // read file as data billboardUrl
                reader.onload = () => { // called once readAsDataURL is completed
                    this.url = reader.result;
                    img.src = reader.result; // The data URL

                    img.onload = () => {
                        this.imageWidth = img.width;
                        this.imageHeight = img.height;
                        this.loading = false;
                        if (this.imageWidth < 200) {
                            this.displayErrorMessage = true;
                            this.displayMessage = 'Required minimum image width is 200px!!!';
                            this.notifier.notify(this.displayMessage, 'error');

                            this.selectedFile = undefined;
                            this.url = this.previousUrl;
                            return;
                        }
                    };

                    this.selectedFile = new File([this.uploadedImage], 'untitled.png', {type: 'image/png'});
                };
            },
            error => {
                this.displayErrorMessage = true;
                this.displayMessage = 'Error resizing image. Required minimum image width is 200px!!!';
                this.notifier.notify(this.displayMessage, 'error');

                this.loading = false;
                this.selectedFile = undefined;
                this.url = this.previousUrl;
            },
        );


    }

    getProductImage(product: Product): string | object | undefined {
        return product.defaultImageUrl;
    }

	storePath(path: string): void {
        sessionStorage.setItem('dashboard-path', path);
    }

    open(content) {
		sessionStorage.removeItem('currentProduct');
		sessionStorage.removeItem('selectedBusiness');
	
		this.storePath('manage-products');
		this.router.navigate(['dashboard', {outlets: {dashboard: ['add-product']}}], {skipLocationChange: true}).then();
		window.scrollTo(0, 0);

    }

    editProduct(product: Product) {
        sessionStorage.setItem('currentProduct', JSON.stringify(product));
        sessionStorage.setItem('history-route', 'manage-products');
        this.navigate('edit-product');

        window.scrollTo(0, 0);
    }

    addProductHistory(product: Product) {
        sessionStorage.setItem('currentProduct', JSON.stringify(product));
        sessionStorage.setItem('history-route', 'manage-products');
        this.navigate('product-history');
        window.scrollTo(0, 0);
    }


    sizesCaptured(): boolean {

        let elementEmpty = false;
        this.sizesElements.forEach((element) => {
            if (element.count === 0) {
                elementEmpty = true;
            }
        });

        if (elementEmpty) {
            this.showErrorMessage('Size cannot be empty. Please fill in the size of one of the color(s)!! ');
            return false;
        }


        return true;

    }

    showErrorMessage(errorMessage: string) {
        this.displayErrorMessage = true;
        this.displaySuccessMessage = false;
        this.displayMessage = errorMessage;

    }

    addProductWithImage(modal) {
        const clothingProduct = this.productCategory === 'clothing';
        if (this.productCategory === '' || this.productCategory === undefined) {
            this.notifier.notify('Please select the product category!!', 'error');

            return;
        }

        if (clothingProduct) {
            if (this.subCategoryId === undefined || this.subCategoryId === 0) {
                this.notifier.notify('Please select the sub product category!!', 'error');

                return;
            }

            if (this.color === undefined || this.color === null) {
                this.notifier.notify('Please select colour for the product!!', 'error');

                return;
            }

        }

        if (this.businessId) {
            this.notifier.notify('Please select the business!!', 'error');
            return;
        }

        if (this.productName === '' || this.productName === undefined) {
            this.notifier.notify('Please fill in the product name!!', 'error');
            return;
        }

        if (this.price === 0 || this.price === undefined) {
            this.notifier.notify('Please enter the product price!!', 'error');
            return;
        }
	
		if (this.product.productMass === undefined || this.product.productMass == 0) {
            this.notifier.notify('Please enter the product mass!!', 'error');
            return;
        }

	    if (this.product.productBreadth === undefined || this.product.productBreadth == 0) {
            this.notifier.notify('Please enter the product breadth!!', 'error');
            return;
        }
	   
		 if (this.product.productHeight === undefined || this.product.productHeight == 0) {
            this.notifier.notify('Please enter the product height!!', 'error');
            return;
        }
	   
		if (this.product.productLength === undefined || this.product.productLength == 0) {
            this.notifier.notify('Please enter the product length!!', 'error');
            return;
        }

        if (this.selectedFile === undefined || this.selectedFile === null) {
            this.notifier.notify('Please select image for the product!!', 'error');
            return;
        }


        // to avoid duplicate submissions
        if (this.buttonDisabled) {
            return;
        }

        this.buttonDisabled = true;

        const productObject = {
            id: 0,
            productId: 0,
            productDescription: this.productDescription,
            price: this.totalProductFee,
            customerPrice: this.price,
            currency: this.currency,
            productName: this.productName,
            businessId: this.businessId,
            productCategory: this.productCategory,
            unitsInStock: this.unitsInStock,
            categoryId: this.subCategoryId,
			productLength: this.product.productLength,
			productHeight: this.product.productHeight, 
			productBreadth: this.product.productBreadth,
			productMass: this.product.productMass,
        };

        this.loading = true;

        if (clothingProduct) {
            this.addProductWithColours(modal, productObject);

        } else {
            this.addProductWithoutColours(modal, productObject);
        }

    }

    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

    addProductWithoutColours(modal, productObject) {

        this.businessService.addProductWithImage(
            this.selectedFile, this.businessId + '', JSON.stringify(productObject)).subscribe(() => {
            // refresh the products  list
            this.getDefaultBusinessProducts();

            this.loading = false;

            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Product added successfully!!';
            this.notifier.notify(this.displayMessage, 'success');

            Utils.delay(5000).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.navigate('manage-products');
                });


            });
        }, error1 => {
            this.loading = false;

            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.buttonDisabled = false;
            this.displayMessage = 'Failed to add product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
            });


        });
    }

    addProductWithColours(modal, productObject) {

        const payLoad = {
            colourName: this.color,
            clothingSizes: this.sizesElements,
        };

        this.businessService.addProductWithColours(
            this.selectedFile, this.businessId + '', JSON.stringify(productObject),
            this.selectedFile, JSON.stringify(payLoad)).subscribe(() => {
            this.getDefaultBusinessProducts();

            // refresh the products  list
            this.loading = false;

            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Product added successfully!!';
            this.notifier.notify(this.displayMessage, 'success');

            Utils.delay(5000).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.router.navigate(['dashboard', {outlets: {dashboard: ['manage-product']}}], {skipLocationChange: true}).then();
                });


            });
        }, error1 => {
            this.loading = false;

            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.buttonDisabled = false;
            this.displayMessage = 'Failed to add product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
            });


        });
    }

    setMultiColourProduct() {
        if (this.multiColourProduct) {
            this.disableColorPicker = true;
            this.color = '#999999A';
        } else {
            this.color = '';
            this.disableColorPicker = false;

        }
    }

    closeModal(modal) {
        this.clearValues();
        modal.close('');
    }

    clearValues() {
        this.url = null;
        this.displayErrorMessage = false;
        this.displaySuccessMessage = false;
        this.displayMessage = '';

    }

    getMapKeys(): Array<string> {
        return Array.from(this.searchProductCategoryMap?.keys());
    }

    setNumberOfItemsPerPage() {

    }


}
