import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'myafrica-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {

    @Input() pageTitle: string;
    @Input() tileImage: string;

    constructor() {
    }

    ngOnInit() {
    }

}
