<div id="navWrap">
    <nav>
        <myafrica-mega-menu></myafrica-mega-menu>
    </nav>
</div>

<myafrica-advert-slides></myafrica-advert-slides>


<div class="container">

    <myafrica-page-title pageTitle="Search Results"></myafrica-page-title>

    <div class="row"
         *ngIf="searchResults && searchResults[0].products.length > 0; else elseBlock;">
        <div class="col-12 col-md-6 col-lg-4 col-xl-4"
             *ngFor="let product of searchResults[0].products">
            <myafrica-product-item [product]="product"></myafrica-product-item>

        </div>

    </div>
    <br/>
    <section>
        <div class="container mb-2"
             *ngIf="searchResults && searchResults[0].products.length > 0">
            <div class="row justify-content-center">
                <div class="col-12 col-md-3">
                    <button class="btn btn-warning btn-block" routerLink="/all-products">View
                        All Products
                    </button>
                </div>
            </div>
        </div>
    </section>

    <ng-template #elseBlock>
        <div class="container no-results">
            <h3 class="text-center">No results found for the specified search</h3>
        </div>
        <section>
            <div class="container mb-2"
                 *ngIf="searchResults && searchResults[0].products.length > 0">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-3">
                        <button class="btn btn-warning btn-block" routerLink="/all-products">View
                            All Products
                        </button>
                    </div>
                </div>
            </div>
        </section>

    </ng-template>
</div>
