<myafrica-page-title pageTitle="YOUR ORDER DETAILS"></myafrica-page-title>
<div class="container">
    <div class="bg-light">
        <div class="bg-light p-1">
            <h5>Payment successfully received. Thank you for shopping on
                MyAfricaConnect! </h5>

        </div>
        <div class="bg-light">
            <h5>Your order will now be processed.</h5>

        </div>

        <div *ngIf="isCustomerCollecting()" class="bg-light">
            <p class="order-success-message-bold">Once order has been
                processed you will receive notification to collect your items.</p>

        </div>
    </div>

    <br/>

    <div class="container product-items-div d-md-none d-lg-none"
         style="margin-left: 0px" *ngFor="let cartItem of cartItems">
        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell">
                <span>Product</span>
            </div>
            <div class="col-6 div-cell d-md-none d-lg-none text-center">
                <img *ngIf="cartItem.product.defaultImageUrl; else elseBlock"
                     class="img-thumbnail p-1" style="height: 80px; width: 80px"
                     src="{{cartItem.product.defaultImageUrl}}"/>
                <ng-template #elseBlock>
                    <img src="../../../assets/img/image-not-available.jpg"
                         alt="business logo" style="height: 80px; width: 80px"
                         class="img-thumbnail p-1"/></ng-template>
                {{cartItem.product.productName}}
            </div>
        </div>

        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell">
                <span>Price</span>
            </div>
            <div class="col-6  text-warning">{{cartItem.product.price
                | currency: cartCurrency + ' '}}</div>
        </div>


        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell">
                <span>Quantity</span>
            </div>
            <div class="col-6 div-cell text-warning">{{cartItem.quantity}}</div>
        </div>


        <div class="row d-md-none d-lg-none" *ngIf="isCustomerCollecting()">
            <div class="col-6 div-cell">Business Address</div>
            <div class="col-6 div-cell">
                {{cartItem.product.productBusiness.businessName}},
                {{cartItem.product.productBusiness.businessAddress.addressline1}},
                {{cartItem.product.productBusiness.businessAddress.streetname}},
                {{cartItem.product.productBusiness.businessAddress.city}},
                {{cartItem.product.productBusiness.businessAddress.zipcode}}</div>
        </div>
    </div>
    <br/>

    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6">Total</div>
        <div class="col-6 ">
            <p class="text-warning">{{cartTotal |
                currency:cartCurrency + ' '}}</p>

        </div>
    </div>

    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6">Delivery Cost</div>
        <div class="col-6 ">
            <p class="text-warning">{{deliveryCost |
                currency:cartCurrency + ' '}}</p>

        </div>
    </div>
    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6">Total Cost</div>
        <div class="col-6 ">
            <p class="text-warning">{{totalCost |
                currency:cartCurrency + ' '}}</p>

        </div>
    </div>

    <div class="panel-body table-responsive d-none d-md-block">
        <table class="table" id="cartTable">
            <thead>
            <tr>
                <th style="text-align: center">Product</th>
                <th style="text-align: center">Price</th>
                <th style="text-align: center">Quantity</th>
                <th *ngIf="isCustomerCollecting()" style="text-align: center">Business
                    Address
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cartItem of cartItems">
                <td style="text-align: center">
                    <div class="form-check form-check-inline">
                        <img style="height: 70px; width: 70px"
                             *ngIf="cartItem.product.defaultImageUrl; else elseBlock"
                             class="img-fluid" src="{{cartItem.product.defaultImageUrl}}"
                             alt="business logo"/>
                        <ng-template #elseBlock><img
                                src="../assets/img/image-not-available.jpg" alt="business logo"
                                style="height: 70px; width: 70px" class="img-fluid"/></ng-template>
                        <h5>{{cartItem.product.productName}}</h5>
                    </div>
                </td>
                <td style="text-align: center">
                    <h5 class="text-warning">{{cartItem.product.price
                        | currency: cartCurrency + ' '}}</h5></td>
                <td style="text-align: center">
                    <!-- TODO: Change this to an input. the on change wa snot triggering the function -->
                    <h5 class="text-warning">{{cartItem.quantity}}</h5>
                </td>

                <td *ngIf="isCustomerCollecting()"
                    style="text-align: center">{{cartItem.product.productBusiness.businessName}},
                    {{cartItem.product.productBusiness.businessAddress.addressline1}},
                    {{cartItem.product.productBusiness.businessAddress.streetname}},
                    {{cartItem.product.productBusiness.businessAddress.city}},
                    {{cartItem.product.productBusiness.businessAddress.zipcode}}</td>

            </tr>
            <tr>
                <td style="text-align: right"><h5>Total</h5></td>
                <td style="text-align: center"><p
                        class="text-warning">{{cartTotal | currency:
                cartCurrency + ' '}}</p></td>
                <td></td>
                <td></td>
            </tr>

            <tr>
                <td style="text-align: right"><h5>Delivery Cost</h5></td>
                <td style="text-align: center"><p
                        class="text-warning">{{deliveryCost | currency:
                cartCurrency + ' '}}</p></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style="text-align: right"><h5>Total Cost</h5></td>
                <td style="text-align: center"><p
                        class="text-warning">{{totalCost | currency:cartCurrency + ' '}}</p></td>
                <td></td>
                <td></td>
            </tr>

            </tbody>
        </table>
    </div>
    <div class="container">
        <div class="row">

            <div class="col-12 col-lg-3"></div>
            <div class="col-12 col-lg-6">

                <button class="btn btn-primary w-90"
                        type="button" (click)="goBack()">
					<span><img class="connect-btn-image"
                               src="../assets/img/add-to-cart36x36.png"></span> Continue Shopping
                </button>

            </div>
            <div class="col-12 col-lg-3"></div>
        </div>
    </div>

</div>
<myafrica-footer></myafrica-footer>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>

