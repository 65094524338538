import {NgModule} from '@angular/core';
import {RouterModule, UrlSerializer} from '@angular/router';
import {routerConfig} from './routerConfig';


@NgModule({
    imports: [RouterModule.forRoot(routerConfig, {
        scrollPositionRestoration: 'enabled',
        paramsInheritanceStrategy: 'always',
        malformedUriErrorHandler: (error: URIError, urlSerializer: UrlSerializer, url: string) =>
            urlSerializer.parse('/page-not-found')
        ,
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
