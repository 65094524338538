<div class="container pt-md-2 mt-5 mb-5 px-0">

    <div class="container align-middle col p-md-5 col-sm-12 col-md-8 p-0">
        <div class="card  text-dark shadow">
            <div class="card-header text-center h3 bg-primary text-white">
                RESET PASSWORD
            </div>

            <div class="card-body">
                <div class="alert alert-danger" role="alert"
                     *ngIf="confirmPassword.touched && password.touched && (password.value !== confirmPassword.value)">
                    The two new passwords do not match
                </div>

                <form (ngSubmit)="resetPassword()" #resetPasswordForm="ngForm" class="sm">
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="emailAddress">Email Address *</label>
                            <input type="email" class="form-control form-control-sm" id="emailAddress"
                                   [ngClass]="{'border border-danger':emailAddress.touched && emailAddress.invalid}"
                                   [(ngModel)]="email" name="email" required #emailAddress="ngModel"
                                   pattern="^\S*[@]\S*[.]\S*$">
                            <div *ngIf="emailAddress.touched && emailAddress.invalid " class="text-danger">valid
                                Email required
                            </div>

                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label for="oldPassword">Current Password *</label>
                            <input type="password" class="form-control form-control-sm" id="oldPassword"
                                   [ngClass]="{'border border-danger':oldPasswordField.touched && oldPasswordField.invalid}"
                                   [(ngModel)]="oldPassword" name="oldPassword" required #oldPasswordField="ngModel"
                                   minlength="8" maxlength="50">
                            <div *ngIf="oldPasswordField.touched && oldPasswordField.invalid " class="text-danger">
                                Current password is required
                            </div>

                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label for="emailAddress">New Password *</label>
                            <input type="password" class="form-control form-control-sm" id="password"
                                   [ngClass]="{'border border-danger':password.touched && password.invalid}"
                                   [(ngModel)]="pwd" name="password" required #password="ngModel"
                                   pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$">
                            <div *ngIf="password.touched ">
                                <p class="text-danger" *ngIf="password?.errors?.required">Password
                                    is required</p>
                                <p class="text-danger" *ngIf="password?.errors?.minlength">Password
                                    needs to be more than 8 characters.</p>
                                <p class="text-danger" *ngIf="password?.errors?.pattern">Must
                                    contain one lower &amp; uppercase letter, one special character
                                    and a number</p>
                            </div>

                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label for="confirmPassword">Confirm Password *</label>
                            <input type="password" class="form-control form-control-sm" id="confirmPassword"
                                   [ngClass]="{'border border-danger':confirmPassword.touched && confirmPassword.invalid}"
                                   [(ngModel)]="confirmPwd" name="confirmPassword" required #confirmPassword="ngModel"
                                   minlength="8" maxlength="50"/>
                            <div *ngIf="confirmPassword.touched && confirmPassword.invalid " class="text-danger">valid
                                Confirm password required
                            </div>

                        </div>
                    </div>


                    <hr class="bg-warning">
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block "
                                [disabled]="resetPasswordForm.form.invalid">
                            Reset Password
                        </button>

                    </div>
                    <hr class="bg-warning">
                    <div class="text-center pb-3">
                        <a class="text-decoration-none text-dark"
                           [routerLink]="'/login'"> SIGN IN</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


