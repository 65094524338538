import {AfterContentInit, Component, OnInit} from '@angular/core';
import {CartItem} from '../model/CartItem';
import {Router} from '@angular/router';
import {ApplicationStoreService} from '../services/application-store.service';
import {CartItemsService} from '../services/cart-items.service';
import Swal from 'sweetalert2';
import {Product} from '../model/Product';
import {Observable, of} from 'rxjs';
import {ClothingColour} from '../model/ClothingColour';
import {ClothingSize} from '../model/ClothingSize';
import {NotifierService} from '../services/notifier.service';
import {SessionScopeValues} from '../shared/SessionScopeValues';

@Component({
    selector: 'myafrica-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit, AfterContentInit {
    cartCurrency = 'ZAR';
    closeResult: string;
    deliveryMethod = 'delivery';
    cartTotal: number;
    cartCount: number;
    selectedCartItem: CartItem;

    shoppingCartItems$: Observable<Array<CartItem>>;
    selectedProduct: Product;
    clothingSizes: Array<ClothingSize>;

    constructor(private router: Router,
                private cartService: CartItemsService,
                private appStore: ApplicationStoreService,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct')) as Product;
        this.appStore.deleteItem('ORDER_NO');
        this.shoppingCartItems$ = of(this.cartService.getCart());
        this.cartTotal = 0;
    }

    removeItem(cartItem: CartItem): void {
        Swal.fire({
            showCancelButton: true,
            cancelButtonText: 'NO Do not Remove',
            confirmButtonColor: 'red',
            confirmButtonText: 'Yes remove from cart',
            cancelButtonColor: '#008eb0',
            html: '<div style="padding: 5px; margin: auto;">' +
                '<img class="img-fluid mx-auto p-1" src="https://www.myafricaconnect.com/images/proudly-african.png">' +
                '<p class="py-1 h4">' + `Remove "${cartItem.product.productName}" from Cart?` + '</p>' +
                '</div>',

        }).then((result) => {
            if (result.isConfirmed) {
                this.cartService.removeCartItem(cartItem);
                this.updateCartCounts();
            }
        });


    }


    clearAllCartItems(): void {
        Swal.fire({
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: 'red',
            confirmButtonText: 'Yes clear everything',
            cancelButtonColor: '#008eb0',
            showDenyButton: false,
            html: '<div style="padding: 5px; margin: auto;">' +
                '<img class="img-fluid mx-auto p-1" src="https://www.myafricaconnect.com/images/proudly-african.png">' +
                '<p class="py-1 h2">Are you sure you want to clear everything in your shopping cart?</p>' +
                '</div>',
        }).then((result) => {
            if (result.isConfirmed) {
                this.cartService.clearCart();
                this.updateCartCounts();
            }
        });


    }

    getSelectedImage(cartItem: CartItem): string {
        try {
            if ('clothing' === cartItem.product.productCategory) {
                return cartItem
                    .product
                    .colours
                    .filter(colour => colour.colourName === cartItem.productColour)
                    .reduce((item) => item)
                    .imageUrl;
            }
        } catch (e) {
            return cartItem.product.defaultImageUrl;
        }
        return cartItem.product.defaultImageUrl;
    }

    ngAfterContentInit() {
        this.updateCartCounts();
    }

    updateCartCounts() {
        this.updateValues();
        this.shoppingCartItems$ = of(this.cartService.getCart());
    }

    updateValues() {
        this.cartTotal = 0;
        this.cartCount = 0;
        const cart = this.appStore.getJsonItem('cart');
        if (cart) {
            cart.forEach(cartItem => {
                this.cartTotal += cartItem.product.price * cartItem.quantity;
                this.cartCount += cartItem.quantity;
            });
        }
    }

    goBack() {
        this.router.navigate(['']).then();
    }

    goToDeliveryOptions() {

		sessionStorage.setItem(SessionScopeValues.DELIVERY_METHOD, this.deliveryMethod);

		//shopper need to register login or register
		if(sessionStorage.getItem('userDetails') === null || sessionStorage.getItem('userDetails')  === undefined) {
			 Swal.fire({
            showCancelButton: true,
            confirmButtonColor: '#008eb0',
            confirmButtonText: 'Yes - Login',
            cancelButtonText: 'No  - Register',
            cancelButtonColor: '#008eb0',
            showDenyButton: false,
            html: '<div style="padding: 5px; margin: auto;">' +
			    '<p class="py-1 h2">To proceed you need to be registered and logged in.</p>' +
                '<p class="py-1 h2">Are you registered already?</p>' +
                '</div>',
			}).then((result) => {
				if (result.isConfirmed) {
				  sessionStorage.setItem(SessionScopeValues.SHOPPING_LOGIN_REQUIRED, 'true');
				  this.router.navigate(['login']).then();
				} else {
				  sessionStorage.setItem('shoppingRegisterRequired', 'true');
				  sessionStorage.setItem('user-type', 'Shopper');
				  this.router.navigate(['user-registration']).then();
				}
			});
			return;
		
		}	
		

        this.appStore.storeItem(SessionScopeValues.DELIVERY_METHOD, this.deliveryMethod);

        if (this.deliveryMethod === 'delivery') {
            this.router.navigate(['/shopping/view-delivery-options']).then();
        } else {
            this.router.navigate(['view/collect-option']).then();
        }
    }

    setRadioValue(value: string) {
        this.deliveryMethod = value;
    }

    selectCartItem(cartItem: CartItem) {
        this.selectedCartItem = this.cartService.getACartItem(cartItem);
    }

    updateCart(cartItem: CartItem) {
        this.validateCounts(cartItem);
        this.cartService.addToCart(cartItem);
        this.updateValues();
    }

    viewProduct(cartItem: CartItem) {
        console.log('Getting here');
        of(this.appStore.deleteItem('currentProduct')).subscribe(() => {
            this.appStore.storeStringItem('currentProduct', cartItem.product);
            if ('clothing' === cartItem.product.productCategory) {
                this.router.navigate([`/shopping/${cartItem.product.productCategory}`]).then();
            } else {
                this.router.navigate(['/shopping']).then();
            }
        }, () => {
        });
    }


    updateCartItem() {
        this.cartService.updateACartItem();
        this.updateValues();
        this.shoppingCartItems$ = of(this.cartService.getCart());
    }

    filterSizes(clothingColour: ClothingColour) {
        this.selectedCartItem.productColour = clothingColour.colourName;
        this.selectedCartItem.image = clothingColour.imageUrl;
        this.clothingSizes = clothingColour.clothingSizes;
        this.setSizeSelected(this.clothingSizes[0]);
    }

    setSizeSelected(clothingSize: ClothingSize) {
        this.selectedCartItem.productSize = clothingSize.name;
        this.selectedCartItem.productSizeId = clothingSize.id;
        this.selectedCartItem.maxCount = clothingSize.count;
        clothingSize.active = true;
        this.clothingSizes.filter(size => size.name !== clothingSize.name)
            .forEach(entry => entry.active = false);
    }

    validateCounts(cartItem: CartItem) {
        if (cartItem.quantity > cartItem.maxCount) {
            this.notifier.notifyError(`We only have ${cartItem.maxCount} in stock`);
            cartItem.quantity = cartItem.maxCount;
            return;
        }
    }

    clearCart(): void {
        this.clearAllCartItems();
    }
}
