<myafrica-breadcrumbs title="Businesses"></myafrica-breadcrumbs>
<div class="container">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 mb-2">
            <div class="input-group">
                <input type="text" [(ngModel)]="businessNameSearch"
                       class="form-control search-input"
                       placeholder="What are you looking for..."
                       (keyup.enter)="searchForBusiness()">
                <div class="input-group-append">
                    <button class="btn text-white text-center  bg-success"
                            type="button" (click)="searchForBusiness()">
                        <i  class="fa fa-search" aria-hidden="true"></i>
                    </button>

                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ml-auto">
            <button class="btn text-white text-center  bg-primary w-100" type="button"
                    (click)="open(content)">
                <i  class="fa fa-plus" aria-hidden="true"></i>
                Add Business
            </button>
        </div>

    </div>
    <br/>


    <div class="container mb-3 p-0">
        <div class="row">
            <div class="col-sm-12 col-md-4 p-0" *ngFor="let business of businesses">
                <myafrica-admin-business-item [business]="business"></myafrica-admin-business-item>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="form-panel col-lg-12 col-md-12 col-sm-12 mt-2">
        <form class="form-horizontal style-form" #businessForm="ngForm">

            <div class="bg-primary text-white rounded">
                <h2 class="form-heading p-3 text-center">Business Details</h2>
            </div>
            <div class="modal-body">

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="control-label">Business Name</label> <input
                            type="text"
                            class="form-control"
                            name="businessName" required [(ngModel)]="businessName"
                            [maxlength]="100">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="control-label">Category</label> <select required
                                                                              [(ngModel)]="businessCategory"
                                                                              name="businessCategory"
                                                                              class="form-control">
                        <option [value]="category.categoryId"
                                *ngFor="let category of businessCategories">{{category.description}}
                        </option>

                    </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="control-label">Years In Business</label> <input
                            type="number" class="form-control" name="yearsInBusiness"
                            [maxlength]="3" numbersOnly [(ngModel)]="yearsInBusiness"
                            required placeholder="Numbers only">

                    </div>
                    <div class="form-group col-md-6">
				<label class="control-label">Email</label> 
				<input type="email"	class="form-control" name="businessEmail" 
				required [(ngModel)]="businessEmail" [maxlength]="100" >
			</div>
                </div>
                <div class="form-row">
                    <div class="form-group">
                        <div class="form-row">
                            <label class="control-label">Reg Number</label>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="2020" maxlength="4"
                                       name="regNumberPart1" [(ngModel)]="regNumberPart1">
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="123456" maxlength="6"
                                       name="regNumberPart2" [(ngModel)]="regNumberPart2">
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" placeholder="07" maxlength="2"
                                       name="regNumberPart3" [(ngModel)]="regNumberPart3">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="form-group row bg-primary text-white rounded">
                    <div class="col-12">
                        <h3 class="form-heading p-3 text-center ">Business Address</h3>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="control-label">Address Line 1</label> <input
                            type="text"
                            class="form-control"
                            name="addressline1" [(ngModel)]="addressline1"
                            required>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="control-label">Street Name</label> <input
                            type="text"
                            class="form-control"
                            name="streetname" [(ngModel)]="streetname"
                            required>
                    </div>
                </div>


                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label class="control-label">City</label> <input type="text"
                                                                         class="form-control"
                                                                         name="city" [(ngModel)]="city"
                                                                         required>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="control-label">Province</label> <select required
                                                                              name="businessProvince"
                                                                              class="form-control"
                                                                              [(ngModel)]="businessProvince">
                        <option [value]="province.id"
                                *ngFor="let province of provinces">{{province.name}}</option>

                    </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="control-label">Zip Code</label> <input type="text"
                                                                             class="form-control"
                                                                             name="zipcode" [maxlength]="4" numbersOnly
                                                                             [(ngModel)]="zipcode" required>
                    </div>

                </div>

                <div class="row">
                    <div class="col-6 ">
                        <button type="button" (click)="addBusiness(business, modal)"
                                [disabled]="!businessForm.form.valid"
                                class="btn btn-primary btn-block">Add
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="modal.close('')"
                                class="btn btn-danger btn-block">Cancel
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>


</ng-template>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
