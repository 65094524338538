<div class="container-fluid">
    <div class="row">
        <div class="col-3 bg-primary p-2">
            <aside class="min-vh-100 w-25 position-fixed m-auto py-1">
                <div class="col-sm-12  text-white">

            <span class="col-sm-12 my-2" routerLink="/dashboard">
                <i class="fa fa-home fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Home</span>
            </span>
                    <hr class="bg-danger shadow">
                    <span class="col-sm-12 my-2" (click)="navigate('profile')">
                <i class="fa fa-user fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Profile</span>
            </span>
                    <hr class="bg-success shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-businesses']}}]"
                          skipLocationChange (click)="storePath('manage-businesses')">
                <i class="fa fa-building fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Manage Businesses</span>
            </span>
                    <hr class="bg-warning shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-products']}}]"
                          skipLocationChange (click)="storePath('manage-products')">
                <i class="fa fa-shopping-basket fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Manage Products</span>
            </span>
                    <hr class="bg-info shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-orders']}}]"
                          skipLocationChange (click)="storePath('manage-orders')">
                <i class="fa fa-shopping-cart fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Manage Orders</span>
            </span>
                    <hr class="bg-light shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-returns']}}]"
                          skipLocationChange (click)="storePath('manage-returns')">
                <i class="fa fa-truck-loading fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Manage Returns</span>
            </span>
                    <hr class="bg-success shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['reset-password']}}]"
                          skipLocationChange (click)="storePath('reset-password')">
                <i class="fa fa-lock fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Reset Password</span>
            </span>
                    <hr class="bg-warning shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['vendor-agreement']}}]"
                          skipLocationChange (click)="storePath('vendor-agreement')">
                <i class="fa fa-sticky-note fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Merchant Agreement</span>
            </span>

                    <hr class="bg-info shadow">
                    <span class="col-sm-12 my-2"
                          [routerLink]="['/dashboard', {outlets: {'dashboard': ['transactions']}}]"
                          skipLocationChange (click)="storePath('transactions')">
                <i class="fa fa-dollar-sign fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Transactions</span>
            </span>
                    <hr class="bg-white shadow">
                </div>
            </aside>
        </div>
        <div class="col-9 mb-3 mx-0 px-1">
            <div class="container-fluid min-vh-100 overflow-hidden p-1">
                <router-outlet name="dashboard" class="container-fluid px-0"></router-outlet>
            </div>
        </div>
    </div>
</div>
