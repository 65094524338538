export class ContactUsMessage {
    messageId: number;
    messagersEmail: string;
    messagersContactNumber: string;
    message: string;
    businessId: string;
    messageDate: string;
    messageType: string;
    messagersName: string;
    links: string[];
    messagersSurname: string;
}
