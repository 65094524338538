<div class="col-12 rounded-bottom rounded-top my-2 text-center w-100 shadow bg-white" (mouseover)="alert()"
     (mouseout)="closeAlert()"
     [ngClass]="class">
    <div class="row">
        <div class="col-12 text-center m-1 p-1 mx-auto">
            <div>
                <myafrica-out-of-stock [product]="product" class="text-left card-img-overlay"
                                       (click)="handleClick(product)"></myafrica-out-of-stock>
                <div style="height: 200px !important;" class="text-center pt-2">
                    <img class="img-fluid " [src]="product.defaultImageUrl" [alt]="product.productName"
                         (click)="handleClick(product)">
                </div>
                <div class="card-body">
                    <h5 class=" text-dark text-center">{{product.productName | truncate: 18}}&nbsp;</h5>
                    <p>{{product.productDescription | truncate: 21}}&nbsp;</p>
                    <myafrica-product-price [product]="product"></myafrica-product-price>
                </div>
                <div class="card-footer bg-white border-0 position-sticky">
                    <myafrica-add-to-basket-button [cartItem]="cartItem"></myafrica-add-to-basket-button>
                </div>
            </div>
        </div>
    </div>
</div>

