<myafrica-page-title pageTitle="PAYMENT DETAILS"></myafrica-page-title>

<div class="container pb-3">

    <div class="bg-light p-3 rounded shadow-lg">
        <div class="bg-light">
            <p class="order-success-message-bold">Thank you for shopping on
                MyAfricaConnect!</p>

        </div>
        <div class="bg-light">
            <p class="order-success-message-bold">We have reserved the items for you until you have made a payment, you have 24 hours to make a payment. Failure to do so will result in cancellation your order.
                Please use the following details to pay
                and email proof of payment to MyAfricaConnect at payments@myafricaconnect.com</p>

        </div>

        <div *ngIf="isCustomerCollecting()" class="bg-light">
            <p class="order-success-message-bold">Once payment has been
                processed you will receive notification to collect your items.</p>

        </div>
    </div>
    <div class="row my-5 py-3">
        <div class="col-12 col-lg-10">
            <div class="row">
                <div class="container">
                    <div class="row">
                        <div class="col-5 col-lg-2 font-weight-bold">Order/Ref No:</div>
                        <div class="col-7 col-lg-6">{{orderNo}}</div>
                        <div class="col-5 col-lg-2 font-weight-bold">Bank Name:</div>
                        <div class="col-7 col-lg-2">{{bankName}}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="container">
                    <div class="row">
                        <div class="col-5 col-lg-2 font-weight-bold">Account No:</div>
                        <div class="col-7 col-lg-6">{{bankAccountNumber}}</div>
                        <div class="col-5 col-lg-2 font-weight-bold">Branch:</div>
                        <div class="col-7 col-lg-2">{{bankBranch}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-2">
			<span><img class="img-fluid m-auto"
                       style="height: 50px; "
                       src="https://myafricaconnect.com/images/fnblogo.png"></span>
        </div>

    </div>
    <br/>

    <div class="container product-items-div d-md-none d-lg-none" *ngFor="let cartItem of cartItems">
        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell  font-weight-bold">
                <span>Product</span>
            </div>
            <div class="col-6 div-cell d-md-none d-lg-none text-center">
                <img *ngIf="cartItem.image; else elseBlock"
                     class="img-thumbnail px-2 rounded shadow" style="height: 80px;"
                     src="{{cartItem.image}}"/>
                <ng-template #elseBlock>
                    <img src="https://myafricaconnect.com/images/image-not-available.jpg"
                         alt="business logo" style="height: 80px; "
                         class="img-fluid rounded shadow px-2"/></ng-template>
                {{cartItem.product.productName}}
                <span *ngIf="cartItem.productColour" class="mx-1 p-1 rounded"
                      [ngStyle]="{'background-color': cartItem.productColour,'border': '2px solid blue'}"
                      [ngClass]="cartItem.productColour === '#999999A' ? 'multicolourdiv' : ''">&nbsp;&nbsp;</span>
                <span *ngIf="cartItem.productSize" class="mx-1 p-1 rounded text-primary"
                      [ngStyle]="{ 'border': '1px solid blue'}">{{cartItem.productSize}}</span>
            </div>
        </div>

        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell font-weight-bold">
                <span>Price</span>
            </div>
            <div class="col-6 text-warning">{{cartItem.product.price| currency:cartCurrency}}</div>
        </div>


        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell font-weight-bold">
                <span>Quantity</span>
            </div>
            <div class="col-6 text-warning">{{cartItem.quantity}}</div>
        </div>


        <div class="row d-md-none d-lg-none" *ngIf="isCustomerCollecting()">
            <div class="col-6 div-cell font-weight-bold">Business Address</div>
            <div class="col-6 div-cell">
                {{cartItem.product.productBusiness.businessName}},
                {{cartItem.product.productBusiness.businessAddress.addressline1}},
                {{cartItem.product.productBusiness.businessAddress.streetname}},
                {{cartItem.product.productBusiness.businessAddress.city}},
                {{cartItem.product.productBusiness.businessAddress.zipcode}}</div>
        </div>
    </div>
    <br/>

    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6 font-weight-bold">Total</div>
        <div class="col-6 ">
            <p class="product-div-price-orange">{{cartTotal |currency:cartCurrency}}</p>
        </div>
    </div>

    <div class="panel-body table-responsive d-none d-md-block">
        <table class="table" id="cartTable">
            <thead class="table-success shadow-lg">
            <tr>
                <th style="text-align: center" class="font-weight-bold">Product</th>
                <th style="text-align: center" class="font-weight-bold">Price</th>
                <th style="text-align: center" class="font-weight-bold">Quantity</th>
                <th *ngIf="isCustomerCollecting()" style="text-align: center" class="font-weight-bold">Business
                    Address
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cartItem of cartItems">
                <td style="text-align: left">
                    <div class="form-check form-check-inline">
                        <img style="height: 70px;"
                             *ngIf="cartItem.image; else elseBlock"
                             class="img-fluid rounded shadow p-1 mx-1" src="{{cartItem.image}}"
                             alt="Cart item image"/>
                        <ng-template #elseBlock><img
                                src="https://myafricaconnect.com/images/image-not-available.jpg" alt="business logo"
                                style="height: 70px;" class="img-fluid rounded p-1 mx-1"/></ng-template>
                        <span class="float-right">
                            <h5 class="mx-1">{{cartItem.product.productName}}</h5>
                        <span *ngIf="cartItem.productColour" class="mx-1 p-1 rounded"
                              [ngStyle]="{'background-color': cartItem.productColour, 'border' : '2px solid blue'}"
                              [ngClass]="cartItem.productColour === '#999999A' ? 'multicolourdiv' : ''">&nbsp;&nbsp;</span>
                        <span *ngIf="cartItem.productSize" class="mx-1 p-1 text-primary rounded"
                              [ngStyle]="{'border': '2px solid blue'}">{{cartItem.productSize}}</span>
                        </span>
                    </div>
                </td>
                <td style="text-align: center"><p
                        class="product-div-price-orange">{{cartItem.product.price| currency: cartCurrency}}</p></td>
                <td style="text-align: center">
                    <!-- TODO: Change this to an input. the on change wa snot triggering the function -->
                    <h5>{{cartItem.quantity}}</h5>
                </td>

                <td *ngIf="isCustomerCollecting()"
                    style="text-align: center">{{cartItem.product.productBusiness.businessName}},
                    {{cartItem.product.productBusiness.businessAddress.addressline1}},
                    {{cartItem.product.productBusiness.businessAddress.streetname}},
                    {{cartItem.product.productBusiness.businessAddress.city}},
                    {{cartItem.product.productBusiness.businessAddress.zipcode}}</td>
            </tr>
            <tr>
                <td style="text-align: right"><h5 class=" font-weight-bold">Total</h5></td>
                <td style="text-align: center"><p
                        class="font-weight-bold text-warning">{{cartTotal | currency:cartCurrency}}</p></td>
                <td></td>
                <td></td>
            </tr>

            </tbody>
        </table>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 py-5">
                <button class="btn btn-primary mx-auto" type="button" (click)="goBack()">
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i> Continue Shopping
                </button>
            </div>
        </div>
    </div>

</div>
