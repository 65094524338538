<div class="input-group my-3 ">
    <div class="input-group-prepend">
        <button class="btn btn-success" (click)="subtractQuantity()" [disabled]="isDisabled()">
            <i class="fa fa-minus fa-1x" aria-hidden="true"></i>
        </button>
    </div>

    <input type="number" class="form-control border-warning text-right" [(ngModel)]="cartItem.quantity" value="1"
           (ngModelChange)="validateCounts()">

    <div class="input-group-append">
        <button class="btn btn-success" (click)="addQuantity()" [disabled]="isDisabled()">
            <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
        </button>
    </div>
</div>
