import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Product} from '../../model/Product';
import {ClothingService} from '../clothing.service';

@Injectable()
export class ClothingResolver implements Resolve<Array<Product>> {
    constructor(private clothingService: ClothingService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Product>> {
        return this.clothingService.getAllClothingProducts();
    }

}
