import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../model/Product';
import {of} from 'rxjs';
import {ClothingSize} from '../model/ClothingSize';
import {Router} from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';
import {ClothingColour} from '../model/ClothingColour';
import {environment} from '../../environments/environment';
import {ApplicationStoreService} from '../services/application-store.service';
import {NotifierService} from '../services/notifier.service';
import {CartItem} from '../model/CartItem';


@Component({
    selector: 'myafrica-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit, OnDestroy {

    @Input() product: Product;

    clothingSizes: ClothingSize[];
    defaultImageUrl: string;

    cartItem: CartItem;
    class: string;

    constructor(private router: Router,
                private appStore: ApplicationStoreService,
                private clipboardService: ClipboardService,
                private notifierService: NotifierService) {
    }

    ngOnInit() {
        this.setDefaults();
        this.defaultImageUrl = this.product.defaultImageUrl;
        if (this.product.colours != null) {
            let count = 0;
            this.product.colours.forEach(element => {
                if (count === 0) {
                    this.defaultImageUrl = element.imageUrl;
                }
                count++;
            });
        }
    }


    filterSizes(colour: ClothingColour) {

        this.product.colours.forEach(element => {
            if (element.id === colour.id) {
                this.clothingSizes = [];
                let count = 0;

                this.defaultImageUrl = colour.imageUrl;

                element.clothingSizes.forEach(innerElement => {

                    const newColour = new ClothingSize();

                    newColour.id = innerElement.id;
                    newColour.name = innerElement.name;
                    newColour.count = innerElement.count;
                    newColour.active = false;

                    this.clothingSizes[count] = newColour;
                    count++;
                });
            }
        });

    }

    alert() {
        this.class = 'border border-info';
    }

    closeAlert() {
        this.class = '';
    }

    handleClick(product: Product) {
        of(this.appStore.deleteItem('currentProduct')).subscribe(() => {
    		this.appStore.storeStringItem('currentProduct', product);
			sessionStorage.setItem('categoryId', ''+product.categoryId); 
    		sessionStorage.setItem('searchProductName', "all"); 
			this.navigate(product);
        }, () => {
        });
    }

	navigate(product: Product) {
		var isClothingCategory = false;
	     if (product.category.clothingCategory) {
				isClothingCategory = true;
			} 
			
		if('/shopping/clothing' === this.router.url || '/shopping' === this.router.url) {
			 if(isClothingCategory) {
				 this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
					 this.router.navigate(['/shopping/clothing']).then();
				});
			 } else {
				 this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
					 this.router.navigate(['/shopping']).then();
				});

			 }
		} else {
			 if(isClothingCategory) {
			  this.router.navigate(['/shopping/clothing']).then();
			 } else {
			  this.router.navigate(['/shopping']).then();
			 }

		}
	}

    viewHistory(product: Product) {
        of(this.appStore.deleteItem('currentProduct')).subscribe(() => {
            this.appStore.storeStringItem('currentProduct', product);
            this.router.navigate([`/product-history`]).then();
        });
    }

    copy(product: Product) {
        of(this.clipboardService.copy(`${environment.mainUrl}/sharing/${product.sharingLink}`)).subscribe(() => {
            this.showNotification(`Link Copied to Clipboard`);
        });
    }

    public showNotification(message: string): void {
        this.notifierService.notify(message);
    }

    ngOnDestroy() {

    }

    setDefaults(): void {
        this.cartItem = new CartItem();
        this.cartItem.product = this.product;
        this.cartItem.quantity = 1;
        this.cartItem.image = this.product.defaultImageUrl;
        if ('clothing' === this.product.productCategory.toLowerCase().trim()) {
            if (this.product.colours !== null && this.product.colours[0] !== undefined) {
                this.cartItem.productColour = this.product.colours[0].colourName;
                this.cartItem.productSize = this.product.colours[0].clothingSizes[0].name;

            }
        }
    }
}
