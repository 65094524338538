
<div
	class="form-panel col-lg-12 col-md-12 col-sm-12 form-group required control-label">
	<form class="form-horizontal style-form" #addressForm="ngForm">


		<div class=" row form-group">
			<div class="col-12">
				<h3 class="form-heading">Address Details</h3>
			</div>
		</div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label class="control-label">Address Line 1</label>
     <input type="text"
					[ngClass]="[ addressline1Class ? 'form-control input-required-shadow-border' : 'form-control']"
					name="addressline1" [(ngModel)]="addressline1"
					(ngModelChange)="dataChanged()" required>
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Street Name</label>
     <input type="text"
					[ngClass]="[ streetnameClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="streetname" [(ngModel)]="streetname"
					(ngModelChange)="dataChanged()" required>
    </div>
  </div>


		<div class="form-row">
			<div class="form-group col-md-4">
				<label class="control-label">City</label>
				<input type="text"
					[ngClass]="[ cityClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="city" [(ngModel)]="city" (ngModelChange)="dataChanged()"
					required>
			</div>
			<div class="form-group col-md-4">
				<label class="control-label">Province</label>
				<select required name="businessProvince"
					[ngClass]="[ businessProvinceClass ? 'form-control input-required-shadow-border' : 'form-control']"
					(ngModelChange)="dataChanged()" [(ngModel)]="businessProvinceId">
					<option [value]="province.id"
						*ngFor="let province of provinces">{{province.name}}</option>

				</select>
			</div>
			<div class="form-group col-md-4">
				<label class="control-label">Zip Code</label>
				<input type="text"
					[ngClass]="[ zipcodeClass ? 'form-control input-required-shadow-border' : 'form-control']"
					name="zipcode" [maxlength]="4" numbersOnly
					(ngModelChange)="dataChanged()" [(ngModel)]="zipcode" required>
			</div>

		</div>

		<div class="modal-footer">
			<button type="button" (click)="updateBusinessAddress()"
				[disabled]="!addressForm.form.valid" class="btn btn-success">Update</button>
		</div>

	</form>
</div>
