import { Component, OnInit } from '@angular/core';
import {DroppaBookingRequestInfo} from '../../../model/DroppaBookingRequestInfo';
import { DatePipe } from '@angular/common';
import {CustomerOrder} from '../../../model/CustomerOrder';
import {OrderDelivery} from '../../../model/OrderDelivery';
import {OrderDeliveryService} from '../../../services/order.delivery.service';
import {NotifierService} from '../../../services/notifier.service';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'myafrica-order-delivery',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.scss']
})
export class OrderDeliveryComponent implements OnInit {

  selectedCustomerOrder: CustomerOrder;
  disableRequestBookingButton: boolean;
  bookingDate = new Date();
  loading = false;
  profileId: string;
  comment: string;
  
  deliveryNo: string;
  trackNo: string;
  pickUpate: string;
  orderDeliveries: any[];
  orderDelivery: OrderDelivery;
  vendorOrderStatus: string;
  

  constructor(private orderDeliveryService: OrderDeliveryService,
			  private datePipe: DatePipe,
			  private notifier: NotifierService,
			  private appStore: ApplicationStore,
			  private router: Router) { }

  ngOnInit(): void {
	  this.profileId = this.appStore.getJsonItem('profileId');
	  this.selectedCustomerOrder = JSON.parse(sessionStorage.getItem('selectedCustomerOrder'));
	  this.comment = this.selectedCustomerOrder.deliveryInstructions;
	  if(this.selectedCustomerOrder.customerOrderDeliveries !== undefined && this.selectedCustomerOrder.customerOrderDeliveries.length > 0) {
		this.orderDelivery = this.selectedCustomerOrder.customerOrderDeliveries[0];

		this.deliveryNo =  this.orderDelivery.deliveryNo;
	    this.trackNo =  this.orderDelivery.trackNo;
		this.pickUpate = this.orderDelivery.pickUpDate + " " + this.orderDelivery.pickUpTime;
		this.comment = this.orderDelivery.comment;
		this.vendorOrderStatus = this.orderDelivery.vendorOrderStatus;
	  }
	  this.setCreateButtonFlag();
  }
  
  
  requestBooking() {
		this.loading = true;
		const bookingRequest = new DroppaBookingRequestInfo(); 
		let formattedDate = this.datePipe.transform(this.bookingDate, 'yyyy-MM-ddTHH:mm');
		formattedDate = formattedDate + ":00";
		bookingRequest.orderNo = this.selectedCustomerOrder.orderId;		
		bookingRequest.transportMode = "ROAD";
		bookingRequest.serviceType = "BUDGET_COURIER";
		bookingRequest.collectionDate = formattedDate;
		bookingRequest.profileId = Number(this.profileId);
		bookingRequest.comment = this.comment;
		
		  this.orderDeliveryService.requestBooking(bookingRequest).subscribe((value: HttpResponse<OrderDelivery>) => {
    	   	this.orderDelivery = value.body;
			this.deliveryNo =  this.orderDelivery.deliveryNo;
			this.trackNo = this.orderDelivery.trackNo;
			this.pickUpate =this.orderDelivery.pickUpDate + " " + this.orderDelivery.pickUpTime;
			this.comment = this.orderDelivery.comment;
			this.vendorOrderStatus = this.orderDelivery.vendorOrderStatus;
			this.disableRequestBookingButton = true;
			this.loading = false;
            this.notifier.notify( 'Booking with the courier created successfully!!!', 'success');

        }, error1 => {
	        this.loading = false;
            this.notifier.notify('Failed to create a booking with the courier. Please try again later!', 'error');
        });
		
	}
	
	setCreateButtonFlag() {
		this.disableRequestBookingButton = false;
	    if(this.selectedCustomerOrder.orderStatus !== 'ORDERPAID' && this.selectedCustomerOrder.orderStatus !== 'WAREHOUSE_PACKAGING') {
			this.disableRequestBookingButton = true;
		}
   	
   }
	
	getDisableRequestBookingButtonState() {
		return this.disableRequestBookingButton;
	}
	
   navigate(path: string) {

	   	 sessionStorage.setItem('navigationOutlet', 'adminclerkdashboard');

        this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}], {skipLocationChange: true}).then();
    }

    manageOrders() {
		sessionStorage.setItem('selectedCustomerOrder', undefined);
		
		 this.navigate('admin-manage-orders');

    }

}
