import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Subscription} from 'rxjs';
import {CartItemsService} from '../../services/cart-items.service';
import {CartItem} from '../../model/CartItem';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';


@Component({
    selector: 'myafrica-eft-place-order-success',
    templateUrl: './eft-place-order-success.component.html',
    styleUrls: ['./eft-place-order-success.component.scss'],
})
export class EftPlaceOrderSuccessComponent implements OnInit, OnDestroy {

    cartItems: CartItem[] = [];
    cartTotal = 0;
    subs: Subscription;
    subs2: Subscription;
    loading: boolean;
    displayErrorMessage: boolean;
    totalCost = 0;
    cartCurrency = 'ZAR';
    deliveryCost = 100;
    productFees: string;

    constructor(private appStore: ApplicationStore, private cartService: CartItemsService, private tableService: CodeTablesServiceService,
                private router: Router, private ordersService: OrdersServiceService) {
    }

    ngOnInit() {
        this.cartTotal = 0;
        const cart = this.appStore.getJsonItem('cart');

        if (cart === null || cart === undefined) {

            this.router.navigate(['']);
            return;
        }
        this.cartItems = cart.map(cartItem => {
            const result = cartItem;
            this.cartTotal = this.cartTotal + (result.product.price * result.quantity);
            return result;
        });

        this.subs2 = this.tableService.getProductFees().subscribe((value: any) => {
                this.productFees = value;
                const productFeesObject = JSON.parse(JSON.stringify(this.productFees));

                this.deliveryCost = productFeesObject.courierFee;
                this.totalCost = this.cartTotal + this.deliveryCost;
            },
        );


        this.updateOrderStatus();

        this.appStore.deleteItem('cart');
        this.cartService.updateCount(0);
	    this.cartService.clearCart();
    }


    updateOrderStatus() {
        this.loading = true;
        const orderNo = this.appStore.getItem('ORDER_NO');
        this.subs = this.ordersService.updateOrderStatusToPaid(orderNo).subscribe(value => {
                this.loading = false;
                this.displayErrorMessage = false;
            }, error1 => {
                this.loading = false;
                this.displayErrorMessage = true;

            },
        );

    }

    isCustomerCollecting() {
        if (this.appStore.getItem('DELIVERY_METHOD') === 'collect') {
            return true;
        }
        return false;

    }

    goBack() {
        this.appStore.deleteItem('cart');

        this.router.navigate(['']);

    }

    ngOnDestroy() {
        this.appStore.deleteItem('cart');
        this.cartService.updateCount(0);
    }


}
