import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class SearchFieldService {

    constructor(private http: HttpClient) {
    }

    getSearchResult(category: string, searchString: string) {
        return this.http.get(
            `myafrica-api/product/allproducts/${searchString}/12/0/${category}/undefined/getpaginationproductsbyname`,
            {observe: 'response'},
        );
    }
}
