import {Component, Input, OnInit} from '@angular/core';
import {BusinessItemService} from '../services/business-item.service';
import {Router} from '@angular/router';
import {Business, businessWithUpdatedImages} from '../model/Business';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'myafrica-business-item',
    templateUrl: './business-item.component.html',
    styleUrls: ['./business-item.component.scss'],
})
export class BusinessItemComponent implements OnInit {

    @Input() business: Business;

    constructor(private businessService: BusinessItemService,
                private router: Router) {
    }

    ngOnInit() {
    }

    showBusinessDetails() {
        this.businessService.getBusinessDetails(this.business.businessId).subscribe((value: HttpResponse<Business>) => {
                sessionStorage.setItem('selectedBusiness', JSON.stringify(businessWithUpdatedImages(value.body)));
                this.router.navigate(['business-details']).then();
            },
        );
    }

    hasLogo(business: Business): boolean {
        return business.logoUrl !== undefined;
    }

}
