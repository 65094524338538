import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, of, Subscription} from 'rxjs';
import {Product} from '../model/Product';
import {businessWithUpdatedImages} from '../model/Business';
import {HttpResponse} from '@angular/common/http';
import {ProductHistory} from '../model/ProductHistory';
import {ProductService} from '../services/product.service';
import {HistoryService} from '../services/history.service';

@Component({
    selector: 'myafrica-product-view',
    templateUrl: './product-view.component.html',
    styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements OnInit, OnDestroy {

    allProducts;
    productBusiness;
    loading = false;
    subs: Subscription;

    selectedProduct: Product;
    htmlContent: any;

    constructor(private productService: ProductService,
                private historyService: HistoryService) {
    }

    ngOnInit() {

        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
        if (this.selectedProduct) {
            this.makeCalls();
        }
    }

    viewHistory(product: Product) {
        this.historyService.getHistory(product.productId).subscribe((result: HttpResponse<ProductHistory>) => {
            this.htmlContent = result.body ? result.body.history : null;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    reloadPage() {

        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
        // window.location.reload(true);
        this.makeCalls();
    }

    private makeCalls(): void {

        this.loading = true;
		this.productService.updateProductViewCount(this.selectedProduct.id);
        
       
        this.viewHistory(this.selectedProduct);
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
}
