<div
	class="form-panel col-lg-12 col-md-12 col-sm-12 form-group required control-label">
	<form class="form-horizontal style-form" #bankingForm="ngForm">


		<div class=" row form-group">
			<div class="col-12">
				<h3 class="form-heading">Banking Details</h3>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-md-4">
				<label class="control-label">Bank Name</label> <select required
					[(ngModel)]="bankId" name="bankId" (ngModelChange)="dataChanged()"
					[ngClass]="[ bankIdClass ? 'form-control input-required-shadow-border' : 'form-control']">
					<option [value]="option.id"
						*ngFor="let option of supportedBanks">{{option.branchName}}</option>
				</select>
			</div>
			<div class="form-group col-md-4">
				<label class="control-label">Bank Account Number</label> <input
					type="text"
					[ngClass]="[accountNumberClass ? 'form-control input-required-shadow-border' : 'form-control']"
					numbersOnly [maxlength]="20" [(ngModel)]="accountNumber"
					name="accountNumber" required (ngModelChange)="dataChanged()">
			</div>
			<div class="form-group col-md-4">
				<label class="control-label">Account Type</label> <select required
					[(ngModel)]="bankAccountTypeId" (ngModelChange)="dataChanged()"
					name="bankAccountTypeId"
					[ngClass]="[ bankAccountTypeIdClass ? 'form-control input-required-shadow-border' : 'form-control']">
					<option [value]="option.id"
						*ngFor="let option of bankAccountTypes">{{option.accountTypeName}}</option>

				</select>
			</div>

		</div>


		<div class="modal-footer">
			<button type="button" (click)="updateBusinessBankingDetails()"
				[disabled]="!bankingForm.form.valid" class="btn btn-success">Update
			</button>
		</div>

	</form>
</div>
