import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ProfileService} from '../../shared/services/profile.service';

@Component({
    selector: 'myafrica-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {

    termsAccepted: boolean;
    userName: string;
    buttonDisabled: boolean;
    email: string;
    subscription: Subscription;

    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    loading = false;
    loginErrorMessage: string;
    loginSuccessMessage: string;

    constructor(private profileService: ProfileService,
                private router: Router) {
    }

    ngOnInit() {
        this.termsAccepted = true;
        const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        this.userName = userDetails.userName;
        this.email = userDetails.email;

    }

    activateButton() {
        if (this.termsAccepted) {
            this.buttonDisabled = false;
        } else {
            this.buttonDisabled = true;

        }
    }

    registerUser() {

        const userDetails = {
            email: this.email,
        };

        this.subscription = this.profileService.acceptSellerAgreement(userDetails).subscribe((value: HttpResponse<any>) => {

            this.router.navigate(['/dashboard']);

        }, error1 => {


            this.loginErrorMessage = 'An error occurred activating the account. Please try again later!';

            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;

        });


    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
