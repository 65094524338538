import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'myafrica-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {

    @Input() title: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
