import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CustomerOrder} from '../../model/CustomerOrder';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {CustomerOrderInvoice} from '../../model/CustomerOrderInvoice';
import {NotifierService} from '../../services/notifier.service';
import {SessionManagerService} from '../../services/session-manager.service';
import {CustomerOrderItem} from '../../model/CustomerOrderItem';
import {CartItem} from '../../model/CartItem';
import {Product} from '../../model/Product';



@Component({
    selector: 'myafrica-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {

    rowsControls = [];
    rows: CustomerOrder[];
    // contains the list of product item statuses that have been changed. saved when user selects the save button
    changedStatusTrackers = [];

    displayMessage = false;
    message = 'Data has been modified. Remember to save when done!!';
    displaySaveButton = false;
    displayErrorMessage = false;
    displayPrintMessage = false;
    displayPrintErrorMessage = false;

    orderItemStatuses: string[];

    orderStatuses: string[];

    profileId: string;

    pageTitle = 'Manage Client Orders';

    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    conditionValue = 'outstanding';

    oustandingCheckBoxChecked = true;
    deliveredCheckBoxChecked = false;

    dateFormat: string;

    periodValue = 10;

    orderNo: string;

    startDate = new Date();
    endDate: Date;

    startStringDate: string;
    endStringDate: string;
    closeResult: string;

    businessName: string;
    contactNo: string;
    specialInstructions: string;
    loading = false;
    selectedCustomerOrder: CustomerOrder;
    displayPrint = false;
    modalReference: any
	
	modalReferenceLargerImage: any;
	userId: any;
	selectedCartItem: CartItem;
	currentOrderStatus: string;

	statusIndex: number;


    constructor(private router: Router,
                private ordersService: OrdersServiceService,
                private appStore: ApplicationStore,
                private modalService: NgbModal,
                private notifier: NotifierService,
				private userSessionManager: SessionManagerService) {
    }

    ngOnInit() {
        this.loading = true;
        this.dateFormat = environment.dateFormat;
        this.defaultDates();
        this.ordersService.getOrderItemStatuses().subscribe((value: string[]) => {
            this.orderItemStatuses = value;
        });

    
        this.displayStatusDropDown();

	   this.loadDefaultData(false);

    }
	
	displayStatusDropDown() {
	    const userDetails = this.appStore.getJsonItem('userDetails');
	 	if(this.userSessionManager.isUserAdmin(userDetails) || this.userSessionManager.isUserAdminClerk(userDetails)) {
			return true;
		} else {
			return false;
		}
	
	}

    defaultDates() {
        this.startDate = new Date(new Date().setDate(new Date().getDate() - 10));

        this.endDate = new Date();

    }

    setRadioValue(value: number) {
        this.periodValue = value;
        this.clearOrderNo();
    }


    loadDefaultData(displayAlertMessage: boolean) {
        // by default get the list of in unprocessed orders
        const userDetails = this.appStore.getJsonItem('userDetails');
        this.profileId = this.appStore.getJsonItem('profileId');
		
		this.ordersService.getOrderStatusesByProfile(Number(this.profileId)).subscribe((value: string[]) => {
            this.orderStatuses = value;
	    });
		

		if(userDetails.useRole === undefined || userDetails.useRole === null) {
			this.userId = userDetails.userDetailsId;
		} else if(this.userSessionManager.isUserAdmin(userDetails) || this.userSessionManager.isUserAdminClerk(userDetails)) {
		    this.userId = 0;
			this.profileId = '0';
		} 
		
        this.ordersService.getOrdersByProfile(this.profileId , this.conditionValue, this.periodValue)
            .subscribe((value: CustomerOrder[]) => {
                this.rows = value;
                this.loading = false;
                this.rows.forEach(() => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });
                if (this.rows.length <= 0 && displayAlertMessage) {
                    this.notifier.notifyError('Records matching your search criteria were not found!!');
                }

            }, () => {
                this.loading = false;
                this.notifier.notifyError('Error occurred. Please try again later!!');
            });
        //
    }

    clearOrderNo() {
        this.orderNo = '';
    }

    searchOrders() {
        this.displayMessage = false;
        this.displayErrorMessage = false;
        this.displaySaveButton = false;
        this.startStringDate = Utils.getDateString(this.startDate);
        this.endStringDate = Utils.getDateString(this.endDate);


        if (this.orderNo === '' || this.orderNo === undefined) {
            this.ordersService
                .getOrdersByDatesByProfile(Number(this.profileId), this.startStringDate, this.endStringDate).subscribe((value: CustomerOrder[]) => {
                //         this.rows = JSON.stringify(value.body);
                this.rows = value;

                this.rows.forEach(row => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });
            }, error1 => {
                console.log(error1);
                // this.orderNotProcessCount = "0";
            });
        } else {
            this.ordersService.getOrdersByOrderNo(this.orderNo).subscribe((value: CustomerOrder[]) => {
                //         this.rows = JSON.stringify(value.body);
                this.rows = value;

                this.rows.forEach(row => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });


            }, error1 => {
                console.log(error1);
                // this.orderNotProcessCount = "0";
            });

        }


    }
	
     setCurrentStatus(orderStatus: string) {
	    this.currentOrderStatus = orderStatus;
	 }

    /**
     * Adds changed product item statuses to the status tracker array
     */
    changeOrderStatus(customerOrder: CustomerOrder) {	

        this.displayMessage = false;
        this.displayErrorMessage = false;
        let index = 0;
        this.changedStatusTrackers.forEach(row => {
            // remove item from existing array to not have duplicates
            if (customerOrder.customerOrderId === row.customerOrderId) {
                this.changedStatusTrackers.splice(index, 1);
            }
            index++;
        });
        this.displayMessage = true;
        this.message = 'Data has been modified. Remember to save when done!!';
        this.displaySaveButton = true;
        this.changedStatusTrackers.push(customerOrder);
    }

    /**
     * Uploads the updated order item statuses
     */
    updateStatuses() {
        this.displayMessage = false;
        this.displayErrorMessage = false;
        this.ordersService.updateOrdersStatuses(this.changedStatusTrackers).subscribe(() => {
            this.displayMessage = true;

            this.loadDefaultData(false);
            this.displaySaveButton = false;
            this.message = 'Record(s) updated successfully!!';
            this.searchOrders();

        }, error1 => {
            this.message = 'Error updating records. Please try again later!!';
            this.displayErrorMessage = true;
            console.log(error1);
        });

    }


    generateOrderInvoicesReport(modal) {
        this.loading = true;
        const customerInvoice = new CustomerOrderInvoice();
        customerInvoice.specialInstructions = this.specialInstructions;
        customerInvoice.customerOrder = this.selectedCustomerOrder;

        this.ordersService.generateOrderInvoicesReport(customerInvoice, this.selectedCustomerOrder.orderId).subscribe(value => {
            this.displayPrintMessage = true;
            this.message = 'Report successfully generated!!';
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([value], {type: 'application/pdf'});

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            this.loading = false;

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL = window.URL.createObjectURL(newBlob);

            const tab = window.open();
            tab.location.href = fileURL;

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.loading = false;

                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.router.navigate(['dashboard', {outlets: {dashboard: ['manage-orders']}}],
                        {skipLocationChange: true}).then();
                    this.modalService.dismissAll();
                    this.notifier.notify('Invoice generated successfully', undefined, 'center', false);
                });

            });

        }, () => {
            this.loading = false;
            this.message = 'Error generating report Please try again!!';
            this.displayPrintErrorMessage = true;
        });

    }


    regenerateOrderInvoicesReport(customerOrder: CustomerOrder) {
        this.clearValues();
        this.loading = true;
        this.selectedCustomerOrder = customerOrder;

        this.ordersService.regenerateOrderInvoicesReport(this.selectedCustomerOrder,
            this.selectedCustomerOrder.orderId).subscribe(value => {
            this.displayPrintMessage = true;
            this.message = 'Report successfully generated!!';
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([value], {type: 'application/pdf'});

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            this.loading = false;

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL = window.URL.createObjectURL(newBlob);

            const tab = window.open();
            tab.location.href = fileURL;

        }, error1 => {
            this.loading = false;
            this.message = 'Error generating report Please try again!!';
            this.displayPrintErrorMessage = true;
        });

    }

    clearValues() {
        this.displayPrintMessage = false;
        this.message = '';
        this.displayPrintErrorMessage = false;
    }

    getWindowSize(): string {
        let modalSize = 'sm';

        if (window.matchMedia('(max-width: 767px)').matches) {
            modalSize = 'sm';
        }
        if (window.matchMedia('(max-width: 889px)').matches) {
            modalSize = 'md';
        } else {
            modalSize = 'lg';
        }
        return modalSize;
    }

    open(content, businessName: any, businessContactNumber: string) {
        this.clearValues();
        this.specialInstructions = '';
        this.businessName = businessName;
        this.contactNo = businessContactNumber;

        this.modalReference = this.modalService.open(content, {
            size: this.getWindowSize(),
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }
	
	 hasLogo(customerOrder: CustomerOrderItem): boolean {
        return customerOrder.product.defaultImageUrl !== undefined;
    }

    openOrderItems(content, selectedOrder: any) {
        this.clearValues();

        this.selectedCustomerOrder = selectedOrder;
	    this.modalReference = this.modalService.open(content, {
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

    openInvoiceModal(content, customerOrder: CustomerOrder) {
        this.clearValues();
        this.specialInstructions = '';
        this.selectedCustomerOrder = customerOrder;
        if (customerOrder.customerOrderInvoices.length > 0) {
            this.displayPrint = false;
        } else {
            this.displayPrint = true;
        }


        this.modalReference = this.modalService.open(content, {
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

    closeModal() {
        this.clearValues();
        this.specialInstructions = '';
		try{       
			this.modalService.dismissAll();
		} catch(ex) {
			   
		}
    }

	setCartItem(selectedProduct: Product) {
	    this.selectedCartItem = new CartItem();
        this.selectedCartItem.product = selectedProduct;
        this.selectedCartItem.quantity = 1;
        this.selectedCartItem.image = selectedProduct.defaultImageUrl;
        this.selectedCartItem.maxCount = selectedProduct.unitsInStock;
		
	}
	
	
}

