<myafrica-breadcrumbs title="Feedback"></myafrica-breadcrumbs>
<div class="container">
	<div class="form-row">
		<div class="form-group col-md-6">
			<label class="control-label">Category</label> <select
				[(ngModel)]="productCategory" class="form-control"
				name="productCategory" required>
				<option *ngFor="let cat of items" value="{{cat.key}}">
					{{cat.value | titlecase}}</option>
			</select>

		</div>
		<div class="form-group col-md-6">
			<label class="control-label">Business</label> <select
				class="form-control" name="businessId" id="businessId"
				[(ngModel)]="businessId" required>
				<option *ngFor="let business of businesses"
					ng-selected="business.businessId" value="{{business.businessId}}">
					{{business.businessName}}</option>
			</select>
		</div>

	</div>
	<div class="form-row">
		<div class="form-group col-md-4">
			<label class="control-label">Type</label> <select
				[(ngModel)]="feedbackType" class="form-control">
				<option value="Comment" selected>Comment</option>
				<option value="Enquiry">Enquiry</option>
				<option value="System Error">System Error</option>
			</select>
		</div>
	</div>


	<div class="form-row">
		<angular-editor [placeholder]="'Enter comment here...'"
			[(ngModel)]="htmlContent" [config]="editorConfig"></angular-editor>
	</div>
	<br />
	<div class="form-row">
		<button class="btn btn-primary btn-theme">Submit Feedback</button>

	</div>


</div>