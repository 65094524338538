import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {Product} from '../model/Product';
import {ClothingColour} from '../model/ClothingColour';

@Component({
    selector: 'myafrica-clothing-colour',
    templateUrl: './clothing-colour.component.html',
    styleUrls: ['./clothing-colour.component.scss'],
})
export class ClothingColourComponent implements OnInit {

    @Input() clothingColour: ClothingColour;

    constructor() {
    }

    ngOnInit() {
    }

}
