import {ClothingProduct} from './ClothingProduct';
import {ClothingProductSize} from './ClothingProductSize';
import {Business} from './Business';
import {ClothingColour} from './ClothingColour';
import {CategoryModelRevamped} from './CategoryModelRevamped';

export class Product {
    id: string;
    productId: number;
    productName: string;
    productDescription: string;
    price: number;
    currency: string;
    unitsInStock: number;
    productCategory: string;
    customerPrice: string;
    deleted: boolean;
    clothingProduct: ClothingProduct;
    clothingProductSize: ClothingProductSize;
    defaultImageUrl: string;
    productBusiness: Business;
    uniqueId: string;
    numberOfViews: number;
    categoryId: number;
    colours: ClothingColour[];
    sharingLink: string;
    history: any;
	productLength: number;
	productHeight: number; 
	productBreadth: number;
	productMass: number;
	productStatus: string;
	productCode: string;
	category: CategoryModelRevamped;
}
