import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../model/Product';

@Component({
  selector: 'myafrica-product-dimensions',
  templateUrl: './product-dimensions.component.html',
  styleUrls: ['./product-dimensions.component.scss']
})
export class ProductDimensionsComponent implements OnInit {

  @Input() product: Product;

  constructor() { }

  ngOnInit(): void {
  }

}
