<nav class="navbar navbar-expand-lg navbar-dark bg-primary sticky-top nav-position">
    <a class="navbar-brand" routerLink="/"><img
            src="../../../assets/img/logo.png" class="logo-img img-fluid"></a>
    <btn class="nav-item my-1 mx-1 d-inline d-md-none" routerLink="/shopping/view-cart">
        <myafrica-cart-items-count></myafrica-cart-items-count>
    </btn>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse nav-position" id="navbarSupportedContent">
        <ul class="nav nav-pills ml-auto nav-fill py-1">
            <li class="nav-item d-none d-md-inline px-1 col-sm-12 col-md-auto">
                <a class="nav-link text-white" routerLink="/shopping/view-cart">
                    <myafrica-cart-items-count></myafrica-cart-items-count>
                </a>
            </li>
            <li class="nav-item p-1 col-sm-12 col-md-auto">
                <a class="nav-link text-white" routerLink="/about-us"
                   routerLinkActive="active-link rounded">ABOUT</a>
            </li>
            <li class="nav-item p-1 col-sm-12 col-md-auto" *ngIf="!isUserLoggedIn">
                <a class="nav-link text-white" routerLink="/login"
                   routerLinkActive="active-link rounded">SIGN IN</a>
            </li>
            <li class="nav-item p-1 col-sm-12 col-md-auto" *ngIf="isUserLoggedIn">
                <a class="nav-link text-white" routerLinkActive="active-link rounded" (click)="logout()">LOGOUT</a>
            </li>
            <li class="nav-item p-1 col-sm-12 col-md-auto" *ngIf="!isUserLoggedIn">
                <a class="nav-link text-white bg-warning rounded" (click)="register()">REGISTER</a>
            </li>
            <li class="nav-item p-1 col-sm-12 col-md-auto" *ngIf="isUserLoggedIn">
                <a class="nav-link" (click)="goHome()"
                   routerLink="/dashboard">
                    <img *ngIf="loginResponse?.profileImage else noProfilePic" [src]="loginResponse?.profileImage"
                         class="rounded-circle shadow img-fluid"
                         style="width: 50px; height: 50px; border: 3px solid #f15a22"
                         alt="">
                </a>
            </li>
        </ul>
    </div>
</nav>

<ng-template #noProfilePic>
    <div style="width: 50px; height: 50px; border: 2px solid #f15a22" class="rounded-circle shadow p-1">
        <a class="fa fa-2x fa-user text-white text-center align-middle align-self-center"></a>
    </div>
</ng-template>
