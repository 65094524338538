<myafrica-page-title pageTitle="{{pageTitle}}"></myafrica-page-title>
<div class="container">


    <!-- Control the column width, and how they should appear on different devices -->
    <div class="row">

        <div class="col-md-6">
            <label for="startDate"
                   title="Filters the orders according to the date ordered e.g. Last 15 days=Orders created in the last 15 days">From:</label>
            <input type="date" id="startDate"
                   [ngModel]="startDate | date:'yyyy-MM-dd'" class="form-control"
                   (ngModelChange)="startDate = $event">`
        </div>
        <div class="col-md-6">

            <label for="endDate">To</label> <input type="date" id="endDate"
                                                   [ngModel]="endDate | date:'yyyy-MM-dd'" class="form-control"
                                                   (ngModelChange)="endDate = $event">`


        </div>

    </div>

    <div class="row">

        <div class="col-md-6">
            <label class="search-label" for="returnNo"
                   title="Return No">Return No:</label>
            <input type="text" [(ngModel)]="returnNo" id="returnNo" class="form-control"
                   name="returnNo"/>
        </div>
        <div class="col-md-6">

            <label for="orderNo">Order No</label>
            <input type="text" class="search-input" [(ngModel)]="orderNo" class="form-control formfields"
                   name="orderNo" id="orderNo"/>
            `
        </div>

    </div>

    <div class="row">
        <div class="form-group col-md-4"></div>

        <div class="form-group col-md-4">
            <button type="button" class="btn btn-success w-100"
                    id="search1Button" (keyup.enter)="searchOrder()"
                    (click)="searchOrder()">
                <i class="fa fa-search" aria-hidden="true"></i>&nbsp;&nbsp;{{ buttonLabel }}
            </button>

        </div>

        <div class="form-group col-md-4"></div>
    </div>


</div>

<div class="container">
    <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">{{message}}
        </div>


        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">

            <button type="button" *ngIf="displaySaveButton" class="btn btn-success"
                    (keyup.enter)="updateStatuses()" (click)="updateStatuses()">
                <i class="fa fa-save" aria-hidden="true"></i>&nbsp;&nbsp;Save
            </button>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 pull-right ">

            <button type="button" class="btn btn-success pull-right" (click)="open()">
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;Create Return
            </button>
        </div>


    </div>

</div>

<br/>
<div class="container d-none d-md-block">
    <table class="table table-bordered table-striped">
        <thead class="thead-dark">
        <tr>
            <th>Return No</th>
            <th>Order No</th>
            <th>Date Created</th>
            <th>Return Status</th>
        </tr>
        </thead>
        <tbody>

        <tr *ngFor="let row of customerOrderReturns; let index = index">
            <td class="mw-20 text-blue" style="cursor: pointer"
            ><u (click)="displayRow(row)"
                class="text-anchor" style="color: blue">{{row.refNumber}}</u></td>

            <td class="mw-15">{{row.customerOrder}}</td>
            <td class="mw-20">{{row.created_Date | date :dateFormat}}</td>
            <td class="mw-35">
                <select [(ngModel)]="row.returnStatusDescription"
                        [disabled]="row.returnStatusDescription == 'Items delivered'"
                        (ngModelChange)="changeOrderStatus(row)" style="margin-top: -14px;width: 132px">
                    <option *ngFor="let returnstatus of returnStatuses">{{returnstatus}}</option>
                </select>
            </td>


        </tr>


        </tbody>
    </table>
</div>

<div class="container rounded  d-block d-md-none"
     *ngFor="let row of customerOrderReturns; let index = index" style="font-size: 14px">
    <div class="row">
        <div class="col-5 bg-primary text-white">Ref No:</div>
        <div class="col-7 mx-auto" style="cursor: pointer">
            <span class="text-anchor" style="color: blue" (click)="displayRow(row)">{{row.refNumber}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-5 bg-primary text-white">Order No:</div>
        <div class="col-7 mb-1">
            <span class="text-anchor">{{row.customerOrder}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-5 bg-primary text-white">Date:</div>
        <div class="col-7"><u class="text-anchor mb-1">{{row.created_Date | date :dateFormat}}</u></div>
    </div>
    <div class="row">
        <div class="col-5 bg-primary text-white">Status:</div>
        <div class="col-7">
            <select [(ngModel)]="row.returnStatusDescription"
                    [disabled]="row.returnStatusDescription == 'Items delivered'"
                    (ngModelChange)="changeOrderStatus(row)" style="margin-top: -14px; max-width: 125px">
                <option *ngFor="let returnstatus of returnStatuses">{{returnstatus}}</option>
            </select>
        </div>
    </div>

</div>


<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
