import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import $ from 'jquery';
import {faBars, faSearch} from '@fortawesome/free-solid-svg-icons';
import {ClothingGender} from '../model/ClothingGender';
import {ApplicationStoreService} from '../services/application-store.service';
import {ProductService} from '../services/product.service';
import {TableService} from '../services/table.service';
import {SearchFieldService} from '../services/search-field.service';

@Component({
    selector: 'myafrica-mega-menu',
    templateUrl: './mega-menu.component.html',
    styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent implements OnInit, OnDestroy {

    @ViewChild('menuBtn', {static: true}) menuBtn: ElementRef;

    search = faSearch;
    menu = faBars;
    subs: Subscription;
    subs1: Subscription;
    clothingGenders: ClothingGender[];
    searchedProductName = 'all';
	category = '';
    currentUrl: string;
    refreshUrl: string;
    icon = faSearch;
    toggle = 'dropdown';
    loading: boolean;

    constructor(private productService: ProductService,
                private location: Location,
                private router: Router,
                private searchService: SearchFieldService,
                private appStore: ApplicationStoreService,
                private tableService: TableService) {

    }

    toggleDropdown() {
        console.log('Here');
        this.toggle = 'dropdown';
        this.menuBtn.nativeElement.click();
    }

    resetField() {
        this.searchedProductName = '';
    }

    ngOnInit() {
        this.clothingGenders = JSON.parse(this.appStore.getItem('clothingGenders'));
        // angular does not fire events again when routing, this logs jquery script again
        $.getScript('assets/js/mega-menu.js');
    }

    openGender(genderId: string, breadCrumb: string) {
        this.appStore.storeItem('breadCrumb', breadCrumb);
//        this.router.navigate(['shopping/clothing/products']);

    }

    openGroup(groupdId: string, breadCrumb: string) {
        this.appStore.storeItem('breadCrumb', breadCrumb);
        //      this.router.navigate(['shopping/clothing/products']);

    }

    openCategory(categoryId: number, breadCrumb: string) {

        this.appStore.storeItem('breadCrumb', breadCrumb);
        this.appStore.storeItem('clothingCategory', categoryId);
        this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
            this.router.navigate([decodeURI('shopping/clothing/products')]).then();
        });

    }

    getProductsByCategory(category, searchValue) {

        this.subs = this.searchService.getSearchResult(category, searchValue).subscribe(response => {
            this.appStore.storeItem('search-result', JSON.stringify(response.body));

            // tslint:disable-next-line:only-arrow-functions
            this.router.routeReuseStrategy.shouldReuseRoute = function() {
                return false;
            };

            this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                this.router.navigate(['/search-result']).then();

            });
        }, () => {
        });
    }

    searchForProducts() {
        this.loading = true;
        this.searchedProductName = (document.getElementById('searchInput') as HTMLInputElement).value;
        if (this.searchedProductName === '' || this.searchedProductName === undefined || this.searchedProductName === null) {
            this.searchedProductName = 'All';
        }

        sessionStorage.setItem('searchProductName', this.searchedProductName);
		sessionStorage.setItem('category', this.category);

		this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
			this.router.navigate(['/all-products']).then();
		});


    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

}
