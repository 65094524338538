<div class="container pt-md-2 mb-5">

    <div class="container align-middle col col-sm-12 col-md-10 p-md-5">
        <div class="card shadow text-dark">
            <div class="card-header text-center h3 bg-primary text-white">
                REGISTER AS {{registerAs  | uppercase }}
            </div>
            <div class="card-body">
                <div class="alert alert-danger" role="alert"
                     *ngIf="confirmPassword.touched && password.touched && (password.value !== confirmPassword.value)">
                    The two passwords do not match
                </div>
                <form (ngSubmit)="registerUser()" #registerForm="ngForm" class="sm">
                   
                     <div class="form-row">
                        <div class="form-group col">
                            <label for="firstname">First Name *</label>
                            <input type="text" class="form-control form-control-sm" id="firstname"
                                   [(ngModel)]="regDetails.firstName" name="firstname" required #firstName="ngModel"
                                   [ngClass]="{'is-invalid':firstName.touched && firstName.invalid
                                   ,'is-valid':firstName.touched && firstName.valid}">
                            <div class="text-danger" *ngIf="firstName.touched && firstName.invalid">First name is required
                            </div>
                        </div>
                    </div>
                    
                      <div class="form-row">
                        <div class="form-group col">
                            <label for="lastname">Last Name *</label>
                            <input type="text" class="form-control form-control-sm" id="lastname"
                                   [(ngModel)]="regDetails.lastName" name="lastname" required #lastName="ngModel"
                                   [ngClass]="{'is-invalid':lastName.touched && lastName.invalid
                                   ,'is-valid':lastName.touched && lastName.valid}">
                            <div class="text-danger" *ngIf="lastName.touched && lastName.invalid">Last name is required
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="emailAddress">Email Address *</label>
                            <input type="email" class="form-control form-control-sm" id="emailAddress"
                                   #emailAddress="ngModel"
                                   [(ngModel)]="regDetails.email" name="emailAddress" required
                                   [ngClass]="{'is-invalid':emailAddress.touched && emailAddress.invalid
                                   , 'is-valid':emailAddress.touched && emailAddress.valid}"
                                   pattern="^\S*[@]\S*[.]\S*$">
                            <div class="text-danger" *ngIf="emailAddress.touched && emailAddress.invalid">A valid email
                                is required
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="password">Password *</label>
                            <input type="password" class="form-control form-control-sm " id="password"
                                   [ngClass]="{'is-invalid':password.touched && password.invalid,
                                   'is-valid':password.touched && password.valid}"
                                   [(ngModel)]="regDetails.password" name="password" required #password="ngModel"
                                   pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$">
                            <div *ngIf="password.touched ">
                                <p class="text-danger" *ngIf="password?.errors?.required">Password
                                    is required</p>
                                <p class="text-danger" *ngIf="password?.errors?.minlength">Password
                                    needs to be more than 8 characters.</p>
                                <p class="text-danger" *ngIf="password?.errors?.pattern">Must
                                    contain one lower &amp; uppercase letter, one special character
                                    and a number</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="pwdConfirm">Confirm Password *</label>
                            <input type="password" class="form-control form-control-sm" id="pwdConfirm"
                                   required
                                   [(ngModel)]="regDetails.confirmPwd" name="pwdConfirm" #confirmPassword="ngModel"
                                   [ngClass]="{'is-valid': confirmPassword.touched && confirmPassword.valid,
                                   'is-invalid': confirmPassword.touched && confirmPassword.invalid}">
                            <div class="text-danger" *ngIf="confirmPassword.touched && confirmPassword.invalid">Confirm
                                Password required
                            </div>
                        </div>
                    </div>
                     
                      <div class="form-row" *ngIf="userSeller">
                        <div class="form-group col-xs-12 col-sm-12 col-md-7 col-lg-7">
                            <label for="businessName">Company Name *</label>
                            <input type="text" numbersOnly [maxlength]="100" class="form-control form-control-sm" id="businessName"
                                   [(ngModel)]="regDetails.companyName" name="businessName" required #businessName="ngModel"
                                   [ngClass]="{'is-invalid':businessName.touched && businessName.invalid
                                   ,'is-valid':businessName.touched && businessName.valid}">
                                   
                            <div class="text-danger" *ngIf="businessName.touched && businessName.invalid">Business Name is required
                            </div>
                        </div>
                         <div class="form-group col-xs-12 col-sm-12 col-md-5 col-lg-5">
                        <label class="control-label">Category</label> <select required
                                                                              [(ngModel)]="businessCategory"
                                                                              name="businessCategory"
                                                                              class="form-control form-control-sm">
                        <option [value]="category.categoryId"
                                *ngFor="let category of businessCategories">{{category.description}}
                        </option>

                    </select>
                    </div>
                        
                    </div>
                     
                      <div class="form-row">
                        <div class="form-group col">
                            <label for="cellphoneNumber">Cellphone Number *</label>
                            <input type="text" numbersOnly [maxlength]="10" class="form-control form-control-sm" id="cellphoneNumber"
                                   [(ngModel)]="regDetails.cellphoneNumber" name="cellphoneNumber" required #cellphoneNumber="ngModel"
                                   [ngClass]="{'is-invalid':cellphoneNumber.touched && cellphoneNumber.invalid
                                   ,'is-valid':cellphoneNumber.touched && cellphoneNumber.valid}">
                                   
                            <div class="text-danger" *ngIf="cellphoneNumber.touched && cellphoneNumber.invalid">Cellphone number is required
                            </div>
                        </div>
                    </div>
                    
                      <div class="form-row" *ngIf="userSeller">
                        <div class="form-group col">
                            <label for="aboutBusiness">About</label>
                                   
                                <textarea type="text" class="form-control input-lg" id="aboutBusiness"
								name="aboutBusiness" rows="6" [(ngModel)]="regDetails.aboutCompany"
								 required #aboutBusiness="ngModel"  [ngClass]="{'is-invalid':aboutBusiness.touched && aboutBusiness.invalid
                                   ,'is-valid':aboutBusiness.touched && aboutBusiness.valid}"></textarea>   
                            <div class="text-danger" *ngIf="aboutBusiness.touched && aboutBusiness.invalid">About Business is required
                            </div>
                        </div>
                    </div>
                    
                   <div class="form-row" *ngIf="userSeller">
                        <div class="form-group col">
                            <label for="aboutBusiness">Are you VAT Registered?</label>
                         	 <div class="row bg-light">
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="optradio"  (change)="setVatRegistered(true)"
					                       checked id="last7DaysRadio"><label
					                    class="search-label">&nbsp;Yes </label>
					
					            </div>
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="optradio" (change)="setVatRegistered(false)">
					                <label class="search-label"> &nbsp;No</label>
					            </div>
					
					        </div>
                        
                        </div>
                    </div>

                  
                   <div class="form-row" *ngIf="displayRegNumber && userSeller">
                        <div class="form-group col">
			                <label class="control-label">VAT Number</label> 
			                <input	type="text"	name="vatNumber" required [(ngModel)]="regDetails.vatNumber"
								 required #vatNumber="ngModel"  [ngClass]="{'is-invalid':vatNumber.touched && vatNumber.invalid
                                   ,'is-valid':vatNumber.touched && vatNumber.valid}"
								[maxlength]="20" >
								
								 <div class="text-danger" *ngIf="vatNumber.touched && vatNumber.invalid">VAT Number is required
                            </div>
						</div>
                </div> 
                 <div class="form-row" *ngIf="userSeller">
                        <div class="form-group col">
                            <label>Monthly Revenue</label>
                         	 <div class="row">
	                         	  <div class="col-12">
	                         	 
	                         	    <div class="row">
					            <div class=" col-12 bg-light">
					                <input type="radio" name="monthlyRevenue"  (change)="setMontlyRevenue(1)"
					                       checked id="carryStockRadio"><label
					                    class="search-label">&nbsp;Less than 20K</label>
					
					            </div>
					            </div>
					          
					       		 <div class="row">
							            <div class="col-12 bg-light">
							                <input type="radio" name="monthlyRevenue" (change)="setMontlyRevenue(2)">
							                <label class="search-label">&nbsp;R20K - 50K</label>
							            </div>
					
					       		 </div>
					       		 
					       		 <div class="row">
							            <div class=" col-12 bg-light">
							                <input type="radio" name="monthlyRevenue" (change)="setMontlyRevenue(3)">
							                <label class="search-label">&nbsp;R50K - 100K</label>
							            </div>
					
					       		 </div>
					       		 
					       		 <div class="row">
							            <div class=" col-12 bg-light">
							                <input type="radio" name="monthlyRevenue" (change)="setMontlyRevenue(4)">
							                <label class="search-label">&nbsp;R100K - 500K</label>
							            </div>
					
					       		 </div>
					       		 <div class="row">
							            <div class=" col-12 bg-light">
							                <input type="radio" name="monthlyRevenue" (change)="setMontlyRevenue(5)">
							                <label class="search-label">&nbsp;More than 500K</label>
							            </div>
					
					       		 </div>
                        </div>
                        </div>
                    </div>
                  </div>
                 <div class="form-row" *ngIf="userSeller">
                        <div class="form-group col">
                            <label >Do you have a physical store?</label>
                         	 <div class="row bg-light">
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="physicalStore"  (change)="setPhysicalStore('Yes')"
					                       checked id="physicalStoreRadio"><label
					                    class="search-label">&nbsp;Yes, I have a store </label>
					
					            </div>
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="physicalStore" (change)="setPhysicalStore('No')">
					                <label class="search-label">&nbsp;No, I sell through other channels</label>
					            </div>
					
					        </div>
                        
                        </div>
                    </div>

                       
                <div class="form-row" *ngIf="userSeller">
                        <div class="form-group col">
                            <label for="carryStock">Do you carry stock?</label>
                         	 <div class="row bg-light">
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="carryStock"  (change)="setCarryStock('Yes')"
					                       checked id="carryStockRadio"><label
					                    class="search-label">&nbsp;Yes</label>
					
					            </div>
					            <div class=" col-6  col-md-6">
					                <input type="radio" name="carryStock" (change)="setCarryStock('No')">
					                <label class="search-label">&nbsp;No</label>
					            </div>
					
					        </div>
                        
                        </div>
                    </div>       
                       
                    
                     <div class="form-row" *ngIf="!userSeller">
                        <div class="form-group col">
                            <label for="birthDate">Birth Date *</label>
                            
                                   
                                   <input type="date" id="birthDate" required #birthDateValue="ngModel"
									[ngModel]="birthDate | date:'yyyy-MM-dd'" class="form-control"
									(ngModelChange)="birthDate = $event"  name="birthDate"
						 			[ngClass]="{'is-invalid':birthDateValue.touched && birthDateValue.invalid
                                   ,'is-valid':birthDateValue.touched && birthDateValue.valid}">
                                   
                            <div class="text-danger" *ngIf="birthDateValue.touched && birthDateValue.invalid">Birth Date is required
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col">
                           
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="marketing"
                                       [(ngModel)]="regDetails.newsletterAccepted" name="marketing"
                                       [attr.checked]="'bg-secondary'">
                                <label class="form-check-label" for="marketing">Sign up for our newsletter</label>
                            </div>
                        </div>

                    </div>


                    <hr class="bg-warning">
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary btn-block"
                                [disabled]="!registerForm.form.valid">
                            Register
                        </button>

                    </div>
                    <hr class="bg-warning">
                    <div class="text-right pb-3 text-white">
                        <a routerLink="/login" class="text-decoration-none text-dark">Have an account? Login.</a>
                    </div>
                </form>

            </div>
        </div>

    </div>

</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


<!-- Modal -->
<div class="modal fade" id="termsAndConditions" data-backdrop="static" data-keyboard="false" tabindex="-1"
     aria-labelledby="termsAndConditionsLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="termsAndConditionsLabel">Terms And Conditions</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <myafrica-terms class="m-0 p-0 col-12"></myafrica-terms>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
