import {Injectable} from '@angular/core';


const TOKEN_KEY = 'AuthToken';

const REFRESH_TOKEN_KEY = 'RefreshAuthToken';

const REFRESH_TOKEN_INDICATOR = 'N';

const DISPLAY_NAV_MENU = 'true';

@Injectable({
    providedIn: 'root',
})
export class ApplicationStoreService {

    constructor() {
    }

    signOut() {
        this.removeItem(TOKEN_KEY);
        window.sessionStorage.clear();
    }

    public saveToken(token: string) {
        this.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        return this.retrieveItem(TOKEN_KEY);
    }

    public saveRefreshToken(token: string) {
        this.removeItem(REFRESH_TOKEN_KEY);
        window.sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
    }

    public setRefreshTokenIndicator(indicator: string) {
        this.removeItem(REFRESH_TOKEN_INDICATOR);
        window.sessionStorage.setItem(REFRESH_TOKEN_INDICATOR, indicator);
    }

    public getRefreshTokenIndicator(): string {
        return this.retrieveItem(REFRESH_TOKEN_INDICATOR);
    }


    public getRefreshToken(): string {
        return this.retrieveItem(REFRESH_TOKEN_KEY);
    }

    public storeItem(itemKey: string, item: any) {
        this.removeItem(itemKey);
        window.sessionStorage.setItem(itemKey, item);
    }

    public deleteItem(itemKey: string) {
        this.removeItem(itemKey);
    }

    public getItem(itemKey: string) {
        return this.retrieveItem(itemKey);
    }

    public getJsonItem(itemKey: string) {
        return JSON.parse(this.retrieveItem(itemKey));
    }

    public storeStringItem(itemKey: string, item: any) {
        return window.sessionStorage.setItem(itemKey, JSON.stringify(item));
    }

    private retrieveItem(itemKey) {
        return window.sessionStorage.getItem(itemKey);
    }

    private removeItem(itemKey: string): void {
        window.sessionStorage.removeItem(itemKey);
    }

    public setDisplayNavMenu(indicator: string) {
        this.removeItem(DISPLAY_NAV_MENU);
        window.sessionStorage.setItem(DISPLAY_NAV_MENU, indicator);
    }

    public getDisplayNavMenu(): string {
        return this.retrieveItem(DISPLAY_NAV_MENU);
    }


}
