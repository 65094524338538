import {Component, OnInit} from '@angular/core';
import {Business} from '../model/Business';
import {CustomerOrder} from '../model/CustomerOrder';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ApplicationStore} from '../interceptors/application-store.service';
import {OrdersServiceService} from '../shared/services/orders-service.service';
import {BusinessServiceService} from '../shared/services/business/business-service.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'myafrica-order-tracker',
    templateUrl: './order-tracker.component.html',
    styleUrls: ['./order-tracker.component.scss'],
})
export class OrderTrackerComponent implements OnInit {

    selectedBusiness: Business;
    closeResult: string;
    loading = false;
    rowsControls = [];
    rows: CustomerOrder[];
    changedStatusTrackers: Array<any> = [];

    displayMessage: boolean;
    message = 'Data has been modified. Remember to save when done!!';
    displaySaveButton = false;

    orderItemStatuses: string[];

    profileId: string;

    pageTitle = 'Track Your Order';

    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    conditionValue = 'outstanding';

    oustandingCheckBoxChecked = true;
    deliveredCheckBoxChecked = false;

    dateFormat: string;

    periodValue = 15;

    orderNo: string;


    constructor(private modalService: NgbModal, private router: Router,
                private ordersService: OrdersServiceService, private appStore: ApplicationStore,
                private businessService: BusinessServiceService) {

    }

    ngOnInit() {
        this.dateFormat = environment.dateFormat;

        this.ordersService.getOrderItemStatuses().subscribe((value: string[]) => {
            this.orderItemStatuses = value;
        });


    }


    clearOrderNo() {
        this.orderNo = '';
    }

    searchOrders() {
        this.displayMessage = false;

        if (this.orderNo.length < 3) {
            this.displayMessage = true;
            this.message = 'Please type in at least three characters to search!!';
            return;
        }
        this.ordersService.getOrdersByOrderNo(this.orderNo).subscribe((value: CustomerOrder[]) => {
            this.rows = value;
            console.log(JSON.stringify(this.rows));
            if (this.rows.length <= 0) {
                this.displayMessage = true;
                this.message = 'Not records were found matching search criteria!!';
            }

            this.rows.forEach(() => {
                this.rowsControls.push({
                    isCollapsed: true,
                });
            });


        }, error1 => {
            console.log(error1);
            // this.orderNotProcessCount = "0";
        });


    }

    open(content, businessId: string) {

        this.businessService.getAllBusinessDetails(businessId).subscribe((value: Business) => {
                this.selectedBusiness = value;

                this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });

            },
        );


    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
