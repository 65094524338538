import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CartItemsService} from '../services/cart-items.service';

@Component({
    selector: 'myafrica-cart-items-count',
    templateUrl: './cart-items-count.component.html',
    styleUrls: ['./cart-items-count.component.scss'],
})
export class CartItemsCountComponent implements OnInit, AfterContentChecked {
    totalNumberOfItems: number;

    constructor(private router: Router,
                private cartService: CartItemsService) {
    }

    ngOnInit() {
    }

    goToCart() {
        this.router.navigate(['/shopping/view-cart']).then();
    }

    ngAfterContentChecked() {
        this.totalNumberOfItems = 0;
        this.cartService.getCart().forEach(cartItem => {
            this.totalNumberOfItems += cartItem.quantity;
        });
    }

}
