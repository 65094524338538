import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../shared/services/profile.service';
import {Router} from '@angular/router';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

    email: string;

    constructor(private profileService: ProfileService,
                private router: Router,
                private notifier: NotifierService) {
    }

    ngOnInit() {
    }


    resetPassword() {
        const userDetails = {
            email: this.email,
        };

        this.profileService.forgotPassword(userDetails).subscribe(() => {
            this.notifier.notify('Email sent with new password. ' +
                'Please SIGN-IN with the new password sent. ' +
                'You will then be required to change the password to your own!!');

        }, error1 => {
            if (parseInt(error1.status, 0) === 417) {
                this.notifier.notify('Email account does not exist!!', 'error');
            } else {
                this.notifier.notify('Error sending email. Please try again later!', 'error');
            }
        });

    }
}
