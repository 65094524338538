<myafrica-breadcrumbs title="Manage Orders"></myafrica-breadcrumbs>


<div class="container my-5">
    <div class="row shadow">
        <div class="container">
            <div class="row">
                <div class="col-md-4">

                    <label for="orderNo" title="Order Number">Order No:</label> <input
                        type="text" class="form-control" [(ngModel)]="orderNo"
                        id="orderNo"/>
                </div>

                <div class="col-md-4">
                    <label for="startDate"
                           title="Filters the orders according to the date ordered e.g. Last 15 days=Orders created in the last 15 days">From:</label>
                    <input type="date" id="startDate"
                           [ngModel]="startDate | date:'yyyy-MM-dd'" class="form-control"
                           (ngModelChange)="startDate = $event">`
                </div>
                <div class="col-md-4">

                    <label for="endDate">To</label> <input type="date" id="endDate"
                                                           [ngModel]="endDate | date:'yyyy-MM-dd'" class="form-control"
                                                           (ngModelChange)="endDate = $event">`


                </div>

            </div>
            <div class="row">
                <div class="form-group col-md-4"></div>

                <div class="form-group col-md-4">
                    <button type="button" class="btn btn-success w-100"
                            id="search1Button" (keyup.enter)="searchOrders()"
                            (click)="searchOrders()">
                        <i class="fa fa-search" aria-hidden="true"></i>&nbsp;&nbsp;{{ buttonLabel }}
                    </button>

                </div>

                <div class="form-group col-md-4"></div>
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row">
        <div class="col-2 pull-right"></div>
       <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-info text-dark" *ngIf="displayMessage">
		{{message}}
		</div>
		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-danger text-white"  *ngIf="displayErrorMessage">
		{{message}}</div>

        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">

            <button type="button" *ngIf="displaySaveButton" class="btn btn-success w-40"
                    (keyup.enter)="updateStatuses()" (click)="updateStatuses()">
                <i class="fa fa-save" aria-hidden="true"></i>&nbsp;&nbsp;Save
            </button>
        </div>

    </div>
</div>
<div class="container d-none d-md-block">

    <table class="table table-bordered table-striped">
        <thead class="thead-dark">
        <tr>
            <th>Order No</th>
            <th>Date</th>
            <th>Total</th>
            <th>Status</th>
            <th>Invoice(s)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of rows; let index = index">
            <td class="mw-20 text-blue" style="cursor: pointer"
                (click)="openOrderItems(orderItemsModal, row)"><u
                    class="text-anchor" style="color: blue">{{row.orderId}}</u></td>

            <td class="mw-15">{{row.created_Date | date: dateFormat}}</td>
            <td class="mw-20">{{row.orderAmount | currency : 'ZAR'}}</td>
            <td class="mw-35">
            <select  *ngIf="displayStatusDropDown(); else displayStatus" id="dropdown{{index}}"
                   [(ngModel)]="row.orderStatusDescription" id="dropdown{{index}}"
					[disabled]="row.orderStatusDescription == 'Items delivered'"
					(click)="setCurrentStatus(row.orderStatusDescription)" (ngModelChange)="changeOrderStatus(row)" style="width: 132px">
					 <option *ngFor="let orderstatus of orderStatuses" 
					[disabled]="orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
					|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled'"
                	[ngStyle]="{'background-color': orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
                	|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'white':'green', 'color': orderstatus.disabled 
                	|| orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence || 
                	(orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'black':'white'}">{{orderstatus.description}}</option>

				</select>
	       <ng-template #displayStatus>
    			 <p>{{row.orderStatusDescription}}</p>
			</ng-template>
            </td>
            <td class="mw-10">
            <img
                    title="Re-print invoice(s)" class="invoice-icon"
                    (click)="regenerateOrderInvoicesReport(row)"
                    *ngIf="row.customerOrderInvoices.length > 0"
                    src="https://myafricaconnect.com/images/greeninvoice.png"></td>

        </tr>
        </tbody>
    </table>

</div>

<div class="container rounded  d-block d-md-none"
     *ngFor="let row of rows; let index = index">
    <div class="row">
        <div class="col-6 bg-primary text-white">Order No:</div>
        <div class="mx-auto" style="cursor: pointer"
             (click)="openOrderItems(orderItemsModal, row)">
            <u class="text-anchor" style="color: blue">{{row.orderId}}</u>
        </div>
    </div>
    <div class="row">
        <div class="col-6 bg-primary text-white">Date:</div>
        <div class="mx-auto">{{row.created_Date | date: dateFormat}}</div>
    </div>
    <div class="row">
        <div class="col-6 bg-primary text-white">Total:</div>
        <div class="mx-auto">{{row.orderAmount | currency : 'ZAR'}}</div>
    </div>
    <div class="row">
        <div class="col-6 bg-primary text-white">Status:</div>
        <div class="col-6 mx-auto">
            <select *ngIf="displayStatusDropDown(); else displayStatus" 
           [(ngModel)]="row.orderStatusDescription" id="dropdown{{index}}"
					[disabled]="row.orderStatusDescription == 'Items delivered'"
					(click)="setCurrentStatus(row.orderStatusDescription)" (ngModelChange)="changeOrderStatus(row)" style="width: 132px">
					 <option *ngFor="let orderstatus of orderStatuses" 
					[disabled]="orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
					|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled'"
                	[ngStyle]="{'background-color': orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
                	|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'white':'green', 'color': orderstatus.disabled 
                	|| orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence || 
                	(orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'black':'white'}">{{orderstatus.description}}</option>

				</select>
            
            <ng-template #displayStatus>
    			 <p>{{row.orderStatusDescription}}</p>
			</ng-template>

        </div>
    </div>
    <div class="row mb-1">
        <div class="col-6 bg-primary text-white"
             *ngIf="row.customerOrderInvoices.length <= 0">Invoice(s):
        </div>
        <div class="col-6 bg-primary text-white"
             *ngIf="row.customerOrderInvoices.length > 0">Invoice(s):
        </div>
        <div class="mx-auto">

				<img
                title="Re-print invoice(s)" class="center-block img-responsive"
                (click)="regenerateOrderInvoicesReport(row)"
                *ngIf="row.customerOrderInvoices.length > 0"
                src="https://myafricaconnect.com/images/greeninvoice.png">

        </div>
    </div>
</div>
<!-- /.container -->


<ng-template #orderItemsModal>
    <!-- Mobile -->

    <div class="container d-block d-md-none">
        <div class="row">
            <div class="col-12 modal-header bg-primary  text-white text-capitalize rounded-top">
			<span id="orderNoId">Order No :
                {{selectedCustomerOrder.orderId}}</span>

            </div>
        </div>


        <div class="container"
             *ngFor="let itemrow of selectedCustomerOrder.customerOrderItems">
            <div class="row">
                <div class="col-4 bg-primary text-white"></div>
          
             
                <div class="col-8">
          		    <img *ngIf="hasLogo(itemrow); else logoMissing"  [src]="itemrow.product.defaultImageUrl" [alt]="itemrow.product.productName" class="d-inline img-fluid mx-3 p-2 border border-primary"
                         [ngStyle]="{'max-height':'80px'}" data-toggle="modal" style="cursor: pointer"
                         data-target="#viewBigImageModal" (click)="setCartItem(itemrow.product)" >
                 </div>
            </div>
            
            <div class="row">
                <div class="col-4 bg-primary text-white"> Name</div>

                <div class="col-8">{{
                    itemrow.product.productName }}</div>
            </div>
            <div class="row">
                <div class="col-4 bg-primary text-white">Quantity</div>
                <div class="col-8">{{
                    itemrow.quantity }}</div>
            </div>
            <div class="row">
                <div class="col-4 bg-primary text-white">Price</div>
                <div class="col-8">{{
                    itemrow.itemPrice | currency : itemrow.product.currency }}</div>
            </div>
            <div class="row">
                <div class="col-4 bg-primary text-white">Total</div>
                <div class="col-8">{{
                    itemrow.itemPrice * itemrow.quantity | currency :
                        itemrow.product.currency}}</div>
            </div>
            <div class="row">
                <div class="col-4 bg-primary text-white">Status</div>
                <div *ngIf="selectedCustomerOrder.orderStatusDescription; else mobileStatusMissing" class="col-8">
                    {{selectedCustomerOrder.orderStatusDescription}}

                </div>
            </div>
            <ng-template #mobileStatusMissing>
                <div class="col-8">
                    Unknown</div>

            </ng-template>
            
        </div>

        <hr/>
        <div class="row">
            <div class="col-12 py-3 text-center">
                <button type="button"  (click)="closeModal()"
                        class="btn btn-danger mx-auto">Close
                </button>
            </div>
        </div>
    </div>


    <div class="container d-none d-md-block">
        <div class="row">
            <div class="col-12 modal-header bg-primary  text-white text-capitalize">
			<span class="modal-title" id="exampleModalLabel">Order No :
                {{selectedCustomerOrder.orderId}}</span>
                <button type="button" class="close" data-dismiss="modal"
                        (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
        </div>
        <div class="row product-items-div bg-primary">
            <div
                    class="card-body col-2 col-md-2 text-white">
            </div>
            <div class="card-body col-4 col-md-4  col-sm-7 text-white">Product
                Name
            </div>
            <div
                    class="card-body col-1 col-md-1 text-white">Quantity
            </div>
            <div
                    class="card-body col-2 col-md-2 text-white">Price
            </div>
            

            <div class="card-body col-3 col-md-3 col-sm-5 text-white">Status</div>
        </div>

        <div class="row product-items-div form-group mt-2"
             *ngFor="let itemrow of selectedCustomerOrder.customerOrderItems">
            <div class="col-6  col-md-2 ">
             <img *ngIf="hasLogo(itemrow); else logoMissing"  [src]="itemrow.product.defaultImageUrl" [alt]="itemrow.product.productName" class="d-inline img-fluid mx-3 p-2 border border-primary"
                         [ngStyle]="{'max-height':'80px'}" data-toggle="modal" style="cursor: pointer"
                         data-target="#viewBigImageModal" (click)="setCartItem(itemrow.product)" >
                       
            </div>
            
            <div class="col-4 col-md-4 col-sm-7 inner-div-cell">{{
                itemrow.product.productName }}</div>
            <div class="col-4 col-md-1  inner-div-cell">{{
                itemrow.quantity }}</div>
            <div class="col-4 col-md-2  inner-div-cell">{{
                itemrow.itemPrice | currency : itemrow.product.currency }}</div>
            
            <div class="col-5 col-md-3  col-sm-5 inner-div-cell"
                 *ngIf="selectedCustomerOrder.orderStatusDescription; else statusMissing">
                {{selectedCustomerOrder.orderStatusDescription}}
            </div>
            <ng-template #statusMissing>
                <div class="col-5 col-md-3  col-sm-5 inner-div-cell">
                    Unknown</div>

            </ng-template>
           
        </div>

        <hr/>
        <div class="row">
            <div class="col-12 py-2">
                <button type="button" data-dismiss="modal" (click)="closeModal()"
                        class="btn btn-danger mx-auto">Close
                </button>
            </div>
        </div>
    </div>
</ng-template>

<myafrica-shopping-cart-modals [cartItem]="selectedCartItem"></myafrica-shopping-cart-modals>

<ng-template #displayRadioButtonsDetails let-modal>
    <div class="container p7 mt-1">
        <b> Order No: {{businessName}}</b> <br/> <b>Contact No:
        {{contactNo}}</b> <br/>

        <hr/>
        <div class="row">
            <div class="col-12 py-2">
                <button type="button" data-dismiss="modal" (click)="closeModal()"
                        class="btn btn-danger btn-block">Close
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #invoiceModal let-modal>

    <div class="row modal-header m-0">
        <h2 class="form-heading">Print Invoice</h2>
    </div>
    <div class="modal-body p-2">
        <form class="form-horizontal style-form" #messageform="ngForm"
              name="messageform">
            <div class="row">
                <div class="col-12">
				<textarea class="form-control input-lg mx-auto" maxlength="255" required
                          placeholder="Special Instructions"
                          [(ngModel)]="specialInstructions" name="specialInstructions"
                          rows="6" required></textarea>
                </div>

            </div>

            <div class="row">
                <div class="col-2 pull-right"></div>
                <div class="col-4 " class="success-message-bold "
                     *ngIf="displayPrintMessage">{{message}}</div>
                <div class="col-4 " *ngIf="displaySaveButton"
                     class="error-message-bold">{{message}}</div>

                <div class="col-2 pull-right"></div>
            </div>

            <div class="modal-footer">
                <button type="submit" [disabled]="displayPrintMessage"
                        *ngIf="displayPrint"
                        (click)="generateOrderInvoicesReport(invoiceModal)"
                        class="btn btn-primary ">Print
                </button>
                <button type="submit" [disabled]="displayPrintMessage"
                        *ngIf="!displayPrint"
                        (click)="generateOrderInvoicesReport(invoiceModal)"
                        class="btn btn-primary ">Re-Print
                </button>
            </div>
            <div class="modal-footer">

                <div class="container">
                    <div class="row">
                        <div class="col-12 py-2">
                            <button type="button" (click)="modal.close('Save click')"
                                    class="btn btn-danger mx-auto">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #logoMissing>
    <img src="https://myafricaconnect.com/images/image-not-available.jpg" class="shadow rounded" [alt]=""
         height="80px">
</ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
