import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {CustomerOrderReturn} from '../../model/CustomerOrderReturn';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerOrder} from '../../model/CustomerOrder';
import {NotifierService} from '../../services/notifier.service';

@Component({
  selector: 'myafrica-shopper-manage-returns',
  templateUrl: './shopper-manage-returns.component.html',
  styleUrls: ['./shopper-manage-returns.component.scss']
})
export class ShopperManageReturnsComponent implements OnInit {
    
   pageTitle = 'Manage Orders Returns';
   rowsControls = [];
   customerOrderReturns: CustomerOrderReturn[];
   
   customerOrders: CustomerOrder[];
   
   startDate= new Date();
   endDate: Date;
   
   orderNo: string;
   returnNo: string;//ticket ref no
   buttonLabel = 'Search';
   buttonIcon = 'fa fa-search';
   dateFormat: string;
   
  subs1: Subscription;
  subs2: Subscription;
  
  orderItemStatuses: string[];
  orderStatuses: string[];
  closeResult: string;
  
  displayMessage = false;
  message = 'Data has been modified. Remember to save when done!!';
  displaySaveButton = false;
  displayErrorMessage = false;
  returnStatuses: string[];
  changedStatusTrackers = [];
  loading = false;
  profileId: string;

  constructor(private router: Router, private ordersService: OrdersServiceService, private appStore: ApplicationStore,
			private modalService: NgbModal, private notifier: NotifierService) { }

  ngOnInit() {
	  this.loading = true;
      this.dateFormat = environment.dateFormat;

      this.defaultDates();
	  
	  this.subs1 = this.ordersService.getOrderItemStatuses().subscribe((value: string[]) => {
      this.orderItemStatuses = value;
    });
	
	
	this.subs1 = this.ordersService.getReturnStatuses().subscribe((value: string[]) => {
      this.returnStatuses = value;

    });
	
	
	this.loadDefaultData();
  }
  
  defaultDates() {
      this.startDate = new Date(new Date().setDate(new Date().getDate()-12));

      this.endDate = new Date();
      
    }
	
loadDefaultData() {
    // by default get the list of in unprocessed orders
    const userDetails = this.appStore.getJsonItem('userDetails');
    this.profileId = this.appStore.getJsonItem('profileId')

    this.subs2 = this.ordersService.getOrderReturnsByProfileByDates(Number(this.profileId), Utils.getDateString(this.startDate), Utils.getDateString(this.endDate))
      .subscribe((value: CustomerOrderReturn[]) => {
        this.customerOrderReturns = value;

		this.loading = false;
        this.customerOrderReturns.forEach(() => {
          this.rowsControls.push({
            isCollapsed: true
          });
        });


      }, error1 => {
		  this.loading = false;
      });
    //
  }
  
   searchOrder() {
	this.displayMessage = false;
	this.displayErrorMessage = false;
	
	this.getSearchedData();
	
  }
  
  getSearchedData() {
  if(this.returnNo !== '' && this.returnNo !== 'undefined' && this.returnNo !== undefined) {
      this.subs2 = this.ordersService.getOrderReturnsByProfileByRefNumber(Number(this.profileId), this.returnNo).subscribe((value: CustomerOrderReturn[]) => {
        this.customerOrderReturns = value;

        this.customerOrderReturns.forEach(row => {
          this.rowsControls.push({
            isCollapsed: true
          });
        });


      }, error1 => {
      });
	} else if(this.orderNo !== '' && this.orderNo !== 'undefined'  && this.orderNo !== undefined) {
      this.subs2 = this.ordersService.getOrderReturnsByProfileByOrderNumber(Number(this.profileId), this.orderNo).subscribe((value: CustomerOrderReturn[]) => {
        this.customerOrderReturns = value;

        this.customerOrderReturns.forEach(row => {
          this.rowsControls.push({
            isCollapsed: true
          });
        });


      }, error1 => {
        // this.orderNotProcessCount = "0";
      });
	} else {
		this.loadDefaultData();
	}

  }

/**
   * Adds changed product item statuses to the status tracker array
   */
  changeOrderStatus(customerOrderReturn: CustomerOrderReturn) {
	 this.displayMessage = false;
	this.displayErrorMessage = false;
    let index = 0;
    this.changedStatusTrackers.forEach(row => {
      // remove item from existing array to not have duplicates
      if (customerOrderReturn.customerOrderReturnId === row.customerOrderReturnId) {
        this.changedStatusTrackers.splice(index, 1);
      }
      index++;
    });
    this.displayMessage = true;
    this.message = 'Data has been modified. Remember to save when done!!';
    this.displaySaveButton = true;
    this.changedStatusTrackers.push(customerOrderReturn);
  }


 /**
   * Uploads the updated order return statuses
   */
  updateStatuses() {
    console.log(this.changedStatusTrackers);
	this.displayMessage = false;
	this.displayErrorMessage = false;
     this.subs2 = this.ordersService.updateReturnsStatuses(this.changedStatusTrackers).subscribe(value => {
      this.displayMessage = true;

      this.getSearchedData();
      this.displaySaveButton = false;
      this.message = 'Record(s) updated successfully!!';
	  this.searchOrder();

    }, error1 => {
      this.message = 'Error updating records. Please try again later!!';
	 this.displayErrorMessage = true;
    });

  }

  
  printReport(customerReturn: CustomerOrderReturn) {
	   this.loading = true;
	    this.subs2 = this.ordersService
        .printReturn(customerReturn).subscribe(value  => {
            this.displayMessage = true;
			this.generatePDF(value);
	
        }, error1 => {
		    this.loading = false;
           this.message = 'Error generating report Please try again!!';
           this.notifier.notify(this.message, 'error');
	    });

  }
  

  generatePDF(value: any) {
			
			if([value][0].byteLength > 0) {
			// It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([value], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
			this.loading = false;

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL  = window.URL.createObjectURL(newBlob);

			let tab = window.open(); 
			tab.location.href = fileURL;
			} else {
				 this.loading = false;
	     		 this.message = 'No records founds to generate report. Please try different options!!';
			     this.notifier.notify(this.message, 'erprintOrdersByOrderNoReportror');
				}
	  }

			
  
  
	
 open(path: string) {
   
	const navigationOutlet = sessionStorage.getItem('navigationOutlet') + '';
        if (navigationOutlet === 'shopperdashboard') {
	         this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: [path]}}]).then();
   		} else if (navigationOutlet === 'adminclerkdashboard') {
            this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}]).then();
        } else {
            this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}]).then();

        }
  }
  
   doUnsubs(subs: Subscription) {
    if (subs) {
      subs.unsubscribe();
    }
  }
  
 ngOnDestroy() {
    this.doUnsubs(this.subs1);
    this.doUnsubs(this.subs2);
 }
 
  displayRow(customerOrderReturn: Object) {
 	 sessionStorage.setItem('selectedCustomerOrderReturn', JSON.stringify(customerOrderReturn));
   	 this.open('edit-shopper-return');
  }

}