<div class="card w-100 my-2 shadow">
    <div style="height: 200px !important;" class="text-center pt-2" (click)="showBusinessDetails()">
        <img *ngIf="hasLogo(business); else logoMissing" [src]="business.logoUrl" class="img-fluid"
             [alt]="business.businessName">
    </div>

    <div class="card-body text-center">
        <h5 class="card-title">{{business.businessName | uppercase}}</h5>

        <div class="btn btn-success btn-block"
             (click)="showBusinessDetails()">
					<span><img class="connect-btn-image"
                               src="../assets/img/connect36x36.png" height="15"></span>Connect With
            Business
        </div>
    </div>
</div>

<ng-template #logoMissing>
    <img src="../assets/img/image-not-available.jpg" class=" img-thumbnail" [alt]="business.businessName" height="100">
</ng-template>
