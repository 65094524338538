<myafrica-page-title pageTitle="Contact Us"></myafrica-page-title>

<div class="container mb-auto">
    <div class="container contact ">
        <div class="row">
            <div class="col-md-3 bg-info">
                <div class="contact-info  text-white">
                    <i class="fa fa-envelope-open-text fa-3x text-center"></i>
                    <h2>Contact Us</h2>
                    <h4>We would love to hear from you !</h4>
                </div>
            </div>
            <div class="col-md-9">
                <div *ngIf="displaySuccessMessage" class="success-message-bold">{{displayMessage}}</div>
                <div *ngIf="displayErrorMessage" class="error-message-bold">{{displayMessage}}</div>

                <form class="form-horizontal style-inner-form" #contactUsForm="ngForm">
                    <div class="row form-group">
                        <div class="col-12">
                            <input type="text" class="form-control" maxlength="50" placeholder="Name"
                                   name="messagersName" [(ngModel)]="message.messagersName" required>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-12">
                            <input type="email" class="form-control" [attr.maxlength]="100" placeholder="Surname"
                                   name="messagersSurname" [(ngModel)]="message.messagersSurname">
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-12">
                            <input type="email" class="form-control" required [attr.maxlength]="100"
                                   placeholder="Email"
                                   name="messagersEmail" [(ngModel)]="message.messagersEmail" required>
                        </div>
                    </div>

                    <div class="row form-group ">
                        <div class="col-12">
                            <input type="tel" class="form-control" numbersOnly placeholder="Phone"
                                   [attr.maxlength]="10" required
                                   name="messagersContactNumber" [(ngModel)]="message.messagersContactNumber">
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-12 col-md-12">
						<textarea class="form-control input-lg" maxlength="255" required
                                  placeholder="ContactUsMessage/Comment"
                                  name="message" rows="6" [(ngModel)]="message.message" required></textarea>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-12 ">
                            <button type="submit" (click)="sendMessage()" [disabled]="!contactUsForm.form.valid"
                                    class="btn btn-primary btn-block">Send
                            </button>
                        </div>
                    </div>
                </form>
                <div class="container py-1 py-1">
                    <div class="col-12 ">
                        <label>For any inquiries about listing it general use:</label> <a
                            href="mailto:info@myafricaconnect.com"><span
                            style="color: blue"> info@myafricaconnect.com</span></a>
                    </div>

                    <div class="col-12 ">
                        <label>For merchant related questions:</label> <a
                            href="mailto:merchants@myafricaconnect.com"><span
                            style="color: blue"> merchants@myafricaconnect.com</span></a>
                    </div>


                    <div class="col-12 ">
                        <label>For any inquiries about payments:</label> <a
                            href="mailto:payments@myafricaconnect.com"><span
                            style="color: blue"> payments@myafricaconnect.com</span></a>
                    </div>

                    <div class="col-12 ">
                        <label>For any technical related questions:</label> <a
                            href="mailto:support@myafricaconnect.com"><span
                            style="color: blue"> support@myafricaconnect.com</span></a> or on our whatsapp line
                        <span><a
                                href="tel:+27679125212">067 146 5666</a></span>
                    </div>

                    <div class="col-12 ">
                        <label>Office Hours:</label> <label>Monday-Friday
                        08:00-17:00</label>
                        <label>
                            <small>(Closed on Christmas & New Year’s Day)</small>
                        </label>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<myafrica-loader [isPageLoading]="loading"></myafrica-loader>

