import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Business} from '../../model/Business';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {BusinessCategory} from '../../model/BusinessCategory';
import {NotifierService} from '../../services/notifier.service';
import {BusinessServiceService} from '../../shared/services/business/business-service.service';
import {CategoryModel} from '../../model/CategoryModel';
import {AngularEditorConfig} from '@kolkov/angular-editor';


@Component({
  selector: 'myafrica-seller-feedback',
  templateUrl: './seller-feedback.component.html',
  styleUrls: ['./seller-feedback.component.scss']
})
export class SellerFeedbackComponent implements OnInit {
	
	businessCategories: BusinessCategory[];
	categoriesMap: Map<string, string>;
	businesses: Business[] = [];
    businessIds: string;
	items: Array<CategoryModel>;
    searchProductCategory: string;
    searchProductCategoryMap: Map<string, string>;
    businessId: number;
    productCategory: string;
    htmlContent: any;
	feedbackType: string = "Comment";
	
	subs1: Subscription;
	subs2: Subscription;
	subs3: Subscription;
	subs4: Subscription;
	
	  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

	  constructor( private tableService: CodeTablesServiceService,  private router: Router, 
				   private appStore: ApplicationStore, private notifier: NotifierService, private businessService: BusinessServiceService) { }

	  ngOnInit(): void {
		
		this.setBusinessCategories();
		
		const userDetails: { userDetailsId: number } = this.appStore.getJsonItem('userDetails');
        const profileId = this.appStore.getJsonItem('profileId');
        this.businessService.getBusinessesByProfileId(profileId).subscribe((value: Business[]) => {
                this.businesses = value;

               
                this.businessIds = ',';
                let index = 1;
                this.businesses.forEach(element => {

                    // element.products.filter(item => !item.deleted).forEach(entry => this.searchedProducts.push(entry));

                    if (index === this.businesses.length) {
                        this.businessIds += element.businessId;
                    } else {
                        this.businessIds += element.businessId + ',';

                    }
                    index++;
                });
            },
        );
		  
	  }


    populateData() {
        this.items = [];
        this.businessCategories.forEach(element => {
            this.items.push(new CategoryModel(element.key, element.description));
        });

        this.searchProductCategoryMap = new Map<string, string>();
        this.searchProductCategoryMap.set('all', 'All');
        this.items.forEach(element => {
            this.searchProductCategoryMap.set(element.key, element.value);

        });

    }

		setBusinessCategories() {
			const businessCategoriesStored = JSON.parse(sessionStorage.getItem('businessCategories'));

			if (businessCategoriesStored === undefined || businessCategoriesStored === null) {
				this.subs3 = this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
						this.businessCategories = value;

						sessionStorage.setItem('businessCategories', JSON.stringify(this.businessCategories));
						this.populateCategories();
						this.populateData();
					}, error => {
						alert(error);
					},
				);
			} else {
				this.businessCategories = businessCategoriesStored;
				this.populateCategories();
				this.populateData();
			}
		}
		
	 populateCategories() {
        this.categoriesMap = new Map<string, string>();
        this.categoriesMap.set('all', 'All');


        this.businessCategories.forEach(element => {
            this.categoriesMap.set(element.key, element.description);
        });
    }


}
