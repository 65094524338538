import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import Swal from 'sweetalert2';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  static addTokenToAuthorizationHeaderVal(request: HttpRequest<any>, next: HttpHandler, token: string): HttpRequest<any> {
    if (token) {
      const myHeaders = new HttpHeaders()
        .set('Authorization', `Bearer ${token}`);

      return request.clone({
        headers: myHeaders
      });
    }
    return request.clone();
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //  const authRequest = AuthInterceptor
   //   .addTokenToAuthorizationHeaderVal(request, next, JSON.parse(sessionStorage.getItem('Authorization')));
	 
    const authToken = sessionStorage.getItem('Active-Profile-Id');
     const authorizationToken = sessionStorage.getItem('Authorization');
    if (authToken) {
      // Clone the request and attach the token
      const authReq = request.clone({
        setHeaders: {
          'active-profile-id': `${authToken}`,
          'Authorization': `${authorizationToken}`
        }
      });
	  
      return next.handle(authReq);
	}
	//  console.log('Outgoing HTTP request', request);
	  
    return next.handle(request)
      .pipe(
        catchError(
          error => {
            if (error instanceof HttpErrorResponse) {
              switch ((error as HttpErrorResponse).status) {
                case 403:
                  sessionStorage.clear();

                  Swal.fire({
                    titleText: 'Request Declined!',
                    text: 'Please login again',
                    icon: 'error',
                    confirmButtonColor: '#008eb0',
                    confirmButtonText: 'Go to Login'
                  }).then(() => this.router.navigate(['login']));
              }
              // NB: https://stackoverflow.com/questions/47849258/typeerror-you-provided-undefined-where-a-stream-was-expected?rq=1
              // return actual error to higher layers if not handled here
              return throwError(error);
            } else {
              console.log('Throwing error');
              console.log(error);
              return throwError(error);
            }
          })) as any;

  }
}
