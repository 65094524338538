import {Component, Input, OnInit} from '@angular/core';
import {CartItem} from '../model/CartItem';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-increase-cart-items',
    templateUrl: './increase-cart-items.component.html',
    styleUrls: ['./increase-cart-items.component.scss'],
})
export class IncreaseCartItemsComponent implements OnInit {

    @Input() cartItem: CartItem;
    iconPlus = faPlus;
    iconMinus = faMinus;

    constructor(private notifier: NotifierService) {
    }

    ngOnInit() {
    }


    subtractQuantity(): void {
        this.cartItem.quantity--;
        if (this.cartItem.quantity <= 1) {
            this.cartItem.quantity = 1;
        }
    }

    isDisabled(): boolean {
        const valid = this.cartItem && this.cartItem.product;
        if (valid && this.cartItem.product.colours) {
            const clothingColours = this.cartItem.product.colours
                .filter(color => color.clothingSizes.filter(size => size.count > 0));
            return clothingColours.length === 0;
        }
        return valid && this.cartItem.product.unitsInStock <= 0;
    }

    validateCounts(){
        if (this.cartItem.quantity > this.cartItem.maxCount) {
            this.notifier.notifyError(`We only have ${this.cartItem.maxCount} in stock`);
            this.cartItem.quantity = this.cartItem.maxCount;
        }
    }

    addQuantity(): void {
        this.cartItem.quantity++;
        if (this.cartItem.quantity > this.cartItem.maxCount) {
            this.notifier.notifyError(`We only have ${this.cartItem.maxCount} in stock`);
            this.cartItem.quantity = this.cartItem.maxCount;
        }
    }
}
