import {Injectable} from '@angular/core';
import {CartItem} from '../model/CartItem';

@Injectable({
    providedIn: 'root',
})
export class CartItemsService {

    cart = new Array<CartItem>();
    activeCartItem: CartItem;
    copyOfOriginal: CartItem;
    cartItemsCount = 0;

    constructor() {
    }

    public getCart(): Array<CartItem> {
        if (this.getCartFromSessionStorage()) {
            this.cart = this.getCartFromSessionStorage().map(rec => {
                return rec as CartItem;
            });
        }
        return this.cart;
    }

    public addToCart(cartItem: CartItem) {
        if (this.getCartFromSessionStorage()) {
            this.cart = this.getCartFromSessionStorage().map(rec => {
                return rec as CartItem;
            });
        }

        if (this.cart.length > 0) {
            const existingRec = this.cart.filter(cartRec => this.carItemsEqual(cartRec, cartItem));
            if (existingRec.length > 0) {
                existingRec.forEach(cart => {
                    cart.quantity = cartItem.quantity;
                });
            } else {
                this.cart.push(cartItem);
            }
        } else {
            this.cart.push(cartItem);
        }
        this.saveCart();
    }

    public clearCart(): void {
        this.cart = [];
        this.saveCart();
    }

    public updateACartItem() {
        if (this.getCartFromSessionStorage()) {
            this.cart = this.getCartFromSessionStorage().map(rec => {
                return rec as CartItem;
            }).filter(entry => !this.carItemsEqual(entry, this.copyOfOriginal));
            this.cart.push(this.activeCartItem);
        }


        this.saveCart();
    }

    getACartItem(cartItem: CartItem): CartItem {
        if (this.getCartFromSessionStorage()) {
            this.cart = this.getCartFromSessionStorage().map(rec => {
                return rec as CartItem;
            });
        }

        this.copyOfOriginal = cartItem;

        this.activeCartItem = this.cart.filter(cartRec => this.carItemsEqual(cartRec, cartItem)).pop();

        return this.activeCartItem;
    }

    private getCartFromSessionStorage() {
        return JSON.parse(sessionStorage.getItem('cart'));
    }

    private saveCart() {
        sessionStorage.setItem('cart', JSON.stringify(this.cart));
    }

    carItemsEqual(cartItem1: CartItem, cartItem2: CartItem): boolean {
        return (cartItem1.productColour === cartItem2.productColour
            && cartItem1.productSize === cartItem2.productSize
            && cartItem1.image === cartItem2.image
            && cartItem1.product.productId === cartItem2.product.productId);

    }


    removeCartItem(cartItem: CartItem): void {
        if (this.getCartFromSessionStorage()) {
            this.cart = this.getCartFromSessionStorage().map(rec => {
                return rec as CartItem;
            });
        }

        sessionStorage.setItem('cart', JSON.stringify(this.cart.filter(
            entry => !this.carItemsEqual(entry, cartItem))));
    }

    public updateCount(count: number) {
        this.cartItemsCount = count;
    }

    public getCount() {
        return this.cartItemsCount;
    }

    public resetCounter() {
        this.cartItemsCount = 0;
    }

}
