import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'myafrica-product-history-view',
    templateUrl: './product-history-view.component.html',
    styleUrls: ['./product-history-view.component.scss'],
})
export class ProductHistoryViewComponent implements OnInit {

    @Input() htmlContent: any;

    constructor() {
    }

    ngOnInit() {
    }

}
