import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Product} from '../../model/Product';
import {ProductHistory} from '../../model/ProductHistory';
import {Business, businessWithUpdatedImages} from '../../model/Business';
import {CartItemsService} from '../cart-items.service';
import {ProductService} from '../product.service';
import {ApplicationStoreService} from '../application-store.service';
import {HistoryService} from '../history.service';

@Injectable()
export class ClothingProductResolver implements Resolve<[Array<Product>, ProductHistory, Business]> {

    constructor(private cartService: CartItemsService,
                private router: Router,
                private productService: ProductService,
                private appStore: ApplicationStoreService,
                private historyService: HistoryService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[Array<Product>, ProductHistory, Business]> {
        const selectedProduct = this.appStore.getJsonItem('currentProduct') as Product;
        return forkJoin([
                this.productService.getAllProductOfCat(selectedProduct.productCategory),
                this.historyService.getHistory(selectedProduct.id ? selectedProduct.id : selectedProduct.productId),
                this.productService.getProductBusiness(Number(selectedProduct.productBusiness.businessId))
                    .pipe(map(businessWithUpdatedImages)),
            ],
        );

    }

}
