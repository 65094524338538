<br />
<div class="container" *ngIf="!getDisableRequestBookingButtonState()">
	<div class="row">
		<div class=" form-group col-sm-12 col-md-2 col-lg-2 font-weight-bold">
			<span>Comment: </span>
		</div>

		<div class="form-group col-sm-12 col-md-10 col-lg-10">
			<textarea type="text" class="form-control input-lg" id="comment"
				name="comment" rows="4" [(ngModel)]="comment"
				[disabled]="getDisableRequestBookingButtonState()" required> {{comment}}
               </textarea>

		</div>
		<div class="col-2"></div>
	</div>
	<div class="row">
		<div class="col-md-1 col-sm-3 font-weight-bold">
			<label title="Date when courier company can collect parcel">When?</label>
		</div>
		<div class="col-md-5  col-sm-9">
			<input type="datetime-local" id="bookingDate"
				[ngModel]="bookingDate | date:'yyyy-MM-ddTHH:mm:ss'"
				class="form-control  w-50" (ngModelChange)="bookingDate = $event">`
		</div>

		<div class="form-group col-md-6 col-sm-12">
			<button type="button" class="btn btn-success"
				[disabled]="getDisableRequestBookingButtonState()"
				id="requestBookingButton" (click)="requestBooking()">
				<i class="fa fa-truck " aria-hidden="true"></i>&nbsp;&nbsp;Request
				Booking
			</button>

		</div>
	</div>
</div>

<div class="container modal-width shadow"
	*ngIf="getDisableRequestBookingButtonState()">


	<div class="row">
		<div class="col-sm-12 col-md-4 col-lg-4">
			<span class="font-weight-bold">Delivery No : </span>{{deliveryNo}}
		</div>

		<div class="col-sm-12 col-md-4 col-lg-4 ">
			<span class="font-weight-bold">Courier Track No : </span>{{trackNo}}
		</div>
		<div class="col-sm-12 col-md-4 col-lg-4">
			<span class="font-weight-bold">Pickup Time : </span>{{pickUpate}}
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-12 col-md-4 col-lg-4">
			<span class="font-weight-bold">Vendor Status:</span>
			{{vendorOrderStatus}}
		</div>


		<div class=" form-group col-sm-12 col-md-1 col-lg-1">
			<span class="font-weight-bold">Comment: </span>
		</div>

		<div class="form-group col-sm-12 col-md-7 col-lg-7">
			<textarea type="text" class="form-control input-lg" id="comment"
				name="comment" rows="4" [(ngModel)]="comment" disabled> {{comment}}
               </textarea>

		</div>
	</div>
</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
