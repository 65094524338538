import {Component, OnInit} from '@angular/core';
import {LoginResponse} from '../../model/LoginResponse';
import {UserAddress} from '../../model/UserAddress';
import {ProfileService} from '../../shared/services/profile.service';
import {NotifierService} from '../../services/notifier.service';
import {UserDetails} from '../../model/UserDetails';
import {HttpResponse} from '@angular/common/http';
import {ApplicationStoreService} from '../../services/application-store.service';
import {Province} from '../../model/Province';
import {Router} from '@angular/router';


@Component({
    selector: 'myafrica-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

    loginResponse: LoginResponse;
    ownerAddress: UserAddress;
    url: string | ArrayBuffer;
    loading: boolean;
    selectedFile: File;
    userName: string;
    mouseOver: boolean;
    userDetails: UserDetails;

    provinces: Province[];
    province: any;

    constructor(private profileService: ProfileService,
                private notifier: NotifierService,
                private appStore: ApplicationStoreService,
                private router: Router) {
    }

    ngOnInit() {
        this.ownerAddress = new UserAddress();
       
 	    this.loginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));
	     const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
	    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
		
     	this.userName = this.userDetails.userName;

		if (JSON.parse(sessionStorage.getItem('userDetails')).address !== null) {
            this.ownerAddress = JSON.parse(sessionStorage.getItem('userDetails')).address;
        }

    }

    updateMouseOver(value: boolean){
        this.mouseOver = value;
    }

    updateUserDetails() {
        const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

        userDetails.userName = this.userDetails.userName;

        sessionStorage.setItem('userDetails', JSON.stringify(this.userDetails));

    }

    navigate(path: string) {
        const navigationOutlet = sessionStorage.getItem('navigationOutlet') + '';
        if (navigationOutlet === 'shopperdashboard') {
            this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: ['/shopperdashboard']}}]).then();
        } else if (navigationOutlet === 'adminclerkdashboard') {
            this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: ['/adminclerkdashboard']}}]).then();
        } else {
            this.router.navigate(['dashboard', {outlets: {dashboard: ['/dashboard']}}]).then();

        }
    }


    updateProfile() {
		const userDetails = this.appStore.getJsonItem('userDetails');
		
	    this.loading = true;
        this.profileService.updateUserDetails(this.userDetails).subscribe((response: HttpResponse<UserDetails>) => {
            this.loading = false;

            this.notifier.notify('User details updated successfully!!', 'success');
            this.updateUserDetails();

        }, error1 => {
            this.loading = false;

            this.notifier.notify('Error updating user details!!.', 'error');
        });
    }

    setProfilePic() {
        this.loading = true;
	    this.profileService.addProfilePicture(this.selectedFile, this.userDetails.id)
            .subscribe((res: { rel: string, href: string }) => {
                this.loading = false;
                this.url = null;
                this.loginResponse.profileImage = res.href;
                sessionStorage.setItem('loginResponse', JSON.stringify(this.loginResponse));
                this.notifier.notify('Profile picture updated successfully!!');
            }, () => {
                this.notifier.notifyError('Failed to update profile Pic, please try again later');
                this.url = null;
                this.loading = false;
            });
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.selectedFile = event.target.files[0];
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            };
        }
    }
}
