<div class="input-group my-3 ">
    <div class="input-group-prepend">
      <a class="cd-dropdown-trigger"
		href="javascript: void(0);"><span class="d-block d-sm-none">&#9776;</span><span class="d-none d-sm-block">&#9776;Categories</span></a>

	<nav class="cd-dropdown">
		<h2 class="bg-warning text-white">Shop by....</h2>
		<a class="cd-close" href="#0">Close</a>
		<ul class="cd-dropdown-content">

			<!--li class="cd-search">
				<div class="input-group">
		   			<input id="searchInput" class="searchInput" type="search" (keyup.enter)="searchForProducts()"  placeholder="Search...">
				 <div class="input-group-append search-icon-button" >
		     		 <button class="btn search-btn-right" type="button" style="width: 100%" (click)="searchForProducts()">
		       		   <fa-icon [icon]="icon"></fa-icon>
		     		 </button>
		    </div>
		  </div>

			</li-->

			<li class="clickableanchor"><a  (click)="getProductsByCategory('art','all')">Arts</a></li>
			<li class="clickableanchor"><a (click)="getProductsByCategory('books','all')">Books</a></li>
			<li class="has-children"><a
				href="javascript: void(0);">Clothing</a>

				<ul class="cd-secondary-dropdown is-hidden">
					<li class="go-back"><a href="javascript: void(0);">Menu</a></li>

					<li class="has-children"><a href="javascript: void(0);">Men</a>
						<ul class="is-hidden menu-div-height">
							<li class="go-back"><a href="javascript: void(0);">Clothing</a></li>

							<li class="has-children"><a href="#0">Clothing</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Men</a></li>

									<li><a class="clickableanchor" (click)="openCategory(1,'Men/Clothing/Jean')"> Jean</a></li>
									<li><a class="clickableanchor" (click)="openCategory(2,'Men/Clothing/Shirts')"> Shirts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(3,'Men/Clothing/Shorts')"> Shorts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(3,'Men/Clothing/Tops  &amp; Tees')"> Tops &amp; Tees</a></li>
									<li><a class="clickableanchor" (click)="openCategory(5,'Men/Clothing/Casual Pants  &amp; Chinos')"> Casual Pants &amp;
											Chinos</a></li>
									<li><a class="clickableanchor" (click)="openCategory(32,'Men/Clothing/Knitwear')"> Knitwear</a></li>
									<li><a class="clickableanchor" (click)="openCategory(33,'Men/Clothing/Jackets')"> Jackets</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Formal Wear</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Men</a></li>

									<li><a class="clickableanchor" (click)="openCategory(6,'Men/Formal Wear/Suits')"> Suits</a></li>
									<li><a class="clickableanchor" (click)="openCategory(7,'Men/Formal Wear/Formal Shirts')"> Formal Shirts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(8,'Men/Formal Wear/Ties &amp; Handkerchiefs')"> Ties &amp; Handkerchiefs</a></li>
									<li><a class="clickableanchor" (click)="openCategory(34,'Men/Formal Wear/Blazers')"> Blazers</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Shoes</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Men</a></li>

									<li><a class="clickableanchor" (click)="openCategory(9,'Men/Shoes/Sneakers')"> Sneakers</a></li>
									<li><a class="clickableanchor" (click)="openCategory(10,'Men/Shoes/Casual Shoes')"> Casual Shoes</a></li>
									<li><a class="clickableanchor" (click)="openCategory(11,'Men/Shoes/Formal Shoes')"> Formal Shoes</a></li>
									<li><a class="clickableanchor" (click)="openCategory(35,'Men/Shoes/Boots')"> Boots</a></li>
									<li><a class="clickableanchor" (click)="openCategory(36,'Men/Shoes/Sandals
											& Flipflops')"> Sandals &amp; Flipflops</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Accessories</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Men</a></li>

									<li><a class="clickableanchor" (click)="openCategory(37,'Men/Accessories/Belts')"> Belts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(39,'Men/Accessories/Sunglasses')"> Sunglasses</a></li>
									<li><a class="clickableanchor" (click)="openCategory(77,'Men/Accessories/Watches')"> Watches</a></li>
									<li><a class="clickableanchor" (click)="openCategory(81,'Men/Accessories/Masks')"> Masks</a></li>
									<li><a class="clickableanchor" (click)="openCategory(38,'Men/Accessories/Bags &amp; Wallets')"> Bags &amp; Wallets</a></li>
									<li><a class="clickableanchor" (click)="openCategory(84,'Men/Accessories/Hats')"> Hats</a></li>
									<li><a class="clickableanchor" (click)="openCategory(41,'Men/Accessories/Pocket Squares')"> Pocket Squares</a></li>
									<li><a class="clickableanchor" (click)="openCategory(42,'Men/Accessories/Handkerchiefs')"> Handkerchiefs</a></li>
									<li><a class="clickableanchor" (click)="openCategory(40,'Men/Accessories/Ties')"> Ties</a></li>

								</ul></li>
							<li class="has-children"><a href="#0">Underwear &amp; Sleepwear</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Men</a></li>

									<li><a class="clickableanchor" (click)="openCategory(43,'Men/Underwear &amp; Sleepwear/Sleepwear')"> Sleepwear</a></li>
									<li><a class="clickableanchor" (click)="openCategory(44,'Men/Underwear &amp; Sleepwear/Socks')"> Socks</a></li>
									<li><a class="clickableanchor" (click)="openCategory(45,'Men/Underwear &amp; Sleepwear/Underwear')"> Underwear</a></li>
								</ul></li>
						</ul>
					</li>
					<li class="has-children"><a href="javascript: void(0);">Women</a>
						<ul class="is-hidden menu-div-height">
							<li class="go-back"><a href="javascript: void(0);">Clothing</a></li>

							<li class="has-children"><a href="#0">Clothing</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Women</a></li>

									<li><a class="clickableanchor" (click)="openCategory(12,'Women/Clothing/Dresses')"> Dresses</a></li>
									<li><a class="clickableanchor" (click)="openCategory(13,'Women/Clothing/Shorts')"> Shorts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(14,'Women/Clothing/Jeans')"> Jeans</a></li>
									<li><a class="clickableanchor" (click)="openCategory(15,'Women/Clothing/Leggings')"> Leggings</a></li>
									<li><a class="clickableanchor" (click)="openCategory(16,'Women/Clothing/Skirts')"> Skirts</a></li>
								</ul></li>
								<li class="has-children"><a href="#0">Formal Wear</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Women</a></li>

									<li><a class="clickableanchor" (click)="openCategory(91,'Women/Formal Wear/Suits')"> Suits</a></li>
									<li><a class="clickableanchor" (click)="openCategory(92,'Women/Formal Wear/Formal Shirts')"> Formal Shirts</a></li>
								</ul></li>		
								
							<li class="has-children"><a href="#0">Lingerie &amp; Sleepwear</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Women</a></li>

									<li><a class="clickableanchor" (click)="openCategory(17,'Women/Lingerie &amp; Sleepwear/Bras')"> Bras</a></li>
									<li><a class="clickableanchor" (click)="openCategory(18,'Women/Lingerie &amp; Sleepwear/Panties')"> Panties</a></li>
									<li><a class="clickableanchor" (click)="openCategory(29,'Women/Lingerie &amp; Sleepwear/Sleepwear')"> Sleepwear</a></li>
									<li><a class="clickableanchor" (click)="openCategory(30,'Women/Lingerie &amp; Sleepwear/Lingerie Sets')"> Lingerie Sets</a></li>
									<li><a class="clickableanchor" (click)="openCategory(31,'Women/Lingerie &amp; Sleepwear/Bodysuits')"> Bodysuits</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Shoes</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Women</a></li>
 									<li><a class="clickableanchor" (click)="openCategory(24,'Women/Shoes/Sneakers')"> Sneakers</a></li>
									<li><a class="clickableanchor" (click)="openCategory(25,'Women/Shoes/Boots')"> Boots</a></li>
									<li><a class="clickableanchor" (click)="openCategory(27,'Women/Shoes/Heels')"> Heels</a></li>
									<li><a class="clickableanchor" (click)="openCategory(28,'Women/Shoes/Sandals &amp; Flipflops')"> Sandals &amp; Flipflops</a></li>
									<li><a class="clickableanchor" (click)="openCategory(26,'Women/Shoes/Pumps &amp; FLats')"> Pumps &amp; FLats</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Accessories</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Women</a></li>
									<li><a class="clickableanchor" (click)="openCategory(19,'Women/Accessories/Jewellery')"> Jewellery</a></li>
									<li><a class="clickableanchor" (click)="openCategory(20,'Women/Accessories/Bags	&amp; Purses')"> Bags &amp; Purses</a></li>
									<li><a class="clickableanchor" (click)="openCategory(76,'Women/Accessories/Watches')"> Watches</a></li>
									<li><a class="clickableanchor" (click)="openCategory(80,'Women/Accessories/Masks')"> Masks</a></li>
									<li><a class="clickableanchor" (click)="openCategory(85,'Women/Accessories/Hats')"> Hats</a></li>
									<li><a class="clickableanchor" (click)="openCategory(21,'Women/Accessories/Sunglasses')"> Sunglasses</a></li>
									<li><a class="clickableanchor" (click)="openCategory(22,'Women/Accessories/Belts')"> Belts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(23,'Women/Accessories/Hair	Accessories')"> Hair Accessories</a></li>
								</ul></li>
						</ul>
					</li>
					<li class="has-children"><a href="javascript: void(0);">Kids/Girls</a>
						<ul class="is-hidden menu-div-height">
							<li class="go-back"><a href="javascript: void(0);">Clothing</a></li>

							<li class="has-children"><a href="#0">Clothing</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Kids/Girls</a></li>

									<li><a class="clickableanchor" (click)="openCategory(46,'Kids/Girls/Clothing/Dresses')"> Dresses</a></li>
									<li><a class="clickableanchor" (click)="openCategory(47,'Kids/Girls/Clothing/Knitwear')"> Knitwear</a></li>
									<li><a class="clickableanchor" (click)="openCategory(48,'Kids/Girls/Clothing/Skirts')"> Skirts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(49,'Kids/Girls/Clothing/Tops &amp; Tees')"> Tops &amp; Tees</a></li>
									<li><a class="clickableanchor" (click)="openCategory(50,'Kids/Girls/Clothing/Jeans')"> Jeans</a></li>
									<li><a class="clickableanchor" (click)="openCategory(51,'Kids/Girls/Clothing/Jumpsuits')"> Jumpsuits</a></li>
									<li><a class="clickableanchor" (click)="openCategory(66,'Kids/Girls/Clothing/Jackets &amp; Hoodies')"> Jackets &amp;
											Hoodies</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Shoes</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Kids/Girls</a></li>

									<li><a class="clickableanchor" (click)="openCategory(52,'Kids/Girls/Shoes/Boots')"> Boots</a></li>
									<li><a class="clickableanchor" (click)="openCategory(53,'Kids/Girls/Shoes/Sandals &amp; Flipflops')"> Sandals &amp;
											Flipflops</a></li>
									<li><a class="clickableanchor" (click)="openCategory(54,'Kids/Girls/Shoes/Sneakers')"> Sneakers</a></li>
									<li><a class="clickableanchor" (click)="openCategory(55,'Kids/Girls/Shoes/Casual Shoes')"> Casual Shoes</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">School Uniform</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Kids/Girls</a></li>
									<li><a class="clickableanchor" (click)="openCategory(56,'Kids/Girls/School Uniform/Shirts')"> Shirts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(57,'Kids/Girls/School Uniform/Shorts')"> Shorts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(58,'Kids/Girls/School Uniform/Skirts')"> Skirts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(59,'Kids/Girls/School Uniform/Trousers')"> Trousers</a></li>
									<li><a class="clickableanchor" (click)="openCategory(60,'Kids/Girls/School Uniform/Shoes')"> Shoes</a></li>
									<li><a class="clickableanchor" (click)="openCategory(61,'Kids/Girls/School Uniform/Knitwear')"> Knitwear</a></li>
								</ul></li>

								<li class="has-children"><a href="#0">Accessories</a>
									<ul class="is-hidden">
									  <li class="go-back"><a href="javascript: void(0);">Kids/Girls</a></li>
									  <li><a class="clickableanchor" (click)="openCategory(78,'Kids/Girls/Accessories/Watches')"> Watches</a></li>
 									  <li><a class="clickableanchor" (click)="openCategory(82,'Kids/Girls/Accessories/Masks')"> Masks</a></li>
								    <li><a class="clickableanchor" (click)="openCategory(87,'Kids/Girls/Accessories/Hats')"> Hats</a></li>
								    <li><a class="clickableanchor" (click)="openCategory(89,'Kids/Girls/Accessories/Bags')"> Bags</a></li>

								   </ul>
								</li>

						</ul>
					</li>
					<li class="has-children"><a href="javascript: void(0);">Kids/Boys</a>
						<ul class="is-hidden menu-div-height">
							<li class="go-back"><a href="javascript: void(0);">Clothing</a></li>
							<li class="has-children"><a href="#0">Clothing</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Kids/Boys</a></li>

									<li><a class="clickableanchor" (click)="openCategory(62,'Kids/Boys/Clothing/Tops &amp; Tees')"> Tops &amp; Tees</a></li>
									<li><a class="clickableanchor" (click)="openCategory(63,'Kids/Boys/Clothing/Jeans')"> Jeans</a></li>
									<li><a class="clickableanchor" (click)="openCategory(64,'Kids/Boys/Clothing/Shorts')"> Shorts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(65,'Kids/Boys/Clothing/Jackets &amp; Hoodies')"> Jackets &amp;
											Hoodies</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">Shoes</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Kids/Boys</a></li>



									<li><a class="clickableanchor" (click)="openCategory(67,'Kids/Boys/Shoes/Boots')"> Boots</a></li>
									<li><a class="clickableanchor" (click)="openCategory(68,'Kids/Boys/Shoes/Sandals &amp; Flipflops')"> Sandals &amp;
											Flipflops</a></li>
									<li><a class="clickableanchor" (click)="openCategory(69,'Kids/Boys/Shoes/Sneakers')"> Sneakers</a></li>
									<li><a class="clickableanchor" (click)="openCategory(70,'Kids/Boys/Shoes/Casual Shoes')"> Casual Shoes</a></li>
								</ul></li>
							<li class="has-children"><a href="#0">School Uniform</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Kids/Boys</a></li>

									<li><a class="clickableanchor" (click)="openCategory(71,'Kids/Boys/School Uniform/Shirts')"> Shirts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(72,'Kids/Boys/School Uniform/Shorts')"> Shorts</a></li>
									<li><a class="clickableanchor" (click)="openCategory(73,'Kids/Boys/School Uniform/Trousers')"> Trousers</a></li>
									<li><a class="clickableanchor" (click)="openCategory(74,'Kids/Boys/School Uniform/Shoes')"> Shoes</a></li>
									<li><a class="clickableanchor" (click)="openCategory(75,'Kids/Boys/School Uniform/Knitwear')"> Knitwear</a></li>
								</ul></li>

								<li class="has-children"><a href="#0">Accessories</a>
									<ul class="is-hidden">
									  <li class="go-back"><a href="javascript: void(0);">Kids/Boys</a></li>
									  <li><a class="clickableanchor" (click)="openCategory(79,'Kids/Boys/Accessories/Watches')"> Watches</a></li>
									  <li><a class="clickableanchor" (click)="openCategory(83,'Kids/Boys/Accessories/Masks')"> Masks</a></li>
										<li><a class="clickableanchor" (click)="openCategory(87,'Kids/Boys/Accessories/Hats')"> Hats</a></li>
								    	<li><a class="clickableanchor" (click)="openCategory(90,'Kids/Boys/Accessories/Bags')"> Bags</a></li>

								   </ul>
								</li>

						</ul>
					</li>

						<li class="has-children"><a href="javascript: void(0);">Homeware</a>
						<ul class="is-hidden menu-div-height">
							<li class="go-back"><a href="javascript: void(0);">Homeware</a></li>
							<li class="has-children"><a href="#0">Bedroom</a>
								<ul class="is-hidden">
									<li class="go-back"><a href="javascript: void(0);">Bedroom</a></li>

									<li><a class="clickableanchor" (click)="openCategory(88,'Homeware/Bedroom/Blankets')"> Blankets</a></li>

								</ul>
							</li>

					</ul></li>

				</ul></li>
			<li class="clickableanchor"><a (click)="getProductsByCategory('accessories','all')">Accessories &amp; Jewellery</a></li>
			<li class="clickableanchor"><a (click)="getProductsByCategory('skincare','all')">Skin Care &amp; Beauty</a></li>
		</ul>
	</nav>
    </div>
    	<input id="searchInput"  class="form-control" type="search" (keyup.enter)="searchForProducts()"  placeholder="Search for products...">
    <div class="input-group-append">
         <button class="btn text-white text-center  bg-success" type="button" style="width: 100%" (click)="searchForProducts()">
			 <i  class="fa fa-search fa-1x" aria-hidden="true"></i>
		     		 </button>
    </div>
</div>
