import {Component, OnInit} from '@angular/core';
import {
    faBuilding,
    faEnvelope,
    faFileInvoiceDollar,
    faHome,
    faLockOpen,
    faShoppingBasket,
    faShoppingCart,
    faStickyNote,
    faTruckLoading,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import {LoginResponse} from '../../model/LoginResponse';
import {Router} from '@angular/router';
@Component({
  selector: 'myafrica-admin-clerk-dashboard',
  templateUrl: './admin-clerk-dashboard.component.html',
  styleUrls: ['./admin-clerk-dashboard.component.scss']
})
export class AdminClerkDashboardComponent implements OnInit {

    cartIcon = faShoppingCart;
    moneyIcon = faFileInvoiceDollar;
    messagesIcon = faEnvelope;
    profileIcon = faUser;
    homeIcon = faHome;
    businessesIcon = faBuilding;
    productIcon = faShoppingBasket;
    returnsIcon = faTruckLoading;
    passwordIcon = faLockOpen;
    vendorAgreementIcon = faStickyNote;
    loginResponse: LoginResponse;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.loginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));
    }

    navigate(path: string) {
        this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}], {skipLocationChange: true}).then();
    }
}
