<br />
<div class="container" >
	<div class="row">
		<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 pull-right">
		</div>
		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-info text-dark" *ngIf="displayMessage">
		{{message}}
		</div>
		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-danger text-white"  *ngIf="displayErrorMessage">
		{{message}}</div>


		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">

			<button  type="button" *ngIf="displaySaveButton" class="btn btn-success"
				(keyup.enter)="updateStatuses()" (click)="updateStatuses()">
				<i  class="fa fa-save " aria-hidden="true"></i>&nbsp;&nbsp;Save
			</button>
		</div>
	</div>
	<br />
</div>

<div class="container modal-width d-block d-md-none">
	<div class="row">
		<div
			class="col-12 text-capitalize">
			<span class="font-weight-bold">Order No :
				</span>{{selectedCustomerOrder.orderId}}
		</div>
		
		<div
			class="col-12 text-capitalize">
			<span class="font-weight-bold">Status :
				</span>{{selectedCustomerOrder.orderStatusDescription}}
		</div>
		<div
			class="col-12 text-capitalize">
			<span class="font-weight-bold">Amount :
				</span> {{selectedCustomerOrder.orderAmount | currency :
				currencyValue }}
		</div>
	</div>

	<div class="container mt-2"
		*ngFor="let itemrow of selectedCustomerOrder.customerOrderItems">
		<div class="row">
			<div class="col-4 bg-primary text-white">Item</div>

			<div class="col-8">
				<img *ngIf="hasLogo(itemrow); else logoMissing"
					[src]="itemrow.product.defaultImageUrl"
					[alt]="itemrow.product.productName"
					class="d-inline img-fluid mx-3 p-2 border border-primary"
					[ngStyle]="{'max-height':'80px'}" data-toggle="modal"
					style="cursor: pointer" data-target="#viewBigImageModal">

			</div>
		</div>

		<div class="row">
			<div class="col-4 bg-primary text-white">Product Name</div>

			<div class="col-8">{{ itemrow.product.productName }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Quantity</div>
			<div class="col-8">{{ itemrow.quantity }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Price</div>
			<div class="col-8">{{ itemrow.itemPrice | currency :
				itemrow.product.currency }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Total</div>
			<div class="col-8">{{ itemrow.itemPrice * itemrow.quantity |
				currency : itemrow.product.currency}}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Status</div>
			<div
				*ngIf="selectedCustomerOrder.orderStatusDescription; else mobileStatusMissing"
				class="col-8">
				{{selectedCustomerOrder.orderStatusDescription}}</div>
		</div>
		<ng-template #mobileStatusMissing>
		<div class="col-8">Unknown</div>

		</ng-template>
		<div class="row">
			<div class="col-4 bg-primary text-white"></div>
			<div class="col-8"
				(click)="open(displayRadioButtonsDetails, itemrow.product.productBusiness.businessName, itemrow.product.productBusiness.businessContactNumber)">
				<i class="fa fa-info-circle fa-1x" style="color: #f15a22;"></i>
			</div>
		</div>

	</div>

	<hr />
</div>

<div class="container d-none d-none d-md-block">
<br/>
<div class="shadow  ">
	<div class="row">
		<div
			class="col-3 text-capitalize">
			<span class="font-weight-bold">Order No :
				</span>{{selectedCustomerOrder.orderId}}
		</div>
		
		<div
			class="col-6 text-capitalize">
			<span class="font-weight-bold">Status :
				<select
					[(ngModel)]="selectedCustomerOrder.orderStatusDescription" id="dropdown"
					[disabled]="selectedCustomerOrder.orderStatusDescription == 'Items delivered' || selectedCustomerOrder.orderStatusDescription == 'Order cancelled'"
					(click)="setCurrentStatus(selectedCustomerOrder.orderStatusDescription)" (ngModelChange)="changeOrderStatus(selectedCustomerOrder)" style="width: 250px">
					 <option *ngFor="let orderstatus of orderStatuses" [disabled]="orderstatus.disabled"
                	[ngStyle]="{'background-color': orderstatus.disabled ? 'white':'green', 'color': orderstatus.disabled ? 'black':'white'}">{{orderstatus.description}}</option>

				</select>
				</span>
		</div>
		<div
			class="col-3  text-capitalize">
			<span class="font-weight-bold">Amount :
				</span>{{selectedCustomerOrder.orderAmount | currency :currencyValue }}
		</div>
	</div>
	
	<div class="row mt-2">
		<div
			class="col-3 text-capitalize">
			<span class="font-weight-bold">Customer Name :
				</span>{{selectedCustomerOrder.customer_name}}
		</div>
		
		<div
			class="col-6">
			<span class="font-weight-bold">Email :
				
				</span>{{selectedCustomerOrder.customer_email_address}}
		</div>
		<div
			class="col-3  text-capitalize">
			<span class="font-weight-bold">Contact No :
				
				</span>{{selectedCustomerOrder.customer_cell_no}}
		</div>
	</div>
	
<div class="row mt-2">

		<div
			class="col-3 text-capitalize">
			<span class="font-weight-bold">Created Date :
				</span>{{selectedCustomerOrder.created_Date | date: dateFormat}}
		</div>
		
		<div
			class="col-2 text-capitalize">
			<span class="font-weight-bold">Instructions:
				</span>
		</div>
		<div
			class="col-7 text-capitalize">
			   <textarea type="text" class="form-control input-lg" id="comment" disabled
			name="comment" rows="3" [(ngModel)]="selectedCustomerOrder.deliveryInstructions"
			 required> {{selectedCustomerOrder.deliveryInstructions}}
               </textarea>   			
		</div>
		
	</div>
</div>
<br/>
<div class="shadow mt-2">
	<div style="width:100%;" class="bg-primary">
	<div class="row container-fluid">
		<div class="card-body text-white col-2 col-md-2">Item</div>
		<div class="card-body text-white col-3 col-md-3  col-sm-6">Product Name</div>
		<div class="card-body text-white col-1 col-md-1 d-none d-md-block">Qty</div>
		<div class="card-body text-white col-2 col-md-2 d-none d-md-block">Price</div>

		<div class="card-body text-white col-3 col-md-3 col-sm-5">Status</div>
		<div class="card-body text-white col-1 col-md-1 col-sm-1"></div>
	</div>
	</div>
	<div class="row  container-fluid"
		*ngFor="let itemrow of selectedCustomerOrder.customerOrderItems">
		<div class="col-6  col-md-2 ">
			<img *ngIf="hasLogo(itemrow); else logoMissing"
				[src]="itemrow.product.defaultImageUrl"
				[alt]="itemrow.product.productName"
				class="d-inline img-fluid mx-3 p-2 border border-primary"
				[ngStyle]="{'max-height':'80px'}" data-toggle="modal"
				style="cursor: pointer" data-target="#viewBigImageModal"
				>

		</div>

		<div class="col-3 col-md-3 col-sm-7 inner-div-cell">{{
			itemrow.product.productName }}</div>
		<div class="col-1 col-md-1  inner-div-cell">{{ itemrow.quantity
			}}</div>
		<div class="col-2 col-md-2  inner-div-cell">{{ itemrow.itemPrice
			| currency : itemrow.product.currency }}</div>

		<div class="col-3 col-md-3  col-sm-3 inner-div-cell"
			*ngIf="selectedCustomerOrder.orderStatusDescription; else statusMissing">
			{{selectedCustomerOrder.orderStatusDescription}}</div>
		<ng-template #statusMissing>
		<div class="col-3 col-md-3  col-sm-3 inner-div-cell">Unknown</div>

		</ng-template>

		<div class="col-1 col-md-1 col-sm-1 inner-div-cell"
			(click)="open(displayRadioButtonsDetails, itemrow.product.productBusiness.businessName, itemrow.product.productBusiness.businessContactNumber)">
			<i class="fa fa-info-circle fa-1x" style="color: #f15a22;"></i>
		</div>

	</div>
	<hr />
   </div>
</div>

<ng-template #logoMissing> <img
	src="https://myafricaconnect.com/images/image-not-available.jpg"
	class="shadow rounded" [alt]="" height="80px"> 
</ng-template>

<ng-template #displayRadioButtonsDetails let-modal>
	<div class="container p7 mt-1">
		<b> Order No: {{businessName}}</b> 
		<br /> 
		<b>Contact No:
			{{contactNo}}</b> 
		<br />
	
		<hr />
		<button type="button" data-dismiss="modal" (click)="modal.close('')"
			class="btn btn-danger">Close</button>
	</div>
</ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
