import {CustomerOrder} from './CustomerOrder';

export class CustomerOrderReturn {
  customerOrderReturnId: string;
  refNumber: string;
  returnReason: string;
  created_Date: string;
  statusUpdate_Date: string;
  customer_cell_no: string;
  consignmentID: string;
  description: string;
  customerOrder: string;
  moneyBack: boolean; // customer wants to be paid back or not
  exchange: boolean; // customer wants items exchange or not
  flaggedItems: string; // items flagged to be returned
  customerOrderReturnItems: any[];
  returnStatusDescription: string;

}
