<div class="container-fluid shadow">
    <div class="container-fluid my-4 py-2">
        <hr class="bg-success shadow">
        <div class="row justify-content-center ">
            <h1 class="text-center  text-primary px-1 position-sticky">
                <i class="fa fa-book text-info"></i>&nbsp;{{product.productName}}
            </h1>
        </div>
        <hr class="bg-warning shadow">
    </div>

    <div class="row">
        <div class="col-12">
            <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="productHistory"
                            [config]="editorConfig"></angular-editor>
        </div>
    </div>
    <div class="row">
        <div class="col-12 py-2">
            <button class="btn btn-primary btn-theme" (click)="addHistory()" *ngIf="!alreadyHasHistory">Add History
            </button>
            <button class="btn btn-primary btn-theme" (click)="updateHistory()" *ngIf="alreadyHasHistory">Update
                History
            </button>
        </div>
    </div>
</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
