<myafrica-page-title pageTitle="YOUR ORDER DETAILS"></myafrica-page-title>

<div class="container">

    <div class="bg-light">
        <div class="bg-light">
            <h5>Unfortunately something went wrong with the payment process. </h5>

        </div>
        <div class="bg-light">
            <h5>Please select another option or try the payment process again!! </h5>

        </div>


    </div>

    <br/>

    <div class="container product-items-div d-md-none d-lg-none"
         style="margin-left: 0px" *ngFor="let cartItem of cartItems">
        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell">
                <span>Product</span>
            </div>
            <div class="col-6 div-cell d-md-none d-lg-none text-center">
                <img *ngIf="cartItem.product.defaultImageUrl; else elseBlock"
                     class="img-thumbnail" style="height: 80px; width: 80px"
                     src="{{cartItem.product.defaultImageUrl}}"/>
                <ng-template #elseBlock>
                    <img src="../../htpps://myafricaconnect.com/images/image-not-available.jpg"
                         alt="business logo" style="height: 80px; width: 80px"
                         class="img-thumbnail"/></ng-template>
                {{cartItem.product.productName}}
            </div>
        </div>

        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell">
                <span>Price</span>
            </div>
            <div class="col-6 text-warning"><h6 class="text-warning">{{cartItem.product.price
                | currency: cartCurrency + ' '}}</h6></div>
        </div>


        <div class="row d-md-none d-lg-none">
            <div class="col-6 div-cell">
                <span>Quantity</span>
            </div>
            <div class="col-6 div-cell"><h6 class="text-warning">{{cartItem.quantity}}</h6></div>
        </div>


        <div class="row d-md-none d-lg-none" *ngIf="isCustomerCollecting()">
            <div class="col-6 div-cell">Business Address</div>
            <div class="col-6 div-cell">
                {{cartItem.product.productBusiness.businessName}},
                {{cartItem.product.productBusiness.businessAddress.addressline1}},
                {{cartItem.product.productBusiness.businessAddress.streetname}},
                {{cartItem.product.productBusiness.businessAddress.city}},
                {{cartItem.product.productBusiness.businessAddress.zipcode}}</div>
        </div>
    </div>
    <br/>

    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6">Total</div>
        <div class="col-6 ">
            <h6 class="text-warning">{{cartTotal |
                currency:cartCurrency + ' '}}</h6>

        </div>
    </div>

    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6">Delivery Cost</div>
        <div class="col-6 ">
            <h6 class="text-warning">{{deliveryCost |
                currency:cartCurrency + ' '}}</h6>

        </div>
    </div>
    <div class="row d-md-none d-lg-none"
         style="border-top: 1px solid #dee2e6;">
        <div class="col-6">Total Cost</div>
        <div class="col-6 ">
            <h6 class="text-warning">{{totalCost |
                currency:cartCurrency + ' '}}</h6>

        </div>
    </div>

    <div class="panel-body table-responsive d-none d-md-block">
        <table class="table" id="cartTable">
            <thead>
            <tr>
                <th style="text-align: center">Product</th>
                <th style="text-align: center">Price</th>
                <th style="text-align: center">Quantity</th>
                <th *ngIf="isCustomerCollecting()" style="text-align: center">Business
                    Address
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cartItem of cartItems">
                <td style="text-align: center">
                    <div class="form-check form-check-inline">
                        <img style="height: 70px; width: 70px"
                             *ngIf="cartItem.product.defaultImageUrl; else elseBlock"
                             class="img-fluid mx-auto" src="{{cartItem.product.defaultImageUrl}}"
                             alt="business logo"/>
                        <ng-template #elseBlock><img
                                src="https://myafricaconnect.com/image-not-available.jpg" alt="business logo"
                                style="height: 70px; width: 70px" class="img-fluid"/></ng-template>
                        <h5>{{cartItem.product.productName}}</h5>
                    </div>
                </td>
                <td style="text-align: center"><h6
                        class="text-warning">{{cartItem.product.price
                    | currency: cartCurrency + ' '}}</h6></td>
                <td style="text-align: center">
                    <h6 class="text-warning">{{cartItem.quantity}}</h6>
                </td>

                <td *ngIf="isCustomerCollecting()"
                    style="text-align: center">{{cartItem.product.productBusiness.businessName}},
                    {{cartItem.product.productBusiness.businessAddress.addressline1}},
                    {{cartItem.product.productBusiness.businessAddress.streetname}},
                    {{cartItem.product.productBusiness.businessAddress.city}},
                    {{cartItem.product.productBusiness.businessAddress.zipcode}}</td>

            </tr>
            <tr>
                <td style="text-align: right"><h5>Total</h5></td>
                <td style="text-align: center">
                    <h6 class="text-warning">{{cartTotal | currency:
                    cartCurrency + ' '}}</h6></td>
                <td></td>
                <td></td>
            </tr>

            <tr>
                <td style="text-align: right"><h5>Delivery Cost</h5></td>
                <td style="text-align: center">
                    <h6 class="text-warning">{{deliveryCost | currency:
                    cartCurrency + ' '}}</h6></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style="text-align: right"><h5>Total Cost</h5></td>
                <td style="text-align: center">
                    <h6 class="text-warning">{{totalCost | currency:
                    cartCurrency + ' '}}</h6></td>
                <td></td>
                <td></td>
            </tr>

            </tbody>
        </table>
    </div>
    <div class="container">
        <div class="row">


            <div class="col-12 col-lg-6">
                <button class="btn btn-success w-90"
                        type="button" (click)="instantEFTPayment()">
					<i class="fa fa-shopping-basket text-white" aria-hidden="true"></i>
                                    Try again? <i class="fa fa-arrow-right text-white"></i>
                </button>
            </div>
            <div class="col-12 col-lg-6">

                <button class="btn btn-success btn-block"
                                        type="button" (click)="goBack()"
                                        >
                                    <i class="fa fa-shopping-basket text-white" aria-hidden="true"></i>
                                    Continue Shopping <i class="fa fa-arrow-right text-white"></i>
                  </button>

            </div>

        </div>
    </div>

</div>

<ng-template #payoption let-modal>
    <div class="container">
        <div class="row modal-header m-0">
            <h2 class="form-heading">How would you like to pay?</h2>
        </div>

        <div class="row" style="margin-top: 5px;">

            <div class=" col-6  col-md-6">
                <input type="radio" name="optradio" (change)="setInstantPayment(true)"
                       checked id="last7DaysRadio"><label
                    class="search-label"> &nbsp;
                <img class="connect-btn-image" width="90px" height="50"
                     src="htpps://myafricaconnect.com/images/payfast.png">


            </label>

            </div>
            <div class=" col-6  col-md-6">
                <input type="radio" name="optradio" (change)="setInstantPayment(false)"
                ><label class="search-label"> &nbsp;EFT Transfer</label>
            </div>

        </div>

        <div class="row">

            <div class="col-12 col-lg-6">

                <button class="btn btn-primary w-90"
                        type="button" (click)="placeOrder()"
                        [disabled]="cartItems && cartItems.length <= 0">
										<span><img class="connect-btn-image"
                                                   src="htpps://myafricaconnect.com/images/cart36x36.png"></span> View Cart
                </button>


            </div>
            <div class="col-12 col-lg-6">

                <button type="button" data-dismiss="modal" (click)="modal.close('')"
                        class="btn btn-danger">Close
                </button>

            </div>
        </div>
    </div>
</ng-template>


<myafrica-footer></myafrica-footer>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
