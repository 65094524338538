<div class="container pt-md-2 mt-5 mb-5">

    <div class="container align-middle col p-md-5 col-sm-12 col-md-8">
        <div class="card  text-dark shadow">
            <div class="card-header text-center h3 bg-primary text-white">
                LOGIN
            </div>
            <div class="card-body">
                <form (ngSubmit)="login(loginDetails)" #loginForm="ngForm" class="sm">
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="emailAddress">Email Address</label>
                            <input type="email" class="form-control form-control-sm" id="emailAddress"
                                   [ngClass]="{'border border-danger':emailAddress.touched && emailAddress.invalid}"
                                   [(ngModel)]="loginDetails.email" name="idNumber" required #emailAddress="ngModel"
                                   pattern="^\S*[@]\S*[.]\S*$">
                            <div *ngIf="emailAddress.touched && emailAddress.invalid " class="text-danger">valid
                                Email required
                            </div>

                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="password">Password</label>
                            <input type="password" class="form-control form-control-sm" id="password"
                                   #password="ngModel"
                                   required
                                   [(ngModel)]="loginDetails.password" name="password"
                                   [ngClass]="{'border border-danger':password.touched && password.invalid}">
                            <div *ngIf="password.touched && password.invalid" class="text-danger">Password
                                required
                            </div>


                        </div>
                    </div>


                    <hr class="bg-warning">
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block "
                                [disabled]="loginForm.form.invalid">
                            Sign-In
                        </button>

                    </div>
                    <hr class="bg-warning">
                    <div class="text-center pb-3">
                        <a routerLink="/register-option" class="text-decoration-none text-dark">Create new account.</a>
                    </div>
                    <div class="text-center pb-3">
                        <a class="text-decoration-none text-dark"
                           [routerLink]="'/reset-password'"> Reset password</a>
                    </div>

                    <div class="text-center pb-3">
                        <a class="text-decoration-none text-dark"
                           [routerLink]="'/forgot-password'"> Forgot password</a>
                    </div>

                    <div class="text-center pb-3">
                        <a class="text-decoration-none text-dark"
                           (click)="resendActivationLink(loginDetails.email,loginDetails.password)"> <span class="txt">Re-send Activation Email</span></a>
                    </div>

                </form>

            </div>
        </div>

    </div>

</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


