import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'myafrica-all-products',
    templateUrl: './all-products.component.html',
    styleUrls: ['./all-products.component.scss'],
})
export class AllProductsComponent implements OnInit {

    loading = false;


    constructor() {}

    ngOnInit() {}    

}
