import {Component, ElementRef, ViewChild} from '@angular/core';
import {EventTargetInterruptSource, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {AppIdleService} from './shared/services/app-idle.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationStore} from './interceptors/application-store.service';
import {UserSessionManager} from './shared/services/session-manager.service';
import {Router} from '@angular/router';


@Component({
    selector: 'myafrica-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    title = 'angular-idle-timeout';
    @ViewChild('displayCountDown', {static: false}) displayCountDown: ElementRef;
    appCountDown: number;

    constructor(private modalService: NgbModal, private router: Router, private idle: Idle, private keepalive: Keepalive,
                private idleAppService: AppIdleService, private element: ElementRef, private tokenStore: ApplicationStore,
                private userSessionManager: UserSessionManager) {
        this.appCountDown = 0;
        // session to expire in 20 minutes
        idle.setIdle(1200);

        // give user 60 seconds option to continue or be logged out automatically
        idle.setTimeout(60);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        const events = 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';

        idle.setInterrupts([new EventTargetInterruptSource(this.element.nativeElement, events)]);

        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            this.userSessionManager.refreshAccessToken();
// 	  this.modalService.dismissAll();
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            const cart = this.tokenStore.getJsonItem('cart');

            this.idleState = 'Timed out!';
            this.modalService.dismissAll();
            this.timedOut = true;
            localStorage.clear();

            this.tokenStore.signOut();
            if (cart !== null && cart !== undefined) {
                sessionStorage.setItem('cart', JSON.stringify(cart));
            }

            this.router.navigate(['/']);
        });

        idle.onIdleStart.subscribe(() => {
            this.modalService.open(this.displayCountDown, {
                size: 'sm',
                centered: true,
                ariaLabelledBy: 'modal-basic-title',
            }).result.then((result) => {
                },
            );
            this.idleState = 'You\'ve gone idle!';
            // this.childModal.show();
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'Your session is about to time out ....';
            this.appCountDown = countdown;
            // console.log(this.idleState);
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.idleAppService.getUserLoggedIn().subscribe(userLoggedIn => {

            if (userLoggedIn) {
                idle.watch();
                this.timedOut = false;
            } else {
                idle.stop();
            }
        });

        // this.reset();
    }

    isLoggedIn(): boolean {
        if (sessionStorage.getItem('userName')) {
            return true;
        }
        return false;
    }


    reset() {
        this.idle.watch();
        this.timedOut = false;
    }


    stay() {
        this.modalService.dismissAll();
        this.reset();
    }

    closeModal() {
        this.reset();
    }

    logout() {

        this.modalService.dismissAll();
        localStorage.clear();
        this.tokenStore.signOut();
        this.idleAppService.setUserLoggedIn(false);
        this.router.navigate(['/']);
    }


}
