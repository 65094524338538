import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'myafrica-edit-customer-order',
  templateUrl: './edit-customer-order.component.html',
  styleUrls: ['./edit-customer-order.component.scss']
})
export class EditCustomerOrderComponent implements OnInit {

   pageTitle = 'Order Details';
	
  constructor() { }

  ngOnInit(): void {
  }

}
