import {Component, OnInit} from '@angular/core';
import {Product} from '../../model/Product';
import {Business} from '../../model/Business';
import {BusinessServiceService} from '../../shared/services/business/business-service.service';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {CategoryModel} from '../../model/CategoryModel';
import {CategoryModelRevamped} from '../../model/CategoryModelRevamped';
import {BusinessCategory} from '../../model/BusinessCategory';
import {ClothingGender} from '../../model/ClothingGender';
import {ClothingGroup} from '../../model/ClothingGroup';
import {ClothingCategory} from '../../model/ClothingCategory';
import {NotifierService} from '../../services/notifier.service';
import {Subscription} from 'rxjs';
import {AppIdleService} from '../../shared/services/app-idle.service';
import Swal from 'sweetalert2';
import {Ng2ImgMaxService} from 'ng2-img-max';
import {Utils} from '../../shared/Utils';
import {HttpResponse} from '@angular/common/http';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import {HomeService} from '../../services/home.service';


@Component({
  selector: 'myafrica-add-product-details',
  templateUrl: './add-product-details.component.html',
  styleUrls: ['./add-product-details.component.scss']
})
export class AddProductDetailsComponent implements OnInit {

    selectedBusiness: Business;
    selectedProduct: Product;
    businesses: Business[];

    productId: string;
    unitsInStock: number;
    currency = 'ZAR';
    productCategory: string;
    businessId: string;
    productName: string;
    price: number;
    productDescription: string;
	productLength: number;
	productHeight: number; 
	productBreadth: number;
	productMass: number;

    calculatedServiceFee = 0;
    calculatedMarkUpFee = 0;
    calculatedEftFee = 0;

    serviceFee = 0;
    markUpFee = 0;
    courierFee = 0;
    totalProductFee = 0;
    customerPrice = 0;
    eftFee = 0;
    adminFee = 0;
    productFees: any;

    displayMessage: string;
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;

    loading = false;
	url: ArrayBuffer | string;
    defaultUrl: ArrayBuffer | string;
    previousUrl = null;
    imageWidth: number;
    imageHeight: number;
   
    selectedFile: File;
    defaultUrlImage: File;
	 uploadedImage: Blob;

	
    private businessCategories: BusinessCategory[];
    searchProductCategoryMap: Map<string, string>;

    clothingGenders: ClothingGender[];
    clothingGroups: ClothingGroup[];
    clothingCategories: ClothingCategory[];
    displayGenders: boolean;
    displayGroups: boolean;
    displaySubCategory: boolean;

    genderId: number;
    groupId: number;
    subCategoryId: number;
   
    subs1: Subscription;
    subs2: Subscription;
    sizesElements: { name, count }[];
    buttonDisabled = false;
	disableBusinessSelect = false;
	categories: any[];
	rootCategory: any[];
	categoryModelRevamped: CategoryModelRevamped;
	subCategories: CategoryModelRevamped[];
	subCategoryModelRevamped: CategoryModelRevamped;
	subSubCategories: CategoryModelRevamped[];
	subSubCategoryModelRevamped: CategoryModelRevamped;
	
    constructor(private tableService: CodeTablesServiceService,
                private businessService: BusinessServiceService,
                private location: Location,  private ng2ImgMax: Ng2ImgMaxService,
                private router: Router, private idleAppService: AppIdleService,
                private appStore: ApplicationStore, private notifier: NotifierService,
				private homeService: HomeService) {
    }

    ngOnInit() {
		this.genderId  = 0;
		this.groupId  = 0;
		this.subCategoryId  = 0;
		this.businessId = "";
		if(sessionStorage.getItem('categories')) {
           this.categories = JSON.parse(sessionStorage.getItem('categories'));
		   this.setDefaultGendersData();
		} else {
			this.homeService.getCategories().subscribe((value: any) => {
					this.rootCategory = value.body[Object.keys(value.body)[0]];
					this.categories = Object.values(this.rootCategory)[2];
					this.setDefaultGendersData();
					sessionStorage.setItem('categories', JSON.stringify(this.categories));
			},
			);
		}
	    this.businessCategories = JSON.parse(sessionStorage.getItem('businessCategories'));
	    this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
		if( this.selectedProduct) {
			
			this.currency = this.selectedProduct.currency;
			if (this.selectedProduct.categoryId !== 0 && this.selectedProduct.categoryId !== undefined) {
				this.subCategoryId = this.selectedProduct.categoryId;
				this.setData();

			}
		}
		
		
        const userDetails: { userDetailsId: any } = JSON.parse(sessionStorage.getItem('userDetails'));
	    
		const profileId = sessionStorage.getItem('profileId');
	
		this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
		
		const storedBusinesses = JSON.parse(sessionStorage.getItem('businesses'));
		if (storedBusinesses === undefined || storedBusinesses === null) {
			this.businessService.getBusinessesByProfileId(profileId).subscribe((value: Business[]) => {
					this.businesses = value;
					
					if(!this.selectedBusiness) {
						this.selectedBusiness  = this.businesses[0];
					}
				},
			);
		} else {
			this.businesses = storedBusinesses;
			if(!this.selectedBusiness) {
				this.selectedBusiness  = this.businesses[0];
			}
		}
		
   	
		if( this.selectedBusiness) {
			this.businessId = this.selectedBusiness.id;
		}
    
	    if (this.selectedProduct.productName) {
            this.productId = this.selectedProduct.id;
			this.unitsInStock = this.selectedProduct.unitsInStock;
            this.productName = this.selectedProduct.productName;
            this.productDescription = this.selectedProduct.productDescription;
            this.currency = this.selectedProduct.currency;
            this.productCategory = this.selectedProduct.productCategory;
            this.totalProductFee = this.selectedProduct.price;
            this.price = parseFloat(this.selectedProduct.customerPrice);
			this.productLength = this.selectedProduct.productLength;
			this.productHeight = this.selectedProduct.productHeight; 
			this.productBreadth = this.selectedProduct.productBreadth;
			this.productMass = this.selectedProduct.productMass;
        } else {
			if(this.selectedBusiness) {
				//this.productCategory = this.selectedBusiness.businessCategory.key;
				//this.displayGendersDropdown(this.productCategory);
			}
		}
		this.getProductFees();	
		this.businessSelected();
    }
	
	getProductFees() {
	
	    this.productFees =  sessionStorage.getItem('codeTablesData');     
		const productFeesObject = JSON.parse(this.productFees).productPricingFee;
		this.serviceFee = productFeesObject[0].serviceFee;
		this.markUpFee =  productFeesObject[0].markUpFee;
		this.courierFee =  productFeesObject[0].courierFee;
		this.adminFee =  productFeesObject[0].serviceFee;
		this.eftFee =  productFeesObject[0].eftFee;

	}

	businessSelected() {
		this.disableBusinessSelect = (this.businessId) ? true:  false;
	}

	
	updateDefaultUrl(event) {
	    if (event.target.files && event.target.files[0]) {
            this.defaultUrlImage = event.target.files[0];
            this.validateImage();
	
			const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
			
            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.defaultUrl = event.target.result;
		
            };
        }

    }
	
    validateImage() {
        this.loading = true;
        this.displayErrorMessage = false;
        this.displayMessage = '';
        const image = this.defaultUrlImage;
        const reader = new FileReader();
        let img = null;
        img = new Image();
	    this.url = reader.result;
		let messagePrefix = "We have encountered a problem resizing the image.";

	    this.ng2ImgMax.resizeImage(image, 300, 300).subscribe(
            result => {
                this.uploadedImage = result;
                // this.selectedFile = this.uploadedImage;
                reader.readAsDataURL(this.uploadedImage); // read file as data billboardUrl
                reader.onload = () => { // called once readAsDataURL is completed
                    this.url = reader.result;
                    img.src = reader.result; // The data URL

                    img.onload = () => {
                        this.imageWidth = img.width;
                        this.imageHeight = img.height;
                        this.loading = false;
                        if (this.imageWidth < 160) {
                            this.displayErrorMessage = true;
                            this.displayMessage = messagePrefix + ' Required minimum image width is 200px!!!';
							this.notifier.notify(this.displayMessage, 'error');
							this.defaultUrl = undefined;
                            this.defaultUrlImage = undefined;
                            this.url = this.previousUrl;
                            return;
                        }
                    };

                    this.defaultUrlImage = new File([this.uploadedImage], 'untitled.png', {type: 'image/png'});
                };
            },
            () => {
                this.displayErrorMessage = true;
                this.displayMessage = messagePrefix +' Please resize the images. Required minimum image width is 200px!!!';
                this.notifier.notify(this.displayMessage, 'error');
				this.defaultUrl = undefined;
                this.loading = false;
                this.defaultUrlImage = undefined;
                this.url = this.previousUrl;
            },
        );
	}


    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.selectedFile = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            };
        }

    }
	

	logout() {
        const cart = this.appStore.getJsonItem('cart');
		
        this.appStore.signOut();
        this.idleAppService.setUserLoggedIn(false);
        sessionStorage.clear();
        if (cart !== null && cart !== undefined) {
            sessionStorage.setItem('cart', JSON.stringify(cart));
        }

        this.router.navigate(['/']).then();
    }

    setData() {
        let found = false;

        this.clothingGenders.forEach(element => {
            if (!found) {
                this.genderId = parseInt(element.id, 0);
            }
            element.clothingGroups.forEach(groupElement => {
                if (!found) {
                    this.groupId = parseInt(groupElement.id, 0);
                }
                groupElement.clothingCategories.forEach(categoryElement => {
                    if (this.subCategoryId === parseInt(categoryElement.id, 0)) {
                        found = true;
                        return;
                    }

                });

            });

        });

    }

    // displays the gneder, group and sub category dropdowns
    setDefaultGendersData() {
        this.displayGenders = true;
		this.subCategories = this.categories[0].childCategories;
		this.subSubCategories = this.categories[0].childCategories[0].childCategories;
		this.subCategoryModelRevamped = this.subCategories[0];
		this.subSubCategoryModelRevamped = this.subSubCategories[0];
    }

    displayGendersDropdown(categoryModelRevamped: CategoryModelRevamped) {
            this.displayGenders = true;
			this.subCategories = categoryModelRevamped.childCategories;
			this.subSubCategories = null;
			this.subCategoryModelRevamped = null;
			this.subSubCategories = categoryModelRevamped.childCategories[0].childCategories;
	}

    displayGroupsDropDown(suCategoryModelRevamped: CategoryModelRevamped) {
	    this.displayGroups = true;
		this.subSubCategories = suCategoryModelRevamped.childCategories;
    }


    displaySubCategoryDropDown(groupId: number) {
        this.displaySubCategory = true;
	    this.clothingGroups.forEach(element => {
            if (parseInt(element.id, 0) == groupId) {
                this.clothingCategories = element.clothingCategories;
				return;
    
            }
        });	
    }
   


    calculateProductPrice() {
        const productPrice = this.price;
        this.calculatedServiceFee = parseFloat((productPrice * (this.serviceFee / 100)).toFixed(2));

        this.totalProductFee = productPrice + this.calculatedServiceFee;

        this.calculatedMarkUpFee = parseFloat((this.totalProductFee * (this.markUpFee / 100)).toFixed(2));


        this.totalProductFee = this.totalProductFee + this.calculatedMarkUpFee;

        if (this.price === 0 || this.price === null || isNaN(this.price)) {
            this.totalProductFee = 0;
            //    this.adminFee = 0;
        }

        this.calculatedEftFee = parseFloat((this.totalProductFee * (this.eftFee / 100)).toFixed(2));

        this.totalProductFee = parseFloat((this.calculatedEftFee + this.totalProductFee).toFixed(2));


    }

    goBack() {
        this.loading = true;
		 if(sessionStorage.getItem('dashboard-path') !== undefined) {
				this.navigate(sessionStorage.getItem('dashboard-path'));
		}
    }

   navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

 
    updateProduct() {

	    const unitsInStock = this.unitsInStock;
        const numberOfViews = 0;

		if(this.subSubCategoryModelRevamped) {} else {
			if (this.subSubCategoryModelRevamped === undefined) {
				this.notifier.notify('Please select the product category!!', 'error');
				return;
			}
		}
       
        if (this.productName === '' || this.productName === undefined) {
            this.notifier.notify('Please fill in the product name!!', 'error');
            return;
        }

        if (this.price === 0 || this.price === undefined) {
            this.notifier.notify('Please enter the product price!!', 'error');
            return;
        }
      
        if (this.selectedProduct.productMass === undefined || this.productMass == 0) {
            this.notifier.notify('Please enter the product mass!!', 'error');
            return;
        }
	    if (this.selectedProduct.productBreadth === undefined || this.productBreadth == 0) {
            this.notifier.notify('Please enter the product breadth!!', 'error');
            return;
        }
	   
		 if (this.selectedProduct.productHeight === undefined || this.productHeight == 0) {
            this.notifier.notify('Please enter the product height!!', 'error');
            return;
        }
	   
		if (this.selectedProduct.productLength === undefined || this.productLength == 0) {
            this.notifier.notify('Please enter the product length!!', 'error');
            return;
        }
	
        if (this.defaultUrlImage === undefined || this.defaultUrlImage === null) {
            this.notifier.notify('Please select image for the product!!', 'error');
            return;
        }
	
		const productObject = {
            productDescription: this.productDescription,
            price: this.totalProductFee,
            customerPrice: this.price,
            currency: this.currency,
            productName: this.productName,
            businessId: this.selectedBusiness.id,
            productCategory: this.productCategory,
            categoryId: this.subSubCategoryModelRevamped.id,
			productLength: this.selectedProduct.productLength,
			productHeight: this.selectedProduct.productHeight, 
			productBreadth: this.selectedProduct.productBreadth,
			productMass: this.selectedProduct.productMass,
			unitsInStock: this.unitsInStock   
        };
		
        this.loading = true;
        this.addProductWithoutColours(productObject);
 	}


    addProductWithoutColours(productObject) {

        this.subs2 = this.businessService.addProductWithImage_ProductReturn(
            this.defaultUrlImage, this.businessId + '', JSON.stringify(productObject)).subscribe((value: HttpResponse<Product>) => {
            this.loading = false;
			this.selectedProduct = value.body;
			sessionStorage.setItem('currentProduct', JSON.stringify(this.selectedProduct));

            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
			
			const clothingProduct = this.productCategory == 'clothing' ? true : false;

			if(clothingProduct) {
				this.displayMessage = 'Product added successfully!! Please remember to add colours and sizes for your product else they will not appear on the shopping list!!! ';
			} else {
				this.displayMessage = 'Product added successfully!!';
			}
            this.notifier.notify(this.displayMessage, 'success');

            Utils.delay(5000).then(() => {
                this.clearValues();
                this.loading = false;
            	this.router.navigate(['dashboard', {outlets: {dashboard: ['edit-product']}}], {skipLocationChange: true}).then();
				window.scrollTo(0, 0);
            });
        }, error1 => {
			alert(JSON.stringify(error1));
	        this.loading = false;
	        this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.buttonDisabled = false;
            this.displayMessage = 'Failed to add product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.clearValues();
                this.loading = false;
            });
        });
    }

	compareFn( optionOne, optionTwo ) : boolean {
	  return optionOne.id === optionTwo.id;
	}
	
    clearValues() {
        this.url = null;
        this.displayErrorMessage = false;
        this.displaySuccessMessage = false;
        this.displayMessage = '';

    }
}