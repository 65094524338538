<myafrica-breadcrumbs title="Profile Address"></myafrica-breadcrumbs>

<div class="container my-5">

    <div class="row shadow">

        <div class="container mx-0 px-1">
            <div class="col-12 p-0 m--0">
                <form ngForm #userDetailsForm="ngForm">
                    <div class="form-group">
                        <label for="inputAddress">Address</label>
                        <input type="text" class="form-control" id="inputAddress" placeholder="Apartment, complex, studio, or floor"
                               name="address" [(ngModel)]="ownerAddress.addressline1" required>
                    </div>
                    <div class="form-group">
                        <label for="inputAddress2">Address Line 2</label>
                        <input type="text" class="form-control" id="inputAddress2"
                               [(ngModel)]="ownerAddress.streetname" name="addressLine2"
                               placeholder="1234 Main St" required>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputCity">City</label>
                            <input type="text" class="form-control" id="inputCity" name="city"
                                   [(ngModel)]="ownerAddress.city" required>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputState">Province</label>
                             <select required name="businessProvince" class="form-control"
                                                [(ngModel)]="province"
                                                placeholder="Province">
                                            <option [value]="provinceItem.id" 
                                                    *ngFor="let provinceItem of provinces">{{provinceItem.name}}
                                            </option>

                           </select>

                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputZip">Code</label>
                            <input type="text" class="form-control" id="inputZip" name="zipCode"
                                   [(ngModel)]="ownerAddress.zipcode" required>
                        </div>
                    </div>
                    <!--div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="gridCheck" name="signUpForNewsletter"
                                   [(ngModel)]="loginResponse.userDetails.newsletterAccepted">
                            <label class="form-check-label" for="gridCheck">
                                Sign up for newsletter
                            </label>
                        </div>
                    </div-->
                </form>
            </div>
        </div>
        <div class="container mb-5">
            <div class="col-12">
                <button class="btn btn-success float-right" type="submit" (click)="updateProfile()"
                      [disabled]="userDetailsForm.form.invalid" >Update
                    Profile
                </button>
            </div>
        </div>
    </div>
</div>


<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
