import { Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {NotifierService} from '../services/notifier.service';
import {MessageService} from '../services/message.service';
import {ContactUsMessage} from '../model/ContactUsMessage';

import {Utils} from '../shared/Utils';

@Component({
    selector: 'myafrica-newsletter-signup',
    templateUrl: './newsletter-signup.component.html',
    styleUrls: ['./newsletter-signup.component.scss']
})
export class NewsletterSignupComponent implements OnInit, OnDestroy {

    subs: Subscription;

    displayMessage: string;
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;

    email = '';
	message: ContactUsMessage;

    constructor(private notifier: NotifierService, private messageService: MessageService, private router: Router) { }

    ngOnInit() {
    }

      sendMessage() {
        this.displayErrorMessage = false;
        this.displayMessage = '';

        if (this.email == '' || this.email === ' ' || this.email === '') {
      	    this.notifier.notify('Please enter your email!!!', 'error');
           this.displayErrorMessage = true;
           return;
        }

		      this.message = new ContactUsMessage();
        this.message.messagersEmail = this.email;

        this.subs = this.messageService.sendNewsLetterSignup(this.message).subscribe(() => {
        	this.notifier.notify('You have successfully subscribed!!!', 'info');
         this.displayErrorMessage = false;
         this.displaySuccessMessage = true;
         Utils.delay(3000).then(any => {
                this.router.navigate(['/']);
            });



        }, () => {
        	 this.notifier.notify('Error subscribing. Please try again later!!!', 'error');
          this.displayErrorMessage = true;
          this.displaySuccessMessage = false;
        });


    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }


}
