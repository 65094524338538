import {Component, OnInit} from '@angular/core';
import {RegistrationDetails} from '../../model/RegistrationDetails';
import {ProfileService} from '../../shared/services/profile.service';
import {Router} from '@angular/router';
import {NotifierService} from '../../services/notifier.service';
import {HttpResponse} from '@angular/common/http';
import {BusinessCategory} from '../../model/BusinessCategory';
import {Subscription} from 'rxjs';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {UserRole} from '../../model/UserRole';

@Component({
    selector: 'myafrica-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss'],
})

export class UserRegistrationComponent implements OnInit {

    regDetails: RegistrationDetails;
    userRole: string;
    userSeller: boolean;
    registerAs: string;
    birthDate = new Date();
    loading = false;
    displayRegNumber: boolean;

    companyName: string;
    vatNumber: string;
    aboutCompany: string;
    physicalStore: string;
    carryStock: string;
    businessCategories: BusinessCategory[];
    subs1: Subscription;
    businessCategory: number;
    userRoles: any[];


    constructor(private profileService: ProfileService,
                private router: Router, private tableService: CodeTablesServiceService,
                private notifier: NotifierService) {
    }

    ngOnInit(): void {
		 this.userRoles = [];
        this.displayRegNumber = true;
        this.userSeller = false;
        this.registerAs = sessionStorage.getItem('user-type');
        this.regDetails = new RegistrationDetails();
        this.regDetails.monthlyRevenue = 1;
        this.regDetails.physicalStore = 'Yes';
        this.regDetails.carryStock = 'Yes';
		let userSetRole =  new UserRole();
        userSetRole.active = true;
		if (this.registerAs === 'Merchant' || this.registerAs === 'Seller') {
            userSetRole.userRole = 'Merchant';
			this.userRoles.push(userSetRole);
        } else {
	       userSetRole.userRole = 'Shopper';
		   this.userRoles.push(userSetRole);
  		}
		
		this.regDetails.userRoles = this.userRoles;

	
        const businessCategoriesStored = JSON.parse(sessionStorage.getItem('businessCategories'));

        if (businessCategoriesStored === undefined || businessCategoriesStored === null) {

            this.subs1 = this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
                    this.businessCategories = value;
                    sessionStorage.setItem('businessCategories', JSON.stringify(this.businessCategories));
                },
            );
        } else {
            this.businessCategories = businessCategoriesStored;
        }

    }

    setVatRegistered(option: boolean) {
        if (option) {
            this.displayRegNumber = true;
        } else {
            this.displayRegNumber = false;

        }
    }

    setMontlyRevenue(monthlyRevenue: number) {
        this.regDetails.monthlyRevenue = monthlyRevenue;
    }

    setPhysicalStore(physicalStore: string) {
        this.regDetails.physicalStore = physicalStore;
    }

    setCarryStock(carryStock: string) {
        this.regDetails.carryStock = carryStock;
    }


    registerUser() {

        this.regDetails.userRole = this.registerAs;
        this.regDetails.userName = this.regDetails.firstName;
        this.regDetails.birthDate = this.birthDate;
        this.loading = true;
        this.regDetails.businessCategoryId = Number(this.businessCategory);

        if (this.userSeller && this.regDetails.businessCategoryId == 0) {
            this.notifier.notify('Please select the business category!!', 'error');
            return;
        }
		
		this.profileService.registerUser(this.regDetails).subscribe((response: HttpResponse<any>) => {
            this.loading = false;
            this.router.navigate(['/register', 'success']).then();
        }, (error1) => {
            this.loading = false;

            if (error1.status === 409) {
                this.notifier.notifyError('Account already exists but not yet activated! ' +
                    'Please click Re-send Activation Email button below.');
            } else if (error1.status === 417) {
                this.notifier.notifyError('User with the same credentials already exist');
            } else {
                this.notifier.notifyError('Registration failed. Please try again later!');
            }

        });
    }

}
