<div
  class="form-panel col-12 business-details-form">
  <form class="form-horizontal style-form" name="hoursForm"
        #hoursForm="ngForm">

    <div class="modal-header">
      <h2 class="form-heading">Business  Hours</h2>
    </div>
    
	<div class="form-row">
	
					<div class="form-group col-md-2 d-none d-md-block">
						<label>&nbsp;</label>
						 <label>&nbsp;</label>
  
					</div>
					<div class="form-group col-md-4 d-none d-md-block" >
						<label class="control-label  ">Opening Time</label>
						 <label>&nbsp;</label>
					</div>
					<div class="form-group col-md-4 d-none d-md-block" >
						<label class="control-label  ">Closing Time</label> 
						<label>&nbsp;</label>
					</div>

			 

			</div>

	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="mondayOpenForBusiness"
		               	[(ngModel)]="mondayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Monday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="mondayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="mondayOpeningTimeInput" required
                 name="mondayOpeningTimeInput" [(ngModel)]="mondayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="mondayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="mondayClosingTimeInput" [(ngModel)]="mondayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!mondayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>

     	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="tuesdayOpenForBusiness"
		               	[(ngModel)]="tuesdayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Tuesday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="tuesdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="tuesdayOpeningTimeInput" required
                 name="tuesdayOpeningTimeInput" [(ngModel)]="tuesdayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="tuesdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="tuesdayClosingTimeInput" [(ngModel)]="tuesdayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!tuesdayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>

     	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="wednesdayOpenForBusiness"
		               	[(ngModel)]="wednesdayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Wednesday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="wednesdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="wednesdayOpeningTimeInput" required
                 name="wednesdayOpeningTimeInput" [(ngModel)]="wednesdayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="wednesdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="wednesdayClosingTimeInput" [(ngModel)]="wednesdayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!wednesdayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>
	
     	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="thursdayOpenForBusiness"
		               	[(ngModel)]="thursdayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Thursday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="thursdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="thursdayOpeningTimeInput" required
                 name="thursdayOpeningTimeInput" [(ngModel)]="thursdayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="thursdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="thursdayClosingTimeInput" [(ngModel)]="thursdayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!thursdayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>
	
      	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="fridayOpenForBusiness"
		               	[(ngModel)]="fridayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Friday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="fridayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="fridayOpeningTimeInput" required
                 name="fridayOpeningTimeInput" [(ngModel)]="fridayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="fridayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="fridayClosingTimeInput" [(ngModel)]="fridayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!fridayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>
       
        	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="saturdayOpenForBusiness"
		               	[(ngModel)]="saturdayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Saturday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="saturdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="saturdayOpeningTimeInput" required
                 name="saturdayOpeningTimeInput" [(ngModel)]="saturdayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="saturdayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="saturdayClosingTimeInput" [(ngModel)]="saturdayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!saturdayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>
	<div class="form-row">
	
					<div class="form-group col-md-2">
						<input type="checkbox" class="col-1" name="sundayOpenForBusiness"
		               	[(ngModel)]="sundayOpenForBusiness"
					        /> <label
					        class="col-3 text-center">Sunday</label>
  
					</div>
					<div class="form-group col-md-4" *ngIf="sundayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Opening Time</label>
						 <input type="time" class="form-control" placeholder="HH:mm:ss" id="sundayOpeningTimeInput" required
                 name="sundayOpeningTimeInput" [(ngModel)]="sundayOpeningTime">
					</div>
					<div class="form-group col-md-4" *ngIf="sundayOpenForBusiness">
						<label class="control-label d-md-none d-lg-none">Closing Time</label> 
						 <input type="time" class="form-control" placeholder="HH:mm:ss"
                 name="sundayClosingTimeInput" [(ngModel)]="sundayClosingTime">
					</div>

			  <div class="form-group col-md-8" *ngIf="!sundayOpenForBusiness">
          <span style="color: red" class="text-center">CLOSED</span>
        </div>
			

	</div>

  
    <div class="modal-footer">
      <button type="button" (click)="updateHours(selectedBusiness.id)"
              [disabled]="!hoursForm.form.valid" class="btn btn-success">Update
      </button>

    </div>
  </form>
</div>
