export class OrderCartItem {

    p_id: string;
    p_name: string;
    p_price: number;
    p_currency: string;
    p_quantity: number;
    clothingProductId: number;
    clothingProductSizeId: number;
    productColour: string;
    productSize: string;

}
