import { Component, OnInit } from '@angular/core';
import {UserAddress} from '../../model/UserAddress';
import {UserDetails} from '../../model/UserDetails';
import {Router} from '@angular/router';

@Component({
  selector: 'myafrica-shopper-addresses',
  templateUrl: './shopper-addresses.component.html',
  styleUrls: ['./shopper-addresses.component.scss']
})
export class ShopperAddressesComponent implements OnInit {


   userAddresses: UserAddress[];
   loading: boolean;
   loggedInUser: any;

  constructor(private router: Router) { }

  ngOnInit(): void {

	 this.loggedInUser = JSON.parse(sessionStorage.getItem('userDetails'));

	 this.userAddresses = this.loggedInUser.userAddresses;
  }

  addAddress() {

	  sessionStorage.setItem('selectedAddress', '');

	  this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: ['profile-address']}}]).then();

  }

  editAddress(address: UserAddress) {
      sessionStorage.setItem('selectedAddress', JSON.stringify(address));
	     this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: ['profile-address']}}]).then();

  }

}
