<div class="row modal-header">
    <h4 class="form-heading">Product Colours</h4>
</div>

<div class="panel-body p-2">
    <div class="table-responsive d-none d-md-block">
        <table class="table table-bordered table-hover table-striped">
            <thead class="thead-dark">
            <tr>
                <th class="dashboard-table-td" style="width: 50%">Colour</th>
                <th class="dashboard-table-td" style="width: 30%"> Product</th>
                <th class="dashboard-table-td" style="width: 20%"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let myColor of colours">
                <td class="dashboard-table-td multicolourdiv" *ngIf="myColor.colourName == '#999999A'"></td>
                <td class="dashboard-table-td" *ngIf="myColor.colourName != '#999999A'"
                    [ngStyle]="{'background-color': myColor.colourName}"></td>
                <td class="dashboard-table-td ">
                    <img style=" height: 60px" class="img-fluid mx-auto" src="{{myColor.defaultImageUrl}}">
                </td>
                <td>
                    <button class="btn btn-success" (click)="editColor(editColorModal, myColor)">Update Sizes</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!-- MOBILE DEVICES -->
    <div class="container d-md-none d-lg-none mb-1"
         *ngFor="let myColor of colours">
        <div class="row">
            <div class="col-4 bg-primary text-white">Colour</div>

            <div class="multicolourdiv" *ngIf="myColor.colourName == '#999999A'">


            </div>
            <div class="col-8" [ngStyle]="{'background-color': myColor.colourName}"
                 style="min-height: 30px;margin-bottom: 10px;" *ngIf="myColor.colourName != '#999999A'">
                <div class="col-xs-3"></div>
                <div class="col-xs-6"></div>
                <div class="col-xs-3"></div>
            </div>

        </div>
        <div class="row">
            <div class="col-4 bg-primary text-white">Product</div>
            <div class="col-8">
                <img class="img-fluid mx-auto selected-product w-80" style="max-height: 150px"
                     src="{{myColor.defaultImageUrl}}">

            </div>
        </div>
        <div class="row">
            <div class="col-4 bg-primary text-white"></div>
            <div class="col-8">
                <button class="btn btn-success" (click)="editColor(editColorModal, myColor)">Update Sizes</button>

            </div>
        </div>
    </div>


</div><!-- Panel -->


<ng-template #editColorModal let-modal>
    <myafrica-page-title pageTitle="Colour Sizes"></myafrica-page-title>

    <div class="container col-12 form-inline modal-body">

        <div class="container mt-1 col-sm-12 col-lg-6">


            <div class="input-group mb-1">
                <label class="col-5 control-label">Color</label>

                <span *ngIf="myColor.colourName == '#999999A'"
                      class="badge mr-1 supported-colours multicolourdiv"
                >&nbsp;</span>

                <span class="badge mr-1 supported-colours" *ngIf="myColor.colourName != '#999999A'"
                      [ngStyle]="{'background-color': myColor.colourName}">&nbsp;
								</span>

            </div>
            <br/>

            <div class="row">

                <div class="input-group mb-3" *ngFor="let elem of sizesElements">
                    <div class="input-group-text">Size</div>
                    <select class="form-control" name="name" [(ngModel)]="elem.name">
                        <option [value]="item.clothingSize"
                                *ngFor="let item of clothingSizeCodes">{{item.clothingSize}}</option>
                    </select> <input type="number" class="form-control" name="size"
                                     [(ngModel)]="elem.count" placeholder="count">

                    <div class="input-group-text">Items</div>
                </div>
            </div>


            <div class="input-group mb-3">
                <button class="btn btn-success" (click)="adMoreElement()">Add More Sizes</button>
            </div>

        </div>
        <div class="container mt-1 col-sm-12 col-lg-6 text-center">

            <div class="container-fluid text-center">
                <div class="input-group-text" *ngIf="myColor.imageUrl; else noImage">
                    <td class="dashboard-table-td">
                        <img class="img-fluid mx-auto" style="max-width: 200px" src="{{myColor.imageUrl}}"></td>

                </div>
            </div>
            <div class="container">
            </div>
        </div>
    </div>


    <div class="container">
        <div class="row">
            <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2 mb-2">
            </div>
            <div class="col-xs-5 col-sm-5 col-md-4 col-lg-4 mb-2">
                <button class="btn btn-success" (click)="submit()">Update Sizes</button>


            </div>
            <div class="col-xs-5 col-sm-5 col-md-4 col-lg-4">
                <button data-dismiss="modal" (click)="modal.close('')"
                        class="btn btn-danger">Close
                </button>
            </div>
            <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2">
            </div>

        </div>
    </div>

</ng-template>

<ng-template #displayRadioButtonsDetails let-modal>
    <div class="container mt-1">
        <label class="search-label"> Multi Colour? = The field is to indicate whether the product is multi coloured or
            not</label>
        <label class="search-label"> Color = The field is a colour picker to select the colour of the product. If the
            multi coloured checkbox is selected this field will be hidden</label>
        <label class="search-label"> Add More Sizes = Button to add more sizes for the product</label>
        <label class="search-label"> Size = The size of the product, this can be a number or a string. As an example the
            size of a shirt can be a 41 or M for medium based on the size type used.</label>
        <br/>
        <br/>
        <button type="button" data-dismiss="modal" (click)="modal.close('')"
                class="btn btn-danger">Close
        </button>
        <br/>

    </div>
</ng-template>

<ng-template #noImage>
    <div class="input-group-text text-center m-auto">Image display area</div>
</ng-template>
