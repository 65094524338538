import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Product} from '../model/Product';
import {Business} from '../model/Business';
import {ClothingColour} from '../model/ClothingColour';


@Injectable({
    providedIn: 'root',
})
export class ProductService {

    constructor(private http: HttpClient) {
    }

    public getHomeProducts(): Observable<Product> | Observable<any> {
        return this.http.get(`myafrica-api/products/status?deleted=false&page=0&limit=6`, {observe: 'response'});
    }


    public getAllProducts() {
        return this.http.get(`myafrica-api/public/allproducts`);
    }

    public getProductBusiness(businessId: number): Observable<Business | any> {
        return this.http.get(`myafrica-api/views/${businessId}/viewbusiness`);
    }

    // get a list of products matching a partial or full product name for pagination
    public getPaginationProductsByName(productName: string,
                                       itemsPerPage: number,
                                       pagenumber: number,
                                       categoryId: string,
                                       orderBy: string) {
        return this.http.get(`myafrica-api/products/${productName}`
            .concat(`/${itemsPerPage}/${pagenumber}/${categoryId}/${orderBy}/getpaginationproductsbyname`));

    }

    // get a list of all clothing products matching a partial or full product name for pagination
    public getPaginationClothingProductsByName(productName: string,
                                               itemsPerPage: number,
                                               pagenumber: number,
                                               productCategory: string,
                                               orderBy: string,
                                               clothingSize: string) {
		if(orderBy === null || orderBy === undefined || orderBy.trim().length === 0 || orderBy === "" || orderBy === "  ") {
			orderBy = "all";
		}
	    return this.http.get(`myafrica-api/product/allproducts/${productName}`
            .concat(`/${itemsPerPage}/${pagenumber}/${productCategory}/`)
            .concat(`${orderBy}/${clothingSize}/getpaginationclothingproductsbyname`));

    }


    public getAllProductOfCat(cat: string): Observable<Array<Product> | any> {
        return this.http.get(`myafrica-api/product/allproducts/${cat}/getallcatproducts`);
    }

    // returns a list of the clothing product linked to a product
    public getClothingProducts(productId: number) {
        return this.http.get(`myafrica-api/product/clothingproduct/${productId}`);
    }


    // update the products number of views
    public updateProductViewCount(productId: string) {
	    return this.http.post(`myafrica-api/products/${productId}/viewproduct`, {observe: 'response'});
    }


    // get the product details
    public getAppProduct(productId: string): Observable<HttpResponse<Product | any>> {
        return this.http.get(`myafrica-api/products/product/${productId}`, {observe: 'response'});
    }

    public getProductClothingColours(productId: string): Observable<HttpResponse<Array<ClothingColour> | any>> {
        return this.http.get(`myafrica-api/products/${productId}/get/colours`, {observe: 'response'});
    }

    // Return the share det
    public executeShare(sharingLink: string): Observable<Product> | any {
        return this.http.post(`myafrica-api/share/${sharingLink}`, {observe: 'response'});
    }

}
