
<myafrica-page-title pageTitle="{{pageTitle}}"></myafrica-page-title>


<div class="container">
	<div class="row shadow">
		<div class="container">

			<!-- Control the column width, and how they should appear on different devices -->


			<div class="row">
				<div class="col-md-6">

					<label for="orderNo" title="Order Number">Delivery No:</label> <input
						type="text" class="form-control" [(ngModel)]="deliveryNo"
						id="orderNo" />
				</div>

				<div class="col-md-6">
					<label for="orderNo" title="Order Number">Order No:</label> <input
						type="text" class="form-control" [(ngModel)]="orderNo"
						id="orderNo" />
				</div>
			</div>
			<div class="row">

				<div class="col-md-6">
					<label for="startDate"
						title="Filters the deliveries according to the date ordered e.g. Last 15 days=Deliveries  created in the last 15 days">From:</label>
					<input type="date" id="startDate"
						[ngModel]="startDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="startDate = $event">`
				</div>
				<div class="col-md-6">

					<label for="endDate">To:</label> <input type="date" id="endDate"
						[ngModel]="endDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="endDate = $event">
				</div>

			</div>

			<div class="row">
				<div class="form-group col-md-6">
					<button type="button" class="btn btn-success w-100" id="addButton">
						<i class="fa fa-search " aria-hidden="true"></i>&nbsp;&nbsp;{{
						buttonLabel }}
					</button>

				</div>

				<div class="form-group col-md-6">
					<button type="button" class="btn btn-success w-100"
						id="search1Button">
						<i class="fa fa-plus " aria-hidden="true"></i>&nbsp;&nbsp;Create
						Delivery
					</button>

				</div>


			</div>

		</div>
	</div>
</div>
<br />

<div class="container">
	<div class="row">
		<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 pull-right"></div>
		<div
			class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-info text-dark"
			*ngIf="displayMessage">{{message}}</div>
		<div
			class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-danger text-white"
			*ngIf="displayErrorMessage">{{message}}</div>


		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">

			<button type="button" *ngIf="displaySaveButton"
				class="btn btn-success">
				<i class="fa fa-save " aria-hidden="true"></i>&nbsp;&nbsp;Save
			</button>
		</div>


	</div>


</div>

<div class="container d-none d-md-block">
	<table class="table table-bordered table-striped">
		<thead class="thead-dark">
			<tr>
				<th>Delivery No</th>
				<th>Date</th>
				<th>Order No</th>
				<th>Delivery Option</th>
				<th>Status</th>
			</tr>
		</thead>
		<tbody>



		</tbody>

	</table>

</div>


<!-- /.container -->



<ng-template #logoMissing> <img
	src="https://myafricaconnect.com/images/image-not-available.jpg"
	class="shadow rounded" [alt]="" height="80px"> </ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


