<myafrica-breadcrumbs title="Manage Products"></myafrica-breadcrumbs>

<div class="container p-0">


	<!--   START OF SEARCH SECTION -->
	<div class="container">
		<div class="row">
			<div class="input-group col-xs-12 col-sm-12 col-md-9 col-lg-9 mb-1">
				<div ngbDropdown class="input-group-prepend ml-0">
					<button class="input-group-text btn-search" id="dropdownBasic1"
						ngbDropdownToggle>
						{{searchProductCategoryMap?.get(searchProductCategory)}}</button>
					<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
						<ul class="list-unstyled ">
							<li *ngFor="let key of getMapKeys()"><a
								class="dropdown-item"
								(click)="setProductsByCategory(key, searchProductCategoryMap?.get(key))">
									{{searchProductCategoryMap?.get(key)}} </a></li>
						</ul>

					</div>
				</div>
				<input type="text" [(ngModel)]="searchProductName"
					class="form-control search-input border-right-0 border"
					placeholder="What are you looking for..."
					(keyup.enter)="getProductsByName()"> <span
					class="input-group-append">

					<button class="btn text-white text-center  bg-success"
						type="button" style="width: 100%" (click)="getProductsByName()">
						<i class="fa fa-search" aria-hidden="true"></i>
					</button>

				</span>
			</div>


		</div>
	</div>
	<!--   END OF SEARCH SECTION -->

	<div class="container input-group" *ngIf="searchProductName">
		<span><p>
				Searched for Category: <b>{{searchProductCategory}}</b> : Searched
				Product Name: <b>{{searchProductName}}</b>
			</p></span>
	</div>

	<br />
	<div class="container bg-warning rounded-bottom rounded-top shadow p-3">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-4 pt-2 col-lg-4">
				<span *ngIf="totalProducts > 0" class="text-white">
					{{((currentPage) * displayEntryLimitCount) + 1}} -
					{{displayRangeLimit}} of {{totalProducts}} </span> <span
					*ngIf="totalProducts <= 0"> </span>
			</div>
			<div
				class="input-group col-xs-12 col-sm-12 col-md-4 pt-2 col-lg-4 mb-3 input-group-sm">
				<div class="input-group-prepend">
					<span class="input-group-text">Show</span>
				</div>
				<select [(ngModel)]="entryLimitCount"
					class="form-control text-black" name="entryLimitCount"
					(ngModelChange)="setNumberOfItemsPerPage()">

					<option [value]="item.id" *ngFor="let item of itemsPerPageList">{{item.id}}</option>

				</select>
				<div class="input-group-append">
					<span class="input-group-text">per page</span>
				</div>
			</div>
			<div
				class="col-xs-12 col-sm-12 col-md-4 pt-2 col-lg-4 input-group-sm text-white">
				<select [(ngModel)]="searchBusinessId"
					class="form-control text-black" name="searchBusinessId"
					(ngModelChange)="getProductsByNameByOrder()">
					<option [value]="0" selected>Filter by Business</option>

					<option [value]="item.id" *ngFor="let item of businesses">{{item.businessName}}</option>

				</select>

			</div>

		</div>

	</div>

	<div class="container-fluid">
		<div class="row mb-2 mt-2">
			<div class="col-12 text-right">

				<button class="btn pull-right btn-primary"
					(click)="open(addProduct)" [disabled]="addProductButtonDisabled">
					<i class="fa fa-plus" aria-hidden="true"></i> {{buttonLabel}}
				</button>
			</div>
		</div>
	</div>

	<div class="container-fluid">
		<div class="container mb-2 p-0">
			<div class="row bg-light rounded-bottom rounded-top shadow h-20 my-2">
				<div class="col-6 mt-2">
					<span class="badge badge-success text-white"
						*ngIf="displayRangeLimit - displayEntryLimitCount <= 0"><a
						class="disabled text-white h6">&larr; Previous</a></span> <span
						class="badge badge-success text-white"
						*ngIf="displayRangeLimit - displayEntryLimitCount > 0"><a
						(click)="previous()"><span class="text-white h6">&larr;
								Previous</span></a></span>
				</div>

				<div class="col-6  mt-2 text-right">
					<span class="badge badge-success text-white"
						*ngIf="displayRangeLimit < totalProducts"><a
						(click)="next()"><span class="paginationanchor text-white h6">Next
								&rarr;</span></a></span> <span class="badge badge-success text-white"
						*ngIf="displayRangeLimit >= totalProducts"><a
						class="disabled text-white h6">Next &rarr;</a></span>

				</div>
			</div>
		</div>
	</div>

	<br />

	<div *ngIf="totalProducts <= 0" class="error-message-bold">No
		products were found matching criteria!!!</div>

	<div class="container-fluid" *ngIf="searchedProducts.length > 0">

		<div class="row">
			<div class="col-md-4 d-flex" *ngFor="let product of searchedProducts">
				<div class="card mb-4 shadow-sm">
					<div style="max-height: 201px;" class="text-center pt-1">
						<img class="img-fluid mx-auto" style="max-height: 201px;"
							[src]="product.defaultImageUrl" [alt]="product.productName">
					</div>
					<div class="card-body text-center">
						<p>

							<small><span class="text-muted"
								data-title="{{product.productName | titlecase }}">{{product.productName
									| truncate : 40 | titlecase }}</span></small>

						</p>
					</div>
					<div class="card-footer styled-card-footer p-1">
						<button class="btn btn-success w-100 mb-1"
							(click)="editProduct(product)">Edit Product</button>
						<button class="btn btn-primary w-100"
							(click)="addProductHistory(product)">Add Product History
						</button>

					</div>
				</div>
			</div>
		</div>
	</div>

</div>

<div class="container-fluid">
	<div class="container mb-2 p-0">
		<div class="row bg-light rounded-bottom rounded-top shadow h-20 my-2">
			<div class="col-6 mt-2">
				<span class="badge badge-success text-white"
					*ngIf="displayRangeLimit - displayEntryLimitCount <= 0"><a
					class="disabled text-white h6">&larr; Previous</a></span> <span
					class="badge badge-success text-white"
					*ngIf="displayRangeLimit - displayEntryLimitCount > 0"><a
					(click)="previous()"><span class="text-white h6">&larr;
							Previous</span></a></span>
			</div>

			<div class="col-6  mt-2 text-right">
				<span class="badge badge-success text-white"
					*ngIf="displayRangeLimit < totalProducts"><a
					(click)="next()"><span class="paginationanchor text-white h6">Next
							&rarr;</span></a></span> <span class="badge badge-success text-white"
					*ngIf="displayRangeLimit >= totalProducts"><a
					class="disabled text-white h6">Next &rarr;</a></span>

			</div>
		</div>
	</div>
</div>

<ng-template #addProduct let-modal>
<div class="container p7 mt-1">
	<ngb-tabset [justify]="currentJustify" type="pills" #tabs>
	<ngb-tab id="productDetail" title="Product Detail"> <ng-template
		ngbTabContent> <br />
	<div class="row modal-header">
		<h2 class="form-heading">Product Details</h2>
	</div>
	<div class="form-row">
		<div class="form-group col-md-6">
			<label class="control-label">Category</label> <select
				[(ngModel)]="productCategory" class="form-control"
				name="productCategory" required
				(change)="displayGendersDropdown(productCategory)">
				<option *ngFor="let cat of items" value="{{cat.key}}">
					{{cat.value | titlecase}}</option>
			</select>

		</div>
		<div class="form-group col-md-6">
			<label class="control-label">Business</label> <select
				class="form-control" name="businessId" id="businessId"
				[(ngModel)]="businessId" required>
				<option *ngFor="let business of businesses"
					ng-selected="business.businessId" value="{{business.businessId}}">
					{{business.businessName}}</option>
			</select>
		</div>


	</div>

	<div class="form-row" *ngIf="displayGenders">
		<div class="form-group col-md-4">
			<label class="control-label">Gender</label> <select
				[(ngModel)]="genderId" class="form-control" name="genderId" required
				(change)="displayGroupsDropDown(genderId)">
				<option *ngFor="let gender of clothingGenders" value="{{gender.id}}">
					{{gender.genderDescription | titlecase}}</option>
			</select>
		</div>
		<div class="form-group col-md-4" *ngIf="displayGroups">
			<label class="control-label">Group</label> <select
				[(ngModel)]="groupId" class="form-control" name="groupId" required
				(change)="displaySubCategoryDropDown(groupId)">
				<option *ngFor="let group of clothingGroups" value="{{group.id}}">
					{{group.groupDescription | titlecase}}</option>
			</select>
		</div>
		<div class="form-group col-md-4" *ngIf="displaySubCategory">
			<label class="control-label">Sub Category</label> <select
				[(ngModel)]="subCategoryId" class="form-control"
				name="subCategoryId" required>
				<option *ngFor="let category of clothingCategories"
					value="{{category.id}}">{{category.category}}</option>
			</select>
		</div>

	</div>

	<div class="form-row">
		<div class="form-group col-md-6">
			<label class="control-label">Product Name</label> <input type="text"
				class="form-control" [(ngModel)]="productName" required>
		</div>
		<div class="form-group col-md-6">
			<label class="control-label"># Items in Stock</label> <input
				type="number" class="form-control" min="1" numbersOnly
				[maxlength]="15" [(ngModel)]="unitsInStock">
		</div>

	</div>


	<div class="form-row">
		<div class="form-group col-md-4">
			<label class="control-label">Currency</label> <select
				[(ngModel)]="currency" class="form-control">
				<option value="ZAR" selected>ZAR</option>
				<!--                <option value="USD">USD</option>-->
				<!--                <option value="ZMK">ZMK</option>-->
				<!--                <option value="TZS">TZS</option>-->
				<!--                <option value="LSL">LSL</option>-->
			</select>
		</div>
		<div class="form-group col-md-4">
			<label class="control-label">Price</label> <input type="number"
				id="productprice" class="form-control" min="0" [(ngModel)]="price"
				(input)="calculateProductPrice()">
		</div>
		<div class="form-group col-md-4">
			<label class="control-label">Service Fee</label> <label
				class="no-border border-0 form-control " disabled="true"
				style="color: #f15a22; font-weight: bold; font-size: 15px;">{{calculatedServiceFee}}</label>
		</div>

	</div>

	<div class="form-row">
		<div class="form-group col-md-4">
			<label class="control-label">MAC Fee</label> <label
				class="no-border border-0 form-control " disabled="true"
				style="color: #f15a22; font-weight: bold; font-size: 15px;">{{calculatedMarkUpFee}}</label>

		</div>
		<div class="form-group col-md-4">
			<label class="control-label">Eft Fee</label> <label
				class="no-border border-0 form-control " disabled="true"
				style="color: #f15a22; font-weight: bold; font-size: 15px;">{{calculatedEftFee}}</label>
		</div>
		<div class="form-group col-md-4">
			<label class="control-label">Total Price</label> <label
				class="no-border border-0 form-control" disabled="true"
				style="color: #f15a22; font-weight: bold; font-size: 15px;">{{totalProductFee}}</label>

		</div>

	</div>

	<div
		class="row mb-3 justify-content-xs-start justify-content-sm-start justify-content-md-end justify-content-lg-end">
		<button type="button" (click)="modal.close('')"
			class="btn btn-danger mr-3">Cancel</button>

		<button type="button" (click)="tabs.select('moreInfo')"
			class="btn bg-success text-white mr-3">
			Next <i class="fa fa-arrow-right" aria-hidden="true"></i>
		</button>

	</div>


	</ng-template> </ngb-tab> <ngb-tab title="More Info" id="moreInfo"> <ng-template
		ngbTabContent> <br />

	<div class="modal-header">
		<h2 class="form-heading">More Info</h2>
	</div>
	<br />

	 <myafrica-product-dimensions [product]="product"></myafrica-product-dimensions>

	<div class="form-row">
		<div class="form-group col-md-12">
			<label class="col-2  control-label">Product Info</label>
			<textarea rows="5" class="form-control"
				[(ngModel)]="productDescription">
                                </textarea>

		</div>
	</div>


	<div class="row form-group">
		<fieldset
			style="position: relative; margin: 0px; padding: 0px; width: 100%;">
			<div style="text-align: center">
				<div class="display: inline-block">
					<img [src]="url"
						style="min-height: 190px; max-height: 200px; max-width: 300px"
						ngf-max-size="2MB" *ngIf="url" class="img-responsive center-block"
						ngf-thumbnail="!selectedFile.$error"> <input type="file"
						style="display: none" name="selectedFile" accept="image/*"
						(change)="onImageChange($event)" #fileInput>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-success" ng-hide="imageToLoad"
					(click)="fileInput.click()">
					Update Image &nbsp;<i class="fa fa-camera fa-1x"></i>
				</button>
				<!-- REVERT BUTTON -->
				<button class="btn btn-danger" data-toggle="tooltip" *ngIf="url"
					title="Click here to revert the changes and keep the old logo"
					(click)="url = null">
					<i class="fa fa-undo fa-1x"></i>&nbsp;Revert
				</button>
			</div>


		</fieldset>


	</div>


	<div
		class="row mb-3 justify-content-xs-start justify-content-sm-start justify-content-md-end justify-content-lg-end">
		<button type="button" (click)="modal.close('')"
			class="btn btn-danger mr-3">Cancel</button>

		<button type="submit" (click)="addProductWithImage(modal)"
			*ngIf="!displayColourTab" class="btn bg-success text-white mr-3"
			[disabled]="buttonDisabled">
			<i class="fa fa-plus" aria-hidden="true"></i> Add
		</button>


		<button type="submit" (click)="tabs.select('productColours')"
			*ngIf="displayColourTab" class="btn bg-success text-white mr-3"
			[disabled]="buttonDisabled">Next</button>
	</div>

	</ng-template> </ngb-tab> <ngb-tab *ngIf="displayColourTab" title="Product Colour"
		id="productColours"> <ng-template ngbTabContent>
	<div class="container col-12 form-inline modal-body">


		<div class="container mt-1 col-sm-12 col-lg-6">
			<div class="row mb-2">
				<div class="input-group mb-1">
					<input type="checkbox" name="newsletter" class="form-check-input"
						style="height: 20px; width: 20px;"
						[(ngModel)]="multiColourProduct"
						(change)="setMultiColourProduct()"> <label
						class="ml-2 col-sm-12 col-lg-5 control-label multicolourdiv ml-2 text-white"
						style="width: 250px">Multi-Colour?</label> <span
						class="network-name info-icon ml-3" title=""
						(click)="open(displayRadioButtonsDetails)"> <i
						class="fa fa-info-circle" aria-hidden="true"
						style="color: #f15a22;"></i></span>


				</div>
			</div>
			<div class="row mb-2">
				<div class="input-group mb-1" *ngIf="!disableColorPicker">
					<label class="col-2 control-label mr-3">Color</label> <input
						name="color" [disabled]="disableColorPicker" [(ngModel)]="color"
						[(colorPicker)]="color" [style.background]="color" />
				</div>

			</div>
			<div class="row">

				<div class="input-group mb-3" *ngFor="let elem of sizesElements">
					<div class="input-group-text">Size</div>
					<select class="form-control" name="name" [(ngModel)]="elem.name">
						<option [value]="item.clothingSize"
							*ngFor="let item of clothingSizeCodes">{{item.clothingSize}}</option>
					</select> <input type="number" class="form-control" name="size"
						[(ngModel)]="elem.count" placeholder="count">

					<div class="input-group-text">Items</div>
				</div>
			</div>

			<div class="input-group mb-3">
				<button class="btn btn-success" (click)="adMoreElement()">Add
					More Sizes</button>
			</div>

		</div>

	</div>

	<div
		class="row mb-3 justify-content-xs-start justify-content-sm-start justify-content-md-end justify-content-lg-end">
		<button type="button" (click)="modal.close('')"
			class="btn btn-danger mr-3">Cancel</button>

		<button type="submit" (click)="addProductWithImage(modal)"
			*ngIf="displayColourTab" class="btn bg-success text-white mr-3"
			[disabled]="buttonDisabled">
			<i class="fa fa-plus" aria-hidden="true"></i> Add
		</button>
	</div>
	</ng-template> </ngb-tab> </ngb-tabset>
</div>
</ng-template>
<ng-template #displayRadioButtonsDetails let-modal>
<div class="container p7 mt-1">
	<label class="search-label"> Multi Colour? = The field is to
		indicate whether the product is multi coloured or not</label> <label
		class="search-label"> Color = The field is a colour picker to
		select the colour of the product. If the multi coloured checkbox is
		selected this field will be hidden</label> <label class="search-label">
		Add More Sizes = Button to add more sizes for the product</label> <label
		class="search-label"> Size = The size of the product, this can
		be a number or a string. As an example the size of a shirt can be a 41
		or M for medium based on the size type used.</label> <br /> <br />
	<button type="button" data-dismiss="modal" (click)="modal.close('')"
		class="btn btn-danger">Close</button>
	<br />
</div>
</ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>

