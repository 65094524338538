import {HomeComponent} from './home/home.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ShoppingComponent} from './shopping/shopping.component';
import {ClothingComponent} from './shopping/clothing/clothing.component';
import {BusinessDetailsComponent} from './business-details/business-details.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {AllProductsComponent} from './all-products/all-products.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {DeliveryOptionsComponent} from './delivery-options/delivery-options.component';
import {TermsComponent} from './terms/terms.component';
import {AllBusinessesComponent} from './all-businesses/all-businesses.component';
import {FaqComponent} from './faq/faq.component';
import {OrderTrackerComponent} from './order-tracker/order-tracker.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NewsletterSignupComponent} from './newsletter-signup/newsletter-signup.component';
import {RegSuccessComponent} from './register/reg-success/reg-success.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {AuthGuard} from './auth.guard';
import {DashboardHomeComponent} from './dashboard/dashboard-home/dashboard-home.component';
import {ProfileComponent} from './dashboard/profile/profile.component';
import {VendorAgreementComponent} from './dashboard/vendor-agreement/vendor-agreement.component';
import {ReturnsComponent} from './dashboard/returns/returns.component';
import {OrdersComponent} from './dashboard/orders/orders.component';
import {ProductsComponent} from './dashboard/products/products.component';
import {BusinessesComponent} from './dashboard/businesses/businesses.component';
import {AddCustomerReturnComponent} from './dashboard/returns/add-customer-return/add-customer-return.component';
import {EditCustomerReturnComponent} from './dashboard/returns/edit-customer-return/edit-customer-return.component';
import {EditBusinessComponent} from './dashboard/businesses/edit-business/edit-business.component';
import {ProductHistoryComponent} from './dashboard/product-history/product-history.component';
import {EditProductComponent} from './dashboard/edit-product/edit-product.component';
import {AdminManageReturnsComponent} from './dashboard/admin-manage-returns/admin-manage-returns.component';
import {AdminManageOrdersComponent} from './dashboard/admin-manage-orders/admin-manage-orders.component';
import {AdminClerkDashboardComponent} from './dashboard/admin-clerk-dashboard/admin-clerk-dashboard.component';
import {RegisterOptionComponent} from './register/register-option/register-option.component';
import {UserRegistrationComponent} from './register/user-registration/user-registration.component';
import {ShopperDashboardComponent} from './shopper-dashboard/shopper-dashboard.component';
import {ShopperOrdersComponent} from './shopper-dashboard/shopper-orders/shopper-orders.component';
import {ActivationSuccessComponent} from './register/activation-success/activation-success.component';
import {ActivationFailedComponent} from './register/activation-failed/activation-failed.component';
import {ActivateAccountComponent} from './register/activate-account/activate-account.component';
import {ShopperAgreementComponent} from './shopper-dashboard/shopper-agreement/shopper-agreement.component';
import {ProfileAddressComponent} from './dashboard/profile-address/profile-address.component';
import {ShopperAddressesComponent} from './shopper-dashboard/shopper-addresses/shopper-addresses.component';
import {PlaceOrderSuccessComponent} from './shopping-cart/place-order-success/place-order-success.component';
import {EftPlaceOrderCancelledComponent} from './shopping-cart/eft-place-order-cancelled/eft-place-order-cancelled.component';
import {EftPlaceOrderSuccessComponent} from './shopping-cart/eft-place-order-success/eft-place-order-success.component';
import {AllClothingProductsComponent} from './shopping/clothing/all-clothing-products/all-clothing-products.component';
import {AdminReportsComponent} from './dashboard/admin-reports/admin-reports.component';
import {ShopperInvoicesComponent} from './shopper-dashboard/shopper-invoices/shopper-invoices.component';
import {ChangePasswordComponent} from './dashboard/change-password/change-password.component';
import {BusinessDetailsResolver} from './services/resolvers/business-details.resolver';
import {ClothingProductResolver} from './services/resolvers/clothing-product.resolver';
import {ClothingResolver} from './services/resolvers/clothing.resolver';
import {CanLoadGuard} from './CanLoadGuard';
import {DashboardResolver} from './services/resolvers/dashboard.resolver';
import {ShopperManageReturnsComponent} from './shopper-dashboard/shopper-manage-returns/shopper-manage-returns.component';
import {ClothingProductsComponent} from './shopping/clothing/clothing-products/clothing-products.component';
import {SellerFeedbackComponent} from './dashboard/seller-feedback/seller-feedback.component';
import {TransactionsComponent} from './dashboard/transactions/transactions.component';
import { EditCustomerOrderComponent } from './dashboard/edit-customer-order/edit-customer-order.component';
import {AddProductComponent} from './dashboard/add-product/add-product.component';


export const routerConfig = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'about-us',
        component: AboutUsComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    }, {
        path: 'change/password',
        component: ChangePasswordComponent,
    },
    {
        path: 'register',

        children: [
            {
                path: '',
                component: RegisterComponent,
            },
            {
                path: 'success',
                component: RegSuccessComponent,
            },
        ],
    }, {
        path: 'accounts/activate/:activator',
        component: ActivateAccountComponent,
    }, {
        path: 'activation/success',
        component: ActivationSuccessComponent,
    }, {
        path: 'activation/failed',
        component: ActivationFailedComponent,
    },
    {
        path: 'register-option',
        component: RegisterOptionComponent,
    },
    {
        path: 'user-registration',
        component: UserRegistrationComponent,
    }, {
        path: 'business-details',
        component: BusinessDetailsComponent,
        resolve: {
            selectedBusiness: BusinessDetailsResolver,
        },
    }, {
        path: 'all-products',
        component: AllProductsComponent,
    }, {
        path: 'all-businesses',
        component: AllBusinessesComponent,
    }, {
        path: 'contact-us',
        component: ContactUsComponent,
    }, {
        path: 'terms',
        component: TermsComponent,
    }, {
        path: 'faq',
        component: FaqComponent,
    }, {
        path: 'track-order',
        component: OrderTrackerComponent,
    }, {
        path: 'reset-password',
        component: ResetPasswordComponent,
    }, {
        path: 'forgot-password',
        component: ForgotPasswordComponent,

    }, {
        path: 'newsletter-signup',
        component: NewsletterSignupComponent,
    }, {
        path: 'search-result',
        component: SearchResultsComponent,
    }, {
        path: 'vendor/agreement',
        component: VendorAgreementComponent,
    }, {
        path: 'shopper/agreement',
        component: ShopperAgreementComponent,
    }, {
        path: 'shopping',
        children: [
            {
                path: '',
                component: ShoppingComponent,
            }, {
                path: 'clothing',
                children: [
                    {
                        path: '',
                        component: ClothingComponent,
                        resolve: {
                            allCalls: ClothingProductResolver,
                        },
                    },
                    {
                        path: 'products',
                        component: ClothingProductsComponent,

                    },
                ],
            }, {
                path: 'art',
                component: ShoppingComponent,
            }, {
                path: 'music',
                component: ShoppingComponent,
            }, {
                path: 'food',
                component: ShoppingComponent,
            }, {
                path: 'view-cart',
                component: ShoppingCartComponent,
            }, {
                path: 'view-delivery-options',
                component: DeliveryOptionsComponent,
            },
        ],

    },
    {
        path: 'shopperdashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: ShopperDashboardComponent,
        canLoad: [CanLoadGuard],
        children: [
            {
                path: '',
                component: ShopperOrdersComponent,
                outlet: 'shopperdashboard',
            },
            {
                path: 'profile',
                component: ProfileComponent,
                outlet: 'shopperdashboard',
            }, {
                path: 'shopper-addresses',
                component: ShopperAddressesComponent,
                outlet: 'shopperdashboard',
            }, {
                path: 'profile-address',
                component: ProfileAddressComponent,
                outlet: 'shopperdashboard',
            }, {
                path: 'shopper-invoices',
                component: ShopperInvoicesComponent,
                outlet: 'shopperdashboard',
            }, {
                path: 'manage-returns',
                component: ShopperManageReturnsComponent,
                outlet: 'shopperdashboard',
            }, {
                path: 'add-shopper-return',
                component: AddCustomerReturnComponent,
                outlet: 'shopperdashboard',
            }, {
                path: 'edit-shopper-return',
                component: EditCustomerReturnComponent,
                outlet: 'shopperdashboard',
            },
        ],
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: DashboardComponent,
        canLoad: [CanLoadGuard],
        resolve: {
            loginResponse: DashboardResolver,
        },
        children: [
            {
                path: '',
                component: DashboardHomeComponent,
                outlet: 'dashboard',
            },
            {
                path: 'profile',
                component: ProfileComponent,
                outlet: 'dashboard',
            },
            {
                path: 'profile-address',
                component: ProfileAddressComponent,
                outlet: 'dashboard',
            }, {
                path: 'manage-businesses',
                component: BusinessesComponent,
                outlet: 'dashboard',
            }, {
                path: 'edit-business',
                component: EditBusinessComponent,
                outlet: 'dashboard',
            }, {
                path: 'manage-products',
                component: ProductsComponent,
                outlet: 'dashboard',
            }, {
                path: 'manage-orders',
                component: OrdersComponent,
                outlet: 'dashboard',
            }, {
                path: 'manage-returns',
                component: ReturnsComponent,
                outlet: 'dashboard',
            }, {
                path: 'add-return',
                component: AddCustomerReturnComponent,
                outlet: 'dashboard',
            }, {
                path: 'edit-return',
                component: EditCustomerReturnComponent,
                outlet: 'dashboard',
            }, {
                path: 'reset-password',
                component: ResetPasswordComponent,
                outlet: 'dashboard',
            }, {
                path: 'product-history',
                component: ProductHistoryComponent,
                outlet: 'dashboard',
            }, {
                path: 'edit-product',
                component: EditProductComponent,
                outlet: 'dashboard',
            }, {
                path: 'add-product',
                component: AddProductComponent,
                outlet: 'dashboard',
            }, {
                path: 'vendor-agreement',
                component: VendorAgreementComponent,
                outlet: 'dashboard',
            }, {
                path: 'transactions',
                component: TransactionsComponent,
                outlet: 'dashboard',
            }, {
                path: 'seller-feedback',
                component: SellerFeedbackComponent,
                outlet: 'dashboard',
            },
        ],
    },
    {
        path: 'adminclerkdashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        canLoad: [CanLoadGuard],
        component: AdminClerkDashboardComponent,
        children: [
            {
                path: '',
                component: AdminManageOrdersComponent,
                outlet: 'adminclerkdashboard',
            },
            {
                path: 'profile',
                component: ProfileComponent,
                outlet: 'adminclerkdashboard',
            },
            {
                path: 'admin-manage-orders',
                component: AdminManageOrdersComponent,
                outlet: 'adminclerkdashboard',
            }, {
                path: 'manage-returns',
                component: AdminManageReturnsComponent,
                outlet: 'adminclerkdashboard',
            }, {
                path: 'reset-password',
                component: ResetPasswordComponent,
                outlet: 'adminclerkdashboard',
            }, {
                path: 'add-return',
                component: AddCustomerReturnComponent,
                outlet: 'adminclerkdashboard',
            }, {
                path: 'edit-return',
                component: EditCustomerReturnComponent,
                outlet: 'adminclerkdashboard',
            }, {
                path: 'edit-order',
                component: EditCustomerOrderComponent,
                outlet: 'adminclerkdashboard',
            }, {
                path: 'reports',
                component: AdminReportsComponent,
                outlet: 'adminclerkdashboard',
            },
        ],
    },
    {
        path: 'view/order-success',
        component: PlaceOrderSuccessComponent,
    }, {
        path: 'shopping/payment/success',
        component: EftPlaceOrderSuccessComponent,
    }, {
        path: 'shopping/payment/cancelled',
        component: EftPlaceOrderCancelledComponent,
    }, {
        path: 'add-return',
        component: AddCustomerReturnComponent,
        outlet: 'dashboard',
    },
    {
        path: '**',
        component: NotFoundComponent,
    }

];
