
<div class="alert alert-secondary bg-light-purple
show text-white m-0 rounded-0 border-0 text-center" role="alert">
    <p class="d-none d-md-block text-white mb-1">
        Our site is best viewed using Google Chrome. To install for FREE
        please click <a href="https://www.google.com/chrome/"
                        target="_blank" style="color: white;">here</a>.
    </p>
 
    <h3 class="text-white text-center">Connecting the world to authentic African products and brands.</h3>
</div>

<div id="carouselExampleControls" class="carousel slide m-0" data-ride="carousel">
    <div class="carousel-inner">
        <div *ngFor="let carousel of activeCarousels;" [ngClass]="{'active':carousel.active}" class="carousel-item">
            <img class="d-block w-100" [src]="carousel.imageUrl" alt="{{carousel.heading}}">
        </div>
    </div>
</div>


<!-- Page Content -->
<div class="container mb-2 move-up">
    <nav id="navWrap">
        <myafrica-dynamic-mega-menu>></myafrica-dynamic-mega-menu>>
    </nav>
    <!-- Marketing Icons Section -->
    <div class="container my-4 py-2">
	    <hr class="bg-success shadow">
	    <div class="row justify-content-center ">
	        <h1 class="text-center  text-white px-1 position-sticky">
	         &nbsp;Recent Products
	        </h1>
	    </div>
	    <hr class="bg-warning shadow">
	</div>
    <div class="container mb-3">
        <div class="row">
            <div class="col-sm-12 col-md-3 px-1 mx-0" *ngFor="let product of products">
                <myafrica-product-item [product]="product"></myafrica-product-item>
            </div>
        </div>
    </div>

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-3">
                    <button class="btn btn-warning btn-block"  (click)="allProducts()">View
                        More Products &nbsp;<i class="fa fa-arrow-right text-white"></i>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <br/>

    <myafrica-advert-slides></myafrica-advert-slides>


    <myafrica-page-title pageTitle="Listed Businesses"></myafrica-page-title>
    <div class="container mb-3">
        <div class="row">
            <div class="col-sm-12 col-md-4" *ngFor="let business of businesses">
                <myafrica-business-item [business]="business"></myafrica-business-item>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-3">
                <button class="btn btn-warning btn-block" routerLink="/all-businesses">View
                    More Businesses &nbsp;<i class="fa fa-arrow-right text-white"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
