import {Component, OnInit} from '@angular/core';
import {faCertificate} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'myafrica-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    displaySuccessMessage: boolean;
    displayErrorMessage: boolean;
    emailDefault = 'myemail@email.co.za';
    displayMessage = '';
    email: string;

    faceBookIcon = faCertificate;

    constructor() {
    }

    ngOnInit() {
    }

    subscribeToNewsletter() {

    }
}
