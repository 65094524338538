import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContactUsMessage} from '../model/ContactUsMessage';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private http: HttpClient) {
    }

    deleteMessage(messageId: number): Observable<ContactUsMessage> | Observable<any> {
        return this.http.delete(`/profileadmin/${messageId}/deletemessage`, {
            observe: 'response',
        });
    }

    sendMessage(message: ContactUsMessage): Observable<ContactUsMessage> | Observable<any> {
        return this.http.post(`myafrica-api/public/all/sendmessage`, message, {
            observe: 'response',
        });
    }

    sendNewsLetterSignup(message: ContactUsMessage): Observable<ContactUsMessage> | Observable<any> {
        return this.http.post(`myafrica-api/public/all/newsletter/signup`, message, {
            observe: 'response',
        });
    }

    sendNewsLetterUnsubscribe(message: ContactUsMessage): Observable<ContactUsMessage> | Observable<any> {
        return this.http.post(`myafrica-api/public/all/newsletter/unsubscribe`, message, {
            observe: 'response',
        });
    }

}
