<div class="container-fluid">
    <div class="row">
        <div class="col-3 min-vh-100  bg-primary  text-white">

  			<aside class="min-vh-100 w-25 position-fixed m-auto py-1">
              <div class="col-sm-12  text-white">
          
            <hr class="bg-danger shadow">
            <span class="col-12 my-2" (click)="navigate('profile')">
                <i  class="fa fa-user fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline cursor-pointer">&nbsp;&nbsp;Profile</span>
            </span>

            <hr class="bg-success shadow">
            <span class="col-12 my-2" [routerLink]="['/adminclerkdashboard', {outlets: {'adminclerkdashboard': ['admin-manage-orders']}}]"
                  skipLocationChange>
                <i  class="fa fa-shopping-cart fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline cursor-pointer">&nbsp;&nbsp;Manage Orders</span>
            </span>
            <hr class="bg-light shadow">
            <span class="col-12 my-2" [routerLink]="['/adminclerkdashboard', {outlets: {'adminclerkdashboard': ['manage-returns']}}]"
                  skipLocationChange>
                <i  class="fa fa-truck-loading fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline cursor-pointer">&nbsp;&nbsp;Manage Returns</span>
            </span>
            <hr class="bg-danger shadow">
            <span class="col-12 my-2" [routerLink]="['/adminclerkdashboard', {outlets: {'adminclerkdashboard': ['reset-password']}}]"
                  skipLocationChange>
                <i  class="fa fa-lock-open fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline cursor-pointer">&nbsp;&nbsp;Reset Password</span>
            </span>
               <hr class="bg-info shadow">
     	       <span class="col-12 my-2" [routerLink]="['/adminclerkdashboard', {outlets: {'adminclerkdashboard': ['reports']}}]"
                  skipLocationChange>
                <i  class="fa fa-file fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline cursor-pointer">&nbsp;&nbsp;Reports</span>
            </span>
            </div>
		</aside>
        </div>
        <div class="col-9 mb-3">
            <router-outlet name="adminclerkdashboard" class="container-fluid"></router-outlet>
        </div>
    </div>
</div>
