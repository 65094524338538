import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RequestHeader} from '../RequestHeader';
import {environment} from '../../../environments/environment';
import {HistoryPayload} from '../../model/HistoryPayload';


@Injectable({
  providedIn: 'root'
})
export class HistoryServiceService extends RequestHeader {

  headers = new HttpHeaders()
    .set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {
    super();
  }

  addHistory(historyPayload: Object) {
    return this.http.post(environment.apiAdminPrefix  + '/products/add/history',
      historyPayload, {
        observe: 'response'
      });
  }

  updateHistory(historyPayload: HistoryPayload) {
    return this.http.post(environment.apiAdminPrefix  + '/products/update/history',
      historyPayload, {
        observe: 'response'
      });
  }

  getHistory(productId: any) {
    return this.http.get(environment.apiPrefix  + '/products/product/history/' + productId,
      {
        observe: 'response'
      });
  }


}
