import {Component, OnInit} from '@angular/core';
import {CartItem} from '../model/CartItem';
import {Product} from '../model/Product';
import {Router} from '@angular/router';
import {ApplicationStoreService} from '../services/application-store.service';
import {CartItemsService} from '../services/cart-items.service';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-add-product-to-cart',
    templateUrl: './add-product-to-cart.component.html',
    styleUrls: ['./add-product-to-cart.component.scss'],
})
export class AddProductToCartComponent implements OnInit {

    displayErrorMessage: string;
    items: CartItem[] = [];

    customerProductQuantity = 0;

    itemTotalCount = 0;

    selectedProduct: Product;

    cartItem: CartItem = new CartItem();

    displaySuccessMessage: boolean;

    displayMessage = '';

    constructor(
        private router: Router,
        private appStore: ApplicationStoreService,
        private cartService: CartItemsService,
        private notifier: NotifierService) {
    }


    ngOnInit() {
        // sessionStorage.setItem('cart',null);
        this.customerProductQuantity = 1;
        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));

        const itemProduct = new Product();
        itemProduct.unitsInStock = this.selectedProduct.unitsInStock;
        itemProduct.productName = this.selectedProduct.productName;
        itemProduct.price = this.selectedProduct.price;
        itemProduct.productId = this.selectedProduct.productId;
        itemProduct.productBusiness = this.selectedProduct.productBusiness;
        itemProduct.defaultImageUrl = this.selectedProduct.defaultImageUrl;
        this.cartItem.product = itemProduct;
        this.cartItem.quantity = 0;

        this.init();
    }

    init(): void {
        if (sessionStorage.getItem('cart') != null) {
            const cart = JSON.parse(sessionStorage.getItem('cart'));
            for (let i = 0; i < cart.length; i++) {
                const item = JSON.parse(cart[i]);

                if (item.productItem.productId === this.selectedProduct.productId) {
                    this.cartItem = item;
                    this.customerProductQuantity = item.quantity;
                    break;
                }
            }
        }
    }

    checkOut() {

        const cart = JSON.parse(sessionStorage.getItem('cart'));

        if (cart === undefined || cart == null || cart.length <= 0) {
            this.displaySuccessMessage = true;
            this.displayMessage = 'No items added to the cart. Please add quantity to create cart!!';
            return;
        }

        this.router.navigate(['/shopping/view-cart']);
    }


    updateCartCount(): void {
        //  = 0;
        this.items = [];
        const cart = JSON.parse(sessionStorage.getItem('cart'));
        for (let i = 0; i < cart.length; i++) {
            const item = JSON.parse(cart[i]);
            this.itemTotalCount += item.quantity;
        }

        // this.cartItemCountComponent.updateFullCount(this.itemTotalCount);
    }


    subtractQuantity(cartItemToAdd: CartItem): void {
        this.displayMessage = '';
        const product = JSON.parse(sessionStorage.getItem('currentProduct'));

        const productId = product.productId;

        cartItemToAdd.product = product;

        if (this.customerProductQuantity === 0) {
            const cart: any = JSON.parse(sessionStorage.getItem('cart'));
            for (let i = 0; i < cart.length; i++) {
                // let item: CartItem =  this.items[i];
                const carItem: CartItem = JSON.parse(cart[i]);

                if (carItem.product.productId === cartItemToAdd.product.productId) {
                    this.removeItem(carItem.product.productId);

                    return;
                }
            } // for loop
        } else {
            const productFound = false;
            this.customerProductQuantity = this.customerProductQuantity - 1;

            const cart: any = JSON.parse(sessionStorage.getItem('cart'));
            for (let i = 0; i < cart.length; i++) {
                // let item: CartItem =  this.items[i];
                const carItem: CartItem = JSON.parse(cart[i]);

                if (carItem.product.productId === productId) {
                    this.removeItem(carItem.product.productId);

                    const cart = JSON.parse(sessionStorage.getItem('cart'));

                    carItem.quantity = this.customerProductQuantity;
                    cart.push(JSON.stringify(carItem));
                    // this.total += item.product.price * item.quantity;
                    sessionStorage.setItem('cart', JSON.stringify(cart));
                    break;
                }
            }

        }
    }


    addQuantity(cartItemToAdd: CartItem, addCount: boolean): void {
        this.displayMessage = '';
        const cart: any = JSON.parse(sessionStorage.getItem('cart'));
        if (addCount) {
            this.customerProductQuantity = this.customerProductQuantity + 1;
        }
        const product = JSON.parse(sessionStorage.getItem('currentProduct'));

        const productId = product.productId;

        cartItemToAdd.product = product;
        if (cart === undefined || cart == null || cart.length <= 0) {
            const cart: any = [];
            cartItemToAdd.quantity = this.customerProductQuantity;
            cart.push(JSON.stringify(cartItemToAdd));
            sessionStorage.setItem('cart', JSON.stringify(cart));

        } else {

            const cart: any = JSON.parse(sessionStorage.getItem('cart'));
            let productFound = false;
            for (let i = 0; i < cart.length; i++) {
                // let item: CartItem =  this.items[i];
                const carItem: CartItem = JSON.parse(cart[i]);
                if (carItem.product.productId == productId) {
                    this.removeItem(carItem.product.productId);
                    productFound = true;
                    const updatedCart = JSON.parse(sessionStorage.getItem('cart'));
                    cartItemToAdd.quantity = this.customerProductQuantity;
                    updatedCart.push(JSON.stringify(cartItemToAdd));
                    sessionStorage.setItem('cart', JSON.stringify(updatedCart));
                    break;

                }
            }


            if (!productFound) {
                const updatedCart = JSON.parse(sessionStorage.getItem('cart'));
                cartItemToAdd.quantity = this.customerProductQuantity;
                updatedCart.push(JSON.stringify(cartItemToAdd));
                sessionStorage.setItem('cart', JSON.stringify(updatedCart));

            }

        }

    }


    addToCart(cartItem: CartItem) {
        this.cartService.addToCart(cartItem);
        this.notifier.notify('Cart updated');
    }

    removeItem(id: number): void {
        const cart: any = JSON.parse(sessionStorage.getItem('cart'));
        const index = -1;
        for (let i = 0; i < cart.length; i++) {
            const item: CartItem = JSON.parse(cart[i]);
            if (item.product.productId === id) {
                cart.splice(i, 1);
                break;
            }
        }
        sessionStorage.setItem('cart', JSON.stringify(cart));
    }


}
