import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Product} from '../../model/Product';
import {HistoryServiceService} from '../../shared/services/history-service.service';
import {HistoryPayload} from '../../model/HistoryPayload';
import {NotifierService} from '../../services/notifier.service';
import {HttpResponse} from '@angular/common/http';


@Component({
    selector: 'myafrica-product-history',
    templateUrl: './product-history.component.html',
    styleUrls: ['./product-history.component.scss'],
})
export class ProductHistoryComponent implements OnInit, AfterContentChecked {

    htmlContent: any;
    alreadyHasHistory: boolean;
    currentProduct: Product;
    private appService: ApplicationStore;
    loading: boolean;
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'},
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText',
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize'],
        ],
    };

    constructor(appService: ApplicationStore,
                private historyService: HistoryServiceService, private notifier: NotifierService) {
        this.appService = appService;
    }


    ngOnInit() {
        this.currentProduct = this.appService.getJsonItem('currentProduct');
		this.alreadyHasHistory = false;
        this.loading = true;
        this.historyService.getHistory(this.currentProduct.id).subscribe((result: HttpResponse<Product>) => {
            this.htmlContent = result.body ? result.body.history : null;
            if (this.htmlContent && this.htmlContent.toString().length > 1) {
                this.alreadyHasHistory = true;
            }
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    ngAfterContentChecked(): void {
        // console.log('Content ', this.htmlContent);
    }

    addHistory() {
		if(this.alreadyHasHistory) {
		      this.updateHistory();
			  return;
		}
		
        const historyPayload = new HistoryPayload();
        historyPayload.history = this.htmlContent;
        historyPayload.productId = this.currentProduct.id;
		
		if(historyPayload.history == null || historyPayload.history == "null" || historyPayload.history == "") {
			this.notifier.notify('Please enter product history!!', 'error');
			return;
		}
  
    	this.loading = true;
        this.historyService.addHistory(historyPayload).subscribe((result: HttpResponse<Product>) => {
            this.htmlContent = result.body ? result.body.history : null;
            this.notifier.notify('Product history added successfully!!', 'success');
			this.alreadyHasHistory = true;
            this.loading = false;
        }, () => {
            this.notifier.notify('Error adding product history!! Please try again!!', 'error');
            this.loading = false;
        });
    }

    updateHistory() {
        const historyPayload = new HistoryPayload();
        historyPayload.history = this.htmlContent;
        historyPayload.productId = this.currentProduct.id;
        
		if(historyPayload.history == null || historyPayload.history == "null" || historyPayload.history == "") {
			this.notifier.notify('Please enter product history!!', 'error');
			return;
		}
  
		this.loading = true;
        this.historyService.updateHistory(historyPayload).subscribe((result: HttpResponse<Product>) => {
            console.log('Results of update history : ', result);
            this.htmlContent = result.body ? result.body.history : null;
            this.notifier.notify('Product history updated successfully!!', 'success');

            this.loading = false;
        }, () => {
            this.notifier.notify('Error updating product history!! Please try again!!', 'error');
            this.loading = false;
        });
    }
}
