<div *ngIf="business" class="shadow d-block my-2 text-center rounded-bottom">
    <p class="h4">Associated Business</p>
    <div class="col-lg-12 col-md-12 col-sm-6">

        <img [src]="business.logoUrl" *ngIf="business.logoUrl; else elseBlock"
             class="img-fluid col-6 p-1 cursor-pointer mx-auto"
             (click)="showBusinessDetails(business.businessId)" height="50">
        <p class="d-none d-md-block col-12 text-justify" *ngIf="business.businessDetails">
            {{business.businessDetails.aboutBusiness}}</p>
        <div class="col-12 text-center pb-2">
            <div
                    *ngIf="business?.businessDetails" class="d-inline"><a
                    href="{{business?.businessDetails?.businessFacebookURL}}"
                    target="_blank"><span
                    class="rounded-circle mx-2"><i class="fab fa-facebook fa-1x"></i></span></a></div>
            <div
                    *ngIf="business?.businessDetails" class="d-inline"><a
                    href="{{business?.businessDetails?.businessTwitterURL}}"
                    target="_blank"><span
                    class="rounded-circle"><i class="fab fa-twitter fa-1x"></i></span></a></div>
        </div>

    </div>
</div>


<ng-template #elseBlock class="justify-content-center">
    <img src="https://myafricaconnect.com/images/image-not-available.jpg" class="img-fluid col-6 p-1 rounded cursor-pointer mx-auto"
         (click)="showBusinessDetails(business.businessId)" height="50">
</ng-template>
