import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../model/Product';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {HttpResponse} from '@angular/common/http';
import {ProductHistory} from '../../../model/ProductHistory';
import {HistoryPayload} from '../../../model/HistoryPayload';
import {NotifierService} from '../../../services/notifier.service';
import {HistoryServiceService} from '../../../shared/services/history-service.service';

@Component({
    selector: 'myafrica-edit-history',
    templateUrl: './edit-history.component.html',
    styleUrls: ['./edit-history.component.scss'],
})
export class EditHistoryComponent implements OnInit {
    loading: boolean;
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'},
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText',
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize'],
        ],
    };

    @Input() product: Product;
    alreadyHasHistory = false;
	productHistory : string;

    constructor(private historyService: HistoryServiceService,
                private notifier: NotifierService) {
    }

    ngOnInit(): void {
        this.loading = true;
	    this.historyService.getHistory(this.product.id).subscribe((result: HttpResponse<ProductHistory>) => {
	        this.product.history = result.body ? result.body.history : null;
			this.productHistory = this.product.history;
            this.loading = false;
            if (this.product.history && this.product.history.toString().length > 1) {
                this.alreadyHasHistory = true;
            }
        }, (error) => {
            this.loading = false;
        });
    }

    addHistory() {
        const historyPayload = new HistoryPayload();
        historyPayload.history = this.productHistory;
        historyPayload.productId = this.product.id;
	
		if(historyPayload.history == null || historyPayload.history == "null" || historyPayload.history == "") {
			this.notifier.notify('Please enter product history!!', 'error');
			return;
		}
  
        this.loading = true;
        this.historyService.addHistory(historyPayload).subscribe((result: HttpResponse<ProductHistory>) => {
            this.product.history = result.body ? result.body.history : null;
            this.notifier.notify('Product history added successfully!!', 'success');

            this.loading = false;
        }, () => {
            this.notifier.notify('Error adding product history!! Please try again!!', 'error');
            this.loading = false;
        });
    }

    updateHistory() {
        const historyPayload = new HistoryPayload();
		historyPayload.history = this.productHistory;
        historyPayload.productId = this.product.id;
		
		if(historyPayload.history == null || historyPayload.history == "null" || historyPayload.history == "") {
			this.notifier.notify('Please enter product history!!', 'error');
			return;
		}
  
        this.loading = true;
        this.historyService.updateHistory(historyPayload).subscribe((result: HttpResponse<ProductHistory>) => {
            this.product.history = result.body ? result.body.history : null;
            this.notifier.notify('Product history updated successfully!!', 'success');

            this.loading = false;
        }, () => {
            this.notifier.notify('Error updating product history!! Please try again!!', 'error');
            this.loading = false;
        });
    }
}
