import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Business, businessWithUpdatedImages} from '../model/Business';
import {ApplicationStoreService} from '../services/application-store.service';
import {BusinessItemService} from '../services/business-item.service';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'myafrica-business-snipet',
    templateUrl: './business-snipet.component.html',
    styleUrls: ['./business-snipet.component.scss'],
})
export class BusinessSnipetComponent implements OnInit, OnDestroy {

    @Input() business: Business;

    constructor(private router: Router,
                private businessService: BusinessItemService,
                private appStore: ApplicationStoreService) {
    }

    ngOnInit() {
    }

    showBusinessDetails(businessId: number) {
        this.businessService.getBusinessDetails(businessId).subscribe((value: HttpResponse<Business>) => {
                this.appStore.storeStringItem('selectedBusiness', businessWithUpdatedImages(value.body));
                this.router.navigate(['business-details']).then();
                window.scrollTo(0, 0);
            },
        );
    }

    ngOnDestroy() {
    }
}
