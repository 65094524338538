import {Component, Input, OnInit} from '@angular/core';
import {ClothingColour} from '../model/ClothingColour';

@Component({
    selector: 'myafrica-product-icon',
    templateUrl: './product-icon.component.html',
    styleUrls: ['./product-icon.component.scss'],
})
export class ProductIconComponent implements OnInit {

    @Input() clothingColour: ClothingColour;

    constructor() {
    }

    ngOnInit() {
    }

}
