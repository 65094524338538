<div class="container mt-1 p-2">
    <div class="form-row">
        <div class="form-group col-md-6">
            <label class="control-label">Department</label>
            <select [(ngModel)]="categoryModelRevamped" name="productCategory" required class="form-control"
                 [compareWith]="compareFn"    (change)="displayGendersDropdown(categoryModelRevamped)">
                <option *ngFor="let cat of categories" [ngValue]="cat">
                    {{cat.displayName}}
                </option>
            </select>

        </div>
        <div class="form-group col-md-6">
            <label class="control-label">Business</label>
            <select class="form-control" name="businessId" id="businessId" [(ngModel)]="businessId"
                    required>
                <option *ngFor="let business of businesses"
                        ng-selected="business.id" value="{{business.id}}">
                    {{business.businessName}}
                </option>
            </select>
        </div>


    </div>

    <div class="form-row" >
        <div class="form-group col-md-6">
            <label class="control-label">Category</label>
            <select [(ngModel)]="subCategoryModelRevamped" class="form-control" name="genderId" required
               [compareWith]="compareFn"    (change)="displayGroupsDropDown(subCategoryModelRevamped)">
                <option *ngFor="let catsubcategory of subCategories" [ngValue]="catsubcategory">
                    {{catsubcategory.displayName}}
                </option>
            </select>
        </div>
        <div class="form-group col-md-6" >
             <label class="control-label">Sub-Category</label>
            <select [(ngModel)]="subSubCategoryModelRevamped" class="form-control" name="groupId" required
                   [compareWith]="compareFn" >
                <option *ngFor="let subsubcategory of subSubCategories" [ngValue]="subsubcategory">
                    {{subsubcategory.displayName}}
                </option>
            </select>
        </div>
     

    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label class="control-label">Product Name</label>
            <input type="text" class="form-control" [(ngModel)]="productName"
                   required>
        </div>
        <div class="form-group col-md-6">
            <label class="control-label"># Items in Stock</label>
            <input type="number" class="form-control" min="1" numbersOnly
                   [maxlength]="15" [(ngModel)]="unitsInStock">
        </div>

    </div>

	 <myafrica-product-dimensions [product]="selectedProduct"></myafrica-product-dimensions>

    <div class="row form-group">
        <div class="form-group col-md-12">
            <label class="control-label">Product Info</label>
            <textarea rows="5" class="form-control"
                      [(ngModel)]="productDescription">
                                </textarea>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-4">
            <label class="control-label">Currency</label>
            <select [(ngModel)]="currency" class="form-control">
                <option value="ZAR" selected>ZAR</option>
                <!--                <option value="USD">USD</option>-->
                <!--                <option value="ZMK">ZMK</option>-->
                <!--                <option value="TZS">TZS</option>-->
                <!--                <option value="LSL">LSL</option>-->
            </select>
        </div>
        <div class="form-group col-md-4">
            <label class="control-label">Price</label>
            <input type="number" id="productprice" class="form-control" min="0"
                   [(ngModel)]="price" (input)="calculateProductPrice()">
        </div>
        <div class="form-group col-md-4">
            <label class="control-label">Service Fee</label>
            <label class="no-border border-0 form-control text-warning font-weight-bold" disabled="true"
                   style="font-size: 15px;">{{currency}} {{calculatedServiceFee}}</label>
        </div>

    </div>


    <div class="form-row">
        <div class="form-group col-md-4">
            <label class="control-label">MAC Fee</label>
            <label class="no-border border-0 form-control text-warning font-weight-bold" disabled="true"
                   style="font-size: 15px;">{{currency}} {{calculatedMarkUpFee}}</label>

        </div>
        <div class="form-group col-md-4">
            <label class="control-label">Eft Fee</label>
            <label class="no-border border-0 form-control text-warning font-weight-bold" disabled="true"
                   style="font-size: 15px;">{{currency}} {{calculatedEftFee}}</label>
        </div>
        <div class="form-group col-md-4">
            <label class="control-label">Total Price</label>
            <label class="no-border border-0 form-control text-warning font-weight-bold" disabled="true"
                   style="font-size: 15px;">{{currency}} {{totalProductFee}}</label>

        </div>

    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-6">
        <button type="submit" (click)="updateProduct()"
                class="btn btn-success btn-block my-1">Update Product Details
        </button>
    </div>
    <div class="col-xs-12 col-md-6">
        <button type="submit" (click)="goBack()" class="btn btn-danger w-100 my-1">Cancel
        </button>
    </div>


</div>
