<myafrica-page-title pageTitle="{{pageTitle}}"></myafrica-page-title>

<div class="container">

	<div class="row">
		<div class="col-md-6">

			<label class="search-label" for="startDate"
				title="Filters the orders according to the start date">From:</label>

			<input type="date" id="startDate"
				[ngModel]="startDate | date:'yyyy-MM-dd'"
				class="form-control formfields" (ngModelChange)="startDate = $event">

		</div>

		<div class="col-md-6">
			<label for="endDate"
				title="Filters the orders according to the end date">To</label> <input
				type="date" id="endDate" [ngModel]="endDate | date:'yyyy-MM-dd'"
				class="form-control" (ngModelChange)="endDate = $event">`
		</div>

	</div>
	<div class="row">
		<div class="col-sm-12 col-md-6 col-lg-6">
			<label class="search-label" for="businessId"
				title="Select business for report">Business:</label> 
				<select
				class="form-control" name="businessId" id="businessId"
				[(ngModel)]="businessId" required>
				<option value="0">All</option>
				<option *ngFor="let business of businesses"
					ng-selected="business.businessId" value="{{business.businessId}}">
					{{business.businessName}}</option>
			</select>
		</div>
		<div class="col-sm-12 col-md-4 col-lg-4">

			<button type="button" class="btn btn-success w-100 mt-4"
				title="Print orders by date range" (keyup.enter)="printOrders()"
				(click)="printOrders()">
				<i class="fa fa-file"></i>&nbsp;Print Orders
			</button>
		</div>
		<div class="col-sm-12 col-md-2 col-lg-2">
		</div>
	</div>

</div>
<br />

<div class="container">
	<div class="row">
		<div class="col-2"></div>
		<div class="col-5 " class="success-message-bold "
			*ngIf="displayMessage">{{message}}</div>
		<div class="col-5 " *ngIf="displayErrorMessage"
			class="error-message-bold">{{message}}</div>
		<div class="col-5"></div>
	</div>
	<br />
</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


