
<div class="container mb-2">
	<section>
		<br>
		<myafrica-page-title pageTitle="News Letter Sign Up"></myafrica-page-title>
	</section>

	<div class="col-12">
		<div class="form-panel">
	
			<form class="form-horizontal style-inner-form">
				<div class="row">
					<div class="col-xs-0 col-sm-0 col-md-3 col-lg-3">&nbsp;</div>
					<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

						<p>Signup for our weekly newsletter to get the latest news,
							updates and amazing offers delivered directly in your inbox.</p>
					</div>
					<div class="col-xs-0 col-sm-0 col-md-3 col-lg-3">&nbsp;</div>
				</div>

				<div class="row">
					<div class="col-xs-0 col-sm-0 col-md-3 col-lg-3">&nbsp;</div>
					<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

						<div class="input-group">
							<input type="email" class="form-control" [(ngModel)]="email"
								name="email" 
								placeholder="Enter your email e.g. name@domain.com">
							<div class="input-group-append">
								<button class="btn btn-theme  btn-block btn-success"  
									type="submit" (click)="sendMessage()">Subscribe</button>
							</div>
						</div>
					</div>
					<div class="col-xs-0 col-sm-0 col-md-3 col-lg-3">&nbsp;</div>

				</div>

			</form>
		</div>
	</div>
</div>
<myafrica-footer></myafrica-footer>
