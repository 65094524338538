import {Component, Input, OnInit} from '@angular/core';
import {BusinessItemService} from '../../../services/business-item.service';
import {Router} from '@angular/router';
import {Business, businessWithUpdatedImages} from '../../../model/Business';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'myafrica-admin-business-item',
    templateUrl: './admin-business-item.component.html',
    styleUrls: ['./admin-business-item.component.scss'],
})
export class AdminBusinessItemComponent implements OnInit {

    @Input() business: Business;

    constructor(private businessService: BusinessItemService,
                private router: Router) {
    }

    ngOnInit() {
    }

    showBusinessDetails(business: any) {
	    sessionStorage.setItem('selectedBusiness', JSON.stringify(business));
        this.navigate('edit-business');
    }

    hasLogo(business: Business): boolean {
        return business.logoUrl !== undefined;
    }

    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

}
