import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Product} from '../../../model/Product';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'myafrica-all-clothing-products',
    templateUrl: './all-clothing-products.component.html',
    styleUrls: ['./all-clothing-products.component.scss'],
})
export class AllClothingProductsComponent implements OnInit {

    clothingProducts$: Observable<Array<Product>>;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.clothingProducts$ = this.route.data.pipe(map(data => data.clothingProducts));
    }

}
