import {Component, OnInit} from '@angular/core';
import {RegistrationDetails} from '../model/RegistrationDetails';
import {ProfileService} from '../shared/services/profile.service';
import {Router} from '@angular/router';
import {NotifierService} from '../services/notifier.service';
import {HttpResponse} from '@angular/common/http';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'myafrica-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    loading: boolean;
    regDetails: RegistrationDetails;
    userRole = 'Merchant';
    infoIcon = faInfoCircle;

    constructor(private profileService: ProfileService,
                private router: Router,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        this.notifier.info('Please note that we currently only support business registrations,' +
            ' normal shoppers do not need to register');
        this.regDetails = new RegistrationDetails();
        this.userRole = 'Merchant';
    }


    registerUser() {

        this.regDetails.userRole = this.userRole;

        this.profileService.registerUser(this.regDetails).subscribe((response: HttpResponse<any>) => {
            this.router.navigate(['/register', 'success']).then();

        }, (error1) => {
            if (error1.status === 409) {
                this.notifier.notifyError('Account already exists but not yet activated! ' +
                    'Please click Re-send Activation Email button below.');
            } else if (error1.status === 417) {
                this.notifier.notifyError('User with the same credentials already exist');
            } else {
                this.notifier.notifyError('Registration failed. Please try again later!');
            }

        });


    }

}
