import {Business} from './Business';
import {UserRole} from './UserRole';


export class RegistrationDetails {
    constructor() {
    }

    userName: string;
    email: string;
    pwd: string;
    password: string;
    confirmPwd: string;
	userRole: string;
    newsletterAccepted: boolean;
    termsAccepted: boolean;
	firstName: string;
	lastName: string;
	cellphoneNumber: string;
	birthDate: Date;

	vatNumber: string;
	monthlyRevenue: number;
	physicalStore: string;
	carryStock: string;
	companyName: string;
	aboutCompany: string;
    businessCategoryId: number;
	
	userRoles: UserRole[];

}
