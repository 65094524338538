import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OrderQuote} from '../order-delivery/OrderQuote';
import {environment} from '../../environments/environment';
import {DroppaBookingRequestInfo} from '../model/DroppaBookingRequestInfo';



@Injectable({
  providedIn: 'root'
})
export class OrderDeliveryService {

  constructor(private http: HttpClient) { }
  
   /**
   ** Request quote from the delivery company
   **/
   requestQuote(orderQuote: any) {
            return this.http.post('myafrica-api/get/delivery/quote',
            orderQuote, {observe: 'response'});

    }
	
	/**
     ** Create a delivery booking
    **/
   requestBooking(bookingRequest: DroppaBookingRequestInfo) {
            return this.http.post('myafrica-api/droppa/create/express/booking',
            bookingRequest, {observe: 'response'});

    }
	
	
	getActiveCourierCompanies() {
      return this.http.get('myafrica-api/get/active/courier/companies');
    }

	getAllCourierCompanies() {
      return this.http.get('myafrica-api/get/all/courier/companies');
    }

	getDeliveriesByDeliveryNo(deliveryNo: String, itemsPerPage: String, pageNumber: String) {
	       return this.http.get(`myafrica-api/get/deliveries/deliveryno/{deliveryNo}/{itemsPerPage}/{pageNumber}`);
	
	}
	
	getDeliveriesByOrderNo(orderNo: String, itemsPerPage: String, pageNumber: String) {
	       return this.http.get(`myafrica-api/get/deliveries/orderno/{orderNo}/{itemsPerPage}/{pageNumber}`);
	}

	getDeliveriesByDates(date1: Date, date2: Date, itemsPerPage: String, pageNumber: String) {
       return this.http.get(`myafrica-api/get/deliveries/bydates/{date1}/{date2}/{itemsPerPage}/{pageNumber}`);	}

  
}
