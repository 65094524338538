<div *ngIf="displayErrorMessage" style="text-align: left"
     class="container error-message-bold">{{displayMessage}}</div>

<div class="col-12  row" *ngIf="cartItem.product.unitsInStock <= 0">
    <span class="error-message-bold"> Out Of Stock</span> <br />
</div>
<div *ngIf="displaySuccessMessage" class="success-message-bold">{{displayMessage}}</div>

<!--	<div class="row">-->
<!--	<div class="col-1 col-sm-3">-->
<!--	</div>-->
<!--		<div class="col-2 col-sm-1 form-group">-->
<!--			<button type="button"-->
<!--				[disabled]="cartItem.product.unitsInStock <= 0"-->
<!--				class="removeQuantityProduct btn-default"-->
<!--				(click)="subtractQuantity(cartItem)">-</button>-->
<!--		</div>-->
<!--		<div class="col-6 col-sm-4 form-group">-->

<!--			<input [disabled]="cartItem.product.unitsInStock <= 0"-->
<!--				type="number" style="margin-left: 10px;height: 30px" class="form-control"-->
<!--				[(ngModel)]="customerProductQuantity"  name="customerProductQuantity" required />-->
<!--		</div>-->
<!--		<div class="col-2 col-sm-1 form-group">-->

<!--			<button type="button"-->
<!--				[disabled]="cartItem.product.unitsInStock <= 0"-->
<!--				class="removeQuantityProduct btn-default"-->
<!--				(click)="addQuantity(cartItem)">+</button>-->

<!--		</div>-->
<!--	<div class="col-1 col-sm-3">-->
<!--	</div>-->

<!--	</div>-->

<div class="container">
    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <button (click)="subtractQuantity(cartItem)"
                    [disabled]="cartItem.product.unitsInStock <= 0"
                    class="input-group-text font-weight-bold">&nbsp;-&nbsp;</button>
        </div>
        <input type="number" [(ngModel)]="customerProductQuantity"
               class="quantity-input" required />
        <div class="input-group-append">
            <button [disabled]="cartItem.product.unitsInStock <= 0"
                    (click)="addQuantity(cartItem, true)"
                    class="input-group-text font-weight-bold">&nbsp;+&nbsp;</button>
        </div>
    </div>
</div>
<div class="row">

    <div class="col-12  form-group">
        <button [disabled]="cartItem.product.unitsInStock <= 0"
                class="btn btn-theme-product-large"
                style="margin: 0px 0px; height: 24pt" (click)="addToCart(cartItem)">
			<span><img class="connect-btn-image"
                       src="https://myafricaconnect.com/images/cart36x36.png"></span>Add to Cart

        </button>

    </div>
</div>
<div class="row ">
    <div class="col-12 ">

        <p style="font-size: 10pt">
            Estimated delivery <span style="font-size: 10pt; color: #f15a22">
				6 to 7 working business days</span>
        </p>
    </div>
</div>
<div class="row ">
    <div class="col-12 ">

        <h1 class="selectedproduct-price" (click)="checkOut()">Check Out</h1>
    </div>
</div>
