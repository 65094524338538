import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from '../services/message.service';
import {Utils} from '../shared/Utils';
import {ContactUsMessage} from '../model/ContactUsMessage';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {

    loading: boolean;
    displayMessage: string;
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;

    message: ContactUsMessage;

    constructor(private messageService: MessageService,
                private router: Router,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        this.message = new ContactUsMessage();
        this.message.messageType = 'contactus';

    }

    sendMessage() {
        this.loading = true;
        this.messageService.sendMessage(this.message).subscribe(() => {
            this.notifier.notify('Message sent successfully');
            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            Utils.delay(3000).then(() => {
                this.router.navigate(['/']).then();
            });
            this.loading = false;

        }, () => {
            this.displayMessage = 'Error sending message. Please try again later!!!';
            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.loading = false;
        });


    }


}
