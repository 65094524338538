import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {Product} from '../model/Product';

@Injectable()
export class ClothingService {

    constructor(private httService: HttpClient) {
    }

    getAllClothingProducts(): Observable<Array<Product> | any> {
        return this.httService.get(`myafrica-api/public/clothing-product/`).pipe(
            shareReplay(),
        );
    }
}
