import {Injectable} from '@angular/core';
import {RequestHeader} from '../shared/RequestHeader';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SearchFieldService extends RequestHeader {

  constructor(private http: HttpClient) {
    super();
  }

  getSearchResult(category: string, searchString: string) {
    return this.http.get(
      `${this.config.serverUrl}/rest/product/allproducts/${searchString}/12/0/${category}/undefined/getpaginationproductsbyname`,
      {observe: 'response'}
    );
  }
}
