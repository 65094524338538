import {AfterViewInit, Component, OnInit} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {Product} from '../model/Product';
import {HttpResponse} from '@angular/common/http';
import {ProductHistory} from '../model/ProductHistory';
import {Router} from '@angular/router';
import {CartItem} from '../model/CartItem';
import {CartManagementInput} from '../model/CartManagementInput';
import {businessWithUpdatedImages} from '../model/Business';
import {ProductService} from '../services/product.service';
import {HistoryService} from '../services/history.service';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-shopping',
    templateUrl: './shopping.component.html',
    styleUrls: ['./shopping.component.scss'],
})
export class ShoppingComponent implements OnInit, AfterViewInit {

    customerProductQuantity = 0;
    itemTotalCount = 0;
    cartInput: CartManagementInput;

    cartItem: CartItem;
    allProducts;
    productBusiness;
    loading: boolean;

    selectedProduct: Product;
    htmlContent: any;
    stockCount: number;

    constructor(private productService: ProductService,
                private historyService: HistoryService,
                private router: Router,
                private notifier: NotifierService) {
    }

    ngOnInit() {

        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct')) as Product;
        this.cartItem = new CartItem();
        this.cartItem.product = this.selectedProduct;
        this.cartItem.quantity = 1;
        this.cartItem.image = this.selectedProduct.defaultImageUrl;
        this.stockCount = this.selectedProduct.unitsInStock;
        this.cartItem.maxCount = this.selectedProduct.unitsInStock;

        if (this.selectedProduct) {
            this.makeCalls();
        }
    }

    viewHistory(product: Product) {
        this.historyService.getHistory(product.productId).subscribe((result: HttpResponse<ProductHistory>) => {
            this.htmlContent = result.body ? result.body.history : null;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    reloadPage(product?: Product) {

        this.selectedProduct = product ? product : JSON.parse(sessionStorage.getItem('currentProduct'));
        this.cartItem.product = this.selectedProduct;
        this.stockCount = this.selectedProduct.unitsInStock;
        window.location.reload();
        this.makeCalls();
    }

    private makeCalls(): void {

        this.loading = true;
		this.productService.updateProductViewCount(this.selectedProduct.id).subscribe((value: any) => {
			}, error1 => {
                this.loading = false;
            },
		);
      
        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
        this.viewHistory(this.selectedProduct);
    }

    checkOut() {

        const cart = JSON.parse(sessionStorage.getItem('cart'));

        if (cart === undefined || cart == null || cart.length <= 0) {
            this.notifier.notifyError('No items added to the cart. Please add quantity to create cart!!');
            return;
        }

        this.router.navigate(['/shopping/view-cart']).then();
    }

    ngAfterViewInit() {
        this.loading = false;
    }
}
