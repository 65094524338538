import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {UserAddress} from '../../model/UserAddress';


@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    headers = new HttpHeaders()
        .set('Content-Type', 'application/json');

    constructor(private http: HttpClient) {
    }

    registerUser(userDetails: any): Observable<any> {
        return this.http.post('myafrica-api/register',
            userDetails, {
                observe: 'response',
            });
    }

    activateUserAccount(activatorCode: string) {
        return this.http.post('myafrica-api/accounts/activate/' + activatorCode,
            {
                observe: 'response',
            });
    }

    resetPassword(userDetails: any) {
        return this.http.post('myafrica-api/reset/password',
            userDetails, {
                observe: 'response',
            });

    }

	updateUserAddress(userAddress: any, provinceId: string) {
        return this.http.post(('myafrica-api-admin/profiles/update/user/address/')
				.concat(`${provinceId}`),
            userAddress, {
                observe: 'response',
            });
    }

    resendActivationLink(userDetails: any) {
        return this.http.post('myafrica-api/resend/activation/link',
            userDetails, {
                observe: 'response',
            });

    }


    acceptSellerAgreement(userDetails: any) {
        return this.http.post('myafrica-api/accept/seller/agreement',
            userDetails, {
                observe: 'response',
            });

    }

    updatePassword(userDetails: any) {
        return this.http.post('myafrica-api/update/password',
            userDetails, {
                observe: 'response',
            });

    }

    forgotPassword(userDetails: any) {
        return this.http.post('myafrica-api/forgot/password',
            userDetails, {
                observe: 'response',
            });

    }


    addProfilePicture(file: File, profileId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/*');
        myHeaders.append('Accept', 'application/json');
        return this.http.post(('myafrica-api/profile-admin/')
                .concat(`${profileId}`)
                .concat('/add-profile-pic'),
            formData, {headers: myHeaders});


    }

	 updateUserDetails(userDetails: any) {
        return this.http.post('myafrica-api/update/user/profile',
            userDetails, {
                observe: 'response',
            });

    }

}
