import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {LoginService} from './login.service';
import {ApplicationStoreService} from './application-store.service';
import {Subject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AppIdleService} from './app-idle.service';
import {UserDetails} from '../model/UserDetails';



@Injectable({
    providedIn: 'root',
})
export class SessionManagerService {

    private userLoggedIn = new Subject<boolean>();
    DEFAULT_TIMER_COUNT = 25;
    timeLeft: number = this.DEFAULT_TIMER_COUNT;
    interval;
    subscribeTimer: any;
    subs: Subscription;
    isRefreshingToken = false;

    constructor(private loginService: LoginService, private tokenStore: ApplicationStoreService,
                private router: Router, private idleAppService: AppIdleService) {
        this.userLoggedIn.next(false);
    }


    public startSessionTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.refreshAccessToken();
                this.timeLeft = this.DEFAULT_TIMER_COUNT;
            }
        }, 60000);
    }

    public clearTimer() {
        this.timeLeft = this.DEFAULT_TIMER_COUNT;
        clearInterval(this.interval);
    }

    public resetCount() {
        this.timeLeft = this.DEFAULT_TIMER_COUNT;
    }

	public isUserAdmin(userDetails: UserDetails) {
		return 'Administrator' === userDetails.userRole;
	}
	
	public isUserAdminClerk(userDetails: UserDetails) {
		return 'Admin Clerk' === userDetails.userRole;
	}

    refreshAccessToken() {
        // check that a refresh call has not been initiated already
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenStore.setRefreshTokenIndicator('Y');
            this.subs = this.loginService.refreshAccessToken(
                this.tokenStore.getRefreshToken()).subscribe((value: HttpResponse<any>) => {
                this.isRefreshingToken = false;
                const newToken = value.body.accessToken;
                this.tokenStore.saveToken(newToken);
                const rToken = value.body.refreshToken;
                this.tokenStore.saveRefreshToken(rToken);
                this.tokenStore.setRefreshTokenIndicator('N');
                this.resetCount();
            }, error1 => {
                this.isRefreshingToken = false;
                this.logout();
                this.tokenStore.setRefreshTokenIndicator('N');
            });
        }
    }


    logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.tokenStore.signOut();
        this.idleAppService.setUserLoggedIn(false);
        this.router.navigate(['/']).then();
    }

}
