<myafrica-navbar></myafrica-navbar>
<div class="container-fluid min-vh-100 p-0">
    <router-outlet></router-outlet>
</div>
<myafrica-footer></myafrica-footer>
<ng-template #displayCountDown let-modal> <!-- dialog -->
  <div class="modal-dialog-ts">

    <!-- content -->
    <div class="modal-content">
      <br/><br/><br/><br/>
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <label class="countDownText"><b> {{idleState}}</b></label>

          </div>
        </div>

        <div class="row text-center">
          <div class="col-12 text-center">
            <div class="numberCircle">{{appCountDown}}</div>

          </div>
        </div>
        <hr/>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label class="control-label"></label>
            <label class="control-label"></label>
          </div>
          <div class="form-group col-md-4">
            <label class="control-label"></label>
            <button type="button" (click)="logout()" class="form-control btn btn-info">Logout</button>
          </div>
          <div class="form-group col-md-4">
            <label class="control-label"></label>
            <button type="button" (click)="stay()" class="form-control btn btn-info">Continue</button>


          </div>

        </div>

      </div>
    </div>
  </div>

</ng-template>