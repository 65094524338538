export class Utils {


    public static async delay(ms: number) {
        await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('fired'));
    }

    public static getDelayTime() {
        return 3000;
    }

    public static getDateString(dateToChange: Date): string {
        const day = '';
        const month = '';
        let endday = '';
        let endmonth = '';
        let dateStringChanged = '';

        try {
            endday = ('0' + dateToChange.getDate()).slice(-2);
            endmonth = ('0' + (dateToChange.getMonth() + 1)).slice(-2);

            dateStringChanged = dateToChange.getFullYear() + '-' + (endmonth) + '-' + (endday);
        } catch (ex) {
            if (dateToChange.toString().indexOf('-') !== -1) {
                dateStringChanged = dateToChange.toString();
            }
        }

        return dateStringChanged;


    }

}
