<div class="row text-center">
    <div class="col-12 col-md-6">
        <h2>
            <small>{{product.productName | titlecase}}</small>
        </h2>
        <img src="{{product.defaultImageUrl}}" alt="" class="img-fluid img-max-height">

        <div class="media-body mt-1">
            <p class="h4">Product Info</p>
            <p class="overflow-auto">{{product.productDescription}}.</p>
        </div>

    </div>
    <div class="col-12 col-md-6" *ngIf="productBusiness">
        <div class="col-lg-8 col-md-8 col-sm-6">
            <p class="product-price">{{product.price | currency: product.currency.concat(' ') :true}}</p>

            <myafrica-add-product-to-cart></myafrica-add-product-to-cart>

            <div class="media">
                <myafrica-business-snipet [business]="productBusiness"></myafrica-business-snipet>
            </div>

        </div>
    </div>

</div>
<div class="row text-center">
    <br/>
    <div class="col-12 col-md-6">
    </div>

</div>
