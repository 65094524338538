import { Component, OnInit } from '@angular/core';
import {CustomerOrder} from '../../../model/CustomerOrder';
import {OrderDeliveryService} from '../../../services/order.delivery.service';
import {DroppaBookingRequestInfo} from '../../../model/DroppaBookingRequestInfo';
import { DatePipe } from '@angular/common';
import {NotifierService} from '../../../services/notifier.service';
import {CustomerOrderItem} from '../../../model/CustomerOrderItem';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {OrdersServiceService} from '../../../shared/services/orders-service.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'myafrica-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  selectedCustomerOrder: CustomerOrder;
  loading = false;
  profileId: string;
  modalReference: any;
  businessName: string;
  contactNo: string;
  specialInstructions: string;
  closeResult: string;   
  
  orderStatuses: string[];
  changedStatusTrackers = [];
  displayMessage = false;
  message = 'Data has been modified. Remember to save when done!!';
  displaySaveButton = false;
  displayErrorMessage = false;
  displayPrintMessage = false;
  displayPrintErrorMessage = false;
  changeCount: number;
  rowsControls = [];
  rows: CustomerOrder[];
  currentOrderStatus: string;
  statusIndex: number;
  dateFormat: string;
  currencyValue = 'ZAR';
 
  constructor(private orderDeliveryService: OrderDeliveryService,
			  private datePipe: DatePipe,
			  private notifier: NotifierService,
			  private appStore: ApplicationStore,
			  private modalService: NgbModal,
			  private router: Router,
			  private ordersService: OrdersServiceService) { }

  ngOnInit(): void {
	    this.dateFormat = environment.dateFormat;
   
	    this.profileId = this.appStore.getJsonItem('profileId');
	    this.selectedCustomerOrder = JSON.parse(sessionStorage.getItem('selectedCustomerOrder'));
        this.currentOrderStatus  = this.selectedCustomerOrder.orderStatusDescription;
		this.profileId = this.appStore.getJsonItem('profileId');
		if(sessionStorage.getItem('orderStatuses') === undefined) {
			this.ordersService.getOrderStatusesByProfile(Number(this.profileId)).subscribe((value: string[]) => {
				   this.orderStatuses = value;
				   sessionStorage.setItem('orderStatuses', 'orderStatuses');
				});
		} else {
				
		this.orderStatuses = JSON.parse(sessionStorage.getItem('orderStatuses'));
	}
		
	
  }
 
	getPreviousOrderStatusSequence(customerOrder: CustomerOrder) {
		let sequence = 0;
		let index = 0;
		let jsonOrderStatuses = JSON.parse(JSON.stringify(this.orderStatuses));
		jsonOrderStatuses.forEach(row => {
    		if (row.description == this.currentOrderStatus) {
				sequence = row.orderStatusSequence;
				this.statusIndex = index;
			}
			index++;
         })
		 
		 return sequence;
	}

    getNewOrderStatusSequence(customerOrder: CustomerOrder) {
		let sequence = 0;
		let jsonOrderStatuses = JSON.parse(JSON.stringify(this.orderStatuses));
		jsonOrderStatuses.forEach(row => {
    		if (row.description == customerOrder.orderStatusDescription) {
				sequence = row.orderStatusSequence;
			}
         })
		 
		 return sequence;
	
	}
	
     setCurrentStatus(orderStatus: string) {
	    this.currentOrderStatus = orderStatus;
	 }

    resetStatus(customerOrder: CustomerOrder) {
	  let index = 0;
	  this.rows.forEach(row => {
	        if (customerOrder.customerOrderId === row.customerOrderId) {
				row.orderStatusDescription = this.currentOrderStatus;
				let element = document.getElementById('dropdown');
				element[this.statusIndex].selected = true;
	            this.rows.splice(index, 1, row);
					this.rowsControls.push({
                        isCollapsed: true,
              
			  });
			}
			index++;
	  })
	}

    /**
     * Adds changed product item statuses to the status tracker array
     */
    changeOrderStatus(customerOrder: CustomerOrder) {
		const previousOrderStatusSequence = this.getPreviousOrderStatusSequence(customerOrder);
	    const newOrderStatusSequence = this.getNewOrderStatusSequence(customerOrder);
		
	   if(newOrderStatusSequence < previousOrderStatusSequence) {
	     this.notifier.notify("The selection you made is not allowed, please click the correct selection!", 'error');
		 this.resetStatus(customerOrder);
		 
		 return;
	   }

        this.displayMessage = false;
        this.displayErrorMessage = false;
        let index = 0;
      
		this.displayMessage = true;
        this.message = 'Data has been modified. Remember to save when done!!';
        if (this.changeCount === 0) {
            this.notifier.notify(this.message);
        }

        this.changeCount = this.changeCount + 1;
	    this.displaySaveButton = true;
        this.changedStatusTrackers.push(customerOrder);
    }

  open(content, businessName: any, businessContactNumber: string) {
    
        this.specialInstructions = '';
        this.businessName = businessName;
        this.contactNo = businessContactNumber;

        this.modalReference = this.modalService.open(content, {
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );

    }
	
	  /**
     * Uploads the updated order item statuses
     */
    updateStatuses() {
        this.displayMessage = false;
        this.displayErrorMessage = false;
        this.ordersService.updateOrdersStatuses(this.changedStatusTrackers).subscribe(() => {
            this.displayMessage = true;

            this.displaySaveButton = false;
            this.message = 'Record(s) updated successfully!!';
            this.notifier.notify(this.message);

        }, error1 => {
            this.message = 'Error updating records. Please try again later!!';
            this.displayErrorMessage = true;
            this.notifier.notify(this.message, 'error');

        });

    }

	hasLogo(customerOrder: CustomerOrderItem): boolean {
        return customerOrder.product.defaultImageUrl !== undefined;
    }


    navigate(path: string) {

	   	 sessionStorage.setItem('navigationOutlet', 'adminclerkdashboard');

        this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}], {skipLocationChange: true}).then();
    }

    manageOrders() {
		sessionStorage.setItem('selectedCustomerOrder', undefined);
		
		 this.navigate('admin-manage-orders');

    }

}
