<div class="container-fluid">

    <div class="row">
        <div class="min-vh-100 col-3 bg-primary  text-white">
            <div class="row bg-primary">
                <div class="col-12 text-white mt-2">
                    <span class="d-none d-md-inline">My Account</span>
                </div>
                <hr class="bg-info shadow">

            </div>
            <br/>
            <span class="col-12 my-2">
                <i class="fa fa-shopping-cart fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Orders</span>
            </span>
            <p class="col-12  ml-4 text-white mt-2" routerLink="/shopperdashboard">
                <i class="fa fa-shopping-cart fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline clickableanchor">&nbsp;&nbsp;My Orders</span>
            </p>
            <p class="col-12  ml-4 ml-2" [routerLink]="['/shopperdashboard', {outlets: {'shopperdashboard': ['shopper-invoices']}}]">
                <i class="fa fa-file-invoice-dollar fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline clickableanchor">&nbsp;&nbsp; Invoices</span>
            </p>
             <p class="col-12  ml-4 ml-2" [routerLink]="['/shopperdashboard', {outlets: {'shopperdashboard': ['manage-returns']}}]">
                <i class="fa fa-exchange-alt  fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline clickableanchor">&nbsp;&nbsp; Returns</span>
            </p>
            <hr class="bg-danger shadow">
            <span class="col-12 my-2" >
                <i class="fa fa-money-bill fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Payments and Credits</span>
            </span>
            <hr class="bg-success shadow">
            <span class="col-12 my-2 d-none d-lg-block">
                <i class="fa fa-user fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline">&nbsp;&nbsp;Customer Information</span>
            </span>

            <p class="col-12  ml-4 text-white mt-2" [routerLink]="['/shopperdashboard', {outlets: {'shopperdashboard': ['profile']}}]">
                <i class="fa fa-id-card fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline clickableanchor">&nbsp;&nbsp;Personal Details</span>
            </p>
            <p class="col-12  ml-4 ml-2" [routerLink]="['/shopperdashboard', {outlets: {'shopperdashboard': ['shopper-addresses']}}]">
                <i class="fa fa-address-book fa-1x" aria-hidden="true"></i>
                <span class="d-none d-md-inline clickableanchor">&nbsp;&nbsp;Address Book</span>
            </p>

        </div>
         <div class="col-9 mb-3 mx-0 px-1">
            <div class="container-fluid min-vh-100 overflow-hidden p-1">
            <router-outlet name="shopperdashboard" class="container-fluid px-0"></router-outlet>
        </div>
        </div>
    </div>
</div>
