import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../shared/services/profile.service';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

    loading: boolean;

    email: string;
    // old password
    oldPassword: string;
    // new password
    pwd: string;
    confirmPwd: string;

    displayResendActivationLink: boolean;


    constructor(private profileService: ProfileService, private router: Router,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        if (this.isLoggedIn()) {
            const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
            this.email = userDetails.email;
            return;
        }

    }


    isLoggedIn(): boolean {
        if (sessionStorage.getItem('userDetails')) {
            return true;
        }
        return false;
    }


    resetPassword() {

        this.displayResendActivationLink = false;

        const userDetails = {
            email: this.email,
            password: this.oldPassword,
            newPassword: this.pwd,
            confirmationPassword: this.pwd,
        };
        this.loading = true;
        this.profileService.resetPassword(userDetails).subscribe((value: HttpResponse<any>) => {

            // this.router.navigate(['/register-success']);
            this.notifier.notify('Password reset successfully!! ');
            this.loading = false;

        }, error1 => {
            console.log(error1);

            if (parseInt(error1.status, 0) === 423) {
                // account has not been activated
                this.notifier.notify('Password reset failed. Account not yet activated! ' +
                    'Please click Re-send Activation Email button below.', 'error');
                this.displayResendActivationLink = true;
            } else if (parseInt(error1.status, 0) === 417) {
                this.notifier.notify('Email account does not exist!!', 'error');

            } else if (parseInt(error1.status, 0) === 409) {
                this.notifier.notify('Current password is incorrect. Please try again!!', 'error');
            } else {
                this.notifier.notify('Password reset failed. Please try again later!', 'error');
            }
            this.loading = false;

        });

    }

    // resend activation link for a dormant account
    resendActivationLink() {

        this.displayResendActivationLink = false;

        const userDetails = {
            email: this.email,
            password: this.oldPassword,
            newPassword: this.pwd,
            confirmationPassword: this.pwd,
        };

        this.loading = true;
        this.profileService.resendActivationLink(userDetails).subscribe((value: HttpResponse<any>) => {
            this.notifier.notify('Email with instruction to activate account has been sent!!', 'info');
            this.loading = false;

        }, error1 => {

            if (parseInt(error1.status, 0) === 409) {
                this.notifier.notify('Email account is not registered.!!', 'error');
            } else {
                this.notifier.notify('Email re-send was unsuccessful. Please try again later!', 'error');
            }
            this.loading = false;
        });

    }


}
