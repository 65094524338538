import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProductHistory} from '../model/ProductHistory';
import {HistoryPayload} from '../model/HistoryPayload';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {

    constructor(private http: HttpClient) {
    }

    addHistory(historyPayload: HistoryPayload) {
        return this.http.post(environment.apiAdminPrefix  + '/product/history/add',
            historyPayload, {
                observe: 'response',
            });
    }

    updateHistory(historyPayload: HistoryPayload) {
        return this.http.post(environment.apiAdminPrefix  + '/product/history/update',
            historyPayload, {
                observe: 'response',
            });
    }

    getHistory(productId: any): Observable<ProductHistory> | Observable<any> {
        return this.http.get(environment.apiPrefix  + '/product/history/' + productId,
            {
                observe: 'response',
            });
    }
}
