<myafrica-breadcrumbs [title]="pageTitle"></myafrica-breadcrumbs>


<div class="container my-5">
	<div class="row shadow">

		<div class="container mb-2">
			<!-- Control the column width, and how they should appear on different devices -->
			<div class="row">
				<div class="col-sm=12 col-md-3 col-lg-2">
					<label>For which order?</label>
				</div>
				<div class="col-sm-8 col-md-2 col-lg-2 text-sm-left  text-md-right text-lg-right">
					<label for="orderNo">Order No:</label>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-5 mb-1">
					
					 <input type="text"
						class="form-control" id="orderNo" [(ngModel)]="orderNo"
						name="orderNo" />
				</div>
				<div class="col-sm-12 col-md-3 col-lg-3">
					<button type="button" class="btn btn-success w-100"
						(click)="searchOrder()">
						<i  class="fa fa-search" aria-hidden="true"></i>
						&nbsp;&nbsp;{{ buttonLabel }}
					</button>

				</div>
			</div>
		</div>
	</div>


</div>


<br />

<div class="container p7 mt-1">
	<ngb-tabset class="tabset1" type="pills" #tabs> <ngb-tab
		id="productDetail" title="Return Details"> <ng-template
		ngbTabContent>
	<hr />

	<div class="form-row">
		<div class="form-group col-md-6">
			<label class="control-label">Customer Name</label> <input type="text"
				disabled="true" class="form-control" [(ngModel)]="customerName"
				required>
		</div>
		<div class="form-group col-md-6">
			<label class="control-label">Return No</label> <input type="text"
				disabled="true" class="form-control" [(ngModel)]="refNumber"
				required>
		</div>
	</div>


	<div class="form-row">
		<div class="form-group col-md-4">
			<label for="returnReason">Return Reason</label> <select
				id="returnReason" [(ngModel)]="returnReason" class="form-control">
				<option value="Not Item Ordered" selected>Not Item Ordered</option>
				<option value="Item Quality">Item Quality</option>
				<option value="Item Broken">Item Broken</option>
				<option value="Other">Other</option>
			</select>
		</div>
		<div class="form-group col-md-4">
			<label class="control-label">Contact Number</label> <input (blur)="enableSubmitButton()"
				type="number" class="form-control" min="1" numbersOnly
				[maxlength]="10" [(ngModel)]="customer_cell_no">
		</div>
		<div class="form-group col-md-4 text-center">
			<label class=" control-label"
				title="Customer wants to be paid back or not">Exchange?</label> <input
				type="checkbox" class="form-control pull-left"
				[(ngModel)]="exchange">
		</div>

	</div>




	<div class="form-row">
		<div class="form-group col-md-12">
			<label class="control-label">Description</label>
			<textarea rows="5" class="form-control" (blur)="enableSubmitButton()"
				placeholder="Description of the reason(s) why item(s) are returned"
				title="Description of the reason(s) why item(s) are returned"
				[(ngModel)]="description">
          </textarea>

		</div>
	</div>

	<div class="modal-footer">
		<div class="container">
			<div class="row">
				<div class="col-6">
					<button type="button" (click)="tabs.select('moreInfo')"
						class="btn btn-success">Next</button>
				</div>
				<div class="col-6">
					<button type="button" (click)="goBack()" class="btn btn-danger">Cancel</button>

				</div>
			</div>
		</div>

	</div>

	</ng-template> </ngb-tab> <ngb-tab title="Items Info" id="moreInfo" [disabled]="tabDisabled">
	 <ng-template
		ngbTabContent>
	<hr />

	<div class="container d-none d-md-block bg-primary text-white mb-2">
		<div class="row">
			<div class="col-1 ">
				<p></p>
			</div>
			<div class="col-4 text-white">Product Name</div>
			<div class="col-2 text-white">Quantity</div>
			<div class="col-2 text-white">Total</div>
			<div class="col-3 text-white">Status</div>
			<div class="col-1 text-white"></div>
		</div>
	</div>

	<div class="container d-none d-md-block"
		*ngFor="let itemrow of customerOrder.customerOrderItems; let index = index">
		<div class="row">
			<div class="col-1 div-cell">
				<input type="checkbox" class="div-checkbox"
					(change)="flagOrderItemForReturn(itemrow, itemrow.checked, customerOrder.customerOrderItems)"
					[(ngModel)]="itemrow.checked" />
			</div>
			<div class="col-4 div-cell">
				<img style="height: 60px; width: 60px; margin-top: -6px"
					*ngIf="itemrow.product.defaultImageUrl; else elseBlock"
					class="img-fluid mx-auto" src="{{itemrow.product.defaultImageUrl}}"
					alt="business logo" />
				<ng-template #elseBlock> <img
					src="https://myafricaconnect.com/images/image-not-available.jpg"
					alt="business logo" style="height: 70px; width: 70px"
					class="img-fluid mx-auto" /> </ng-template>
				{{ itemrow.product.productName }}
			</div>
			<div class="col-2 div-cell">{{ itemrow.quantity }}</div>
			<div class="col-2 div-cell">{{ itemrow.itemPrice *
				itemrow.quantity | currency : itemrow.product.currency}}</div>
			<div class="col-3 div-cell" *ngIf="customerOrder.orderStatusDescription; else statusMissing">
				{{customerOrder.orderStatusDescription}}
				</div>
				<ng-template #statusMissing>
			    <div class="col-3 div-cell">
				{{itemrow.orderStatus}}</div>

			</ng-template>
			<div class="col-1 div-cell"></div>
		</div>
	</div>

	<ng-template #displayRadioButtonsDetails let-modal>
	<div class="container p7 mt-1">
		<b> Business: {{businessName}}</b> <br /> <br /> <b> Contact No:
			{{contactNo}}</b> <br />
		<hr />
		<button type="button" data-dismiss="modal" (click)="modal.close('')"
			class="btn btn-danger">Close</button>
	</div>
	</ng-template>


	<div class="container d-block d-md-none"
		*ngFor="let itemrow of customerOrder.customerOrderItems; let index = index">
		<div class="row">
			<div class="col-4 bg-primary text-white"></div>
			<div class="col-8 div-cell">
				<input type="checkbox" class="div-checkbox"
					(change)="flagOrderItemForReturn(itemrow, itemrow.checked, customerOrder.customerOrderItems)"
					[(ngModel)]="itemrow.checked" />
			</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Name</div>

			<div class="col-8">
				<div class="row">
					<div class="col-12">

						<img style="height: 60px; width: 60px; margin-top: -6px"
							*ngIf="itemrow.product.defaultImageUrl; else elseBlock"
							class="img-fluid mx-auto" src="{{itemrow.product.defaultImageUrl}}"
							alt="business logo" />
						<ng-template #elseBlock> <img
							src="https://myafricaconnect.com/images/image-not-available.jpg"
							alt="business logo" style="height: 70px; width: 70px"
							class="img-fluid mx-auto" /> </ng-template>
					</div>
					<div class="col-12">{{ itemrow.product.productName }}</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Qty</div>

			<div class="col-8">{{ itemrow.quantity }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Total</div>

			<div class="col-8 div-cell">{{ itemrow.itemPrice *
				itemrow.quantity | currency : itemrow.product.currency}}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Status</div>

			<div class="col-8" *ngIf="customerOrder.orderStatusDescription; else mobileStatusMissing">
				{{customerOrder.orderStatusDescription}}
				</div>
				<ng-template #mobileStatusMissing>
			    <div class="col-8">
				{{itemrow.orderStatus}}</div>

			</ng-template>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white"></div>

			<div class="col-8"></div>
		</div>
	</div>


	<div class="modal-footer">
		<div class="container">
			<div class="row">
				<div class="col-7">

					<button type="button" class="btn btn-success"
						(click)="addCustomerReturn()" [disabled]="buttonDisabled">
						<i  class="fa fa-plus" aria-hidden="true"></i>
						&nbsp;&nbsp;Create Return
					</button>
				</div>
				<div class="col-5">
					<button type="button" (click)="goBack()" class="btn btn-danger">Cancel</button>

				</div>
			</div>
		</div>
	</div>

	</ng-template> </ngb-tab> </ngb-tabset>
</div>

