import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {first, tap} from 'rxjs/operators';

@Injectable()
export class CanLoadGuard implements CanLoad {

    constructor(private router: Router) {
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.userAuthenticated().pipe(first(), tap(authenticated => {
            if (!authenticated) {
                this.router.navigate(['login']).then();
            }
        }));
    }

    private userAuthenticated(): Observable<boolean> {
        if (sessionStorage.getItem('AuthToken')) {
            return of(true);
        }
        return of(false);
    }
}
