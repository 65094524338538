import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userAuthenticated(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userAuthenticated(state);
    }

    private userAuthenticated(state: RouterStateSnapshot): boolean {
        if (sessionStorage.getItem('AuthToken')) {
            sessionStorage.removeItem('returnUrl');
            return true;
        }

        sessionStorage.setItem('returnUrl', state.url);

        this.router.navigate(['login'], {queryParams: {returnUrl: state.url}}).then();

        return false;
    }
}
