import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '../../shared/services/profile.service';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'myafrica-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {

  activatorCode: string;
  subs: Subscription;

  constructor(private profileService: ProfileService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.activatorCode = this.route.snapshot.paramMap.get('activator');
    this.subs = this.profileService.activateUserAccount(this.activatorCode).subscribe((value: HttpResponse<any>) => {

      this.router.navigate(['/activation/success']);

    }, error1 => {
      this.router.navigate(['/activation/failed']);
    });

  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

}
