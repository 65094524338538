import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'myafrica-shopper-returns',
  templateUrl: './shopper-returns.component.html',
  styleUrls: ['./shopper-returns.component.scss']
})
export class ShopperReturnsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
