<ng-template #content let-modal>

    <div class="row modal-header m-0">
        <h2 class="form-heading">{{business.businessName}}</h2>
    </div>
    <div class="modal-body">
        <form class="form-horizontal style-form" #messageform="ngForm"
              name="messageform">
            <div class="row">
                <div class="col-3"><label class="control-label">Facebook</label></div>
                <div class="col-9 txt-container--break-long-word">
                    <a class="anchor"
                       target="_blank" href="{{business.businessDetails.businessFacebookURL}}">
                        <label *ngIf="business.businessDetails">{{business.businessDetails.businessFacebookURL}}</label>
                    </a></div>
            </div>
            <div class="row">

                <div class="col-3"><label class="control-label">Twitter</label></div>
                <div class="col-9 txt-container--break-long-word">
                    <a class="anchor"
                       target="_blank" href="{{business.businessDetails.businessTwitterURL}}">
                        <label *ngIf="business.businessDetails">{{business.businessDetails.businessTwitterURL}}</label>
                    </a></div>
            </div>
            <div class="row">
                <div class="col-3"><label class="control-label">About Business</label></div>
                <div class="col-9"><label *ngIf="business.businessDetails">{{business.businessDetails.aboutBusiness}}</label></div>
            </div>
            <button type="button" (click)="modal.close('Save click')"
                    class="btn btn-danger">Cancel</button>
        </form>
    </div>
</ng-template>

<div style="border-radius: 0.35rem" class=" text-center btn btn-about-theme-orange" (click)="open(content)"
     data-target="#myModal">About Business</div>

