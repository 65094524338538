import { Component, OnInit } from '@angular/core';
import {CustomerOrderReturn} from '../../../model/CustomerOrderReturn';
import {OrdersServiceService} from '../../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {Utils} from '../../../shared/Utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerOrder} from '../../../model/CustomerOrder';
import {faSearch, faSave, faPlus} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../../services/notifier.service';

@Component({
  selector: 'myafrica-add-customer-return',
  templateUrl: './add-customer-return.component.html',
  styleUrls: ['./add-customer-return.component.scss']
})
export class AddCustomerReturnComponent implements OnInit {

    searchIcon = faSearch;
    plusIcon = faPlus;
    saveIcon = faSave;
    pageTitle = 'Add Order Return';

    orderNo: string;
    refNumber: string; // ticket ref no
    customerName: string;
    description: string;
    customer_cell_no: string;
	moneyBack: boolean;
	exchange: boolean;
    returnReason = 'Not Item Ordered';
	 businessName: string;
    contactNo: string;

    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    displayDeleteMessage: string;
    displayDeleteErrorMessage: boolean;
    displayRefNumber: boolean;


    subs1: Subscription;
    subs2: Subscription;

    rowsControls = [];
    customerOrderReturns: CustomerOrderReturn[];

    customerOrders: CustomerOrder[];

    customerOrder: CustomerOrder;

    customerOrderReturn: CustomerOrderReturn;
	savedCustomerOrderReturn: CustomerOrderReturn;
    selecteItems = [];
	customerOrderReturnItems: any[];
    buttonDisabled: boolean;
	tabDisabled: boolean;
    closeResult: string;


  constructor(private router: Router, private ordersService: OrdersServiceService, private appStore: ApplicationStore,
              private modalService: NgbModal, private notifier: NotifierService) { }

  ngOnInit() {
	   this.customerOrderReturn = new CustomerOrderReturn();
	   this.moneyBack = false;
	   this.exchange = true;
	   this.buttonDisabled = true;
	   this.tabDisabled = true;

  }


  searchOrder() {
      this.displayMessage = '';
      this.displayErrorMessage = false;
  	  const navigationOutlet = sessionStorage.getItem('navigationOutlet') + '';
	  if (navigationOutlet === 'shopperdashboard') {
		 this.searchByOrderNumberByProfile();
	  } else {
		this.searchByOrderNumber();
	  
	  }


    }
	
	searchByOrderNumber() {
		   this.subs2 = this.ordersService.getOrdersByOrderNo(this.orderNo).subscribe((value: CustomerOrder[]) => {

          this.customerOrders = value;
		  
		  this.setCustomerOrderDetails();


        }, error1 => {
          console.log(error1);
          // this.orderNotProcessCount = "0";
        });

	
	}
	
	searchByOrderNumberByProfile() {
		
		  this.subs2 = this.ordersService.getOrdersByOrderNoByProfile(this.appStore.getJsonItem('profileId'), this.orderNo).subscribe((value: CustomerOrder[]) => {

          this.customerOrders = value;
          
          this.setCustomerOrderDetails();

        }, error1 => {
          console.log(error1);
          // this.orderNotProcessCount = "0";
        });

	
	}
	
	setCustomerOrderDetails() {
		if(this.customerOrders.length != 0) {
			if (this.customerOrders[0].customer_surname === null ||
					  this.customerOrders[0].customer_surname === '' ||
					  this.customerOrders[0].customer_surname === undefined) {
				  this.customerName = this.customerOrders[0].customer_name;

			  } else {
				   this.customerName = this.customerOrders[0].customer_name + ' ' + this.customerOrders[0].customer_surname;
			  }
			  if (this.customerOrders[0].customer_cell_no != null) {
			   this.customer_cell_no = this.customerOrders[0].customer_cell_no;
			  }

			  this.customerOrder = this.customerOrders[0];
			  this.customerOrders.forEach(row => {
				this.rowsControls.push({
				  isCollapsed: true
				});
			  });  
		} else {
			this.notifier.notify("Order not found. Please try a different number!!", 'error');

		}
	
	}
	

	flagOrderItemForReturn(itemrow: any, moneyBackChecked: boolean, customerOrderItems: any) {
		let index = 0;

		customerOrderItems.forEach(row => {
			// remove item from existing array to not have duplicates
			if (itemrow.orderItemId === row.orderItemId) {
				this.selecteItems.splice(index, 1);
			}
			index++;
			});

		if (moneyBackChecked)	 {
			this.selecteItems.push(itemrow.orderItemId);
		}
		this.enableSubmitButton();

	}
	
	enableButtons() {
		if ((this.customerName === undefined || this.customerName === '') || 
		     (this.description === undefined || this.description === null || this.description === '') ||
			 (this.customer_cell_no === undefined || this.customer_cell_no === null  || this.customer_cell_no === '' )) {
			this.tabDisabled = true;
			this.buttonDisabled = true;
		} else {
			this.tabDisabled = false;	
			this.buttonDisabled =  false;			
		}
	
	}
	
	enableSubmitButton() {
	
	this.enableButtons();

	if (this.selecteItems.length == 0) {
			this.buttonDisabled = true;
		} else {
			if (this.description === undefined || this.description === '') {
			 this.buttonDisabled	= true;
			} else {
			 this.buttonDisabled	= false;
			}
		}

	}

	addCustomerReturn() {
		let index = 0;
		this.buttonDisabled = true;
		this.displayRefNumber = false;
		this.selecteItems.forEach(row => {
			if (index === 0) {
			this.customerOrderReturn.flaggedItems = '' + row;

			} else {
			 this.customerOrderReturn.flaggedItems = this.customerOrderReturn.flaggedItems + ',' + row;
			}
			index++;
		});

		this.customerOrderReturn.description = this.description;
		this.customerOrderReturn.moneyBack = this.moneyBack;
		this.customerOrderReturn.exchange = this.exchange;
		this.customerOrderReturn.returnReason = this.returnReason;
		this.customerOrderReturn.customer_cell_no = this.customer_cell_no;
		this.customerOrderReturn.customerOrder   = this.customerOrder.orderId;
		this.customerOrderReturn.customerOrderReturnItems = this.customerOrderReturnItems;
	
		this.subs1 = this.ordersService.generateOrderReturn(this.customerOrderReturn, Number(this.appStore.getJsonItem('profileId'))).subscribe(value => {

		this.displayMessage = 'Return successfuly created!! CUSTOMER REFERENCE NO: ' + value.body.refNumber;
	    this.notifier.notify(this.displayMessage, 'success');

			// this.displaySuccessMessage = true;
		this.refNumber = value.body.refNumber;
		this.buttonDisabled = true;
		 // this.refNumber = value.refNumber;

      }, error1 => {
		   if (parseInt(error1.status) === 412) {
		        this.notifier.notify('Return has already been created for  order: ' + this.customerOrder.orderId + '. Only one return can be generated per order!!', 'error');
		   } else {
		   this.notifier.notify('Error creating customer return! Please try again later!!', 'error');
		   this.buttonDisabled = false;
		  }
      });
	}

  goBack() {

	   this.navigate('manage-returns');

    }

     navigate(path: string) {
	  const navigationOutlet  = sessionStorage.getItem('navigationOutlet') + '';
		 if (navigationOutlet === 'shopperdashboard') {
		    this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: [path]}}], {skipLocationChange: true}).then();
		 } else if (navigationOutlet === 'adminclerkdashboard') {
		    this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: [path]}}], {skipLocationChange: true}).then();
		} else {
		  this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();

		}
    }


   open(content, customerOrder: any) {
	this.businessName = customerOrder.productBusiness.businessName;
	this.contactNo = customerOrder.productBusiness.businessContactNumber;

	this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }
    );
  }

}
