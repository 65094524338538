<myafrica-breadcrumbs title="Manage Orders"></myafrica-breadcrumbs>

<div class="container my-1">
	<div class="row shadow">
		<div class="container">

	    <!-- Control the column width, and how they should appear on different devices -->
   <div class="row">
				<div class="col-md-4">

					<label for="orderNo" title="Order Number">Order No:</label> <input
						type="text" class="form-control" [(ngModel)]="orderNo"
						id="orderNo" />
				</div>

				<div class="col-md-4">
					<label for="startDate"
						title="Filters the orders according to the date ordered e.g. Last 15 days=Orders created in the last 15 days">From:</label>
					<input type="date" id="startDate"
						[ngModel]="startDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="startDate = $event">`
				</div>
				<div class="col-md-4">

					<label for="endDate">To</label> <input type="date" id="endDate"
						[ngModel]="endDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="endDate = $event">`


				</div>

			</div>



	   <div class="row">
				<div class="form-group col-md-4"></div>

				<div class="form-group col-md-4">
					<button type="button" class="btn btn-success w-100"
						id="search1Button" (keyup.enter)="searchOrders()"
						(click)="searchOrders()">
						<i  class="fa fa-search " aria-hidden="true"></i>&nbsp;&nbsp;{{ buttonLabel }}
					</button>

				</div>

				<div class="form-group col-md-4"></div>
			</div>

</div>
</div>
</div>
<br />

<div class="container" >
	<div class="row">
	<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 pull-right">


		</div>
		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-info text-dark" *ngIf="displayMessage">
		{{message}}
		</div>
		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 alert alert-danger text-white"  *ngIf="displayErrorMessage">
		{{message}}</div>


		<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">

			<button  type="button" *ngIf="displaySaveButton" class="btn btn-success"
				(keyup.enter)="updateStatuses()" (click)="updateStatuses()">
				<i  class="fa fa-save " aria-hidden="true"></i>&nbsp;&nbsp;Save
			</button>
		</div>


	</div>
	<br />
</div>

<div class="container d-none d-md-block">
<table class="table table-bordered table-striped">
		<thead class="thead-dark">
			<tr>
				<th>Order No</th>
				<th>Date</th>
				<th>Total</th>
				<th>Status</th>
				<th>Invoice(s)</th>
			</tr>
		</thead>
		<tbody>

		<tr *ngFor="let row of rows; let index = index">
				<td class="mw-20 text-blue" style="cursor: pointer"
					(click)="editOrder(row)"><u
					class="text-anchor" style="color: blue">{{row.orderId}}</u></td>

				<td class="mw-15">{{row.created_Date | date: dateFormat}}</td>
				<td class="mw-20">{{row.orderAmount | currency : 'ZAR'}}</td>
				<td class="mw-35"><select
					[(ngModel)]="row.orderStatusDescription" id="dropdown{{index}}"
					[disabled]="row.orderStatusDescription == 'Items delivered'"
					(click)="setCurrentStatus(row.orderStatusDescription)" (ngModelChange)="changeOrderStatus(row)" style="width: 132px">
					 <option *ngFor="let orderstatus of orderStatuses" 
					[disabled]="orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
					|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled'"
                	[ngStyle]="{'background-color': orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
                	|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'white':'green', 'color': orderstatus.disabled 
                	|| orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence || 
                	(orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'black':'white'}">{{orderstatus.description}}</option>

				</select></td>
				<td class="mw-10"> <img
					title="Re-print invoice(s)" class="invoice-icon"
					(click)="regenerateOrderInvoicesReport(row)"
					*ngIf="row.customerOrderInvoices.length > 0"
					src="https://myafricaconnect.com/images/greeninvoice.png"></td>

			</tr>

		</tbody>

	</table>
</div>

<div class="container rounded  d-block d-md-none"
	*ngFor="let row of rows; let index = index">
	<div class="row">
		<div class="col-6 bg-primary text-white">Order No:</div>
		<div class="mx-auto" style="cursor: pointer"
			(click)="editOrder(row)">
			<u class="text-anchor" style="color: blue">{{row.orderId}}</u>
		</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white">Date:</div>
		<div class="mx-auto">{{row.created_Date | date: dateFormat}}</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white">Total1:</div>
		<div class="mx-auto">{{row.orderAmount | currency : 'ZAR'}}</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white">Status:</div>
		<div class="col-6 mx-auto">
			<select
					[(ngModel)]="row.orderStatusDescription" id="dropdown{{index}}"
					[disabled]="row.orderStatusDescription == 'Items delivered'"
					(click)="setCurrentStatus(row.orderStatusDescription)" (ngModelChange)="changeOrderStatus(row)" style="width: 132px">
					 <option *ngFor="let orderstatus of orderStatuses" 
					[disabled]="orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
					|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled'"
                	[ngStyle]="{'background-color': orderstatus.disabled || orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence 
                	|| (orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'white':'green', 'color': orderstatus.disabled 
                	|| orderstatus.orderStatusSequence < row.orderStatusResource.orderStatusSequence || 
                	(orderstatus.orderStatusSequence > (row.orderStatusResource.orderStatusSequence + 1))
					&& orderstatus.description !== 'Order cancelled' ? 'black':'white'}">{{orderstatus.description}}</option>

				</select>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-6 bg-primary text-white"
			*ngIf="row.customerOrderInvoices.length <= 0">Invoice(s):</div>
		<div class="col-6 bg-primary text-white"
			*ngIf="row.customerOrderInvoices.length > 0">Invoice(s):</div>
		<div class="mx-auto">

			 <img
				title="Re-print invoice(s)" class="center-block img-responsive"
				(click)="regenerateOrderInvoicesReport(row)"
				*ngIf="row.customerOrderInvoices.length > 0"
				src="https://myafricaconnect.com/images/greeninvoice.png">

		</div>
	</div>
</div>
<!-- /.container -->



<ng-template #invoiceModal let-modal>

<div class="row modal-header m-0">
	<h2 class="form-heading">Print Invoice</h2>
</div>
<div class="modal-body">
	<form class="form-horizontal style-form" #messageform="ngForm"
		name="messageform">
		<div class="row">
			<div class="col-1">
				<label class="control-label"></label>
			</div>
			<div class="col-11">
				<textarea class="form-control input-lg" maxlength="255" required
					placeholder="Special Instructions"
					[(ngModel)]="specialInstructions" name="specialInstructions"
					rows="6" required></textarea>
			</div>
		</div>

		<div class="row">
		<div class="col-2 pull-right"></div>
		<div class="col-4 " class="success-message-bold "
			*ngIf="displayPrintMessage">{{message}}
			</div>
		<div class="col-4 " *ngIf="displayPrintErrorMessage"
			class="error-message-bold">{{message}}
			</div>

		<div class="col-2 pull-right"></div>
		</div>
		<br />
		<div class="row">
		<div class="col-6">

		</div>
		<div class="col-6">
		<button type="submit" [disabled]="displayPrintMessage" *ngIf="displayPrint" (click)="generateOrderInvoicesReport(invoiceModal)"
				class="btn btn-success mr-2">Print</button>
					<button type="submit" [disabled]="displayPrintMessage" *ngIf="!displayPrint" (click)="generateOrderInvoicesReport(invoiceModal)"
				class="btn btn-succcess mr-2">Re-Print</button>
			<button type="button" (click)="modal.close('Save click')"
				class="btn btn-danger mr-2">Cancel</button>
		</div>
		</div>
	</form>
</div>
</ng-template>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


