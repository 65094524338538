import {Component, OnInit} from '@angular/core';
import {Business} from '../model/Business';
import {Product} from '../model/Product';
import {PaginationProduct} from '../model/PaginationProduct';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationStoreService} from '../services/application-store.service';
import {BusinessService} from '../services/business.service';
import {HttpResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';

@Component({
    selector: 'myafrica-business-details',
    templateUrl: './business-details.component.html',
    styleUrls: ['./business-details.component.scss'],
})
export class BusinessDetailsComponent implements OnInit {

    selectedBusiness: Business;
    today = new Date();

    itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];

    entryLimitCount = 12; // entries per page
    displayEntryLimitCount = 12; // entries per page
    currentPage = 1;
    totalProducts = 0;
    allProducts = [];
    loading: boolean;
    displayRangeLimit: number;

    searchProductName: string;
    searchProductCategory: string;
    searchedProducts: Product[] = [];


    constructor(private modalService: NgbModal,
                private appStore: ApplicationStoreService,
                private businessService: BusinessService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.searchProductName = 'all';
        this.searchProductCategory = 'all';
        this.loading = true;
        this.route.data.pipe(map(data => data.selectedBusiness)).subscribe(result => {
            this.selectedBusiness = result;
        });
        this.getDefaultBusinessProducts();
    }


    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    getDefaultBusinessProducts() {
        this.currentPage = this.currentPage - 1;

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.businessId]).subscribe((value: HttpResponse<PaginationProduct[]>) => {
                this.searchedProducts = value.body[0].products;
                this.totalProducts = value.body[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    setNumberOfItemsperPage() {
        this.currentPage = 1;
        this.displayEntryLimitCount = this.entryLimitCount;
        this.getDefaultBusinessProducts();
    }

    previous() {

        this.loading = true;
        this.currentPage = this.currentPage - 1;

        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.businessId]).subscribe((value: PaginationProduct[]) => {

                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    next() {

        this.loading = true;
        this.currentPage = this.currentPage + 1;
        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.businessId]).subscribe((value: PaginationProduct[]) => {
                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    hasLogo(business: Business): boolean {
        return business.logoUrl !== undefined;
    }

    getUrl(url: string): string {
        return 'url('.concat(url).concat(')');
    }
}
