import {Component, OnInit} from '@angular/core';
import {LoginResponse} from '../../model/LoginResponse';
import {UserAddress} from '../../model/UserAddress';
import {ProfileService} from '../../shared/services/profile.service';
import {NotifierService} from '../../services/notifier.service';
import {UserDetails} from '../../model/UserDetails';
import {HttpResponse} from '@angular/common/http';
import {ApplicationStoreService} from '../../services/application-store.service';
import {Province} from '../../model/Province';
import {TableService} from '../../services/table.service';
import {Router} from '@angular/router';

@Component({
  selector: 'myafrica-profile-address',
  templateUrl: './profile-address.component.html',
  styleUrls: ['./profile-address.component.scss']
})
export class ProfileAddressComponent implements OnInit {

    loginResponse: LoginResponse;
    ownerAddress: UserAddress;
    url: string | ArrayBuffer;
    loading: boolean;
    selectedFile: File;
	userName: string;

	provinces: Province[];
    province: any;
	userDetails: UserDetails;

    constructor(private profileService: ProfileService,
                private notifier: NotifierService,
				            private appStore: ApplicationStoreService,
				            private router: Router,
				            private tableService: TableService) {
    }

    ngOnInit() {
        this.ownerAddress = new UserAddress();
        this.loginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));
		this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
	
	   	this.userName = this.userDetails.userName;
		 
        if (sessionStorage.getItem('selectedAddress') === "" || JSON.parse(sessionStorage.getItem('selectedAddress')) === null || JSON.parse(sessionStorage.getItem('selectedAddress')) === undefined) {
			 this.ownerAddress = new UserAddress();
		} else {
			this.ownerAddress =  JSON.parse(sessionStorage.getItem('selectedAddress'));
		}

		this.provinces = JSON.parse(sessionStorage.getItem('provinces'));
		this.provinces.forEach(element => {
			if (element.name.toLowerCase() === 'GAUTENG'.toLowerCase()) {
				this.province = element.id;
				this.defaultProvince();
			}
		});
       

    }

	defaultProvince() {
		if (this.ownerAddress !== null || this.ownerAddress !== undefined) {
			if (this.ownerAddress.province) {
			 this.province = JSON.parse(sessionStorage.getItem('userDetails')).address.province.id;
			}
		}

	}

	updateUserDetails() {
		 const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

		 userDetails.userName = this.userName;

	  sessionStorage.setItem('userDetails', JSON.stringify(userDetails));

	}
	 navigate(path: string) {
        const navigationOutlet = sessionStorage.getItem('navigationOutlet') + '';
        if (navigationOutlet === 'shopperdashboard') {
	         this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: ['/shopperdashboard']}}]).then();
   		} else if (navigationOutlet === 'adminclerkdashboard') {
            this.router.navigate(['adminclerkdashboard', {outlets: {adminclerkdashboard: ['/adminclerkdashboard']}}]).then();
        } else {
            this.router.navigate(['dashboard', {outlets: {dashboard: ['/dashboard']}}]).then();

        }
    }


    updateProfile() {
      const userDetails = this.appStore.getJsonItem('userDetails');
      const profileId = sessionStorage.getItem('profileId');
	  this.ownerAddress.provinceId = this.province;
 	
	    this.loading = true;
		this.profileService.updateUserAddress(this.ownerAddress, this.province).subscribe((response: HttpResponse<UserDetails>) => {
               this.loading = false;
			   sessionStorage.setItem('userDetails', JSON.stringify(response.body));
			   this.notifier.notify('Address details updated successfully!!', 'success');
			   this.updateUserDetails();

			   this.router.navigate(['shopperdashboard', {outlets: {shopperdashboard: ['shopper-addresses']}}]).then();

			}, error1 => {
	            this.loading = false;
				this.notifier.notify('Error updating address details!!.', 'error');
        });
    }

    setProfilePic() {
        this.loading = true;
        this.profileService.addProfilePicture(this.selectedFile, this.userDetails.id)
            .subscribe((res: { rel: string, href: string }) => {
                this.loading = false;
                this.url = null;
                this.loginResponse.profileImage = res.href;
                sessionStorage.setItem('loginResponse', JSON.stringify(this.loginResponse));
                this.notifier.notify('Profile picture updated successfully!!');
            }, () => {
                this.notifier.notifyError('Failed to update profile Pic, please try again later');
                this.url = null;
                this.loading = false;
            });
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.selectedFile = event.target.files[0];
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            };
        }
    }
}
