import {Component, Input, OnInit} from '@angular/core';
import {Business} from '../../../model/Business';

@Component({
    selector: 'myafrica-business-logo-edit',
    templateUrl: './business-logo-edit.component.html',
    styleUrls: ['./business-logo-edit.component.scss'],
})
export class BusinessLogoEditComponent implements OnInit {

    @Input() selectedBusiness: Business;

    constructor() {
    }

    ngOnInit() {
    }

    hasLogo(business: Business): boolean {
        return business.logoUrl !== undefined;
    }

}
