import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';


@Component({
    selector: 'myafrica-activation-success',
    templateUrl: './activation-success.component.html',
    styleUrls: ['./activation-success.component.scss']
})
export class ActivationSuccessComponent implements OnInit {

     id: any;

    constructor(private router: Router) { }

    ngOnInit() {
        this.id  = setInterval(a => {
            // navigate to the home page after 5 seconds
            this.router.navigate(['/login']);
        }, 5000, []);

    }

    /**
     * Clears the interval so it stops executing
     */
    ngOnDestroy() {
        if (this.id) {
            clearInterval(this.id);
        }
}

}
