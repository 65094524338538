import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Business} from '../../../model/Business';
import {Product} from '../../../model/Product';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';
import {Subscription} from 'rxjs';
import {Utils} from '../../../shared/Utils';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {ClothingGender} from '../../../model/ClothingGender';
import {ClothingGroup} from '../../../model/ClothingGroup';
import {ClothingCategory} from '../../../model/ClothingCategory';
import {BusinessCategory} from '../../../model/BusinessCategory';
import {Ng2ImgMaxService} from 'ng2-img-max';
import {PaginationProduct} from '../../../model/PaginationProduct';
import {ClothingSizeCode} from '../../../model/ClothingSizeCode';
import {faArrowRight, faInfoCircle, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../../services/notifier.service';
import {AppIdleService} from '../../../shared/services/app-idle.service';


@Component({
    selector: 'myafrica-business-products',
    templateUrl: './business-products.component.html',
    styleUrls: ['./business-products.component.scss'],
})

export class BusinessProductsComponent implements OnInit, OnDestroy {

    @ViewChild('productImage') logo: ElementRef;

    itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];

    currentJustify = 'Justify';
    closeResult: string;
    product: Product;

    selectedFile: File;
    url = null;

    label = 'Products';
    icon = 'fa fa-cog fa-3x fa-spin';

    buttonLabel = 'ADD Product now';
    buttonIcon = 'fa fa-plus';
    selectedBusiness: Business;

    searchedProducts: Product[] = [];

    unitsInStock: number;
    currency = 'ZAR';
    productCategory: string;
    businessId: string;
    productName: string;
    size: string;
    price: number;
    productDescription: string;
	productLength: number;
	productHeight: number; 
	productBreadth: number;
	productMass: number;

    calculatedServiceFee = 0;
    calculatedMarkUpFee = 0;
    calculatedEftFee = 0;


    serviceFee = 0;
    markUpFee = 0;
    courierFee = 0;
    eftFee = 0;
    totalProductFee = 0;
    adminFee = 0;
    productFees: string;

    previousUrl = null;
    imageWidth: number;
    imageHeight: number;
    searchProductName: string;
    searchProductCategory: string;

    subs1: Subscription;
    subs2: Subscription;
    subs3: Subscription;
    subs4: Subscription;
    subs5: Subscription;
    subs6: Subscription;
    subs7: Subscription;

    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    displayDeleteMessage: string;
    displayDeleteErrorMessage: boolean;
    displayDeleteSuccessMessage: boolean;
    categoriesMap: Map<string, string>;
    activeModal;

    clothingGenders: ClothingGender[];
    clothingGroups: ClothingGroup[];
    clothingCategories: ClothingCategory[];
    displayGenders: boolean;
    displayGroups: boolean;
    displaySubCategory: boolean;

    genderId: number;
    groupId: number;
    subCategoryId: number;

    @ViewChild('addProduct', {static: false}) addProduct: ElementRef;
    @ViewChild('clothingProduct', {static: false}) addClothing: ElementRef;

    loading: boolean;

    businessCategories: BusinessCategory[];
    uploadedImage: Blob;

    entryLimitCount = 12; // entries per page
    displayEntryLimitCount = 12; // entries per page
    currentPage = 1;
    totalProducts = 0;
    allProducts = [];

    displayRangeLimit: number;

    sizesElements: { name, count }[];
    clothingSizeCodes: ClothingSizeCode[];
    multiColourProduct = false;
    disableColorPicker = false;
    color: string;
    buttonDisabled = false;
    displayColourTab = false;
    imgResultAfterCompress: string;
    imgResultBeforeCompress: string;
    sizeOfOriginalImage: number;
    sizeOFCompressedImage: number;

    iconSearch = faSearch;
    iconPlus = faPlus;
    iconArrowRight = faArrowRight;
    iconInfoCircle = faInfoCircle;

    constructor(private modalService: NgbModal,
                private tableService: CodeTablesServiceService,
                private businessService: BusinessServiceService,
                private router: Router, private ng2ImgMax: Ng2ImgMaxService,
                private appStore: ApplicationStore, private notifier: NotifierService,
				private idleAppService: AppIdleService) {
    }

    ngOnInit() {

        this.searchProductName = 'all';
		
	    this.searchProductCategory = 'all';
        this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
        this.businessId = this.selectedBusiness.id;
		
        // display the genders dropdown ifbusiness category  clothing
	    this.displayGenders = true;
        const userDetails = sessionStorage.getItem('userDetails');
        const profileId = sessionStorage.getItem('profileId');


        this.setProductFees();

        this.setBusinessCategories();


        this.sizesElements = [];
        this.sizesElements.push({name: 'All', count: 0});

        this.getClothingSizeCodes();


        this.getDefaultBusinessProducts();

    }

    setBusinessCategories() {
        const businessCategoriesStored = JSON.parse(sessionStorage.getItem('businessCategories'));

        if (businessCategoriesStored === undefined || businessCategoriesStored === null) {
            this.subs3 = this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
                    this.businessCategories = value;

                    sessionStorage.setItem('businessCategories', JSON.stringify(this.businessCategories));
                    this.populateCategories();
                }, error => {
                },
            );
        } else {
            this.businessCategories = businessCategoriesStored;
            this.populateCategories();
        }
    }


    setProductFees() {
        const productFeesStored = JSON.parse(sessionStorage.getItem('productFees'));
        if (productFeesStored === undefined || productFeesStored == null) {
            this.subs2 = this.tableService.getProductFees().subscribe((value: any) => {
                    this.productFees = value;
                    sessionStorage.setItem('productFees', JSON.stringify(this.productFees));

                    this.setFeeVariables(this.productFees);
                },
            );

        } else {
            this.setFeeVariables(productFeesStored);
        }

    }


    setFeeVariables(productFees: any) {
        this.serviceFee = productFees.serviceFee;
        this.markUpFee = productFees.markUpFee;
        this.courierFee = productFees.courierFee;
        this.adminFee = productFees.serviceFee;
        this.eftFee = productFees.eftFee;
    }

    getClothingSizeCodes() {

        this.subs1 = this.tableService.getClothingSizeCodes().subscribe((value: ClothingSizeCode[]) => {
                this.clothingSizeCodes = value;
            },
        );
    }


    adMoreElement() {
        this.sizesElements.push({name: 'All', count: 0});

    }


    displayGroupsDropDown(genderId: number) {
        this.displayGroups = true;

        this.clothingGenders.forEach(element => {
            if (parseInt(element.id) == genderId) {
                this.clothingGroups = element.clothingGroups;
            }
        });

    }


    displaySubCategoryDropDown(groupId: number) {
        this.displaySubCategory = true;

        this.clothingGroups.forEach(element => {
            if (parseInt(element.id) == groupId) {
                this.clothingCategories = element.clothingCategories;

            }
        });

    }


    getDefaultBusinessProducts() {
        this.currentPage = this.currentPage - 1;
		this.subs1 = this.businessService.getPaginationBusinessProducts(this.entryLimitCount, this.currentPage,
            null, this.selectedBusiness.id).subscribe((value: any) => {
        	
		     	this.searchedProducts = value.content;
		        this.totalProducts = value.totalElements;
    		      this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, error => {
	            this.loading = false;
            },
        );
    }

    previous() {

        this.loading = true;
        this.currentPage = this.currentPage - 1;

        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.subs3 = this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.id]).subscribe((value: PaginationProduct[]) => {

                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    setNumberOfItemsperPage() {
        this.currentPage = 1;
        this.displayEntryLimitCount = this.entryLimitCount;
        this.getDefaultBusinessProducts();
    }

    next() {

        this.loading = true;
        this.currentPage = this.currentPage + 1;
        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.subs4 = this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.id]).subscribe((value: PaginationProduct[]) => {
                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }


    calculateProductPrice() {
        const productPrice = this.price;
        this.calculatedServiceFee = parseFloat((productPrice * (this.serviceFee / 100)).toFixed(2));

        this.totalProductFee = productPrice + this.calculatedServiceFee;

        this.calculatedMarkUpFee = parseFloat((this.totalProductFee * (this.markUpFee / 100)).toFixed(2));


        this.totalProductFee = this.totalProductFee + this.calculatedMarkUpFee;


        if (this.price === 0 || this.price === null || isNaN(this.price)) {
            this.totalProductFee = 0;
            //    this.adminFee = 0;
        }

        this.calculatedEftFee = parseFloat((this.totalProductFee * (this.eftFee / 100)).toFixed(2));

        this.totalProductFee = parseFloat((this.calculatedEftFee + this.totalProductFee).toFixed(2));

    }

    getProductsByName() {
        this.currentPage = 0;
        this.subs4 = this.businessService.getPaginationBusinessProductsByName(
            this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.id]).subscribe((value: any[]) => {
                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    setProductsByCategory(key: string, description?: string) {
        this.searchProductCategory = key;
        this.searchProductName = description ? description : '';
    }


    deleteProduct(businessId: string, productId: number) {
        this.subs5 = this.businessService.deleteProduct(businessId, productId).subscribe(() => {
            this.getDefaultBusinessProducts();
            this.displayDeleteErrorMessage = false;
            this.displayDeleteSuccessMessage = true;
            this.displayDeleteMessage = 'Product deleted successfully!!';
            this.notifier.notify(this.displayDeleteMessage, 'success');

            setInterval(a => {
                this.displayDeleteMessage = '';
            }, Utils.getDelayTime(), []);

        }, () => {
            this.displayDeleteErrorMessage = true;
            this.displayDeleteSuccessMessage = false;

            this.displayDeleteMessage = 'Failed to delete product. Please try again later!';
            this.notifier.notify(this.displayDeleteMessage, 'error');

            setInterval(a => {
                this.displayDeleteMessage = '';
            }, Utils.getDelayTime(), []);

        });

    }

    onImageChange(event) {
        this.loading = true;
        this.selectedFile = event.target.files[0];
        this.displayErrorMessage = false;
        this.displayMessage = '';
        const image = event.target.files[0];
        const reader = new FileReader();
        let img = null;
        img = new Image();
		let messagePrefix = "We have encountered a problem resizing the image.";
        this.url = reader.result;
        this.ng2ImgMax.resizeImage(image, 300, 300).subscribe(
            result => {
                this.uploadedImage = result;
                // this.selectedFile = this.uploadedImage;
                reader.readAsDataURL(this.uploadedImage); // read file as data billboardUrl
                reader.onload = () => { // called once readAsDataURL is completed
                    this.url = reader.result;
                    img.src = reader.result; // The data URL

                    img.onload = () => {
                        this.imageWidth = img.width;
                        this.imageHeight = img.height;
                        this.loading = false;
                        if (this.imageWidth < 160) {
                            this.displayErrorMessage = true;
                            this.displayMessage = messagePrefix + ' Required minimum image width is 200px!!!';
        					this.notifier.notify(this.displayMessage, 'error');                        
                            this.selectedFile = undefined;
                            this.url = this.previousUrl;
                            return;
                        }
                    };

                    this.selectedFile = new File([this.uploadedImage], 'untitled.png', {type: 'image/png'});
                };
            },
            error => {
                this.displayErrorMessage = true;
                this.displayMessage = messagePrefix +' Please resize the images. Required minimum image width is 200px!!!';
                this.loading = false;
   				this.notifier.notify(this.displayMessage, 'error');                        
                this.selectedFile = undefined;
                this.url = this.previousUrl;
            },
        );


    }

    editProduct(product: Product) {
        this.appStore.storeStringItem('currentProduct', product);
        this.storePath('edit-product');
        this.router.navigate(['dashboard', {outlets: {dashboard: ['edit-product']}}], {skipLocationChange: true}).then();
        window.scrollTo(0, 0);
    }


    storePath(path: string): void {
        sessionStorage.setItem('dashboard-path', path);
    }

    addProductHistory(product: Product) {
        this.appStore.storeStringItem('currentProduct', product);
        this.navigate('product-history');
        window.scrollTo(0, 0);
    }

    revert() {
        this.url = '';
        this.selectedFile = undefined;
    }

    openModal(content) {
        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }
	
	logout() {
        const cart = this.appStore.getJsonItem('cart');
		
        this.appStore.signOut();
        this.idleAppService.setUserLoggedIn(false);
        sessionStorage.clear();
        if (cart !== null && cart !== undefined) {
            sessionStorage.setItem('cart', JSON.stringify(cart));
        }

        this.router.navigate(['/']).then();
    }


    open() {
	     // clear the business data if it was populated before
        this.selectedBusiness = this.appStore.getJsonItem('selectedBusiness');
        this.product = new Product();
        this.genderId = 0;
        this.groupId = 0;
        this.subCategoryId = 0;

        this.activeModal = this.addProduct;
      
	    this.openModalAddProduct();
		 
    }

	 openModalAddProduct() {	
		this.storePath('edit-business');
		this.router.navigate(['dashboard', {outlets: {dashboard: ['add-product']}}], {skipLocationChange: true}).then();
		window.scrollTo(0, 0);

	 }

    sizesCaptured(): boolean {

        let elementEmpty = false;
        this.sizesElements.forEach((element) => {
            if (element.count === 0) {
                elementEmpty = true;
            }
        });

        if (elementEmpty) {
            this.notifier.notify('Size cannot be empty. Please fill in the size of one of the color(s)!!', 'error');

            return false;
        }


        return true;

    }

    showErrorMessage(errorMessage: string) {
        this.displayErrorMessage = true;
        this.displaySuccessMessage = false;
        this.displayMessage = errorMessage;

    }

    addProductWithImage(modal) {
        const category = this.selectedBusiness.businessCategory.key;
        const unitsInStock = this.unitsInStock;
        const numberOfViews = 0;


        const clothingProduct = this.productCategory == 'clothing' ? true : false;


        if (this.productCategory === '' || this.productCategory === undefined) {
            this.notifier.notify('Please select the product category!!', 'error');
            return;
        }

        if (clothingProduct) {
            if (this.subCategoryId === undefined || this.subCategoryId === 0) {
                this.notifier.notify('Please select the sub product category!!', 'error');

                return;
            }

            if (this.color === undefined || this.color === null) {
                this.notifier.notify('Please select colour for the product!!', 'error');
                return;
            }

        }

        if (this.businessId) {
            this.notifier.notify('Please select the business!!', 'error');
            return;
        }

        if (this.productName === '' || this.productName === undefined) {
            this.notifier.notify('Please fill in the product name!!', 'error');
            return;
        }

        if (this.price === 0 || this.price === undefined) {
            this.notifier.notify('Please enter the product price!!', 'error');
            return;
        }
        
        if (this.product.productMass === undefined || this.product.productMass == 0) {
            this.notifier.notify('Please enter the product mass!!', 'error');
            return;
        }

	    if (this.product.productBreadth === undefined || this.product.productBreadth == 0) {
            this.notifier.notify('Please enter the product breadth!!', 'error');
            return;
        }
	   
		 if (this.product.productHeight === undefined || this.product.productHeight == 0) {
            this.notifier.notify('Please enter the product height!!', 'error');
            return;
        }
	   
		if (this.product.productLength === undefined || this.product.productLength == 0) {
            this.notifier.notify('Please enter the product length!!', 'error');
            return;
        }

        if (this.selectedFile === undefined || this.selectedFile === null) {
            this.notifier.notify('Please select image for the product!!', 'error');
            return;
        }

        const productObject = {
            id: 0,
            productId: 0,
            productDescription: this.productDescription,
            price: this.totalProductFee,
            customerPrice: this.price,
            currency: this.currency,
            productName: this.productName,
            businessId: this.selectedBusiness.id,
            productCategory: category,
            categoryId: this.subCategoryId,
			productLength: this.product.productLength,
			productHeight: this.product.productHeight, 
			productBreadth: this.product.productBreadth,
			productMass: this.product.productMass,
            category,
            numberOfViews,
            unitsInStock,
        };


        this.loading = true;

        if (clothingProduct) {
            this.addProductWithColours(modal, productObject);

        } else {
            this.addProductWithoutColours(modal, productObject);
        }

    }


    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

    addProductWithoutColours(modal, productObject) {

        this.subs6 = this.businessService.addProductWithImage(
            this.selectedFile, this.businessId + '', JSON.stringify(productObject)).subscribe(() => {
            // refresh the products  list
            this.getDefaultBusinessProducts();

            this.loading = false;

            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Product added successfully!!';
            this.notifier.notify(this.displayMessage, 'success');

            Utils.delay(5000).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.navigate('edit-business');
                });


            });
        }, error1 => {
            this.loading = false;

            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.buttonDisabled = false;
            this.displayMessage = 'Failed to add product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');

            Utils.delay(Utils.getDelayTime()).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
            });


        });
    }

    addProductWithColours(modal, productObject) {

        const payLoad = {
            colourName: this.color,
            clothingSizes: this.sizesElements,
        };

        this.subs6 = this.businessService.addProductWithColours(
            this.selectedFile, this.businessId + '', JSON.stringify(productObject), this.selectedFile, JSON.stringify(payLoad)).subscribe(() => {
            this.getDefaultBusinessProducts();

            // refresh the products  list
            this.loading = false;

            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.displayMessage = 'Product added successfully!!';
            this.notifier.notify(this.displayMessage, 'success');

            Utils.delay(5000).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
                this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                    this.router.navigate(['business-details']).then();
                });


            });
        }, error1 => {
            this.loading = false;

            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.buttonDisabled = false;
            this.displayMessage = 'Failed to add product. Please try again later!';
            this.notifier.notify(this.displayMessage, 'error');
            Utils.delay(Utils.getDelayTime()).then(() => {
                this.clearValues();
                modal.close('');
                this.loading = false;
            });


        });
    }

    clearValues() {
        this.url = null;
        this.displayErrorMessage = false;
        this.displaySuccessMessage = false;
        this.displayMessage = '';

    }

    uploadPic() {
        this.subs7 = this.businessService.uploadFile(this.selectedFile, this.selectedBusiness.id).subscribe(() => {
            alert('File uploaded successfully!');
        }, () => {
            alert('Failed to upload logo. Please try again later!');
        });
    }

	compareFn( optionOne, optionTwo ) : boolean {
	  return optionOne.id === optionTwo.id;
	}

    populateCategories() {
        this.categoriesMap = new Map<string, string>();
        this.categoriesMap.set('all', 'All');


        this.businessCategories.forEach(element => {
            this.categoriesMap.set(element.key, element.description);
        });
    }


    setMultiColourProduct() {
        if (this.multiColourProduct) {
            this.disableColorPicker = true;
            this.color = '#999999A';
        } else {
            this.color = '';
            this.disableColorPicker = false;

        }
    }

    getMapKeys(): Array<string> {
        return Array.from(this.categoriesMap.keys());
    }

    doUnsubs(subs: Subscription) {
        if (subs) {
            subs.unsubscribe();
        }
    }

    getProductImage(product: Product): string | object | undefined {
        return product.defaultImageUrl;
    }

    ngOnDestroy() {
        this.doUnsubs(this.subs1);
        this.doUnsubs(this.subs2);
        this.doUnsubs(this.subs3);
        this.doUnsubs(this.subs4);
        this.doUnsubs(this.subs5);
        this.doUnsubs(this.subs6);
        this.doUnsubs(this.subs7);
    }

}
