import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../model/Product';

@Component({
    selector: 'myafrica-product-price',
    templateUrl: './product-price.component.html',
    styleUrls: ['./product-price.component.scss'],
})
export class ProductPriceComponent implements OnInit {

    @Input() product: Product;

    constructor() {
    }

    ngOnInit() {
    }

}
