import {Component, OnInit} from '@angular/core';
import {ApplicationStore} from '../interceptors/application-store.service';
import {Product} from '../model/Product';
import $ from 'jquery';

@Component({
    selector: 'myafrica-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {

    searchResults: { products: Product[] }[];

    activeCarousels = [];
    showNavigationArrows = true;
    showNavigationIndicators = true;
    pauseOnHover = true;

    constructor(private appStore: ApplicationStore) {
        this.activeCarousels = [];
    }

    ngOnInit() {
        $.getScript('assets/js/menuscroll.js');

        this.searchResults = this.appStore.getJsonItem('search-result');
    }

}
