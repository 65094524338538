import {Component, OnInit} from '@angular/core';
import {Product} from '../../../model/Product';
import {Business} from '../../../model/Business';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {CategoryModel} from '../../../model/CategoryModel';
import {BusinessCategory} from '../../../model/BusinessCategory';
import {ClothingGender} from '../../../model/ClothingGender';
import {ClothingGroup} from '../../../model/ClothingGroup';
import {ClothingCategory} from '../../../model/ClothingCategory';
import {NotifierService} from '../../../services/notifier.service';
import {Subscription} from 'rxjs';
import {AppIdleService} from '../../../shared/services/app-idle.service';
import {HomeService} from '../../../services/home.service';
import {CategoryModelRevamped} from '../../../model/CategoryModelRevamped';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'myafrica-product-details-edit',
    templateUrl: './product-details-edit.component.html',
    styleUrls: ['./product-details-edit.component.scss'],
})
export class ProductDetailsEditComponent implements OnInit {

    items: Array<CategoryModel>;

    selectedBusiness: Business;

    selectedProduct: Product;

    businesses: Business[];

    productId: string;
    unitsInStock: number;
    currency = 'ZAR';
    productCategory: string;
    businessId: string;
    productName: string;
    price: number;
    productDescription: string;
	productLength: number;
	productHeight: number; 
	productBreadth: number;
	productMass: number;

    calculatedServiceFee = 0;
    calculatedMarkUpFee = 0;
    calculatedEftFee = 0;

    serviceFee = 0;
    markUpFee = 0;
    courierFee = 0;
    totalProductFee = 0;
    customerPrice = 0;
    eftFee = 0;
    adminFee = 0;
    productFees: string;

    displayMessage: string;
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;

    loading = false;

    private businessCategories: BusinessCategory[];
    searchProductCategoryMap: Map<string, string>;

    clothingGenders: ClothingGender[];
    clothingGroups: ClothingGroup[];
    clothingCategories: ClothingCategory[];
    displayGenders: boolean;
    displayGroups: boolean;
    displaySubCategory: boolean;

    genderId: number;
    groupId: number;
    subCategoryId: number;
    
     subs1: Subscription;
	categories: any[];
	rootCategory: any[];
	subCategories: CategoryModelRevamped[];
	categoryModelRevamped: CategoryModelRevamped;
	subCategoryModelRevamped: CategoryModelRevamped;
	subSubCategoryModelRevamped: CategoryModelRevamped;
	subSubCategories: CategoryModelRevamped[];


    constructor(private tableService: CodeTablesServiceService,
                private businessService: BusinessServiceService,
                private location: Location,	private homeService: HomeService,
                private router: Router, private idleAppService: AppIdleService,
                private appStore: ApplicationStore, private notifier: NotifierService) {
    }

    ngOnInit() {

		
      this.homeService.getCategories().subscribe((value: any) => {
				this.rootCategory = value.body[Object.keys(value.body)[0]];
				this.categories = Object.values(this.rootCategory)[2];
			     this.setSelectedProductData();
		},
		);
        this.selectedProduct = JSON.parse(sessionStorage.getItem('currentProduct'));
	    this.currency = this.selectedProduct.currency;


        const userDetails: { userDetailsId: any } = this.appStore.getJsonItem('userDetails');
        const profileId = sessionStorage.getItem('profileId');

        this.businessService.getBusinessesByProfileId(profileId).subscribe((value: Business[]) => {
                this.businesses = value;
            },
        );

        this.selectedBusiness = JSON.parse(sessionStorage.getItem('currentProduct')).productBusiness;
	
        this.businessId = this.selectedBusiness.id;
	   


        this.tableService.getProductFees().subscribe((value: any) => {
				
                this.productFees = value;
				const productFeesObject = JSON.parse(JSON.stringify(this.productFees[0]));
 				
                this.serviceFee = productFeesObject.serviceFee;
                this.markUpFee = productFeesObject.markUpFee;
                this.courierFee = productFeesObject.courierFee;
                this.adminFee = productFeesObject.serviceFee;
                this.eftFee = productFeesObject.eftFee;
                this.calculateProductPrice();
            },
        );


    }

	setSelectedProductData() {
		
		 if (this.selectedProduct.productName) {
			this.productId = this.selectedProduct.id;
			this.unitsInStock = this.selectedProduct.unitsInStock;
			this.productName = this.selectedProduct.productName;
			this.productDescription = this.selectedProduct.productDescription;
			this.currency = this.selectedProduct.currency;
			this.productCategory = this.selectedProduct.productCategory;
			this.totalProductFee = this.selectedProduct.price;
			this.price = parseFloat(this.selectedProduct.customerPrice);
			this.productLength = this.selectedProduct.productLength;
			this.productHeight = this.selectedProduct.productHeight; 
			this.productBreadth = this.selectedProduct.productBreadth;
			this.productMass = this.selectedProduct.productMass;

			this.categoryModelRevamped = this.selectedProduct.category.parent.parent;
			this.categories.forEach(categoryElement => {
                    if (categoryElement.id === this.categoryModelRevamped.id) {
			            this.displayGendersDropdown(categoryElement);
                        return;
                    }
            });

			this.subCategoryModelRevamped = this.selectedProduct.category.parent;
			this.subCategories.forEach(categoryElement => {
                    if (categoryElement.id === this.subCategoryModelRevamped.id) {
			            this.displayGroupsDropDown(categoryElement);
                        return;
                    }
             });
			this.subSubCategoryModelRevamped = this.selectedProduct.category;
		 }
	}

	logout() {
        const cart = this.appStore.getJsonItem('cart');
		
        this.appStore.signOut();
        this.idleAppService.setUserLoggedIn(false);
        sessionStorage.clear();
        if (cart !== null && cart !== undefined) {
            sessionStorage.setItem('cart', JSON.stringify(cart));
        }

        this.router.navigate(['/']).then();
    }

    setData() {
        let found = false;

        this.clothingGenders.forEach(element => {
            if (!found) {
                this.genderId = parseInt(element.id, 0);
            }
            element.clothingGroups.forEach(groupElement => {
                if (!found) {
                    this.groupId = parseInt(groupElement.id, 0);
                }
                groupElement.clothingCategories.forEach(categoryElement => {
                    if (this.subCategoryId === parseInt(categoryElement.id, 0)) {
                        found = true;
                        return;
                    }

                });

            });

        });

    }

    // displays the gneder, group and sub category dropdowns
    setDefaultGendersData() {
        this.displayGenders = true;
    }

	compareFn( optionOne, optionTwo ) : boolean {
	  return optionOne.id === optionTwo.id;
	}

    
    displayGendersDropdown(categoryModelRevamped: CategoryModelRevamped) {
            this.displayGenders = true;
		
			this.subCategories = categoryModelRevamped.childCategories;
			this.subSubCategories = null;
			this.subCategoryModelRevamped = null;
	}

    displayGroupsDropDown(suCategoryModelRevamped: CategoryModelRevamped) {
	    this.displayGroups = true;
		this.subSubCategories = suCategoryModelRevamped.childCategories;
    }


    displaySubCategoryDropDown(groupId: number) {
        this.displaySubCategory = true;
	    this.clothingGroups.forEach(element => {
            if (parseInt(element.id, 0) == groupId) {
                this.clothingCategories = element.clothingCategories;
				return;
    
            }
        });	
    }

    populateData() {
        this.items = [];
        this.businessCategories.forEach(element => {
            this.items.push(new CategoryModel(element.key, element.description));
        });

        this.searchProductCategoryMap = new Map<string, string>();
        this.searchProductCategoryMap.set('all', 'All');
        this.items.forEach(element => {
            this.searchProductCategoryMap.set(element.key, element.value);

        });

    }


    calculateProductPrice() {
        const productPrice = this.price;
        this.calculatedServiceFee = parseFloat((productPrice * (this.serviceFee / 100)).toFixed(2));

        this.totalProductFee = productPrice + this.calculatedServiceFee;

        this.calculatedMarkUpFee = parseFloat((this.totalProductFee * (this.markUpFee / 100)).toFixed(2));


        this.totalProductFee = this.totalProductFee + this.calculatedMarkUpFee;

        if (this.price === 0 || this.price === null || isNaN(this.price)) {
            this.totalProductFee = 0;
            //    this.adminFee = 0;
        }

        this.calculatedEftFee = parseFloat((this.totalProductFee * (this.eftFee / 100)).toFixed(2));

        this.totalProductFee = parseFloat((this.calculatedEftFee + this.totalProductFee).toFixed(2));


    }

    goBack() {
        this.loading = true;
        this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
            this.loading = false;
            this.navigate('edit-product');
        });

    }

    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

    updateProduct() {

	if(this.subSubCategoryModelRevamped) {} else {
			if (this.subSubCategoryModelRevamped === undefined) {
				this.notifier.notify('Please select the product category!!', 'error');
				return;
			}
		}
       
        if (this.productName === '' || this.productName === undefined) {
            this.notifier.notify('Please fill in the product name!!', 'error');
            return;
        }

        if (this.price === 0 || this.price === undefined) {
            this.notifier.notify('Please enter the product price!!', 'error');
            return;
        }
      
        if (this.selectedProduct.productMass === undefined || this.productMass == 0) {
            this.notifier.notify('Please enter the product mass!!', 'error');
            return;
        }
	    if (this.selectedProduct.productBreadth === undefined || this.productBreadth == 0) {
            this.notifier.notify('Please enter the product breadth!!', 'error');
            return;
        }
	   
		 if (this.selectedProduct.productHeight === undefined || this.productHeight == 0) {
            this.notifier.notify('Please enter the product height!!', 'error');
            return;
        }
	   
		if (this.selectedProduct.productLength === undefined || this.productLength == 0) {
            this.notifier.notify('Please enter the product length!!', 'error');
            return;
        }
	
    
		const productObject = {
			id: this.selectedProduct.id,
            productDescription: this.productDescription,
            price: this.totalProductFee,
            customerPrice: this.price,
            currency: this.currency,
            productName: this.productName,
            businessId: this.businessId,
            productCategory: this.productCategory,
            categoryId: this.subSubCategoryModelRevamped.id,
			productLength: this.selectedProduct.productLength,
			productHeight: this.selectedProduct.productHeight, 
			productBreadth: this.selectedProduct.productBreadth,
			productMass: this.selectedProduct.productMass,
			unitsInStock: this.unitsInStock,
			productStatus: this.selectedProduct.productStatus,
			uniqueId: this.selectedProduct.uniqueId,
			productCode: this.selectedProduct.productCode,
			defaultImageUrl: this.selectedProduct.defaultImageUrl
			
        };
        this.loading = true;
        this.businessService.updateProduct(this.selectedBusiness.id, productObject).subscribe((value: HttpResponse<Product>) => {
		  	sessionStorage.setItem('currentProduct', JSON.stringify(value.body));
			this.displayMessage = 'Product updated successfully!!!';
            this.displayErrorMessage = false;
            this.displaySuccessMessage = true;
            this.loading = false;
            this.notifier.notify(this.displayMessage, 'success');

        }, () => {
            this.displayMessage = 'Failed to update product. Please try again later!';
            this.displayErrorMessage = true;
            this.displaySuccessMessage = false;
            this.loading = false;
            this.notifier.notify(this.displayMessage, 'error');
        });


    }

}
