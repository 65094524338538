import {Component, OnInit} from '@angular/core';
import {LoginResponse} from '../../model/LoginResponse';
import {Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet} from 'ng2-charts';
import {ChartOptions, ChartType} from 'chart.js';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {CustomerOrder} from '../../model/CustomerOrder';

@Component({
    selector: 'myafrica-dashboard-home',
    templateUrl: './dashboard-home.component.html',
    styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent implements OnInit {

    loginResponse: LoginResponse;

    // Pie
    public chartOptions: ChartOptions = {
        responsive: true,
    };
    public pieChartLabels: Label[] = [];
    public pieChartData: SingleDataSet = [];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];


    constructor(private router: Router,
                private route: ActivatedRoute,
                private orderService: OrdersServiceService) {
        monkeyPatchChartJsTooltip();
        monkeyPatchChartJsLegend();
    }

    ngOnInit() {
        this.route.data.pipe(map(data => data.loginResponse))
            .subscribe((loginResponse: LoginResponse) => {
                this.loginResponse = loginResponse;

                this.orderService.getOrdersByProfile(
                    this.loginResponse.profileId.toString(),
                    'outstanding', 10)
                    .subscribe((orders: CustomerOrder[]) => {
                        orders.forEach(order => {
                            order.customerOrderItems.forEach(orderItem => {
                                loginResponse.moniesDue += orderItem.product.customerPrice;
                            });
                        });
                    });
                this.loginResponse.products.forEach(prod => {
                    if (prod.numberOfViews === 0) {
                        prod.numberOfViews = 1;
                    }
                    this.pieChartLabels.push(prod.productName);
                    this.pieChartData.push(prod.numberOfViews);
                });
            });
    }

    navigate(path: string) {
        if (path) {
            this.storePath(path);
            this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
        }
    }

    storePath(path: string): void {
        sessionStorage.setItem('dashboard-path', path);
    }

}
