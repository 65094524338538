<div class="row">
	<br /> <br /> <br />
</div>
<div class="container">

	<div id="login-page" class="col-12">
		    <div class="jumbotron text-center shadow">
      
			<h2 class="display-4 text-primary">Congratulations!! Activation
				Successful</h2>

		</div>
		<div class="container col-12 text-center">

			<h5>You will be re-directed to the login page in a short while.</h5>
		</div>
	</div>
</div>
<br />
<br />
<br />
<br />