<div class="form-row">
		<div class="form-group col-md-6">
			<label class="control-label"> Mass(kg)</label> <input type="number"
				placeholder="Max Mass: 70KG" class="form-control"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				[(ngModel)]="product.productMass" maxlength="20">
		</div>
		<div class="form-group col-md-6">
			<label class="control-label"> Breadth(cm)</label> <input
				type="number" placeholder="Max Width: 150cm"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				class="form-control" [(ngModel)]="product.productBreadth" maxlength="20">
		</div>

	</div>
	<div class="form-row">
		<div class="form-group col-md-6">
			<label class="control-label"> Height(cm)</label> <input type="number"
				placeholder="Max Height: 150cm" class="form-control"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				[(ngModel)]="product.productHeight" maxlength="20">
		</div>
		<div class="form-group col-md-6">
			<label class="control-label">Length(cm)</label> <input type="number"
				placeholder="Max Length: 150cm" class="form-control"
				oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
				[(ngModel)]="product.productLength" maxlength="20">
		</div>

	</div>