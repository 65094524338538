import { Component, OnInit } from '@angular/core';
import {faBars, faBuilding, faPlus, faArrowRight, faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'myafrica-register-option',
  templateUrl: './register-option.component.html',
  styleUrls: ['./register-option.component.scss']
})
export class RegisterOptionComponent implements OnInit {

  loading: boolean;

  building = faBuilding;
  plusIcon = faPlus;
  iconArrowRight = faArrowRight;
  shoppingCart = faShoppingCart;

  constructor(private router: Router) { }

  ngOnInit(): void {

  }



    register(userType: string): void {
           sessionStorage.setItem('user-type', userType);

		         this.router.navigate(['/user-registration']).then();
    }


}
