import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class NotifierService {

    constructor() {
    }

    notify(message: string, type?: any, position?: any, toaster?: boolean) {
        Swal.fire({
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 6000,
            icon: type ? type : 'success',
            html: '<span class="p-1 m-auto">' + message + '</span>',
            position: position ? position : 'top',
            toast: toaster ? toaster : true,
        }).then();
    }

    notifyWithExtendedTimer(message: string, type?: any, position?: any) {
        Swal.fire({
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 6000,
            icon: type ? type : 'success',
            html: '<span class="p-1 m-auto">' + message + '</span>',
            position: position ? position : 'top',
            toast: true,
        }).then();
    }


    notifyError(message: string, type?: any, position?: any) {
        Swal.fire({
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 6000,
            icon: type ? type : 'error',
            html: '<span class="p-1 m-auto">' + message + '</span>',
            position: position ? position : 'center',
            toast: false,
        }).then();
    }

    confirm(titleText: string): boolean {
        Swal.fire({
            title: titleText,
            showDenyButton: true,
            confirmButtonText: `Continue`,
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                return true;
            } else if (result.isDenied) {
                return false;
            }
        });
        return false;
    }

    info(titleText: string): void {
        Swal.fire({
            showDenyButton: false,
            html: '<div style="padding: 5px; margin: auto;">' +
                '<img class="img-fluid mx-auto p-1" src="https://www.myafricaconnect.com/images/proudly-african.png">' +
                '<p class="py-1 h2">' + titleText + '</p>' +
                '</div>',
            confirmButtonColor: '#3d3356',
            confirmButtonText: `OK`,
        }).then();
    }
}
