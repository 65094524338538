<myafrica-breadcrumbs title="Profile"></myafrica-breadcrumbs>

<ng-template #noProfileImage>
    <div class="p-1 rounded-circle border border-warning p-1 m-auto" style="width: 50px; height: 50px;">
        <a class="fa fa-user-alt fa-2x"></a>
    </div>
</ng-template>
<div class="container my-5">
    <div class="row">

    </div>
    <div class="row shadow-lg p-2 rounded">
<!--        <span class="p-1 rounded-circle border border-warning p-1 m-auto text-center" style="width: 50px; height: 50px;"-->
<!--             *ngIf="mouseOver">-->
<!--&lt;!&ndash;            <i class="fa fa-camera-retro fa-2x text-primary m-auto cursor-pointer position-absolute"></i>&ndash;&gt;-->
<!--            <a class="fa fa-camera fa-2x position-absolute" data-toggle="modal" data-target="#exampleModalCenter"></a>-->
<!--        </span>-->

        <span class="container-fluid text-center my-3 cursor-pointer" data-toggle="modal" data-target="#exampleModalCenter">
            <img *ngIf="loginResponse?.profileImage else noProfileImage"
                 [src]="loginResponse.profileImage" width="150"
                 height="150" alt="profileImage" class="rounded-circle">
        </span>

        <div class="container mx-0 px-1">
            <div class="col-12 p-0 m--0">
                <form ngForm #userDetailsForm="ngForm">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Email</label>
                            <input type="email" class="form-control" id="inputEmail4" name="email" disabled
                                   [(ngModel)]="userDetails.email">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">User name</label>
                            <input type="text" class="form-control" id="inputPassword4"
                                   [(ngModel)]="userDetails.userName" name="userName" required>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="firstname">First Name *</label>
                            <input type="text" class="form-control form-control-sm" id="firstname"
                                   [(ngModel)]="userDetails.firstName" name="firstname" required #firstName="ngModel"
                                   [ngClass]="{'is-invalid':firstName.touched && firstName.invalid
                                   ,'is-valid':firstName.touched && firstName.valid}">
                            <div class="text-danger" *ngIf="firstName.touched && firstName.invalid">First name is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label for="lastname">Last Name *</label>
                            <input type="text" class="form-control form-control-sm" id="lastname"
                                   [(ngModel)]="userDetails.lastName" name="lastname" required #lastName="ngModel"
                                   [ngClass]="{'is-invalid':lastName.touched && lastName.invalid
                                   ,'is-valid':lastName.touched && lastName.valid}">
                            <div class="text-danger" *ngIf="lastName.touched && lastName.invalid">Last name is required
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label for="cellphoneNumber">Cellphone Number *</label>
                            <input type="text" numbersOnly [maxlength]="10" class="form-control form-control-sm"
                                   id="cellphoneNumber"
                                   [(ngModel)]="userDetails.cellphoneNumber" name="cellphoneNumber" required
                                   #cellphoneNumber="ngModel"
                                   [ngClass]="{'is-invalid':cellphoneNumber.touched && cellphoneNumber.invalid
                                   ,'is-valid':cellphoneNumber.touched && cellphoneNumber.valid}">

                            <div class="text-danger" *ngIf="cellphoneNumber.touched && cellphoneNumber.invalid">
                                Cellphone number is required
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <div class="container mb-5">
            <div class="col-12">
                <button class="btn btn-success float-right" type="submit" (click)="updateProfile()"
                        [disabled]="userDetailsForm.form.invalid">Update
                    Profile
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title display-4 text-primary" id="exampleModalLongTitle">Set Profile Pic</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <img [src]="url" height="200" class="img-fluid mx-auto"> <br/>
                <input type='file' class="pt-3" (change)="onSelectFile($event)">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="setProfilePic()" data-dismiss="modal">Update
                    Pic
                </button>
            </div>
        </div>
    </div>
</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
