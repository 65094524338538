<myafrica-breadcrumbs title="Returns"></myafrica-breadcrumbs>

<div class="container my-5">

	<div class="row shadow">

		<div class="container">
			<!-- Control the column width, and how they should appear on different devices -->
			<div class="row mb-2">
				<div class="col-sm-6" >
						<label for="returnNo" title="Return No">Return No:</label>
						<input type="text" [(ngModel)]="returnNo" id="returnNo"
							class="form-control" name="returnNo" />

				</div>
				<div class="col-sm-6">
						<label for="orderNo" class="search-label" title="Order no">Order No:</label>
						<input type="text" class="search-input" [(ngModel)]="orderNo"
							class="form-control formfields" name="orderNo"  id="orderNo"/>
				</div>
			</div>
		</div>
		<div class="container">
			<!-- Control the column width, and how they should appear on different devices -->
			<div class="row mb-2">
				<div class="col-sm-6">
					<label for="startDate"
						title="Filters the orders according to the date ordered e.g. Last 15 days=Orders created in the last 15 days">From:</label>
					<input type="date" id="startDate"
						[ngModel]="startDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="startDate = $event">`

				</div>
				<div class="col-sm-6" >
						<label for="endDate">To</label> <input type="date" id="endDate"
						[ngModel]="endDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="endDate = $event">`

				</div>
			</div>
		</div>

		<div class="container-fluid mb-2">
			<!-- Control the column width, and how they should appear on different devices -->
			<div class="row">
				<div class="col-sm-12 text-center">

					<button type="button" class="btn btn-success w-50"
						(click)="searchOrder()">
						<i  class="fa fa-search" aria-hidden="true"></i>
						&nbsp;&nbsp;{{ buttonLabel }}
					</button>
				</div>
			</div>

		</div>
	</div>

</div>

<div class="container">
	<div class="row">
		<div class="col-6 " class="success-message-bold "
			*ngIf="displayMessage">{{message}}</div>
		<div class="col-6" *ngIf="displayErrorMessage"
			class="error-message-bold">{{message}}</div>

		<div class="col-2 d-none d-md-block">

			<button type="button" *ngIf="displaySaveButton"
				class="btn btn btn-success" (keyup.enter)="updateStatuses()"
				(click)="updateStatuses()">
				<i  class="fa fa-save" aria-hidden="true"></i>
				&nbsp;Save
			</button>
		</div>

		<div class="col-4 pull-right  d-none d-md-block">

			<button type="button" class="btn btn-success" (click)="open('add-shopper-return')">
				<i  class="fa fa-plus" aria-hidden="true"></i>
				&nbsp;&nbsp;Create Return
			</button>
		</div>


	</div>

</div>
<div class="container d-md-none d-lg-none" style="margin-bottom: 1em"
	*ngIf="displaySaveButton">
	<div class="row">

		<div class="col-12 text-center">

			<button type="button" *ngIf="displaySaveButton" class="btn btn-theme"
				(keyup.enter)="updateStatuses()" (click)="updateStatuses()">
				<i  class="fa fa-save" aria-hidden="true"></i>&nbsp;&nbsp;Save
			</button>
		</div>
	</div>
</div>

<div class="container">
	<div class="row">

		<div class="col-12 text-center d-md-none d-lg-none">

			<button type="button" class="btn btn-success" (click)="open('add-shopper-return')">
				<i  class="fa fa-plus" aria-hidden="true"></i>
				&nbsp;&nbsp;Create Return
			</button>
		</div>
	</div>
</div>
<br />
<div class="container d-none d-md-block">
	<table class="table table-bordered table-striped">
		<thead class="thead-dark">
			<tr>
				<th>Return No</th>
				<th>Order No</th>
				<th>Date Created</th>
				<th>Return Status</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of customerOrderReturns; let index = index">
				<td class="mw-20 text-blue" style="cursor: pointer"
					(click)="displayRow(row)"><u
					class="text-anchor" style="color: blue">{{row.refNumber}}</u></td>

				<td class="mw-15">{{row.customerOrder}}</td>
				<td class="mw-20">{{row.created_Date | date :dateFormat}}</td>
				<td class="mw-50">
					{{row.returnStatusDescription}}
				</td>
				<td class="mw-5 text-center">
					<button type="button" class="btn btn-success w-80"
						title="Print order" (click)="printReport(row)">
						<i class="fa fa-file"></i>&nbsp;Print Return
					</button>


				</td>

			</tr>
		</tbody>
	</table>

</div>


	<div class="container d-md-none d-lg-none mb-1"
			*ngFor="let row  of customerOrderReturns;">
			<div class="row">
			<div class="col-4 bg-primary text-white">Return</div>

			<div class="col-8 text-blue"><span class="mw-20 text-blue" style="cursor: pointer"
					(click)="displayRow(row)"><u><h6>{{row.refNumber}}</h6></u></span></div>
			</div>
			<div class="row">
			<div class="col-4 bg-primary text-white">Order</div>
			<div class="col-8">{{row.customerOrder}}</div>
			</div>
			<div class="row">
			<div class="col-4 bg-primary text-white">Created</div>
			<div class="col-8">{{row.created_Date | date :dateFormat}}</div>
				</div>

			<div class="row">
			<div class="col-4 bg-primary text-white"> Status</div>
			<div class="col-8">
				{{row.returnStatusDescription}}
				</div>
			</div>
			
			<div class="row">
			<div class="col-4 bg-primary text-white"> </div>
			<div class="col-8">
				<button type="button" class="btn btn-success w-80"
						title="Print order" (click)="printReport(row)">
						<i class="fa fa-file"></i>&nbsp;Print Return
					</button>
				</div>
			</div>

</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>

