<!-- Image and text -->
<div class="container-fluid m-0 p-3 bg-dark text-white mt-auto mb-0 small position-absolute">
    <div class="elfsight-app-430d2e8f-15c3-4dda-ad46-e3a04e15a3d4"></div>

    <div class="row">
        <div class="col-sm-12 col-md-3">
            <div class="row">
                <div class="col-12">
                    <a class="navbar-brand col-12 ml-0" routerLink="/">
                        <img src="https://myafricaconnect.com/images/color_footer_logo.png" height="200px"
                             class="align-top img-fluid ml0"
                             alt="myafrica-footer-logo">
                    </a>
                    <p class="col-12 h6">Connecting the world to authentic African products and brands.
                    </p>
                </div>
                <div class="container">
                    <div class="row bg-white rounded-bottom rounded-top m-1">
                        <div class="col p-1 my-auto">
                            <a href="https://www.instantssl.com/" id="comodoTL" target="_blank">
                                <img class="img-fluid "
                                     src="https://myafricaconnect.com/images/siteseal-positive-ssl.png"
                                     alt="Essential SSL"></a>
                            <script language="JavaScript" type="text/javascript">
                                TrustLogo("https://myafricaconnect.com/images/ssl_trust_logo.jpeg", "CL1", "none");
                            </script>
                        </div>
                        <div class="col p-1 my-auto">
                            <img class="img-fluid" src="https://myafricaconnect.com/images/instanteft.png">
                        </div>
                        <div class="col p-1 my-auto">
                            <img class="img-fluid" src="https://myafricaconnect.com/images/payfast1.png">
                        </div>
                        <div class="col p-1 my-auto">
                            <img class="img-fluid"
                                 src="https://myafricaconnect.com/images/EFSA-South-Africa-Logofinal.png">
                        </div>
                        <div class="col p-1 my-auto">
                            <img class="img-fluid"
                                 src="https://myafricaconnect.com/images/proudly-sa-tp.png">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col col-sm-12 col-md-9">
            <div class="row">
                <div class="col-sm-12 col-md-2"><h5>
                    SHOP</h5>
                    <ul class="list-inline text-position footer-items">
                        <!--li><a>Weekly deals </a></li-->
                        <!--li><a>Popular Products</a></li-->
                        <li routerLink="/all-products">Shop By Price</li>
                        <li routerLink="/all-businesses">Businesses</li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-2"><h5>
                    COMPANY
                </h5>
                    <ul class="list-inline text-position footer-items">
                        <li routerLink="/">Home</li>
                        <li routerLink="/about-us">About Us</li>
                        <li routerLink="/about-us"><a>Sell on MyAfrica</a></li>
                        <li routerLink="/register-option">Register</li>
                        <!--  li><a>Careers</a></li-->
                        <!--li><a>Site Map</a></li-->
                        <li routerLink="/contact-us"><a>Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-2"><h5>
                    HELP
                </h5>
                    <ul class="list-inline text-position footer-items">
                        <li routerLink="/faq"> FAQ</li>
                        <li routerLink="/terms"><a>Shipping & Delivery</a></li>
                        <li routerLink="/terms"><a>Exchange & Returns</a></li>
                        <li routerLink="/terms">Terms & Conditions</li>
                        <li routerLink="/track-order"><a>Track Your Order</a></li>
                        <li routerLink="/login"><a>My Account</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-5"><h5>COMMUNITY</h5>
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="list-inline text-position footer-items">
                                <span class="px-3"><a class="text-white text-decoration-none"
                                                      href="https://www.facebook.com/Myafricaconnect"
                                                      target="_blank"> <i
                                        class="fab fa-facebook fa-2x"></i></a>
                                </span>
                                <span class="px-3"><a class="text-white text-decoration-none"
                                                      href="https://www.instagram.com/myafricaconnect/?hl=en"
                                                      target="_blank">
                                    <i class="fab fa-instagram fa-2x"></i></a></span>
                                <span class="px-3"><a class="text-white text-decoration-none"
                                                      href="https://twitter.com/miafricaconnect"
                                                      target="_blank"><i class="fab fa-twitter fa-2x"></i></a></span>
                                <li routerLink="/newsletter-signup" class="my-1"><b>NEWSLETTER SIGNUP</b></li>
                            </div>

                            <form class="form-horizontal style-inner-form">
                                <div class="row">
                                    <div *ngIf="displaySuccessMessage"
                                         class="success-message-bold">{{displayMessage}}</div>

                                    <div *ngIf="displayErrorMessage"
                                         class="error-message-bold">{{displayMessage}}</div>

                                    <div class="col-12">
                                        <div class="content d-block">
                                            <div class="input-group">
                                                <input type="email" class="form-control" [(ngModel)]="email"
                                                       name="email"
                                                       placeholder="{{emailDefault}}"
                                                       required
                                                       #emailAddress="ngModel">

                                                <div class="input-group-append">
                                                    <button class="btn input-group-append btn-success"
                                                            [disabled]="emailAddress.invalid"
                                                            type="submit" (click)="subscribeToNewsletter()"
                                                            id="basic-addon2">Subscribe
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>
                        <div class="col-lg-1"></div>
                        <div class="col-lg-1"></div>
                        <!--  div class="col-lg-12">
                          <div class="row">
                            <ul class="list-inline banner-social-buttons">
                              <li><a href="https://twitter.com/miafricaconnect"
                                     target="_blank"> <span class="network-name"><img
                                data-ng-src="img/twitter36x36.png"/></span></a></li>
                              <li><a href="https://www.facebook.com/Myafricaconnect"
                                     target="_blank"> <span class="network-name"><img
                                data-ng-src="img/facebook36x36.png"/></span></a></li>
                            </ul>
                          </div>
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 text-center  text-white">Copyright 2022. All rights reserved by MyAfricaConnect and
        Powered by <a
                href="http://myafricamedia.co.za/" target="_blank" class="text-white">MyAfricaMedia</a>
    </div>
</div>
