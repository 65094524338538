import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {CartItemsService} from '../../services/cart-items.service';
import {CartItem} from '../../model/CartItem';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'myafrica-place-order-success',
    templateUrl: './place-order-success.component.html',
    styleUrls: ['./place-order-success.component.scss'],
})
export class PlaceOrderSuccessComponent implements OnInit, OnDestroy {

    cartItems: Array<CartItem>;

    cartTotal = 0;

    deliveryCost = 250;

    totalCost = 0;

    cartCurrency = 'ZAR';

    orderNo = '';

    bankAccountNumber = '62538670193';
    bankBranch = '254905';
    bankName = 'FNB';

    // indicates whether customer selected to collect items
    customerCollecting: boolean;


    constructor(private router: Router,
                private cartService: CartItemsService,
                private appStore: ApplicationStore,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.modalService.dismissAll();
        this.cartTotal = 0;
        const cart = this.appStore.getJsonItem('cart');
        if (cart === null || cart === undefined) {

            this.router.navigate(['']).then();
            return;
        }

        this.cartItems = cart.map(cartItem => {
            const result = cartItem;
            this.cartTotal = this.cartTotal + (result.product.price * result.quantity);
            return result;
        });


        this.totalCost = this.cartTotal + this.deliveryCost;

        this.orderNo = this.appStore.getItem('ORDER_NO');

        this.appStore.deleteItem('cart');
        this.cartService.updateCount(0);
		this.cartService.clearCart();
    }

    isCustomerCollecting() {
        if (this.appStore.getItem('DELIVERY_METHOD') === 'collect') {
            return true;
        }
        return false;

    }

    goBack() {
        this.appStore.deleteItem('cart');
        this.router.navigate(['']).then();

    }

    ngOnDestroy() {
        this.appStore.deleteItem('cart');
        this.cartService.updateCount(0);
    }
}
