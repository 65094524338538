<div class="container-fluid m-0 p-0">

    <div class="container-fluid px-0 py-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-primary">
                <li class="breadcrumb-item "><a routerLink="/dashboard" class="text-white">Dashboard</a></li>
                <li *ngIf="breadCrumbDisplayManageProducts" class="breadcrumb-item "><a
                        [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-products']}}]"
                        skipLocationChange class="text-white">Manage Products</a></li>
              
                <li *ngIf="!breadCrumbDisplayManageProducts" class="breadcrumb-item "><a
                        [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-businesses']}}]"
                        skipLocationChange class="text-white">Manage Businesses</a></li>
               
                <li *ngIf="selectedBusiness" class="breadcrumb-item "><a
                        (click)="editBusiness()" class="text-white">{{selectedBusiness.businessName}}</a></li>
               
                <li class="breadcrumb-item "><p
                        class="text-white">Add Product</p></li>
                <li class="breadcrumb-item text-success" aria-current="page">{{selectedProduct.productName}}</li>
            </ol>
        </nav>
    </div>


    <div class="row" *ngIf="selectedBusiness">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 text-center text-md-left">
           </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 p-0 text-center text-md-left">
            <h4 class="selectedproduct-name">{{selectedBusiness.businessName}}</h4>

            <div class="caption col-lg-8 col-md-8 col-sm-6 text-center text-md-left">

                   
            </div>
        </div>
    </div>


    <hr/>

    <div class="container-fluid mt-md-5 px-0 mx-0 text-justify">
                <myafrica-add-product-details></myafrica-add-product-details>
    </div>

    <!--MODALS-->
    <ng-template #addColorModal let-modal>
        <myafrica-page-title pageTitle="Product Colour"></myafrica-page-title>

        <div class="container col-12 form-inline modal-body py-2">
            <div class="container p7 mt-1 col-sm-12 col-lg-6">
                <div class="input-group mb-1">
                    <label class="col-sm-12 col-lg-5 control-label">Multi-Colour?</label>
                    <input type="checkbox" name="newsletter" class="form-check-input"
                           style="height: 20px; width: 20px;" [(ngModel)]="multiColourProduct"
                           (change)="setMultiColourProduct()"> <label
                        class="multicolourdiv text-white" style=" width: 110px">
                    Multi-Colour</label> &nbsp; &nbsp; &nbsp; &nbsp; <span
                        class="network-name info-icon" title="" style="margin-left: 5px"
                        (click)="open(displayRadioButtonsDetails)">
                <i class="fa fa-info-circle text-warning" aria-hidden="true"></i>
                </span>

                </div>

                <div class="input-group mb-1" *ngIf="!disableColorPicker">
                    <label class="col-5 control-label">Color</label>
                    <input name="color" [disabled]="disableColorPicker" [(ngModel)]="color" [(colorPicker)]="color"
                           [style.background]="color"/>
                </div>
                <br/>

                <div class="input-group mb-3" *ngFor="let elem of sizesElements">
                    <div>
                        <div class="input-group-text">
                            Size
                        </div>
                    </div>
                    <select class="form-control" name="name" [(ngModel)]="elem.name">
                        <option [value]="item.clothingSize"
                                *ngFor="let item of clothingSizeCodes">{{item.clothingSize}}</option>
                    </select>

                    <input type="number" class="form-control" name="size" [(ngModel)]="elem.count" placeholder="count">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            Items
                        </div>
                    </div>
                </div>


                <div class="input-group mb-3">
                    <button class="btn btn-success" (click)="adMoreElement()">Add
                        More Sizes
                    </button>
                </div>

            </div>
            <div class="container p7 mt-1 col-sm-12 col-lg-6 text-center">

                <div class="container-fluid">
                    <div class="input-group-text my-1 border-info" *ngIf="url else noImage">
                        <img [src]="url" class="m-auto img-fluid">
                    </div>
                </div>
                <div class="container">
                    <input type='file' class="btn-theme-large" (change)="onSelectFile($event)">
                </div>
            </div>
        </div>


        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-lg-2"></div>
                <div class="col-sm-12 col-lg-4 p-2">
                    <button class="btn btn-success btn-block" [disabled]="!url || !color"
                            (click)="submit()">Load Product
                    </button>
                </div>
                <div class="col-sm-12 col-lg-4 p-2">
                    <button type="button" data-dismiss="modal" (click)="modal.close('')"
                            class="btn btn-danger btn-block">Close
                    </button>
                </div>
                <div class="col-sm-12 col-lg-2"></div>

            </div>
        </div>

    </ng-template>


    <ng-template #displayRadioButtonsDetails let-modal>
        <div class="container p7 mt-1">
            <label class="search-label"> Multi Colour? = The field is to
                indicate whether the product is multi coloured or not</label> <label
                class="search-label"> Color = The field is a colour picker to
            select the colour of the product. If the multi coloured checkbox is
            selected this field will be hidden</label> <label class="search-label">
            Add More Sizes = Button to add more sizes for the product</label> <label
                class="search-label"> Size = The size of the product, this can
            be a number or a string. As an example the size of a shirt can be a 41
            or M for medium based on the size type used.</label> <br/> <br/>
            <button type="button" data-dismiss="modal" (click)="modal.close('')"
                    class="btn btn-danger">Close
            </button>
            <br/>

        </div>
    </ng-template>

    <ng-template #noImage>
        <div class="input-group-text text-center my-1">Image display area</div>
    </ng-template>


</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
