<div class="input-group my-3 ">
	<div class="input-group-prepend">
		<a class="cd-dropdown-trigger" href="javascript: void(0);"><span
			class="d-block d-sm-none">&#9776;</span><span
			class="d-none d-sm-block">&#9776;Categories</span></a>

		<nav class="cd-dropdown">
			<h2 class="bg-warning text-white">Shop by....</h2>
			<a class="cd-close" href="#0">Close</a>
			<ul class="cd-dropdown-content">

				<li *ngFor="let category of categories" class="has-children"><a
					href="javascript: void(0);">{{category.displayName}}</a>
					<ul class="cd-secondary-dropdown is-hidden">
						<li class="go-back"><a href="javascript: void(0);">Menu</a></li>

						<li *ngFor="let subcategory of category.childCategories"
							class="has-children">
							<ul class="is-hidden menu-div-height">
								<li class="go-back"><a href="javascript: void(0);">Clothing</a></li>

								<li class="has-children"><a href="#0">{{subcategory.displayName}}</a>
									<ul class="is-hidden">
										<li class="go-back"><a href="javascript: void(0);">{{subcategory.displayName}}</a></li>
										<li *ngFor="let subsubcategory of subcategory.childCategories">
											<a class="clickableanchor"
											(click)="openCategory(subsubcategory.id,category.displayName,subcategory.displayName,subsubcategory.displayName)">
												{{subsubcategory.displayName}}</a>
										</li>

									</ul></li>
							</ul></li>
					</ul></li>
			</ul>
		</nav>
	</div>

	<input id="searchInput" class="form-control" type="search"  [(ngModel)]="searchedProductName"
		(keyup.enter)="searchForProducts()"
		placeholder="Search for products...">
	<div class="input-group-append">
		<button class="btn text-white text-center  bg-success" type="button"
			style="width: 100%" (click)="searchForProducts()">
			<i class="fa fa-search fa-1x" aria-hidden="true"></i>
		</button>
	</div>
</div>