import {Component, Input, OnInit} from '@angular/core';
import {CartItem} from '../model/CartItem';
import {ClothingSize} from '../model/ClothingSize';
import {Router} from '@angular/router';
import {ApplicationStoreService} from '../services/application-store.service';
import {CartItemsService} from '../services/cart-items.service';
import {NotifierService} from '../services/notifier.service';

@Component({
    selector: 'myafrica-add-to-basket-button',
    templateUrl: './add-to-basket-button.component.html',
    styleUrls: ['./add-to-basket-button.component.scss'],
})
export class AddToBasketButtonComponent implements OnInit {

    @Input() cartItem: CartItem;
    show: boolean;

    constructor(
        private router: Router,
        private appStore: ApplicationStoreService,
        private cartService: CartItemsService,
        private notifierService: NotifierService) {
    }


    ngOnInit() {
    }

    displayButton() {
        this.show = true;

        if (this.cartItem.product.productCategory === 'clothing' && this.cartItem.product.colours != null) {

            this.cartItem.product.colours.forEach(element => {
                element.clothingSizes.forEach(innerElement => {
                    if (Number(innerElement.count) > 0) {

                        this.show = false;
                        return;
                    }
                });
            });

        } else {
            this.show = this.cartItem.product ? this.cartItem.product.unitsInStock <= 0 : false;
        }
    }


    // Filter the product sizes to return those that have stock available
    filterSizesWithNoStock(clothingSizes: ClothingSize[]) {
        return clothingSizes.filter(size => size.count > 0);
    }


    addToCart(cartItemToAdd: CartItem) {
        this.cartService.addToCart(cartItemToAdd);
        this.notifierService.notify('Shopping basket update successfully');
    }


    disableAddToBasket(): boolean {
        const valid = this.cartItem && this.cartItem.product;
        if (valid && this.cartItem.product.colours) {
            const clothingColours = this.cartItem.product.colours
                .filter(color => color.clothingSizes.filter(size => size.count > 0));
            return clothingColours.length === 0;
        }
        return valid && this.cartItem.product.unitsInStock <= 0;
    }

}
