import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {RequestHeader} from '../../RequestHeader';
import {Product} from '../../../model/Product';
import {Business} from '../../../model/Business';
import {BusinessBankingDetail} from '../../../model/BusinessBankingDetail';
import {BusinessAddress} from '../../../model/BusinessAddress';
import {BusinessHour} from '../../../model/BusinessHour';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BusinessServiceService extends RequestHeader {

    headers = new HttpHeaders()
        .set('Content-Type', 'application/json');

    responseResult: any;

    userRole: string;
	
	
    constructor(private http: HttpClient) {
        super();
    }

    getAllBusinessDetails(businessId: string) {
        return this.http.get((environment.apiPrefix + '/views/')
            .concat(`${businessId}/viewbusiness`), {headers: this.headers});
    }

    addBusiness(profileId: string, business: Business | any) {
	        return this.http.post(environment.apiAdminPrefix +'/add-business',
			business, {observe: 'response'});
    }

    updateBusiness(businessId: string, business: Business | any) {
	    return this.http.post(environment.apiAdminPrefix  + '/update-business',
            business,
            {observe: 'response'});
    }

    updateBusinessAddress(businessId: string, businessAddress: BusinessAddress | any) {
	    return this.http.post((environment.apiAdminPrefix + '/update/business/address/')
		    .concat(`${businessId}`),
            businessAddress,
            {observe: 'response'});
    }
	
    updateBusinessDetails(businessId: string, businessName: string, business: Business | any) {
        return this.http.post((environment.apiPrefix +'/businessadmin/business/')
                .concat(`${businessId}`)
                .concat('/adddetails'),
            business,
            {observe: 'response'});
    }

    getBusinessesByProfileId(profileId: string) {
        return this.http.get((environment.apiAdminPrefix  + '/businesses/')
            .concat(`${profileId}`), {headers: this.headers});
    }

    updateBankingDetails(businessId: string, bankingDetails: BusinessBankingDetail | any) {
        return this.http.post((environment.apiAdminPrefix + '/business/banking/details/')
                .concat(`${businessId}`),
            bankingDetails, {observe: 'response'});
    }

    getBusinessBankingDetails(businessId: string) {
	    return this.http.get((environment.apiAdminPrefix + '/get/business/banking/details/')
            .concat(`${businessId}`), {headers: this.headers});
    }

    getBusinessHours(businessId: string) {
        return this.http.get((environment.apiAdminPrefix + '/get/business/hours/')
            .concat(`${businessId}`), {headers: this.headers});
    }

    updateBusinessHours(businessId: string, businessHours: BusinessHour[] | any) {
	    return this.http.post((environment.apiAdminPrefix + '/update/business/hours/')
		    .concat(`${businessId}`),
            businessHours,
            {observe: 'response'});
    }

    getBusinessDetails(businessId: string) {
        return this.http.get((environment.apiPrefix + '/businessadmin/business/')
            .concat(`${businessId}/getbusinessdetails`), {headers: this.headers});
    }

    getBusinessAddress(businessId: string) {
        return this.http.get((environment.apiPrefix + '/businessadmin/business/')
            .concat(`${businessId}/getbusinessaddress`), {headers: this.headers});
    }
	
	
    getPaginationBusinessProducts(itemsPerPage: number,
                                        pageNumber: number,
                                        productCategory: string,
                                        businessIds: string) {
        return this.http.post((environment.apiPrefix + '/products/business')
				.concat(`?itemsPerPage=${itemsPerPage}&`)
                .concat(`pageNumber=${pageNumber}`),
				businessIds,
            {headers: this.headers});
    }

    getPaginationBusinessProductsByName(productName: string,
                                        itemsPerPage: number,
                                        pageNumber: number,
                                        productCategory: string,
                                        businessIds: string[]) {
        return this.http.get((environment.apiPrefix + '/products/')
                .concat(`${productName}/${itemsPerPage}/`)
                .concat(`${pageNumber}/${productCategory}/`)
                .concat(`${businessIds}/getpaginationbusinesssproductsbyname`),
            {headers: this.headers});
    }


    getBusinessByNameAndProfile(businessName: string, profileId: string) {
        return this.http.get((environment.apiAdminPrefix + '/businesses/')
            .concat(businessName)
            .concat(`/${profileId}`), {headers: this.headers});
    }


    // get a list of active businesses matching a partial or full business name for pagination
    getAdminPaginationBusinessByName(businessName: string,
                                     itemsPerPage: number,
                                     pageNumber: number,
                                     businessCategory: string, orderBy: string, filter: string) {

        return this.http.get((environment.apiPrefix + '/allbusiness/')
            .concat(`${businessName}`)
            .concat(`/${itemsPerPage}/`)
            .concat(`${pageNumber}/${businessCategory}/${filter}/getallbusinessbyname`), {headers: this.headers});
    }

    isAdministrator() {
        this.userRole = sessionStorage.getItem('userRole');
        return this.userRole === 'Administrator' || this.userRole === 'Admin Clerk';

    }

    deleteProduct(businessId: string, productId: number) {
        return this.http.post(environment.apiAdminPrefix + '/products/product/delete/${productId}',
            this.responseResult, {observe: 'response'});
    }


    undeleteProduct(businessId: string, productId: string) {
        return this.http.post(environment.apiAdminPrefix + '/products/product/undelete/${productId}',
            this.responseResult, {observe: 'response'});
    }


    updateProduct(businessId: string, product: Product | any) {
        return this.http.post(environment.apiAdminPrefix + '/products/update-product',
            product, {observe: 'response'});
    }


    uploadFile(file: File, businessId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/*');
        myHeaders.append('Accept', 'application/json');
        return this.http.post(environment.apiAdminPrefix + '/businessadmin/business/${businessId}/addbusinesslogo',
            formData, {headers: myHeaders});

    }

    uploadBillboardFile(file: File, businessId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/*');
        myHeaders.append('Accept', 'application/json');
        //        let options = new RequestOptions({ headers: myHeaders });
        return this.http.post(environment.apiAdminPrefix + '/businessadmin/business/${businessId}/addbusinessbillboard',
            formData, {headers: myHeaders});


    }


    addProductWithImage(file: File, businessId: string, product: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('jsonProduct', product);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/*');
        myHeaders.append('Accept', 'application/json');
        return this.http.post(environment.apiPrefix + '/businessadmin/business/${businessId}/addproductwithimage',
            formData, {headers: myHeaders});


    }
	
	
    addProductWithImage_ProductReturn(file: File, businessId: string, product: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('product', product);
	
    const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/*');
        myHeaders.append('Accept', 'application/json');
        return this.http.post(environment.apiAdminPrefix + '/products/${businessId}/add-product',
            formData, {headers: myHeaders, observe: 'response'});


    }


    addProductImage(file: File, productId: string, colour: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('colour', colour);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/jpeg');
        myHeaders.append('Accept', 'application/json');

        return this.http.post(environment.apiAdminPrefix + '/products/${productId}/colours/image',
            formData, {headers: myHeaders});
    }

    addProductWithColours(file: File, businessId: string, product: string, colourFile: File, colourProduct: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('jsonProduct', product);

        formData.append('colourFile', file);
        formData.append('colourProduct', colourProduct);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', 'image/*');
        myHeaders.append('Accept', 'application/json');
        return this.http.post(environment.apiAdminPrefix + '/businessadmin/business/${businessId}/addproductwithcolours',
            formData, {headers: myHeaders});
    }


    updateProductClothingColours(colourId: number, product: string) {
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.apiAdminPrefix + '/products/${colourId}/updatecolourcounts',
            product, {headers: this.headers});
    }

    updateDefaultImage(file: File, productId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        const myHeaders = new HttpHeaders();
        myHeaders.append('Content-Type', `image/*`);
        myHeaders.append('Accept', 'application/json');

        return this.http.post(environment.apiAdminPrefix + '/products/update/defaultimage/${productId}',
            formData, {headers: myHeaders, observe: 'response'});
    }


}
