<myafrica-page-title pageTitle="Frequently Asked Questions"></myafrica-page-title>
<div class="container my-5 pb-3 shadow-lg rounded">
    <div class="accordion" id="accordionFAQ">
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingHowToRegister">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block" type="button" data-toggle="collapse"
                            data-target="#collapseHowToRegister" aria-expanded="true"
                            aria-controls="collapseHowToRegister">
                        How to register on myafricaconnect?
                    </button>
                </h2>
            </div>

            <div id="collapseHowToRegister" class="collapse show" aria-labelledby="headingHowToRegister"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    We will need you to register on the Site as either a business or a client/consumer by creating a
                    profile in this process we will require your Name, last name, email address and password also users
                    are required to read and understand the terms and conditions. After a user has been registered as
                    either a business or client/consumer they will be required on condition of a business to add more
                    information and description about their business for the dashboard area.

                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingServicesAndProducts">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseServicesAndProducts" aria-expanded="false"
                            aria-controls="collapseServicesAndProducts">
                        How to find Services and Products?
                    </button>
                </h2>
            </div>
            <div id="collapseServicesAndProducts" class="collapse" aria-labelledby="headingServicesAndProducts"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    A user can shop by searching for specific items or products on the search bar by name of product, or
                    the name of business, also a user can be able to search for a service by the same way in which a
                    user searches for services which the result is a list of related businesses and service providers. A
                    user can purchase vouchers from listed services at a discounted rate and be able to redeem the
                    vouchers. A user clicks on the business and is able to have an option which shows details of the
                    business and product and can directly send a message to the business through massaging; a client can
                    just add the product to their cart.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingBuy">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseBuy" aria-expanded="false" aria-controls="collapseBuy">
                        How to Buy?
                    </button>
                </h2>
            </div>
            <div id="collapseBuy" class="collapse" aria-labelledby="headingBuy"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    A user can search for a product through the search field of the home page at which they will have an
                    option to refine the search by virtue of region and Locale and also the filtered products will show
                    in categorised formats of the highest rated products and services. Simply a user searches for a key
                    word that brings up an option to filter a search also with a number of possible businesses
                    associated with the product of the specified searched keyword.

                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingAddToCart">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseAddToCart" aria-expanded="false" aria-controls="collapseAddToCart">
                        How to add to cart?
                    </button>
                </h2>
            </div>
            <div id="collapseAddToCart" class="collapse" aria-labelledby="headingAddToCart" data-parent="#accordionFAQ">
                <div class="card-body">
                    When a client has found the item/product that they wish to acquire or buy they can add them to cart
                    whereby a dialog message appears to verify if users need to continue shopping or go to cart for
                    payment process to commence for checkout which will redirect the user to a secure server to complete
                    the transaction.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingCheckout">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseCheckout" aria-expanded="false" aria-controls="collapseCheckout">
                        How Checkout works?
                    </button>
                </h2>
            </div>
            <div id="collapseCheckout" class="collapse" aria-labelledby="headingCheckout" data-parent="#accordionFAQ">
                <div class="card-body">
                    Based on virtue that a user is not yet a registered user of
                    myafricaconnect. A user is presented with a page where they are
                    shown their products and are able to remove an item or proceed to
                    checkout; if a user does decide to proceed then they will be
                    required to:

                    <ul>
                        <li>Register</li>
                        <li>Select delivery method</li>
                        <li>Select address of the delivery</li>
                        <li>Select payment method</li>
                        <li>Click buy</li>
                    </ul>

                    A summarised detail list of a user's order will appear just so
                    that a user can check their details, before being redirected to a
                    secure server to finalise the transaction.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingReturnToSeller">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseReturnToSeller" aria-expanded="false"
                            aria-controls="collapseReturnToSeller">
                        What if I want to return a product to a seller?
                    </button>
                </h2>
            </div>
            <div id="collapseReturnToSeller" class="collapse" aria-labelledby="headingReturnToSeller"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    You will be required to package the product and also include the reference number for the return of
                    the product, there after communicate with the seller at which sold the product to receive a refund
                    within 30 days of receiving the product, in order to get a new product or get a refund.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingExchangeAndReturnsPolicy">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseExchangeAndReturnsPolicy" aria-expanded="false"
                            aria-controls="collapseExchangeAndReturnsPolicy">
                        What is the exchange and return policy of myafricaconnect?
                    </button>
                </h2>
            </div>
            <div id="collapseExchangeAndReturnsPolicy" class="collapse"
                 aria-labelledby="headingExchangeAndReturnsPolicy" data-parent="#accordionFAQ">
                <div class="card-body">
                    We pride ourselves in bringing customers great services and
                    together with all the registered partners, clients and members of
                    this platform strive to achieve that, if a client is unhappy they
                    have the right to receive value for money be it services or
                    purchased products. Our third party sellers also abide by this
                    policy it does not limit the statutory rights of individuals inng g
                    anyway. A user can return a defective or unwanted product in the
                    following conditions:
                    <ul>
                        <li>It is defective or damaged in any form or unused with
                            the original labels intact within 30 days of receiving the
                            product.
                        </li>
                        <li>The product must be in its original packaging it too
                            with all the labels visible.
                        </li>
                        <li>No parts of any accessories must be damaged or missing.</li>
                        <li>Log in the site to Log the discontent and also contact
                            the third party about the issue of the product that is being
                            brought.
                        </li>
                        <li>If the third party is uncooperative contact
                            info@myafricaconnect.com to report the matter with well given
                            evidence of trying to communicate with the third party seller.
                        </li>
                        <li>Please note you can only return a product if it is
                            defective or damaged.
                        </li>
                        <li>No product shall be returned if it was a wrong purchase
                            or any purchase based on unknowledgeable purchasing of a
                            product.
                        </li>
                    </ul>
                    Myafricaconnect and third party sellers have the right to refuse
                    returned items or products that do not meet these conditions.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingExchange">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseExchange" aria-expanded="false" aria-controls="collapseExchange">
                        Exchange
                    </button>
                </h2>
            </div>
            <div id="collapseExchange" class="collapse" aria-labelledby="headingExchange" data-parent="#accordionFAQ">
                <div class="card-body">
                    Any items or products that relate to clothing or jewellery can be exchanged based on a different
                    size, colour, and preference based on availability from the sellers but there will no refund unless
                    if the product in question is based on the conditions above.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingNotWhatYouOrdered">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseNotWhatYouOrdered" aria-expanded="false"
                            aria-controls="collapseNotWhatYouOrdered">
                        Product received is not what I ordered?
                    </button>
                </h2>
            </div>
            <div id="collapseNotWhatYouOrdered" class="collapse" aria-labelledby="headingNotWhatYouOrdered"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    If a user receives and item that they did not order please contact support@myafricaconnect.com so
                    that we can arrange for the product to be recollected and delivered to the right recipients.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingDamagedGoods">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseDamagedGoods" aria-expanded="false"
                            aria-controls="collapseDamagedGoods">
                        Items/Products damaged on delivery
                    </button>
                </h2>
            </div>
            <div id="collapseDamagedGoods" class="collapse" aria-labelledby="headingDamagedGoods"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    Should it be that a product has
                    been damaged on delivery you have the right to contact
                    support@myafricaconnect.com where we will arrange for the product
                    to be collected and returned to the seller.

                    We try and have the best businesses sell on the platform which
                    have products of high grade and quality without any defects. We
                    see a defect as imperfection in the products that are from the
                    manufacturer which will be an exception and are bound to be
                    returned to the third party seller. However there are some forms
                    in a product that will not be regarded as a defect.
                    <ul>
                        <li>Normal tear and wear faults</li>
                        <li>Negligence, abuse or incorrect usage of an item or
                            products
                        </li>
                        <li>Electrical surges damage</li>
                        <li>Alterations to the product by a user</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingDeliveryAndGoods">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseDeliveryAndGoods" aria-expanded="false"
                            aria-controls="collapseDeliveryAndGoods">
                        Shipping and Delivery
                    </button>
                </h2>
            </div>
            <div id="collapseDeliveryAndGoods" class="collapse" aria-labelledby="headingDeliveryAndGoods"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    <ol>
                        <li>The Company offers 3 (three) methods of delivery of Goods to
                            you. You may elect delivery via:
                            <ul>
                                <li>courier;</li>
                                <li>post office;</li>
                                <li>self-collection.</li>
                            </ul>
                        </li>
                        <li>Please
                            see details of our delivery and shipping terms and conditions under
                            FAQ&rsquo;s: Shipping and Delivery which are incorporated herein by
                            reference
                        </li>
                        <li>We shall take all reasonable efforts to
                            accurately reflect the Goods, purchase price and delivery charges
                            of Goods on the Website. However, should there be any errors of
                            whatsoever nature on the Website (which are not due to our gross
                            negligence) we shall not be liable to make good on a transaction
                            based on any error apart from refunding any monies paid by you to
                            the Company.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingPackagingAndDelivery">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapsePackagingAndDelivery" aria-expanded="false"
                            aria-controls="collapsePackagingAndDelivery">
                        Process, pack and dispatch customer orders
                    </button>
                </h2>
            </div>
            <div id="collapsePackagingAndDelivery" class="collapse" aria-labelledby="headingPackagingAndDelivery"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    When a sale is made on myafricaconnect, the seller receives an order confirmation via email and a
                    notification appears on the seller's profile on myafricaconnect.com. The seller needs to pack the
                    order and arrange for a courier to pick up or dispatch the product via the given courier service,
                    and update the seller profile with the necessary shipping information. This information includes the
                    courier company details, tracking number, and estimated delivery date. This information is important
                    since it is communicated to the customer and it triggers the automated payment to the seller in 14
                    working days.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingPayingWithEFT">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block collapsed" type="button" data-toggle="collapse"
                            data-target="#collapsePayingWithEFT" aria-expanded="false"
                            aria-controls="collapsePayingWithEFT">
                        Paying with EFT
                    </button>
                </h2>
            </div>
            <div id="collapsePayingWithEFT" class="collapse" aria-labelledby="headingPayingWithEFT"
                 data-parent="#accordionFAQ">
                <div class="card-body">
                    Login to your respective banking app (Absa, FNB, Capitec, Standard bank or Nedbank) and follow the
                    applications payment process to complete payment.
                </div>
            </div>
        </div>
    </div>
</div>
