<myafrica-page-title pageTitle="Change Password"></myafrica-page-title>
	<div class="container align-middle col p-md-4 col-sm-12 col-md-8 p-0">
		<div class="card  text-dark shadow">

			<div class="row">
				<div class="col-12">
					<div class="form-panel">

						<form class="form-horizontal style-form" #registerForm="ngForm"
							name="registerform">


							<div class="form-row">
								<div class="form-group col">
									<label for="emailAddress">New Password *</label> <input
										type="password" class="form-control form-control-sm"
										id="password"
										[ngClass]="{'border border-danger':password.touched && password.invalid}"
										[(ngModel)]="pwd" name="password" required #password="ngModel"
										pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$">
									<div *ngIf="password.touched ">
										<p class="text-danger" *ngIf="password?.errors?.required">Password
											is required</p>
										<p class="text-danger" *ngIf="password?.errors?.minlength">Password
											needs to be more than 8 characters.</p>
										<p class="text-danger" *ngIf="password?.errors?.pattern">Must
											contain one lower &amp; uppercase letter, one special
											character and a number</p>
									</div>

								</div>
							</div>

							<div class="form-row">
								<div class="form-group col">
									<label for="confirmPassword">Confirm Password *</label> <input
										type="password" class="form-control form-control-sm"
										id="confirmPassword"
										[ngClass]="{'border border-danger':confirmPassword.touched && confirmPassword.invalid}"
										[(ngModel)]="confirmPwd" name="confirmPassword" required
										#confirmPassword="ngModel" minlength="8" maxlength="50" />
									<div
										*ngIf="confirmPassword.touched && confirmPassword.invalid "
										class="text-danger">valid Confirm password required</div>

								</div>
							</div>

							<div
								*ngIf="(password.invalid|| password.value?.pwd != password.value?.confirmPwd) && (password.touched)"
								class="error-message">

								<div *ngIf="password.invalid; else nomatch">Password needs
									to be more than 8 characters</div>
								<ng-template #nomatch> Passwords don't match</ng-template>
							</div>



							<button class="btn btn-success" type="submit"
								[disabled]="password?.invalid || registerForm.invalid"
								(click)="resetPassword()">Submit</button>
						</form>
					</div>



				</div>
			</div>
		</div>
</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


