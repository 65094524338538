import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../model/Product';

@Component({
    selector: 'myafrica-out-of-stock',
    templateUrl: './out-of-stock.component.html',
    styleUrls: ['./out-of-stock.component.scss'],
})
export class OutOfStockComponent implements OnInit {

    @Input() product: Product;

    constructor() {
    }

    ngOnInit() {
        this.showProductOutOfStock();
    }

    showProductOutOfStock(): boolean {
        if (this.product.colours) {
            const clothingColours = this.product.colours.filter(color => color.clothingSizes.filter(size => size.count > 0));
            return clothingColours.length === 0;
        }
        return this.product.unitsInStock <= 0;
    }
}
