<myafrica-page-title pageTitle="DELIVERY DETAILS" tileImage="fa fa-truck text-secondary"></myafrica-page-title>

<div class="container shadow p-2">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6" style="border: 1px solid transparent;">
              <form [formGroup]="paymentForm" novalidate>
                    <ngb-tabset [justify]="currentJustify">

                        <ngb-tab
                                title="Your Details">
                            <ng-template ngbTabContent>
                                <br/>
                                <div class="row form-group required">

                                    <div class="col-10 col-md-11 col-lg-11">

                                        <input type="text" [(ngModel)]="firstName" name="firstName"  formControlName="firstName"
                                               placeholder="First Name" class="form-control" required>

                                    </div>
                                    <label class="col-2  col-md-1  control-label"></label>
                                </div>
                                <div class="row form-group required">
                                    <div class="col-10 col-md-11 col-lg-11">
                                        <input type="text" [(ngModel)]="lastName" name="lastName"  formControlName="lastName"
                                               placeholder="Surname" class="form-control" required>
                                    </div>
                                    <label class="col-2  col-md-1  control-label"></label>
                                </div>
                                <div class="row form-group required">

                                    <div class="col-10 col-md-11 col-lg-11">
                                        <input type="email" [(ngModel)]="emailAddress" formControlName="emailAddress" placeholder="Email Address"
                                               name="emailAddress" class="form-control"
                                               pattern="^\S*[@]\S*[.]\S*$" required>

                                    </div>
                                    <label class="col-2  col-md-1  control-label"></label>

                                </div>

                                <div class="row form-group required">

                                    <div class="col-10 col-md-11 col-lg-11">
                                        <input type="text" numbersOnly [maxlength]="10"  [minlength]="10" formControlName="cellphoneNumber" placeholder="Cellphone No"
                                               [(ngModel)]="cellphoneNumber" name="cellphoneNumber"
                                               class="form-control" required>
                                    </div>
                                    <label class="col-2  col-md-1  control-label"></label>

                                </div>

                                <div class="row form-group">
                                    <div class="col-10 col-md-11 col-lg-11">
                                        <input type="text" numbersOnly [maxlength]="10" placeholder="Alternate No"
                                               class="form-control" [(ngModel)]="alternativeNumber"
                                               name="alternativeNumber">

                                    </div>
                                    <label class="col-1  col-lg-1"></label>
                                </div>


                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Address Details">
                            <ng-template
                                    ngbTabContent>
                                <br/>
                                <div class="row mb-2" *ngIf="displayAddressBookButton">
                                    <div class="col-md-4">
                                        <button type="button" class="btn btn-primary w-90" id="addressBook" (click)="open(addresses)">
                                            <i class="fa fa-address-book" aria-hidden="true"></i>&nbsp;&nbsp;Address
                                            Book
                                        </button>

                                    </div>

                                    <div class="col-md-8"></div>
                                </div>

                                <div class="form-group row required">
                                    <div class="col-10 col-md-11">
                                        <input type="text" class="form-control" name="addressline1" formControlName="addressline1"
                                               placeholder="Address Line"
                                               [(ngModel)]="addressLine1" required>
                                    </div>
                                    <label class="col-2  col-md-1 control-label"></label>
                                </div>
                                <div class="form-group row required">
                                    <div class="col-10 col-md-11">
                                        <input type="text" class="form-control" name="streetname" formControlName="streetname"
                                               placeholder="Street Name"
                                               [(ngModel)]="streetName" required>
                                    </div>
                                    <label class="col-2  col-md-1 control-label"></label>
                                </div>
                                <div class="form-group row required">
                                    <div class="col-10 col-md-11">
                                        <input type="text" class="form-control" name="city" placeholder="City" formControlName="city"
                                               [(ngModel)]="city" required>
                                    </div>
                                    <label class="col-2  col-md-1 control-label"></label>
                                </div>
                                <div class="form-group row required">
                                    <div class="col-10 col-md-11">
                                        <select required name="businessProvince" class="form-control" formControlName="businessProvince"
                                                [(ngModel)]="provinceId"
                                                placeholder="Province">
                                            <option [value]="province.id"
                                                    *ngFor="let province of provinces">{{province.name}}
                                            </option>

                                        </select>

                                    </div>
                                    <label class="col-2  col-md-1 control-label"></label>
                                </div>
                                <div class="form-group row required">
                                    <div class="col-10 col-md-11">
                                        <input type="text" class="form-control" name="zipcode" placeholder="Zip Code" formControlName="zipcode"
                                               [maxlength]="4" numbersOnly 
                                               [(ngModel)]="zipcode" required
                                               (change)="getQuote()">
                                    </div>
                                    <label class="col-2  col-md-1 control-label"></label>
                                </div>
                                <div class="form-group row ">
                                    <div class="col-10 col-md-11">
                                        <textarea class="form-control" name="deliveryInstructions" 
                                                  placeholder="Any special instructions for your delivery"
                                                  numbersOnly [(ngModel)]="deliveryInstructions"></textarea>
                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>

                    </ngb-tabset>
                </form>

            </div>

            <div class="col-12 col-md-6">
                <div class="row">

                    <div class=" modal-header col-12">
                        <h2 class="form-heading text-center mx-auto">MY CART</h2>
                    </div>


                    <div class="panel-body table-responsive ">
                        <table class="table" id="cartTable" style="width: 100%">
                            <thead class="table-info shadow">
                            <tr>
                                <th class="text-center text-dark h4">Product</th>
                                <th class="text-center text-dark h4">Cost</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let cartItem of cartItems" id="{{cartItem.product.productId}}">
                                <td style="text-align: left" width="50%">
                                    <div class="form-check form-check-inline  mt-0">
                                        <div class="shopping-cart-img mr-1">
                                            <img
                                                    *ngIf="cartItem.image; else elseBlock"
                                                    class="img-thumbnail"
                                                    style="height: 80px; min-width: 80px; width: 80px"
                                                    src="{{cartItem.image}}"
                                            />
                                        </div>
                                        <h6 class="table-product-name"
                                            title="{{cartItem.product.productName}}">{{cartItem.product.productName | truncate: 16}}</h6>

                                        <ng-template #elseBlock><img
                                                src="https://myafricaconnect.com/images/image-not-available.jpg"
                                                alt="business logo" style="height: 80px; width: 80px"
                                                class="img-thumbnail"/></ng-template>

                                    </div>

                                </td>
                                <td style="text-align: center"><p
                                        class="text-warning font-weight-bold">  {{cartItem.quantity}} &nbsp; X
                                    &nbsp;{{cartItem.product.price
                                        | currency: cartCurrency + ' '}}</p>


                                </td>


                            </tr>
                            <tr>
                                <td style="text-align: right"><h6>Cart Total</h6></td>
                                <td style="text-align: center"><p
                                        class="text-warning font-weight-bold">{{cartTotal | currency:
                                cartCurrency + ' '}}</p></td>
                            </tr>

                            <tr>
                                <td style="text-align: right"><h6>Delivery Cost</h6></td>
                                <td style="text-align: center"><p  *ngIf="!quoteReceived" 
                                        class="text-warning font-weight-bold">Not yet calculated</p>
                                    <p  *ngIf="quoteReceived" 
                                        class="text-warning font-weight-bold">{{cartDeliveryAmount |
                                    currency: cartCurrency + ' '}}</p>
                                    
                                    </td>
                                    
                            </tr>

                            <tr>
                                <td style="text-align: right"><h6>Total</h6></td>
                                <td style="text-align: center"><p
                                        class="text-warning font-weight-bold">{{totalCost | currency:
                                cartCurrency + ' '}}</p></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="container">


                        <div *ngIf="displaySuccessMessage" class="success-message-bold">{{displayMessage}}
                        </div>

                        <div *ngIf="displayErrorMessage" class="error-message-bold p-2">
                            {{displayMessage}}
                        </div>

                        <div class="row" *ngIf="displayRemoveButton">

                            <div class="col-12 col-lg-12 p-3">
                                <button type="button" style="width: 80%"
                                        (click)="removeItems()"
                                        class="btn btn-danger">
                                    <span>Remove Out Of Stock Items&nbsp;&nbsp;&times;</span>
                                </button>
                            </div>
                            <div class="col-12 col-lg-12"></div>
                        </div>
                        <div class="row">

                            <div class="col-12 col-lg-6 mb-5">

                                <!--  button class="btn btn-theme-product-large button-responsive"
                                        style="width: 80%" type="button" (click)="collect()"
                                        [disabled]="cartItems && cartItems.length <= 0  || placeOrderButtonDisabled">
                                                        <span><img class="connect-btn-image"
                                               src="..https://myafricaconnect.com/images/cart36x36.png"></span> Collect Items?
                                </button-->

                                <button class="btn btn-success btn-block"
                                        type="button" (click)="goBack()"
                                        [disabled]="placeOrderButtonDisabled">
                                    <i class="fa fa-shopping-basket text-white" aria-hidden="true"></i>
                                    Continue Shopping <i class="fa fa-arrow-right text-white"></i>
                                </button>

                            </div>
                            <div class="col-12 col-lg-6">
                                <button class="btn btn-success btn-block"
                                        data-toggle="modal" data-target="#paymentOptionsModal" type="button"
                                        [disabled]="cartItems && cartItems.length <= 0  ||
                                        paymentForm.invalid ||
                                        placeOrderButtonDisabled">
                                    <i class="fa fa-shopping-cart text-white" aria-hidden="true"></i>
                                    Payment Method <i class="fa fa-arrow-right text-white"></i>
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<ng-template #payoption let-modal>

</ng-template>

<ng-template #addresses let-modal>
    <div class="container">
        <div class="row modal-header m-0">
            <h2 class="form-heading">Address Book</h2>
        </div>
        <div class="card bg-light  mb-3" *ngFor="let address of userAddresses">
            <div class="card-body" (click)="setAddressDetails(address)">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<span>
							<h6 class="mb-1">{{address.addressline1}}</h6>
						</span>
                        <h6>{{address.streetname}}, {{address.city}}</h6>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 col-lg-6 mb-2">

            <button type="button" data-dismiss="modal" (click)="modal.close('')"
                    class="btn btn-danger">Close
            </button>

        </div>

    </div>
</ng-template>

<!-- Modal -->
<div class="modal fade rounded-top" id="paymentOptionsModal" tabindex="-1" role="dialog"
     aria-labelledby="paymentOptionsModalLabel"
     aria-hidden="true">
    <div class="modal-dialog rounded-top" role="document">
        <div class="modal-content rounded-top">
            <div class="modal-header bg-primary rounded-top">
                <h5 class="modal-title text-white" id="paymentOptionsModalLabel">How would you like to pay?</h5>
                <i class="fa fa-times text-white cursor-pointer" data-dismiss="modal" aria-hidden="true"></i>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
						<div class=" col-6">
                            <input type="radio" checked name="optradio" (change)="setInstantPayment(false)"
                            ><img class="img-fluid m-auto" style="max-height: 50px"
                                  src="../../../assets/img/instanteft.png">
                        </div>
                        <div class=" col-6  col-md-6">
                            <input type="radio" name="optradio" (change)="setInstantPayment(true)"
                                    id="last7DaysRadio"><label
                                class="search-label"> &nbsp;
                            <img class="img-fluid m-auto" style="max-height: 50px"
                                 src="../../../assets/img/payfast.png">


                        </label>

                        </div>
                        

                    </div>

                </div>
            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-secondary" #closeButton data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="placeOrder()"
                        [disabled]="cartItems && cartItems.length <= 0  
                        || placeOrderButtonDisabled">Submit Order
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-loading
    [show]="httploading"
    [config]="{
        backdropBackgroundColour: 'rgba(255,255,255,0.3)', backdropBorderRadius: '10px',
        primaryColour: '#f15a22', secondaryColour: '#33a5c0', tertiaryColour: '#FFF' }"
   
  ></ngx-loading>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>

