<div class="container">

    <div class="container">
        <div class="row bg-warning rounded-bottom rounded-top shadow pb-3">
            <div class="col-5 col-md-4 pull-left mt-2">
			<span *ngIf="totalProducts > 0" class="text-white">
				{{((currentPage) * displayEntryLimitCount) + 1}} -
                {{displayRangeLimit}} of {{totalProducts}} </span> <span
                    *ngIf="totalProducts <= 0"> </span>
            </div>

            <div class="input-group col-md-4  input-group-sm mt-2 mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Show</span>
                </div>
                <select [(ngModel)]="entryLimitCount"
                        class="form-control text-secondary" name="entryLimitCount"
                        (ngModelChange)="setNumberOfItemsperPage()">

                    <option [value]="item.id" *ngFor="let item of itemsPerPageList">{{item.id}}</option>

                </select>
                <div class="input-group-append">
                    <span class="input-group-text">per page</span>
                </div>
            </div>

            <div class="col-12 col-md-4 input-group-sm mt-2 text-white">
                <select [(ngModel)]="orderBy"
                        class="form-control text-secondary" name="orderBy"
                        (ngModelChange)="searchForProduct()">
                    <option [value]="item.sortValue" *ngFor="let item of orderByList">{{item.name}}</option>

                </select>

            </div>
        </div>
    </div>
    <br/>
    <div class="container">
        <div class="row bg-light rounded-bottom rounded-top shadow h-20 my-2">
            <div class="col-6 p-2">
            <span class="badge badge-success text-white"
                  *ngIf="displayRangeLimit - displayEntryLimitCount <= 0"><a
                    class="disabled text-white h6">&larr; Previous</a></span>
                <span class="badge badge-success text-white"
                      *ngIf="displayRangeLimit - displayEntryLimitCount > 0"><a
                        (click)="previous()"><span
                        class="text-white h6">&larr; Previous</span></a></span>
            </div>

            <div class="col-6  p-2 text-right">
            <span class="badge badge-success text-white" *ngIf="displayRangeLimit < totalProducts"><a
                    (click)="next()"><span class="paginationanchor text-white h6">Next
						&rarr;</span></a></span>
                <span class="badge badge-success text-white" *ngIf="displayRangeLimit >= totalProducts"><a
                        class="disabled text-white h6">Next &rarr;</a></span>

            </div>
        </div>
    </div>

    <div *ngIf="totalProducts <= 0" class="text-red">No
        products were found matching criteria!!!
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-3"
             *ngFor="let product of allProducts">
            <myafrica-product-item [product]="product"></myafrica-product-item>
        </div>
    </div>
</div>
<br>


<div class="container my-2">
    <div class="row bg-light rounded-bottom rounded-top shadow h-20 mb-5">
        <div class="col-6 mt-2">
            <span class="badge badge-success text-white"
                  *ngIf="displayRangeLimit - displayEntryLimitCount <= 0"><a
                    class="disabled text-white h6">&larr; Previous</a></span>
            <span class="badge badge-success text-white"
                  *ngIf="displayRangeLimit - displayEntryLimitCount > 0"><a
                    (click)="previous()"><span
                    class="text-white h6">&larr; Previous</span></a></span>
        </div>

        <div class="col-6  p-2 text-right">
            <span class="badge badge-success text-white" *ngIf="displayRangeLimit < totalProducts"><a
                    (click)="next()"><span class="paginationanchor text-white h6">Next
						&rarr;</span></a></span>
            <span class="badge badge-success text-white" *ngIf="displayRangeLimit >= totalProducts"><a
                    class="disabled text-white h6">Next &rarr;</a></span>

        </div>
    </div>
</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
