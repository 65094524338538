import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CustomerOrder} from '../../model/CustomerOrder';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {CustomerOrderInvoice} from '../../model/CustomerOrderInvoice';
import {NotifierService} from '../../services/notifier.service';
import {PaginationOrder} from '../../model/PaginationOrder';
import {CartItem} from '../../model/CartItem';
import {Product} from '../../model/Product';


@Component({
    selector: 'myafrica-shopper-orders',
    templateUrl: './shopper-orders.component.html',
    styleUrls: ['./shopper-orders.component.scss'],
})
export class ShopperOrdersComponent implements OnInit {

    rowsControls = [];
    rows: CustomerOrder[];
  
    displayMessage = false;
    message = 'Data has been modified. Remember to save when done!!';
    displaySaveButton = false;
    displayErrorMessage = false;
    displayPrintMessage = false;
    displayPrintErrorMessage = false;

    orderItemStatuses: string[];

    orderStatuses: string[];

    profileId: string;

    pageTitle = 'Manage Client Orders';

    buttonLabel = 'Search';
    buttonIcon = 'fa fa-search';

    dateFormat: string;

    periodValue = 2;
    totalOrdersCount = 0;
    orderNo: string;
	itemsPerPage = 12;
	displayRangeLimit: number;
	entryLimitCount = 12;
	currentPage = 1;
    totalProducts = 0;

    subs1: Subscription;
    subs2: Subscription;
    subs3: Subscription;
  
    startDate = new Date();
    endDate: Date;

    startStringDate: string;
    endStringDate: string;
    closeResult: string;
	selectedCartItem: CartItem;
	
    businessName: string;
    contactNo: string;
    specialInstructions: string;
    loading = false;
    selectedCustomerOrder: CustomerOrder;
    displayPrint = false;
    modalReference: any;
	userDetails: any;
	itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];
	
	
	allOrdersSearched: boolean;

    constructor(private router: Router,
                private ordersService: OrdersServiceService,
                private appStore: ApplicationStore,
                private modalService: NgbModal,
                private notifier: NotifierService) {
    }

    ngOnInit() {
	    this.loading = true;
        this.dateFormat = environment.dateFormat;
        this.defaultDates();
        this.orderItemStatuses = this.ordersService.getOrderItemStatuses();
         
        this.orderStatuses = this.ordersService.getOrderStatuses();
	    this.loadDefaultData(false);
	
    }

    defaultDates() {
        this.startDate = new Date(new Date().setDate(new Date().getDate() - 60));

        this.endDate = new Date();

    }

    setRadioValue(value: number) {
        this.periodValue = value;
        this.clearOrderNo();
    }


    loadDefaultData(displayAlertMessage: boolean) {
        // by default get the list of in unprocessed orders
        this.userDetails = this.appStore.getJsonItem('userDetails');
        this.profileId = this.appStore.getItem('profileId')
		this.startStringDate = Utils.getDateString(this.startDate);
        this.endStringDate = Utils.getDateString(this.endDate);
		this.loading = true;
		this.currentPage = this.currentPage - 1;

        this.subs2 = this.ordersService.getPaginationOrders(this.userDetails.email, this.itemsPerPage, this.currentPage, this.orderNo, this.startStringDate, this.endStringDate)
            .subscribe((value: PaginationOrder[]) => {
                //         this.rows = JSON.stringify(value.body);
                this.rows = value[0].orders;
				this.totalOrdersCount = value[0].totalOrdersCount;
			
				this.setLimitsCounts();
				this.loading = false;
                this.rows.forEach(() => {
                    this.rowsControls.push({
                        isCollapsed: true,
                    });
                });
                if (this.rows.length <= 0 && displayAlertMessage) {
                    this.notifier.notifyError('Records matching your search criteria were not found!!');
                }


            }, () => {
                this.loading = false;
                this.notifier.notifyError('Error occurred. Please try again later!!');
                // this.orderNotProcessCount = "0";
            });
        	this.orderNo = "";
     
    }
	
	 setNumberOfItemsperPage() {
        this.currentPage = 1;
		this.entryLimitCount = this.itemsPerPage;
        this.loadDefaultData(false);
    }
	
	setLimitsCounts() {
		this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
			if (this.displayRangeLimit > this.totalOrdersCount) {
				this.displayRangeLimit = this.totalOrdersCount;
			}
	  }

    clearOrderNo() {
        this.orderNo = '';
    }

    searchOrders() {
		
		this.allOrdersSearched = false;
		this.loading = true;
		
        this.displayMessage = false;
        this.displayErrorMessage = false;
        this.displaySaveButton = false;
        this.startStringDate = Utils.getDateString(this.startDate);
        this.endStringDate = Utils.getDateString(this.endDate);
		this.currentPage = 0;
	
		if(this.orderNo ===  "" || this.orderNo === null)  {
		   this.orderNo = null;
		} else {
	  	  this.startStringDate = "null";
          this.endStringDate = "null"; 
		
		}
		
		this.subs3 = this.ordersService
                .getPaginationOrders(this.userDetails.email, this.itemsPerPage, this.currentPage, this.orderNo, this.startStringDate, this.endStringDate).subscribe((value: PaginationOrder[]) => {
                    this.rows = value[0].orders;
					this.totalOrdersCount = value[0].totalOrdersCount;
					this.setLimitsCounts();
                    this.rows.forEach(row => {
                        this.rowsControls.push({
                            isCollapsed: true,
                        });
                    });
		  			this.loading = false;
					
					if (this.rows.length <= 0) {
						this.notifier.notifyError('Records matching your search criteria were not found!!');
					}

                }, error1 => {
        			this.loading = false;
		              console.log(error1);
                });
        


    }
	
	
	searchAllOrders() {
        this.allOrdersSearched = true;
		this.displayMessage = false;
        this.displayErrorMessage = false;
        this.displaySaveButton = false;
        this.loading = true;
		
		this.orderNo = "";
			
		this.subs3 = this.ordersService
                .getPaginationOrders(this.userDetails.email, this.itemsPerPage, this.currentPage, null, "null", "null").subscribe((value: PaginationOrder[]) => {
                    this.rows = value[0].orders;
					this.totalOrdersCount = value[0].totalOrdersCount;
					this.setLimitsCounts();
                    this.rows.forEach(row => {
                        this.rowsControls.push({
                            isCollapsed: true,
                        });
                    });
					   this.loading = false;
					
					if (this.rows.length <= 0) {
						this.notifier.notifyError('Records matching your search criteria were not found!!');
					}

                }, error1 => {
        			this.loading = false;
		            console.log(error1);
                });
        


    }

	defaultOrderNoIfEmpty() {
		if(this.orderNo === '') {
			this.orderNo = 'undefined';
		}
	
	}

    previous() {

        this.loading = true;
        this.currentPage = this.currentPage - 1;
		if(this.allOrdersSearched) {
			this.searchAllOrders();
			return;
		}
		
		
		this.startStringDate = Utils.getDateString(this.startDate);
        this.endStringDate = Utils.getDateString(this.endDate);
	
	    this.defaultOrderNoIfEmpty();
        this.subs3 = this.ordersService
                .getPaginationOrders(this.userDetails.email, this.itemsPerPage, this.currentPage, this.orderNo, this.startStringDate, this.endStringDate).subscribe((value: PaginationOrder[]) => {
                    //         this.rows = JSON.stringify(value.body);
                     this.rows = value[0].orders;
					this.totalOrdersCount = value[0].totalOrdersCount;
					this.setLimitsCounts();
                    this.rows.forEach(row => {
                        this.rowsControls.push({
                            isCollapsed: true,
                        });
                    });
					   this.loading = false;
					if (this.rows.length <= 0) {
						this.notifier.notifyError('Records matching your search criteria were not found!!');
					}

                }, error1 => {
            		 this.loading = false;
			        console.log(error1);
                    // this.orderNotProcessCount = "0";
                });
    }


    next() {

       this.loading = true;
        this.currentPage = this.currentPage + 1;
		if(this.allOrdersSearched) {
			this.searchAllOrders();
			return;
		}
		
		this.startStringDate = Utils.getDateString(this.startDate);
        this.endStringDate = Utils.getDateString(this.endDate);
		this.defaultOrderNoIfEmpty();
        this.subs3 = this.ordersService
                .getPaginationOrders(this.userDetails.email, this.itemsPerPage, this.currentPage, this.orderNo, this.startStringDate, this.endStringDate).subscribe((value: PaginationOrder[]) => {
                    this.rows = value[0].orders;
					this.totalOrdersCount = value[0].totalOrdersCount;
					this.setLimitsCounts();
				    this.rows.forEach(row => {
                        this.rowsControls.push({
                            isCollapsed: true,
                        });
                    });
					   this.loading = false;
					if (this.rows.length <= 0) {
						this.notifier.notifyError('Records matching your search criteria were not found!!');
					}

                }, error1 => {
            		 this.loading = false;
			        console.log(error1);
                 });
    }
	
  
  
    goShopping() {
        this.router.navigate([decodeURI('/')]).then();
    }

    clearValues() {
        this.displayPrintMessage = false;
        this.message = '';
        this.displayPrintErrorMessage = false;
    }

    getWindowSize(): string {
        let modalSize = 'sm';

        if (window.matchMedia('(max-width: 767px)').matches) {
            modalSize = 'sm';
        }
        if (window.matchMedia('(max-width: 889px)').matches) {
            modalSize = 'md';
        } else {
            modalSize = 'lg';
        }
        return modalSize;
    }

    open(content, businessName: any, businessContactNumber: string) {
        this.clearValues();
        this.specialInstructions = '';
        this.businessName = businessName;
        this.contactNo = businessContactNumber;

        this.modalReference = this.modalService.open(content, {
            size: this.getWindowSize(),
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

    openOrderItems(content, selectedOrder: any) {
        this.clearValues();

        this.selectedCustomerOrder = selectedOrder;
	    this.modalReference = this.modalService.open(content, {
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

    openInvoiceModal(content, customerOrder: CustomerOrder) {
        this.clearValues();
        this.specialInstructions = '';
        this.selectedCustomerOrder = customerOrder;
        if (customerOrder.customerOrderInvoices.length > 0) {
            this.displayPrint = false;
        } else {
            this.displayPrint = true;
        }


        this.modalReference = this.modalService.open(content, {
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
        }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

    closeModal() {
        this.clearValues();
        this.specialInstructions = '';
        this.modalService.dismissAll();
    }

   printOrders() {
    this.displayMessage = false;
    this.displayErrorMessage = false;
	this.loading = true;
    this.displaySaveButton = false;
    this.startStringDate = Utils.getDateString(this.startDate);
    this.endStringDate = Utils.getDateString(this.endDate);
     
	if(this.orderNo === null || this.orderNo === undefined || this.orderNo === '') {
		this.printByDatesOnly();
	} else {
		this.printOrdersByOrderNoReport();
	}

  }

  generatePDF(value: any) {
			
			if([value][0].byteLength > 0) {
			// It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([value], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
			this.loading = false;

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL  = window.URL.createObjectURL(newBlob);

			let tab = window.open(); 
			tab.location.href = fileURL;
			} else {
				 this.loading = false;
	     		 this.message = 'No records founds to generate report. Please try different options!!';
			     this.notifier.notify(this.message, 'erprintOrdersByOrderNoReportror');

			}
  }

  printByDatesOnly() {
		  this.loading = true;
	 
         this.subs2 = this.ordersService
        .printOrdersReportByDatesByProfile(this.startStringDate, this.endStringDate, Number(this.profileId)).subscribe(value  => {
          
				this.generatePDF(value);
			
           }, error1 => {
		    this.loading = false;
           this.message = 'Error generating report Please try again!!';
           this.notifier.notify(this.message, 'error');

	       this.displayPrintErrorMessage = true;
        });

  }

  printOrdersByOrderNoReport() {
	   this.loading = true;
	    this.subs2 = this.ordersService
        .printOrdersReportByOrderNo(this.orderNo,  Number(this.profileId)).subscribe(value  => {
            this.displayMessage = true;
			this.orderNo = "";
			this.generatePDF(value);
	
        }, error1 => {
		    this.loading = false;
           this.orderNo = "";
		   this.message = 'Error generating report Please try again!!';
           this.notifier.notify(this.message, 'error');
	       this.displayPrintErrorMessage = true;
        });


  }


   setCartItem(selectedProduct: Product) {
	    this.selectedCartItem = new CartItem();
        this.selectedCartItem.product = selectedProduct;
        this.selectedCartItem.quantity = 1;
        this.selectedCartItem.image = selectedProduct.defaultImageUrl;
        this.selectedCartItem.maxCount = selectedProduct.unitsInStock;
		
	}


	printOrder(customerOrderNo: string) {
	    this.orderNo = customerOrderNo;
	  
		this.printOrdersByOrderNoReport();
	
	}

}
