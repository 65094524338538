<div class="container-fluid">
    <div class="container-fluid px-0 py-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-primary">
                <li class="breadcrumb-item "><a routerLink="/dashboard" class="text-white">Dashboard</a></li>
                <li class="breadcrumb-item "><a [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-businesses']}}]"
                                                skipLocationChange  class="text-white">Manage Business</a></li>
                <li class="breadcrumb-item "><a [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-businesses']}}]"
                                                skipLocationChange  class="text-white">Connect With Business</a></li>
                <li class="breadcrumb-item text-success" aria-current="page">{{selectedBusiness.businessName}}</li>
            </ol>
        </nav>
    </div>


    <div class="container-fluid p-0 m-0">
        <div class="row py-3 mb-2 col-12 mx-0 px-0">
            <div class="col-sm-12 col-md-3 float-left my-1 mx-0">
                <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target="#updateLogo">
                    Update Logo
                </button>
            </div>

            <div class="col-sm-12 col-md-3 float-right my-1 mx-0">
                <button type="button" class="btn btn-primary btn-block" data-toggle="modal"
                        data-target="#updateBillboard">
                    Update Banner
                </button>
            </div>

        </div>
        <div class="row">
            <div class="jumbotron w-100"
                 [ngStyle]="{'background-image': getUrl(selectedBusiness.billboardUrl), 'background-size':'cover'}">

                <myafrica-business-logo-edit [selectedBusiness]="selectedBusiness"></myafrica-business-logo-edit>
            </div>
        </div>
    </div>
</div>


<myafrica-business-details-tabs [selectedBusiness]="selectedBusiness"></myafrica-business-details-tabs>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


<!-- Modal -->
<div class="modal fade" id="updateLogo" tabindex="-1" role="dialog" aria-labelledby="updateLogoTitle"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary  text-white">
                <h3 class="modal-title text-center mx-auto"
                    id="exampleModalLabel">Update Business Logo</h3>
                <i class="fa fa-times text-white" data-dismiss="modal" aria-label="Close"></i>
            </div>
            <div class="modal-body">
                <img [src]="logoUrl" height="200" class="img-fluid"> <br/>
                <input type='file' class="pt-3" (change)="selectLogo($event)">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="uploadLogo()">Update Logo
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="updateBillboard" tabindex="-1" role="dialog" aria-labelledby="updateBillboardTitle"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary  text-white">
                <h3 class="modal-title text-center mx-auto"
                    id="updateBillboardTitle">Update Banner</h3>
                <i class="fa fa-times text-white" data-dismiss="modal" aria-label="Close"></i>
            </div>
            <div class="modal-body">
                <img [src]="billboardUrl" height="200" class="img-fluid"> <br/>
                <input type='file' class="pt-3" (change)="selectBillboard($event)">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="uploadBillboard()">Save
                    changes
                </button>
            </div>
        </div>
    </div>
</div>
