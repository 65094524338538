import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {SearchFieldService} from './search-field/searchFieldService';
import {ApplicationStore} from './interceptors/application-store.service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DatePipe} from '@angular/common';
import {AuthGuard} from './auth.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth.interceptor';
import {RegisterComponent} from './register/register.component';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HomeComponent} from './home/home.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {TermsComponent} from './terms/terms.component';
import {LoginComponent} from './login/login.component';
import {CartItemsComponent} from './cart-items/cart-items.component';
import {CartItemsCountComponent} from './cart-items-count/cart-items-count.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {LoaderComponent} from './loader/loader.component';
import {NgxLoadingModule} from 'ngx-loading';
import {PageTitleComponent} from './page-title/page-title.component';
import {NgbCarouselConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ProductItemComponent} from './product-item/product-item.component';
import {OutOfStockComponent} from './out-of-stock/out-of-stock.component';
import {AddToBasketButtonComponent} from './add-to-basket-button/add-to-basket-button.component';
import {TruncatePipe} from './TruncatePipe';
import {ClipboardModule} from 'ngx-clipboard';
import {MegaMenuComponent} from './mega-menu/mega-menu.component';
import {ProductViewComponent} from './product-view/product-view.component';
import {ProductInfoComponent} from './product-info/product-info.component';
import {ProductHistoryViewComponent} from './product-history-view/product-history-view.component';
import {BusinessSnipetComponent} from './business-snipet/business-snipet.component';
import {AddProductToCartComponent} from './add-product-to-cart/add-product-to-cart.component';
import {BusinessItemComponent} from './business-item/business-item.component';
import {AdvertSlidesComponent} from './advert-slides/advert-slides.component';
import {ClothingComponent} from './shopping/clothing/clothing.component';
import {ShoppingComponent} from './shopping/shopping.component';
import {ProductPriceComponent} from './product-price/product-price.component';
import {IncreaseCartItemsComponent} from './increase-cart-items/increase-cart-items.component';
import {ClothingColourComponent} from './clothing-colour/clothing-colour.component';
import {BusinessDetailsComponent} from './business-details/business-details.component';
import {AboutBusinessComponent} from './about-business/about-business.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {AllProductsComponent} from './all-products/all-products.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {DeliveryOptionsComponent} from './delivery-options/delivery-options.component';
import {ShoppingCartModalsComponent} from './shopping/shopping-cart/modals/shopping-cart-modals/shopping-cart-modals.component';
import {AllBusinessesComponent} from './all-businesses/all-businesses.component';
import {FaqComponent} from './faq/faq.component';
import {OrderTrackerComponent} from './order-tracker/order-tracker.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NewsletterSignupComponent} from './newsletter-signup/newsletter-signup.component';
import {RegSuccessComponent} from './register/reg-success/reg-success.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {ProfileComponent} from './dashboard/profile/profile.component';
import {ProductsComponent} from './dashboard/products/products.component';
import {BusinessesComponent} from './dashboard/businesses/businesses.component';
import {ChangePasswordComponent} from './dashboard/change-password/change-password.component';
import {VendorAgreementComponent} from './dashboard/vendor-agreement/vendor-agreement.component';
import {OrdersComponent} from './dashboard/orders/orders.component';
import {ReturnsComponent} from './dashboard/returns/returns.component';
import {DashboardHomeComponent} from './dashboard/dashboard-home/dashboard-home.component';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {AddCustomerReturnComponent} from './dashboard/returns/add-customer-return/add-customer-return.component';
import {EditCustomerReturnComponent} from './dashboard/returns/edit-customer-return/edit-customer-return.component';
import {EditBusinessComponent} from './dashboard/businesses/edit-business/edit-business.component';
import {AdminBusinessItemComponent} from './dashboard/businesses/admin-business-item/admin-business-item.component';
import {BusinessLogoEditComponent} from './dashboard/businesses/business-logo-edit/business-logo-edit.component';
import {BusinessDetailsTabsComponent} from './dashboard/businesses/business-details-tabs/business-details-tabs.component';
import {BusinessAdditionalDetailsComponent} from './dashboard/businesses/business-additional-details/business-additional-details.component';
import {BusinessAddressDetailsComponent} from './dashboard/businesses/business-address-details/business-address-details.component';
import {BusinessBankingDetailsComponent} from './dashboard/businesses/business-banking-details/business-banking-details.component';
import {BusinessHoursComponent} from './dashboard/businesses/business-hours/business-hours.component';
import {BusinessProductsComponent} from './dashboard/businesses/business-products/business-products.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import {Ng2ImgMaxModule} from 'ng2-img-max';
import {ProductHistoryComponent} from './dashboard/product-history/product-history.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {EditProductComponent} from './dashboard/edit-product/edit-product.component';
import {ProductDetailsEditComponent} from './dashboard/edit-product/product-details-edit/product-details-edit.component';
import {ProductColourEditComponent} from './dashboard/edit-product/product-colour-edit/product-colour-edit.component';
import {AdminClerkDashboardComponent} from './dashboard/admin-clerk-dashboard/admin-clerk-dashboard.component';
import {AdminManageReturnsComponent} from './dashboard/admin-manage-returns/admin-manage-returns.component';
import {AdminManageOrdersComponent} from './dashboard/admin-manage-orders/admin-manage-orders.component';
import {RegisterOptionComponent} from './register/register-option/register-option.component';
import {UserRegistrationComponent} from './register/user-registration/user-registration.component';
import {ShopperDashboardComponent} from './shopper-dashboard/shopper-dashboard.component';
import {ShopperOrdersComponent} from './shopper-dashboard/shopper-orders/shopper-orders.component';
import {ShopperReturnsComponent} from './shopper-dashboard/shopper-returns/shopper-returns.component';
import {ActivationSuccessComponent} from './register/activation-success/activation-success.component';
import {ActivationFailedComponent} from './register/activation-failed/activation-failed.component';
import {ActivateAccountComponent} from './register/activate-account/activate-account.component';
import {BreadcrumbsComponent} from './dashboard/breadcrumbs/breadcrumbs.component';
import {ShopperAgreementComponent} from './shopper-dashboard/shopper-agreement/shopper-agreement.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {EditHistoryComponent} from './dashboard/edit-product/edit-history/edit-history.component';
import {ProductIconComponent} from './product-icon/product-icon.component';
import {ProfileAddressComponent} from './dashboard/profile-address/profile-address.component';
import {ShopperAddressesComponent} from './shopper-dashboard/shopper-addresses/shopper-addresses.component';
import {PlaceOrderSuccessComponent} from './shopping-cart/place-order-success/place-order-success.component';
import {EftPlaceOrderSuccessComponent} from './shopping-cart/eft-place-order-success/eft-place-order-success.component';
import {EftPlaceOrderCancelledComponent} from './shopping-cart/eft-place-order-cancelled/eft-place-order-cancelled.component';
import {AllClothingProductsComponent} from './shopping/clothing/all-clothing-products/all-clothing-products.component';
import {ClothingResolver} from './services/resolvers/clothing.resolver';
import {ClothingService} from './services/clothing.service';
import {ClothingProductResolver} from './services/resolvers/clothing-product.resolver';
import {AdminReportsComponent} from './dashboard/admin-reports/admin-reports.component';
import {ShopperInvoicesComponent} from './shopper-dashboard/shopper-invoices/shopper-invoices.component';
import {BusinessDetailsResolver} from './services/resolvers/business-details.resolver';
import {CanLoadGuard} from './CanLoadGuard';
import {DashboardResolver} from './services/resolvers/dashboard.resolver';
import {ShopperManageReturnsComponent} from './shopper-dashboard/shopper-manage-returns/shopper-manage-returns.component';
import {ClothingProductsComponent} from './shopping/clothing/clothing-products/clothing-products.component';
import {SellerFeedbackComponent} from './dashboard/seller-feedback/seller-feedback.component';
import {ProductDimensionsComponent} from './product-dimensions/product-dimensions.component';
import {AdminManageDeliveriesComponent} from './dashboard/admin-manage-deliveries/admin-manage-deliveries.component';
import {TransactionsComponent} from './dashboard/transactions/transactions.component';
import { EditCustomerOrderComponent } from './dashboard/edit-customer-order/edit-customer-order.component';
import { OrderDetailsComponent } from './dashboard/edit-customer-order/order-details/order-details.component';
import { OrderDeliveryComponent } from './dashboard/edit-customer-order/order-delivery/order-delivery.component';
import { CaptureRequiredFieldsMessageComponent } from './shared/components/capture-required-fields-message/capture-required-fields-message.component';
import { AddProductComponent } from './dashboard/add-product/add-product.component';
import { AddProductDetailsComponent } from './dashboard/add-product-details/add-product-details.component';
import { DynamicMegaMenuComponent } from './dynamic-mega-menu/dynamic-mega-menu.component';
import { ProductsListComponent } from './products-list/products-list.component';

@NgModule({
    declarations: [
        AppComponent, ActivationSuccessComponent,
        ActivationFailedComponent,
        ActivateAccountComponent,
        RegisterComponent,
        FooterComponent,
        NavbarComponent,
        HomeComponent,
        AboutUsComponent,
        TermsComponent,
        LoginComponent,
        CartItemsComponent,
        CartItemsCountComponent,
        NotFoundComponent,
        LoaderComponent,
        PageTitleComponent,
        ProductItemComponent,
        OutOfStockComponent,
        AddToBasketButtonComponent,
        TruncatePipe,
        MegaMenuComponent,
        ProductViewComponent,
        ProductInfoComponent,
        ProductHistoryViewComponent,
        BusinessSnipetComponent,
        AddProductToCartComponent,
        BusinessItemComponent,
        AdvertSlidesComponent,
        ClothingComponent,
        ShoppingComponent,
        ProductPriceComponent,
        IncreaseCartItemsComponent,
        ClothingColourComponent,
        BusinessDetailsComponent,
        AboutBusinessComponent,
        ShoppingCartComponent,
        AllProductsComponent,
        ContactUsComponent,
        DeliveryOptionsComponent,
        ShoppingCartModalsComponent,
        AllBusinessesComponent,
        FaqComponent,
        OrderTrackerComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        NewsletterSignupComponent,
        RegSuccessComponent,
        DashboardComponent,
        SearchResultsComponent,
        ProfileComponent,
        ProductsComponent,
        BusinessesComponent,
        ChangePasswordComponent,
        VendorAgreementComponent,
        OrdersComponent,
        ReturnsComponent,
        DashboardHomeComponent,
        AddCustomerReturnComponent,
        EditCustomerReturnComponent,
        EditBusinessComponent,
        AdminBusinessItemComponent,
        BusinessLogoEditComponent,
        BusinessDetailsTabsComponent,
        BusinessAdditionalDetailsComponent,
        BusinessAddressDetailsComponent,
        BusinessBankingDetailsComponent,
        BusinessHoursComponent,
        BusinessProductsComponent,
        ProductHistoryComponent,
        EditProductComponent,
        ProductDetailsEditComponent,
        ProductColourEditComponent,
        AdminClerkDashboardComponent,
        AdminManageReturnsComponent,
        AdminManageOrdersComponent,
        RegisterOptionComponent,
        UserRegistrationComponent,
        ShopperDashboardComponent,
        ShopperOrdersComponent,
        ShopperReturnsComponent,
        BreadcrumbsComponent,
        ShopperAgreementComponent,
        EditHistoryComponent,
        ProductIconComponent,
        ProfileAddressComponent,
        ShopperAddressesComponent,
        PlaceOrderSuccessComponent,
        EftPlaceOrderSuccessComponent,
        EftPlaceOrderCancelledComponent,
        AllClothingProductsComponent,
        AdminReportsComponent,
        ShopperInvoicesComponent,
        ShopperManageReturnsComponent,
        ClothingProductsComponent,
        SellerFeedbackComponent,
        ProductDimensionsComponent,
        AdminManageDeliveriesComponent,
        TransactionsComponent,
        EditCustomerOrderComponent,
        OrderDetailsComponent,
        OrderDeliveryComponent,
        CaptureRequiredFieldsMessageComponent,
        AddProductComponent,
        AddProductDetailsComponent,
        DynamicMegaMenuComponent,
        ProductsListComponent,
    ],
    imports: [
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        ClipboardModule,
        ReactiveFormsModule,
        ChartsModule,
        Ng2ImgMaxModule,
        ColorPickerModule,
        AngularEditorModule,
        NgIdleKeepaliveModule.forRoot(),
        NgxLoadingModule.forRoot({}),
    ],
    providers: [
        DatePipe,
        AuthGuard,
        SearchFieldService,
        ApplicationStore,
        NgbCarouselConfig,
        ThemeService,
        NgxImageCompressService,
        ClothingResolver,
        ClothingService,
        ClothingProductResolver,
        BusinessDetailsResolver,
        DashboardResolver,
        CanLoadGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
