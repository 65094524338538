import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationStore} from '../../../interceptors/application-store.service';
import {CodeTablesServiceService} from '../../../shared/code-tables-service.service';
import {Subscription} from 'rxjs';
import {ProductService} from '../../../services/product.service';
import {PaginationProduct} from '../../../model/PaginationProduct';
import {ClothingGender} from '../../../model/ClothingGender';
import {ClothingGroup} from '../../../model/ClothingGroup';
import {ClothingCategory} from '../../../model/ClothingCategory';
import {NotifierService} from '../../../services/notifier.service';

@Component({
    selector: 'myafrica-clothing-products',
    templateUrl: './clothing-products.component.html',
    styleUrls: ['./clothing-products.component.scss'],
})
export class ClothingProductsComponent implements OnInit, OnDestroy {

    breadCrumb: string;
    allProducts = [];
    clothingSizes = [];
    subs: Subscription;
    subs1: Subscription;
    subs2: Subscription;
    subs3: Subscription;
    subs4: Subscription;
    searchedProductName = '';
    searchProductName = '';

    entryLimitCount = 12; // entries per page
    displayEntryLimitCount = 12; // entries per page
    currentPage = 1;
    totalProducts = 0;

    displayRangeLimit: number;


    clothingGenders: ClothingGender[];
    clothingGroups: ClothingGroup[];
    clothingCategories: ClothingCategory[];

    orderBy = ' ';
    productCategory = 'all';
    productCategoryMap: Map<string, string>;
    clothingSize = 'all';
    displayNoRecordsFoundMessage: boolean;
    loading: boolean;
    displayErrorMessage: boolean;
    displayMessage = '';

    itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];

    selectedItem = '';

    orderByList = [{
        id: 0,
        name: 'Sort By',
        sortValue: ' ',
    }, {
        id: 1,
        name: 'Price:High to Low',
        sortValue: 'desc',
    }, {
        id: 2,
        name: 'Price:Low to High',
        sortValue: 'asc',
    }];

    constructor(private productService: ProductService, private router: Router,
                private appStore: ApplicationStore, private tableService: CodeTablesServiceService,
                private notifier: NotifierService) {

    }


    ngOnInit() {
        this.breadCrumb = this.appStore.getItem('breadCrumb');
        this.currentPage = this.currentPage - 1;
        this.displayNoRecordsFoundMessage = false;
        this.loading = true;
        this.searchedProductName = 'undefined';

        this.productCategory = this.appStore.getItem('clothingCategory');

        this.displayErrorMessage = false;
        this.subs1 = this.productService.getPaginationClothingProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, ' ', this.clothingSize).subscribe((value: PaginationProduct[]) => {
                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.clothingSizes = value[0].clothingSizes;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                this.loading = false;

                if (this.totalProducts <= 0) {
                    this.displayNoRecordsFoundMessage = true;
                }

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }

            }, () => {
                this.loading = false;
                this.displayErrorMessage = true;
            },
        );

    }

    previous() {

        this.currentPage = this.currentPage - 1;

        this.searchedProductName = 'undefined';
        this.loading = true;
        this.displayErrorMessage = false;

        this.productCategory = this.appStore.getItem('clothingCategory');

        this.subs3 = this.productService.getPaginationClothingProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy, this.clothingSize).subscribe((value: PaginationProduct[]) => {
                this.loading = false;

                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            }, () => {
                this.loading = false;
                this.displayErrorMessage = true;
            },
        );
    }

    next() {
        this.currentPage = this.currentPage + 1;
        this.searchedProductName = 'undefined';

        this.productCategory = this.appStore.getItem('clothingCategory');
        this.loading = true;
        this.displayErrorMessage = false;


        this.subs4 = this.productService.getPaginationClothingProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy, this.clothingSize).subscribe((value: PaginationProduct[]) => {
                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                this.loading = false;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            }, () => {
                this.loading = false;
                this.displayErrorMessage = true;
            },
        );
    }

    reSearchForProduct() {
        this.currentPage = 1;
        this.totalProducts = 0;
        this.searchedProductName = 'undefined';
        this.displayNoRecordsFoundMessage = false;

        this.productCategory = this.appStore.getItem('clothingCategory');

        this.subs2 = this.productService.getPaginationClothingProductsByName(this.searchedProductName.toLowerCase(),
            this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy, this.clothingSize).subscribe((value: PaginationProduct[]) => {
                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.totalProducts <= 0) {
                    this.displayNoRecordsFoundMessage = true;
                }

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            },
        );

    }

    searchForProductField() {
        if (this.searchProductName === undefined || this.searchProductName === null || this.searchProductName == '') {
            this.searchProductName = 'all';
        }
        this.searchedProductName = this.searchProductName;
        this.displayMessage = '';
        this.loading = true;
        this.productCategory = this.appStore.getItem('clothingCategory');
        this.displayNoRecordsFoundMessage = false;
        this.currentPage = 0;
        this.displayErrorMessage = false;
        if (this.searchProductName === '') {
            this.displayMessage = 'Please enter search criteria!!';
            this.displayErrorMessage = true;
            return;
        }

        this.subs2 = this.productService.getPaginationClothingProductsByName(this.searchProductName.toLowerCase(),
            this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy, this.clothingSize).subscribe((value: PaginationProduct[]) => {

                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.clothingSizes = value[0].clothingSizes;

                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                this.loading = false;

                if (this.totalProducts <= 0) {
                    this.displayNoRecordsFoundMessage = true;
                    this.notifier.notify('No products were found matching criteria!!!', 'error');

                }

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            }, () => {
                this.loading = false;
                this.displayErrorMessage = true;

            },
        );

    }

    searchForProduct() {
        this.searchedProductName = 'undefined';
        this.loading = true;

        this.productCategory = this.appStore.getItem('clothingCategory');
        this.displayNoRecordsFoundMessage = false;
        this.currentPage = 0;
        this.displayErrorMessage = false;


        this.subs2 = this.productService.getPaginationClothingProductsByName(this.searchedProductName.toLowerCase(),
            this.entryLimitCount, this.currentPage,
            this.productCategory, this.orderBy, this.clothingSize).subscribe((value: PaginationProduct[]) => {

                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                this.loading = false;

                if (this.totalProducts <= 0) {
                    this.displayNoRecordsFoundMessage = true;
                    this.notifier.notify('No products were found matching criteria!!!', 'error');
                }

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
            }, () => {
                this.loading = false;
                this.displayErrorMessage = true;
            },
        );

    }

    searchBySize(sizeId: string) {
        this.displayErrorMessage = false;
        this.loading = true;

        this.subs1 = this.productService.getPaginationClothingProductsByName(this.searchedProductName, this.entryLimitCount, this.currentPage,
            this.productCategory, ' ', sizeId).subscribe((value: PaginationProduct[]) => {
                this.allProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                //  this.clothingSizes = value[0].clothingSizes;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                this.loading = false;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }

            }, () => {
                this.loading = false;
                this.displayErrorMessage = true;
            },
        );

    }


    getProductsByNameByOrder() {
        this.searchForProduct();
    }

    setNumberOfItemsPerPage() {
        this.displayEntryLimitCount = this.entryLimitCount;
        this.getProductsByNameByOrder();
    }


    ngOnDestroy() {
        if (this.subs1) {
            this.subs1.unsubscribe();
        }

        if (this.subs2) {
            this.subs2.unsubscribe();
        }

        if (this.subs3) {
            this.subs1.unsubscribe();
        }

        if (this.subs4) {
            this.subs1.unsubscribe();
        }
    }


}
