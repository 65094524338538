import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CodeTablesServiceService} from '../../shared/code-tables-service.service';
import {BusinessCategory} from '../../model/BusinessCategory';
import {Business, businessWithUpdatedImages} from '../../model/Business';
import {Province} from '../../model/Province';
import {BusinessServiceService} from '../../shared/services/business/business-service.service';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {NotifierService} from '../../services/notifier.service';

@Component({
    selector: 'myafrica-businesses',
    templateUrl: './businesses.component.html',
    styleUrls: ['./businesses.component.scss'],
})
export class BusinessesComponent implements OnInit, OnDestroy {

    pageHeaderLabel = 'Manage Businesses';
    pageHeaderSmallLabel = ' ';
    label = 'Businesses';
    icon = faSearch;
    plusIcon = faPlus;
    buttonLabel = 'ADD Business';
    buttonIcon = 'fa fa-plus';
    closeResult: string;

    businessCategories: BusinessCategory[];

    provinces: any[];
    businesses: Business[];
    buttonDisabled = false;

    business: Business = new Business();

    businessId = 0;
    businessName: string;
	businessEmail: string;
    businessCategory: string;
    businessProvince: string;

    regNumberPart1: string;
    regNumberPart2: string;
    regNumberPart3: string;

    yearsInBusiness: number;

    addressline1: string;
    streetname: string;
    city: string;
    zipcode: string;

    subs1: Subscription;
    subs2: Subscription;
    subs3: Subscription;

    displayMessage = '';
    displayErrorMessage: boolean;
    displaySuccessMessage: boolean;
    loading = false;
    businessNameSearch = '';
    profileId: string;


    constructor(private modalService: NgbModal,
                private tableService: CodeTablesServiceService,
                private notifier: NotifierService,
                private businessService: BusinessServiceService,
                private router: Router,
                private appStore: ApplicationStore) {

    }

    ngOnInit() {
        this.initialiseData();

    }

    open(content) {
        // clear the business data if it was populated before
        this.business = new Business();
        this.initialiseData();
        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            },
        );
    }

    private defaultBusinessCategory() {
		this.businessCategories.forEach((element, index) => {
		  if (index === 0) {
			this.businessCategory = element.categoryId;
		  }
		})

	}

    private initialiseData(): void {
        this.loading = true;
		const businessCategoriesStored = JSON.parse(sessionStorage.getItem('businessCategories'));
		if (businessCategoriesStored === undefined || businessCategoriesStored === null) {
      
			this.subs1 = this.tableService.getBusinessCategories().subscribe((value: BusinessCategory[]) => {
					this.businessCategories = value;
					sessionStorage.setItem('businessCategories', JSON.stringify(this.businessCategories));
                    this.defaultBusinessCategory();
				},
			);
		}  else {
            this.businessCategories = businessCategoriesStored;
			this.defaultBusinessCategory();
		}
	   	this.provinces = JSON.parse(sessionStorage.getItem('provinces'));
		this.provinces.forEach(element => {
                    if (element.name.toLowerCase() === 'GAUTENG'.toLowerCase()) {
                        this.businessProvince = element.id;
                    }
                });


	    this.profileId = sessionStorage.getItem('profileId');
	    this.subs3 = this.businessService.getBusinessesByProfileId(this.profileId).subscribe((value: Business[]) => {
				this.businesses = value.map(bus => businessWithUpdatedImages(bus));
    			sessionStorage.setItem('businesses', JSON.stringify(this.businesses));
				this.loading = false;
            }, error1 => {
				alert(JSON.stringify(error1))
			},
        );

	    this.loading = false;
    
		this.regNumberPart1 = "";
		this.regNumberPart2 = "";
		this.regNumberPart3 = "";

    }

    private onlyDigits(s) {
        for (let i = s.length - 1; i >= 0; i--) {
            const d = s.charCodeAt(i);
            if (d < 48 || d > 57) {
                return false;
            }
        }
        return true;
    }

    private validYearRegNumber() {

        const minYear = 1700;

        const currentYear = new Date().getFullYear();


        const yearData = parseInt(this.regNumberPart1, 0);
        let isNumber = false;

        isNumber = this.onlyDigits(this.regNumberPart1);

        if (!isNumber || yearData < minYear || yearData > currentYear) {
            this.displayErrorMessage = true;
            this.displayMessage = 'Registration number is invalid. First 4 letters do not represent a valid year!!';
            this.buttonDisabled = false;
            return false;
        }

        return true;
    }


    addBusiness(businessValue, modal) {


        this.loading = true;

        if (this.businessName === null || this.businessName === undefined || this.businessName === '') {

            this.displayErrorMessage = true;

            this.loading = false;
            this.notifier.notify('Please fill in the business name!!', 'error');
            return false;
        }
		
		   if (this.businessEmail === null || this.businessEmail === undefined || this.businessEmail === '') {

            this.displayErrorMessage = true;

            this.loading = false;
            this.notifier.notify('Please fill in the business email!!', 'error');
            return false;
        }

      
        if (this.addressline1 === null || this.addressline1 === undefined || this.addressline1 === '') {

            this.displayErrorMessage = true;

            this.loading = false;
            this.notifier.notify('Please fill in the adddress details!!', 'error');
            return false;
        }

        if (this.streetname === null || this.streetname === undefined || this.streetname === '') {

            this.displayErrorMessage = true;

            this.loading = false;
            this.notifier.notify('Please fill in the street name!!', 'error');
            return false;
        }

        if (this.city === null || this.city === undefined || this.city === '') {

            this.displayErrorMessage = true;

            this.loading = false;
            this.notifier.notify('Please fill in the city!!', 'error');
            return false;
        }

   
        //if (!this.validYearRegNumber()) {
        //    return;
       // }


        if (this.yearsInBusiness === undefined || this.yearsInBusiness === 0) {
            this.notifier.notify('Please fill in the number of years in business!!', 'error');
            return;
        }
	
	   const regNumber = this.buildRegNumber(this.regNumberPart1, this.regNumberPart2, this.regNumberPart3);
        const businessJSONObject = {
            businessName: this.businessName,
			ciproRegNumber: regNumber,
			yearsInBusiness: this.yearsInBusiness,
			businessEmail: this.businessEmail,
            businessAddress: {
                complex: this.addressline1,
                streetname: this.streetname,
                city: this.city,
                province: { id: this.businessProvince},
                zipcode: this.zipcode,
            },
            businessCategory: {
                id: this.businessCategory,
            },
            businessProvince: {
                id: this.businessProvince,
            },

        };


	    if (sessionStorage.getItem('userDetails')) {
            const profileId = sessionStorage.getItem('profileId');
            this.subs3 = this.businessService.addBusiness(profileId, businessJSONObject).subscribe(value => {
		
				this.displayErrorMessage = false;
                this.displaySuccessMessage = true;
                this.displayMessage = 'Business successfully added!!';
        
				this.loading = false;
				modal.close('');
				this.closeBusinessModal();
			
                this.notifier.notify('Business added successfully!! Please remember to atleast add a product to the business. Business will not be active on  site until one product is added to the business!', 'success');

			// this.confirmationDialogService.confirm('Business added successfully!!',
                // 'Please remember to atleast add a product to the business.
                // Business will not be active on  site until one product is added to the business!!')
                // .then((confirmed) => this.closeBusinessModal())
                // .catch(() => this.closeBusinessModal());

            }, error1 => {
                this.buttonDisabled = false;
        		modal.close('');
		    	this.closeBusinessModal();
				if (parseInt(error1.status) === 409) {
                    this.notifier.notify('Failed to add new business. Business with similar registartion number exist!!', 'error');
                } else {
					alert('error diapls');
			
					this.notifier.notify('Failed to add new business. Please try again later!!', 'error');
                }
                this.displayErrorMessage = true;
                this.displaySuccessMessage = false;

            });
        } else {
            this.router.navigate(['']);
        }
    }

    buildRegNumber(regNumberPart1, regNumberPart2, regNumberPart3) {
        return regNumberPart1.concat('-').concat(regNumberPart2).concat('-').concat(regNumberPart3);
    }

    navigate(path: string) {
        this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
    }

    closeBusinessModal() {

        Utils.delay(4000).then(() => {

            this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
                this.navigate('manage-businesses');
            });

        });
    }

    searchForBusiness() {
        if (this.businessNameSearch === '') {
            return;
        }
        // let profileId = userDetails.userDetailsId;
        this.subs2 = this.businessService
            .getBusinessByNameAndProfile(this.businessNameSearch, this.profileId).subscribe((value: Business[]) => {
                    this.businesses = value;

                    if (this.businesses.length === 0) {
                        this.notifier.notify('Records matching your search criteria were not found!!', 'error');

                    }
                },
            );

    }

    ngOnDestroy() {
        this.doUnsub(this.subs1);
        this.doUnsub(this.subs2);
        this.doUnsub(this.subs3);
    }

    doUnsub(subs: Subscription) {
        if (subs) {
            subs.unsubscribe();
        }
    }
}
