import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RequestHeader} from './RequestHeader';
import {Business} from '../model/Business';
import { environment } from '../../environments/environment';
import {CategoryModel} from '../model/CategoryModel';

@Injectable({
    providedIn: 'root'
})
export class CodeTablesServiceService extends RequestHeader {

    items: Array<CategoryModel>;

    constructor(private http: HttpClient) {
        super();


    }

    getProductCategories() {
        // TODO: Retrieve this form the database
        this.items = [new CategoryModel('clothing', 'Clothing/Fashion'),
            new CategoryModel('skincare', 'Skin Care'),
            new CategoryModel('books', 'Books'),
            new CategoryModel('art', 'Art'),
            new CategoryModel('music', 'Music'),
            new CategoryModel('accessories', 'Accessories & Jewellery'),
            new CategoryModel('gadgets', 'Computers & Gadgets')];

        return this.items;

    }

    getBusinessCategories() {
        return this.http.get('myafrica-api/all/getbusinesscategories');
    }

    getProvinces() {
        return this.http.get('myafrica-api/public/all/getprovinces');
    }


    getBankAccountTypes() {
        return this.http.get('myafrica-api/all/getbankaccounttypes');
    }


    getBanks() {
        return this.http.get('myafrica-api/public/all/getsupportedbanks');
    }

    /**
     * Returns the fees on a product which forms part of the price
     */
    getProductFees() {
        return this.http.get('myafrica-api/active/product/fees');

    }

    getClothingGenders() {
        return this.http.get('myafrica-api/public/genders');
    }

     /**
     * Returns the clothins sizes codes
     */
    getClothingSizeCodes() {
        return this.http.get('myafrica-api/all/getclothingsizes');

    }

	getBusinessMonthlyRevenues() {
    return this.http.get('myafrica-api/all/business/monthly/revenues');
  }


}
