import {Component, Input, OnInit} from '@angular/core';
import {CartItem} from '../../../../model/CartItem';

@Component({
    selector: 'myafrica-shopping-cart-modals',
    templateUrl: './shopping-cart-modals.component.html',
    styleUrls: ['./shopping-cart-modals.component.scss'],
})
export class ShoppingCartModalsComponent implements OnInit {

    @Input() cartItem: CartItem;

    constructor() {
    }

    ngOnInit() {
    }

}
