<myafrica-mega-menu></myafrica-mega-menu>
<myafrica-advert-slides></myafrica-advert-slides>

<!-- myafrica-clothing-menu-bar></myafrica-clothing-menu-bar-->
<div class="container">
  <myafrica-page-title pageTitle="All Clothing Products"></myafrica-page-title>
</div>
<div class="container">
    <div class="input-group  d-md-inline-flex searchfield" style="height: 50px">
 
  <input type="text" placeholder="Search for clothing products..." 
  (keyup.enter)="searchForProductField()" [(ngModel)]="searchProductName"
               class="form-control search-input h-100" aria-label="Text input with dropdown button"
         >
  <div class="input-group-append">
    <button class="btn search-btn-right" (click)="searchForProductField()">
      <i  class="fa fa-search"></i>
    </button>
  </div>
</div>

<div class="col-12 col-md-3 p-0 error-message-bold" *ngIf="displayErrorMessage">
	{{displayMessage}}
  </div>
</div>
  <br/>
<div class="container">
<div class="row bg-warning rounded-bottom rounded-top shadow pb-3" style="padding-bottom: 15px;">
      <div class="col-5 col-md-4 pull-left">
				<span *ngIf="totalProducts > 0" style="color: white">
					{{((currentPage) * displayEntryLimitCount) + 1}} -
          {{displayRangeLimit}} of {{totalProducts}} </span> <span
        *ngIf="totalProducts <= 0"> </span>
      </div>

      <div class="input-group col-md-4 mb-3 input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">Show</span>
        </div>
        <select style="color: #4d4d4d;" [(ngModel)]="entryLimitCount" class="form-control" name="entryLimitCount"
                (ngModelChange)="setNumberOfItemsPerPage()">

          <option [value]="item.id" *ngFor="let item of itemsPerPageList">{{item.id}}</option>

        </select>
        <div class="input-group-append">
          <span class="input-group-text">per page</span>
        </div>
      </div>

      <div class="col-12 col-md-4 input-group-sm" style="color: white; ">
        <select style="color: #4d4d4d;" [(ngModel)]="orderBy" class="form-control" name="orderBy"
                (ngModelChange)="getProductsByNameByOrder()">

          <option [value]="item.sortValue" *ngFor="let item of orderByList">{{item.name}}</option>

        </select>

      </div>
    </div>
    </div>
<br/>
<!--  div class="container">
	<ol class="breadcrumb breadcrumb-arrow">
		<li><a href="#">Men</a></li>
		<li class="active"><a  [routerLink]="'/shopping/clothing/category'">Clothing</a></li>
	</ol>
</div-->

<div class="container">
  <div class="row">

    <div *ngIf="totalProducts > 0" class="col-2 row-offcanvas row-offcanvas-left"
         class="d-none d-md-block d-sm-block">

      <div id="sidebar" class="bg-light">
        <div class="sidenav col-md-12">
          <h6>
            <b>Size</b>
          </h6>
          <div class="bg-light" *ngFor="let size of clothingSizes">
            <label><input name="clothingsizesradio" type="radio" (click)='searchBySize(size.clothingSize)'
                          value="size.clothingSizeId">&nbsp;{{size.clothingSize}}</label>
          </div>

        </div>
      </div>
    </div>
    <div class="col-10">

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-4" *ngFor="let product of allProducts">
          <myafrica-product-item [product]="product"></myafrica-product-item>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12 col-md-12 p-0 text-danger" *ngIf="displayNoRecordsFoundMessage">No
    products were found matching criteria!!!
  </div>
  <div *ngIf="displayErrorMessage" class="error-message-bold">An error has occurred. Please try again later!</div>

  <br/>
 <div class="container">
        <div class="row bg-light rounded-bottom rounded-top shadow h-20 my-2">
            <div class="col-6 p-2">
            <span class="badge badge-success text-white"
                  *ngIf="displayRangeLimit - displayEntryLimitCount <= 0"><a
                    class="disabled text-white h6">&larr; Previous</a></span>
                <span class="badge badge-success text-white"
                      *ngIf="displayRangeLimit - displayEntryLimitCount > 0"><a
                        (click)="previous()"><span
                        class="text-white h6">&larr; Previous</span></a></span>
            </div>

            <div class="col-6  p-2 text-right">
            <span class="badge badge-success text-white" *ngIf="displayRangeLimit < totalProducts"><a
                    (click)="next()"><span class="paginationanchor text-white h6">Next
						&rarr;</span></a></span>
                <span class="badge badge-success text-white" *ngIf="displayRangeLimit >= totalProducts"><a
                        class="disabled text-white h6">Next &rarr;</a></span>

            </div>
        </div>
    </div>
</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
