<div id="navWrap">
    <nav>
        <myafrica-dynamic-mega-menu></myafrica-dynamic-mega-menu>
    </nav>
</div>

<myafrica-advert-slides></myafrica-advert-slides>

<myafrica-page-title pageTitle="All Products"></myafrica-page-title>

<myafrica-products-list></myafrica-products-list>

