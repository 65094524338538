import {Component, OnInit} from '@angular/core';
import {
    faBuilding,
    faEnvelope,
    faFileInvoiceDollar,
    faHome,
    faLockOpen,
    faShoppingBasket,
    faShoppingCart,
    faStickyNote,
    faTruckLoading,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import {LoginResponse} from '../model/LoginResponse';
import {Router} from '@angular/router';

@Component({
    selector: 'myafrica-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    cartIcon = faShoppingCart;
    moneyIcon = faFileInvoiceDollar;
    messagesIcon = faEnvelope;
    profileIcon = faUser;
    homeIcon = faHome;
    businessesIcon = faBuilding;
    productIcon = faShoppingBasket;
    returnsIcon = faTruckLoading;
    passwordIcon = faLockOpen;
    vendorAgreementIcon = faStickyNote;
    loginResponse: LoginResponse;


    constructor(private router: Router) {
    }

    ngOnInit() {
        this.navigate(sessionStorage.getItem('dashboard-path'));
        this.loginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));
    }

    navigate(path: string) {
        if (path) {
            this.storePath(path);
            this.router.navigate(['dashboard', {outlets: {dashboard: [path]}}], {skipLocationChange: true}).then();
        }
    }

    storePath(path: string): void {
        sessionStorage.setItem('dashboard-path', path);
    }
}
