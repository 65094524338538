<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="container-fluid my-4 py-2">
                <hr class="bg-success shadow">
                <div class="row justify-content-center ">
                    <h1 class="text-center  text-primary px-1 position-sticky">
                        <i class="fa fa-tachometer-alt text-dark"></i>&nbsp;Dashboard
                    </h1>
                </div>
                <hr class="bg-warning shadow">
            </div>

            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-sm-12 col-md-3 p-3 mb-1 rounded  border border-info shadow cursor-pointer"
                         (click)="navigate('manage-orders')">
                        <i class="fa fa-shopping-cart float-left" aria-hidden="true"></i>
                        <span class="mt-2">&nbsp;&nbsp;Manage Orders

                            </span>
                    </div>
                    <div class="col-sm-12 col-md-3 p-3 mb-1 rounded  border border-warning shadow cursor-pointer"
                         (click)="navigate('manage-products')">
                        <i class="fa fa-shopping-basket float-left" aria-hidden="true"></i>
                        <span class="mt-2">&nbsp;&nbsp;Manage Products
                            </span>
                    </div>
                    <div class="col-sm-12 col-md-3 p-3 mb-1 rounded border border-secondary shadow cursor-pointer"
                         (click)="navigate('manage-businesses')">
                        <i class="fa fa-building float-left" aria-hidden="true"></i>
                        <span class="mt-2">&nbsp;&nbsp;Manage Businesses</span>
                    </div>
                </div>
            </div>
            <div class="row">

            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="d-block">
                        <canvas baseChart
                                [data]="pieChartData"
                                [labels]="pieChartLabels"
                                [chartType]="pieChartType"
                                [options]="chartOptions"
                                [plugins]="pieChartPlugins"
                                [legend]="pieChartLegend">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
