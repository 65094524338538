import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {md5} from '../shared/services/md5generator';
import {environment} from '../../environments/environment';
import {OrderCartItem} from '../model/OrderCartItem';
import {Province} from '../model/Province';
import {ApplicationStoreService} from '../services/application-store.service';
import {TableService} from '../services/table.service';
import {Router} from '@angular/router';
import {CartItemsService} from '../services/cart-items.service';
import {OrdersServiceService} from '../shared/services/orders-service.service';
import {Product} from '../model/Product';
import {CartItem} from '../model/CartItem';
import {UserAddress} from '../model/UserAddress';
import {NotifierService} from '../services/notifier.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {OrderDeliveryService} from '../services/order.delivery.service';

@Component({
    selector: 'myafrica-delivery-options',
    templateUrl: './delivery-options.component.html',
    styleUrls: ['./delivery-options.component.scss'],
})
export class DeliveryOptionsComponent implements OnInit {

    @ViewChild('closeButton') closeModal: ElementRef;

    currentJustify = 'justify';
    displaySuccessMessage: string;
    productsWithNoStock: Product[];
    provinces: Province[];
    userAddresses: UserAddress[];


    displayMessage: string;
    displayErrorMessage: boolean;

    firstName = '';
    lastName = '';
    emailAddress = '';
    cellphoneNumber = '';
    alternativeNumber = '';

    provinceId: string;

    addressId: string;
    addressLine1: string;
    streetName: string;
    city: string;
    zipcode: string;

    orderCartItems: OrderCartItem[] = [];

    cartItems: CartItem[] = [];

    orderCartItem: OrderCartItem = new OrderCartItem();

    cartTotal = 0;

    deliveryCost = 100;
	courierDeliveryCost: number;
	
    totalCost = 0;
	cartDeliveryAmount = 0;

    cartCurrency = 'ZAR';


    placeOrderButtonDisabled = false;
    loading: boolean;
	httploading: boolean;

    codeTablesData: string;
    closeResult: string;

    displayRemoveButton = false;
    instantPayment = true;

    modalReference: any;
    isUserLoggedIn: boolean;
    loggedInUser: any;
    displayAddressBookButton: boolean;
    deliveryInstructions: string;
    paymentForm: FormGroup;
	//indicates if quote form courier was receved successfully
	quoteReceived: boolean;
	businessProvinceId: string;
	businessProvince: any;
	customerProvinceName: string;
	provinceName: string;
	
    constructor(private tableService: TableService,
                private router: Router,
                private cartService: CartItemsService,
                private appStore: ApplicationStoreService,
                private ordersService: OrdersServiceService,
                private modalService: NgbModal,
                private notifier: NotifierService,
				private fb: FormBuilder,
				private deliveryService: OrderDeliveryService) {
    }

    ngOnInit() {
	    this.createForm();
        this.instantPayment = false;
        this.displayRemoveButton = false;
        this.isUserLoggedIn = JSON.parse(sessionStorage.getItem('isUserLoggedIn'));
        this.displayAddressBookButton = false;
		this.quoteReceived = false;
   		this.loading = true;
		
	    this.codeTablesData =  sessionStorage.getItem('codeTablesData');  
		const productFeesObject = JSON.parse(this.codeTablesData).productPricingFee;
		this.deliveryCost = productFeesObject[0].courierFee;
        this.provinces = JSON.parse(sessionStorage.getItem('provinces'));
	 	this.provinces.forEach(element => {
			if (element.name.toLowerCase() === 'GAUTENG'.toLowerCase()) {
				this.businessProvince = element;
				this.businessProvinceId = element.id;
			}
		});
      
		this.setUserData();
        this.displayErrorMessage = false;
        this.placeOrderButtonDisabled = false;
		this.loading = false;
	
		this.calculatePrices();
    }


 createForm() {
    this.paymentForm = this.fb.group({
       firstName: ['', Validators.required ],
       lastName: ['', Validators.required ],
		emailAddress: ['', Validators.required ],
		cellphoneNumber: ['', Validators.required ],
		addressline1: ['', Validators.required ],
		streetname: ['', Validators.required ],
		city: ['', Validators.required ],
		businessProvince: ['', Validators.required ],
		zipcode: ['', Validators.required ]
    });
  }

    // set user data if its alogged in user
    setUserData() {
        if (this.isUserLoggedIn) {
		    this.loggedInUser = JSON.parse(sessionStorage.getItem('userDetails'));
            // if user shopper has already logged in default address details
            this.firstName = this.loggedInUser.firstName;
            this.lastName = this.loggedInUser.lastName;
            this.emailAddress = this.loggedInUser.email;
            this.cellphoneNumber = this.loggedInUser.cellphoneNumber;
		   if (this.loggedInUser.userAddresses) {
                this.userAddresses = this.loggedInUser.userAddresses;
	            if (this.userAddresses.length > 0) {
        		
		            if (this.userAddresses.length > 1) {
                        this.displayAddressBookButton = true;
                    }
                	this.addressLine1 = this.userAddresses[0].addressline1;
                    this.streetName = this.userAddresses[0].streetname;
                    this.city = this.userAddresses[0].city;
                    this.zipcode = this.userAddresses[0].zipcode;
					if(this.userAddresses[0].province) {
                   	 this.provinceId = this.userAddresses[0].province.id;
					}
                }
            }
        }
    }

    setAddressDetails(address: UserAddress) {
		this.loading = true;
        this.addressLine1 = address.addressline1;
        this.streetName = address.streetname;
        this.city = address.city;
        this.zipcode = address.zipcode;
        if(address.province) {
			this.provinceId = address.province.id;
			this.customerProvinceName = address.province.name;
		}
        this.modalReference.close();
		this.quoteReceived = false;
		this.getQuote();

    }


    calculatePrices() {
	    this.loading = false;
        this.cartTotal = 0;
        this.cartItems = this.cartService.getCart();
        this.cartItems.forEach(cartItem => {
            this.cartTotal = this.cartTotal + (cartItem.product.price * cartItem.quantity);
        });
		if(this.quoteReceived) {
        	this.totalCost = this.cartTotal + this.courierDeliveryCost + this.deliveryCost;
            this.cartDeliveryAmount = this.courierDeliveryCost + this.deliveryCost;
			
		} else {
        	this.totalCost = this.cartTotal + this.deliveryCost;
			this.cartDeliveryAmount = this.deliveryCost;
    	}
	}


    goBack() {
        this.appStore.deleteItem('ORDER_NO');
        this.router.navigate(['']).then();

    }

	getProvinceName() {
	
		this.provinces.forEach(element => {
			if (element.id === this.provinceId) {
				this.provinceName = element.name;
			}
		});
		return ""; 
	}
	

	getQuote() {
		this.orderCartItems = [];
        const cart = this.appStore.getJsonItem('cart');
        this.cartItems = cart.map((cartItem: CartItem) => {
            const result = cartItem;
            this.orderCartItem = new OrderCartItem();

            this.orderCartItem.p_id = result.product.id;
            this.orderCartItem.p_name = result.product.productName;
            this.orderCartItem.p_price = result.product.price;
            this.orderCartItem.p_currency = this.cartCurrency;
            this.orderCartItem.p_quantity = result.quantity;
            this.orderCartItems.push(this.orderCartItem);

            return result;
        });

		if(this.addressLine1) {} else { return;}
		if(this.zipcode) {} else { return;}
		this.getProvinceName();
		
		  const cartData = {
			customer_name: this.firstName,
            customer_surname: this.lastName,
            customer_email_address: this.emailAddress,
            customer_cell_no: this.cellphoneNumber,
            customer_alternate_no: this.alternativeNumber,
            physical_address1: this.addressLine1,
            physical_addr_street_name: this.streetName,
            physical_addr_city: this.city,
            physical_province_id: this.provinceId,
			physical_province: this.provinceName,
            physical_addr_zip_code: this.zipcode,
            deliveryInstructions: this.deliveryInstructions,
			deliveryCost: this.cartDeliveryAmount,
            cartItems: this.orderCartItems,

        };
		
		this.httploading = true;
		this.deliveryService.requestQuote(cartData).subscribe(value => {
			this.quoteReceived = true;  
			let courierQuote =  JSON.parse(JSON.stringify(value.body));
			 this.courierDeliveryCost  = courierQuote;
			 this.httploading = false;   
			this.calculatePrices();
		
		}, error1 => {
			this.quoteReceived = false;         
			this.httploading = false;
				
			this.calculatePrices();
		});
	}

    placeOrder() {
        this.closeModal.nativeElement.click();
        this.loading = true;
        this.placeOrderButtonDisabled = true;
        this.orderCartItems = [];

        const cart = this.appStore.getJsonItem('cart');
        this.cartItems = cart.map((cartItem: CartItem) => {
            const result = cartItem;
            this.orderCartItem = new OrderCartItem();

            this.orderCartItem.p_id = result.product.id;
            this.orderCartItem.p_name = result.product.productName;
            this.orderCartItem.p_price = result.product.price;
            this.orderCartItem.p_currency = this.cartCurrency;
            this.orderCartItem.p_quantity = result.quantity;

            if (result.productSizeId !== null || result.productSizeId !== undefined) {
                this.orderCartItem.clothingProductSizeId = result.productSizeId;
                this.orderCartItem.productColour = result.productColour;
                this.orderCartItem.productSize = result.productSize;
            }

            this.orderCartItems.push(this.orderCartItem);

            return result;
        });


        const cartData = {
            customer_name: this.firstName,
            customer_surname: this.lastName,
            customer_email_address: this.emailAddress,
            customer_cell_no: this.cellphoneNumber,
            customer_alternate_no: this.alternativeNumber,
            physical_address1: this.addressLine1,
            physical_addr_street_name: this.streetName,
            physical_addr_city: this.city,
            physical_province_id: this.provinceId,
			physical_province: this.customerProvinceName,
            physical_addr_zip_code: this.zipcode,
            deliveryInstructions: this.deliveryInstructions,
			deliveryCost: this.cartDeliveryAmount,
            cartItems: this.orderCartItems,

        };

        if (this.addressLine1 === '' ||
            this.addressLine1 === undefined ||
            this.streetName === '' || this.streetName === undefined ||
            this.city === '' || this.zipcode === '' || this.zipcode === undefined) {
            this.loading = false;
            this.placeOrderButtonDisabled = false;

            this.displayErrorMessage = true;
            this.notifier.notifyError('Please enter address details!!');
            return;
        }
		
        this.ordersService.generateOrder(cartData, this.cartTotal).subscribe(value => {
            this.loading = false;
	        this.appStore.storeItem('ORDER_NO', value.body.orderId);
		    if (this.instantPayment) {
                this.instantEFTPayment();
            } else {
                this.router.navigate(['view/order-success']).then();
            }
        }, error1 => {
            if (parseInt(error1.status, 0) === 409) {

                let i = 0;
                this.productsWithNoStock = [];
                for (const entry of error1.error.customerOrderItems) {
                    const tableRow = (document.getElementById(entry.product.productId)) as HTMLTableRowElement;

                    tableRow.className = 'red-border-tablerow';
                    this.productsWithNoStock[i] = entry.product;
                    i++;
                }
                this.displayRemoveButton = true;
                this.loading = false;
                this.displayMessage = 'Stock no longer available for items highlighted in RED.' +
                    ' Click on Remove Items to remove them from the cart.';
                this.notifier.notifyError(this.displayMessage);

                return;
            }


            this.loading = false;
            this.displayMessage = 'Failed to create order. Please try again later!';
            this.notifier.notifyError(this.displayMessage);
            this.placeOrderButtonDisabled = false;
        });

    }


    instantEFTPayment() {

        const itemNameWithoutSpaces = ('ORDER ' + this.appStore.getItem('ORDER_NO')).replace(/\s+/g, '+');

        const appAddress = environment.mainUrl;
        const merchant_id = environment.merchant_id;
        const merchantKey = environment.merchant_key;
        const payFastUrl = environment.payfast_url;

        const returnUrl = appAddress + '/shopping/payment/success';
        const cancelUrl = appAddress + '/shopping/payment/cancelled';
        const notifyUrl = appAddress + '/payment/testnotify';
	
        const generatedId = this.generateRandomString() + '-' + this.generateDateTime();

        this.calculatePrices();

        const tempUrlParams = 'merchant_id=' + encodeURIComponent(merchant_id) + '&' +
            'merchant_key=' + encodeURIComponent(merchantKey) + '&' +
            'return_url=' + encodeURIComponent(returnUrl) + '&' +
            'cancel_url=' + encodeURIComponent(cancelUrl) + '&' +
            'notify_url=' + encodeURIComponent(notifyUrl) + '&' +
            'email_address=' + encodeURIComponent(this.emailAddress) + '&' +
            'm_payment_id=' + encodeURIComponent(generatedId) + '&' +
            'amount=' + encodeURIComponent(this.totalCost) + '&' +
            'item_name=' + encodeURIComponent(itemNameWithoutSpaces);

        const tempSignature = md5(tempUrlParams);

        const urlPostParameters = tempUrlParams + '&signature=' + tempSignature;

        window.location.href = payFastUrl + urlPostParameters;

    }

    /*
     * Generate a time in the following format yyyyMMddHHMMSS
     * @returns
     */
    generateDateTime() {
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        const hourMMSS = '' + d.getHours() + d.getMinutes() + d.getSeconds();


        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return year + month + day + hourMMSS;
    }

    /*
     * Generates random string with a length of 2
     * @returns
     */
    generateRandomString() {
        const STRING_LENGTH = 8;
        let text = '';
        const charList = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = 0; i < STRING_LENGTH; i++) {
            text += charList.charAt(Math.floor(Math.random() * charList.length));
        }
        return text;
    }


    removeItems() {
        if (this.productsWithNoStock.length > 0) {
            for (const entry of this.productsWithNoStock) {
                this.removeItem(entry.productId);
            }
        }
        this.displayRemoveButton = false;
        this.ngOnInit();
    }


    removeItem(id: number): void {
        const cart = JSON.parse(sessionStorage.getItem('cart'));
        const index = -1;
        for (let i = 0; i < cart.length; i++) {
            const item: CartItem = cart[i];
            if (item.product.productId === id) {
                cart.splice(i, 1);
                break;
            }
        }
        sessionStorage.setItem('cart', JSON.stringify(cart));
    }

    open(content) {
        this.modalReference = this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
        this.modalReference.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });

    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    setInstantPayment(option: boolean) {
        this.instantPayment = option;
    }

    collect() {
        this.router.navigate(['/view/collect-option']).then();

    }


}
