<div class="container-fluid px-0 mt-md-5 text-justify">
    <ul class="nav nav-tabs justify-content-center " id="myTab" role="tablist">
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link active" id="business-details-tab" data-toggle="tab" href="#business-details" role="tab"
               aria-controls="business-details" aria-selected="true">Business Details</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="address-details-tab" data-toggle="tab" href="#address-details" role="tab"
               aria-controls="address-details"
               aria-selected="false">Address Details</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="banking-details-tab" data-toggle="tab" href="#banking-details" role="tab"
               aria-controls="banking-details"
               aria-selected="false">Banking Details</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="business-hours-tab" data-toggle="tab" href="#business-hours" role="tab"
               aria-controls="business-hours"
               aria-selected="false">Business Hours</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane px-0 fade show active" id="business-details" role="tabpanel"
             aria-labelledby="business-details-tab">
            <myafrica-business-additional-details></myafrica-business-additional-details>
        </div>
        <div class="tab-pane px-0 fade" id="address-details" role="tabpanel" aria-labelledby="address-details-tab">
            <myafrica-business-address-details></myafrica-business-address-details>
        </div>
        <div class="tab-pane px-0 fade" id="banking-details" role="tabpanel" aria-labelledby="banking-details-tab">
            <myafrica-business-banking-details></myafrica-business-banking-details>
        </div>
        <div class="tab-pane px-0 fade" id="business-hours" role="tabpanel" aria-labelledby="business-hours-tab">
            <myafrica-business-hours></myafrica-business-hours>
        </div>
    </div>
</div>
<hr/>
<myafrica-business-products></myafrica-business-products>
