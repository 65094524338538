import {Component, OnDestroy, OnInit} from '@angular/core';
import {BusinessServiceService} from '../../../shared/services/business/business-service.service';
import {HttpResponse} from '@angular/common/http';
import {Business} from '../../../model/Business';
import {BusinessHour} from '../../../model/BusinessHour';
import {Subscription} from 'rxjs';
import {NotifierService} from '../../../services/notifier.service';

@Component({
  selector: 'myafrica-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss']
})
export class BusinessHoursComponent implements OnInit, OnDestroy {

  businessHours: BusinessHour[];
  businessId: string;
  selectedBusiness: Business;
  displayMessage: string;
  displayErrorMessage: boolean;
  displaySuccessMessage: boolean;

  businessHoursDataData: any[];

  finderloader: boolean;
  businessHoursDataDataJSON: string;

  defaultYear = 1977;
  defaultMonth = 1;
  defaultDay = 1;

  sundayOpeningTime = '07:00:00';
  sundayClosingTime = '17:00:00';
  sundayOpenForBusiness = false;
  //
  mondayOpeningTime = '07:00:00';
  mondayClosingTime = '17:00:00';
  mondayOpenForBusiness = true;
  //
  tuesdayOpeningTime = '07:00:00';
  tuesdayClosingTime = '17:00:00';
  tuesdayOpenForBusiness = true;
  //
  wednesdayOpeningTime = '07:00:00';
  wednesdayClosingTime = '17:00:00';
  wednesdayOpenForBusiness = true;
  //
  thursdayOpeningTime = '07:00:00';
  thursdayClosingTime = '17:00:00';
  thursdayOpenForBusiness = true;
  //
  fridayOpeningTime = '07:00:00';
  fridayClosingTime = '17:00:00';
  fridayOpenForBusiness = true;
  //
  saturdayOpeningTime = '07:00:00';
  saturdayClosingTime = '17:00:00';
  saturdayOpenForBusiness = true;
  subs1: Subscription;
  subs2: Subscription;
  subs3: Subscription;
  subs4: Subscription;

  constructor(private businessService: BusinessServiceService,
			           private notifier: NotifierService) {
  }

  ngOnInit() {
    this.selectedBusiness = JSON.parse(sessionStorage.getItem('selectedBusiness'));
    this.businessId = this.selectedBusiness.id + '';
    this.subs1 = this.businessService.getBusinessHours(this.businessId).subscribe((value: BusinessHour[]) => {
        this.businessHours = value;
        this.defaultData();
      } , error1 => { 
	  }
    );
  }

  defaultData() {
    // lert

    for (let i = 0; i < this.businessHours.length; i++) {
      const value = this.businessHours[i];

      const dateOfWeek = Number(value.dayOfWeek);

      switch (dateOfWeek) {
        case 0:
          this.mondayOpeningTime = value.openTime;
          this.mondayClosingTime = value.closeTime;
          this.mondayOpenForBusiness = value.openForBusiness;
          break;
        case 1:
          this.tuesdayOpeningTime = value.openTime;
          this.tuesdayClosingTime = value.closeTime;
          this.tuesdayOpenForBusiness = value.openForBusiness;
          break;
        case 2:
          this.wednesdayOpeningTime = value.openTime;
          this.wednesdayClosingTime = value.closeTime;
          this.wednesdayOpenForBusiness = value.openForBusiness;
          break;
        case 3:
          this.thursdayOpeningTime = value.openTime;
          this.thursdayClosingTime = value.closeTime;
          this.thursdayOpenForBusiness = value.openForBusiness;

          break;
        case 4:
          this.fridayOpeningTime = value.openTime;
          this.fridayClosingTime = value.closeTime;
          this.fridayOpenForBusiness = value.openForBusiness;
          break;
        case 5:
          this.saturdayOpeningTime = value.openTime;
          this.saturdayClosingTime = value.closeTime;
          this.saturdayOpenForBusiness = value.openForBusiness;

          break;
        case 6:
          this.sundayOpeningTime = value.openTime;
          this.sundayClosingTime = value.closeTime;
          this.sundayOpenForBusiness = value.openForBusiness;
          break;
        default:
          break;
      }
    }
  }


  setDateTime(dateObject, hour, minutes, seconds) {
    dateObject.setHours(hour);
    dateObject.setMinutes(minutes);

    if (seconds === null || seconds === undefined) {
    } else {
      dateObject.setSeconds(seconds);
    }
  }


  // iterate through the operating hours and set the time
  updateHours(businessId) {
    this.finderloader = true;
	if(this.businessHours) {
		if (this.businessHours.length > 0) {
		  for (let i = 0; i < this.businessHours.length; i++) {
			const value = this.businessHours[i];
			value.businessId = businessId;
			this.setDataToSave(value);
		  }

		  this.addBusinessHours();
		}
	} else {
      this.buildOperatingHoursData();

    }
  }

  addBusinessHours() {
    if (this.businessHours && this.businessHours.length > 0) {
      const regexp = /businessHoursId/g;
      const busStringData = JSON.stringify(this.businessHours).replace(regexp, 'id');

      this.subs2 = this.businessService.updateBusinessHours(this.businessId, JSON.parse(busStringData)).subscribe((value: HttpResponse<any>) => {
        this.displayMessage = 'Business Hours updated successfully!!!';
        this.notifier.notify(this.displayMessage, 'success');

      }, error1 => {
        this.displayMessage = 'Error updating business hours. Please try again later!!!';
        this.notifier.notify(this.displayMessage, 'error');

      });

    } else {
      const regexp = /\\/g;
      const busObject = this.businessHoursDataDataJSON.replace(regexp, '');
      this.subs3 = this.businessService.updateBusinessHours(this.businessId, this.businessHoursDataData).subscribe((value: HttpResponse<any>) => {
      this.displayMessage = 'Business Hours updated successfully!!!';
      this.notifier.notify(this.displayMessage, 'success');

      this.reloadBusinessHours();

      }, error1 => {
        this.displayMessage = 'Error updating business hours. Please try again later!!!';
        this.notifier.notify(this.displayMessage, 'error');

      });
    }
  }

  // re-assign data to the businessHours scope variable
  reloadBusinessHours() {
    this.subs4 = this.businessService.getBusinessHours(this.businessId).subscribe((value: BusinessHour[]) => {
        this.businessHours = value;
      }
    );


  }


  // creates a JSON object that can be to create new business operating hours
  buildOperatingHoursData() {
    this.businessHoursDataData = [];
    const mondayHoursData = new BusinessHour(' ', '0', this.mondayOpeningTime, this.mondayClosingTime,
      this.businessId, this.mondayOpenForBusiness);
    const tuesdayHoursData = new BusinessHour(' ', '1', this.tuesdayOpeningTime, this.tuesdayClosingTime,
      this.businessId, this.tuesdayOpenForBusiness);

    const wednesdayHoursData = new BusinessHour(' ', '2', this.wednesdayOpeningTime, this.wednesdayClosingTime,
      this.businessId, this.wednesdayOpenForBusiness);
    const thursdayHoursData = new BusinessHour(' ', '3', this.thursdayOpeningTime, this.thursdayClosingTime,
      this.businessId, this.thursdayOpenForBusiness);
    const fridayHoursData = new BusinessHour(' ', '4', this.fridayOpeningTime, this.fridayClosingTime,
      this.businessId, this.fridayOpenForBusiness);
    const saturdayHoursData = new BusinessHour(' ', '5', this.saturdayOpeningTime, this.saturdayClosingTime,
      this.businessId, this.saturdayOpenForBusiness);
    const sundayHoursData = new BusinessHour(' ', '6', this.sundayOpeningTime, this.sundayClosingTime,
      this.businessId, this.sundayOpenForBusiness);
    this.businessHoursDataData.push(mondayHoursData);
    this.businessHoursDataData.push(tuesdayHoursData);
    this.businessHoursDataData.push(wednesdayHoursData);
    this.businessHoursDataData.push(thursdayHoursData);
    this.businessHoursDataData.push(fridayHoursData);
    this.businessHoursDataData.push(saturdayHoursData);
    this.businessHoursDataData.push(sundayHoursData);
    // convert object to JSON
    this.businessHoursDataDataJSON = JSON.stringify(this.businessHoursDataData);

    this.addBusinessHours();

  }

  setDataToSave(dataObject) {
    const dateOfWeek = dataObject.dayOfWeek;

    switch (dateOfWeek) {
      case 0:
        dataObject.openTime = this.mondayOpeningTime;
        dataObject.closeTime = this.mondayClosingTime;
        dataObject.openForBusiness = this.mondayOpenForBusiness;
        break;
      case 1:
        dataObject.openTime = this.tuesdayOpeningTime;
        dataObject.closeTime = this.tuesdayClosingTime;
        dataObject.openForBusiness = this.tuesdayOpenForBusiness;
        break;
      case 2:
        dataObject.openTime = this.wednesdayOpeningTime;
        dataObject.closeTime = this.wednesdayClosingTime;
        dataObject.openForBusiness = this.wednesdayOpenForBusiness;
        break;
      case 3:
        dataObject.openTime = this.thursdayOpeningTime;
        dataObject.closeTime = this.thursdayClosingTime;
        dataObject.openForBusiness = this.thursdayOpenForBusiness;

        break;
      case 4:
        dataObject.openTime = this.fridayOpeningTime;
        dataObject.closeTime = this.fridayClosingTime;
        dataObject.openForBusiness = this.fridayOpenForBusiness;
        break;
      case 5:
        dataObject.openTime = this.saturdayOpeningTime;
        dataObject.closeTime = this.saturdayClosingTime;
        dataObject.openForBusiness = this.saturdayOpenForBusiness;

        break;
      case 6:
        dataObject.openTime = this.sundayOpeningTime;
        dataObject.closeTime = this.sundayClosingTime;
        dataObject.openForBusiness = this.sundayOpenForBusiness;
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.doUnsubs(this.subs1);
    this.doUnsubs(this.subs2);
    this.doUnsubs(this.subs3);
    this.doUnsubs(this.subs4);
  }

  doUnsubs(subs: Subscription) {
    if (subs) {
      subs.unsubscribe();
    }
  }
}
