import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from '../../shared/services/profile.service';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Subscription,} from 'rxjs';
import {Utils} from '../../shared/Utils';
import {NotifierService} from '../../services/notifier.service';

@Component({
  selector: 'myafrica-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  showMsgs: boolean;

  email: string;
  // old password
  oldpwd: string;
  // new password
  pwd: string;
  confirmPwd: string;

  // disabled email field if user has logged in
  emailFieldDisabled: boolean;

  errorMessage = '';
  displayErrorMessage: boolean;
  loginErrorMessage: string;

  displaySuccessMessage: boolean;
  loginSuccessMessage: string;

  subscription: Subscription;
  loading: boolean;
 
  constructor(private profileService: ProfileService, private router: Router,
                private notifier: NotifierService) {
  }

  ngOnInit() {
    if (this.isLoggedIn()) {
      const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      this.oldpwd = sessionStorage.getItem('psswd') + '';
      this.email = userDetails.email;
      return;
    }

  }


  isLoggedIn(): boolean {
    if (sessionStorage.getItem('userDetails')) {
      return true;
    }


    return false;
  }


  resetPassword() {
	this.loading = true;

    let userDetails = {
      'email': this.email,
      'password': this.oldpwd,
      'newPassword': this.pwd,
      'confirmationPassword': this.pwd
    };

    this.subscription = this.profileService.updatePassword(userDetails).subscribe((value: HttpResponse<any>) => {

      //this.router.navigate(['/register-success']);
      this.displayErrorMessage = false;
      this.displaySuccessMessage = true;
	  this.loading = false;
	  this.notifier.notify('Password reset successfully!! You will be re-directed to the dashboard shortly...');

      Utils.delay(6000).then(any => {
        this.navigate();
      });


    }, error1 => {
      this.loading = false;
	
	  if (parseInt(error1.status) == 417) {
         this.notifier.notifyError('Email account does not exist!!');

      } else if (parseInt(error1.status) == 409) {
		this.notifier.notifyError('Current password is incorrect. Please try again!!');
      } else {
		this.notifier.notifyError('Password reset failed. Please try again later!');
      }
      this.displayErrorMessage = true;
      this.displaySuccessMessage = false;

    });

  }

	navigate() {
        const navigationOutlet = sessionStorage.getItem('navigationOutlet') + '';
        if (navigationOutlet === 'shopperdashboard') {
	      this.router.navigate(['/shopperdashboard']);
		 } else if (navigationOutlet === 'adminclerkdashboard') {
			this.router.navigate(['/adminclerkdashboard']);   
		} else {
            this.router.navigate(['/dashboard']);
		}
    }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

