<myafrica-breadcrumbs title="Merchant Agreement"></myafrica-breadcrumbs>


<div class="container mb-2">
	<div>
		<div class="form-group">
			<div class="col-12 field-control">

				<span>Welcome to MyAfricaConnect {{userName}}, before you proceed please read and accept our merchant agreement, and tick the checkbox to agree</span>
			</div>
		</div>
		<div class="form-group">
			<div class="col-12 field-control">

				<a href="../assets/MARKETPLACESELLERAGREEMENT.pdf" download> <span class="anchor">Click
						here to download the Merchant Agreement</span>
				</a>
			</div>
		</div>
		<div class="form-group">
			<div class="col-12 field-control text-warning font-weight-bold" >
						Please pay particular attention to the following sections:
				        <ul>
				          <li><p>7.1. MyAfricaConnect will earn an Admin fee equal to a percentage of Sales Proceeds from each sale of Seller's Products through the Myafricaconnect Site</p>
				          <p>MyAfricaConnect charges a collection fee of R99.00 per product or a set of products for local Gauteng businesses or sellers, for sellers outside of this jurisdiction the fees vary on courier partners services.</p></li>
				          <li><p>7.3. MyAfricaConnect Marketplace will make payment of the Seller Share for Orders that meet the Deemed Completion criteria on two set days of each month, namely the 15th and 30th of each month.</p></li>

				        <li><p>8.12.1. The MyAfricaConnect Site will accept multiple different payment methods from Customers.
							The Seller acknowledges that not all payment methods are processed by the relevant merchant bank within the same day in which they were received, and as such the Seller may only receive notification of an Order after the date of the Order.
							</p></li>
				          <p>When these dates do not fall on business days, payment will be made on the next business day after such a date.</p>

				        </ul>
					</div>
			</div>

		<div class="form-group" *ngIf="!termsAlreadyAccepted">
			<div class="col-12 field-control">
				<label> <input type="checkbox" name="terms" (change)="activateButton()"
					class="form-check-input" [(ngModel)]="termsAccepted">I
					agree that I have read and accept the <a
					class="mylink">Merchant
						Agreement Terms and conditions</a>
				</label>
			</div>
		</div>


		<button *ngIf="!termsAlreadyAccepted" class="btn bg-success text-white text-center" type="submit"  [disabled]="buttonDisabled"
			(click)="registerUser()">Submit</button>
	</div>
</div>
