<myafrica-page-title pageTitle="History behind the product"></myafrica-page-title>

<div class="container">
 <h2 class="display-4 text-center text-primary" style="font-size: 2vw">{{currentProduct.productName}}</h2>


  <div class="row mb-2">
    <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="htmlContent"
                    [config]="editorConfig"></angular-editor>
  </div>
  <div class="row">
    <button class="btn btn-primary btn-theme" (click)="addHistory()" *ngIf="!alreadyHasHistory">Add History</button>
    <button class="btn btn-primary btn-theme" (click)="updateHistory()" *ngIf="alreadyHasHistory">Update History
    </button>
  </div>
</div>

