<myafrica-breadcrumbs title="Manage Orders"></myafrica-breadcrumbs>

<div class="container-fluid px-0  text-justify">
    <ul class="nav nav-tabs justify-content-center " id="myTab" role="tablist">
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link active" id="order-details-tab" data-toggle="tab" href="#order-details" role="tab"
               aria-controls="order-details" aria-selected="true">Order Details</a>
        </li>
        <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
            <a class="nav-link" id="order-delivery-tab" data-toggle="tab" href="#order-delivery" role="tab"
               aria-controls="address-details"
               aria-selected="false">Order Delivery</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane px-0 fade show active" id="order-details" role="tabpanel"
             aria-labelledby="order-details-tab">
            <myafrica-order-details></myafrica-order-details>
        </div>
        <div class="tab-pane px-0 fade" id="order-delivery" role="tabpanel" aria-labelledby="order-delivery-tab">
            <myafrica-order-delivery></myafrica-order-delivery>
        </div>
        
    </div>
</div>

