<div id="navWrap">
    <nav>
        <myafrica-mega-menu></myafrica-mega-menu>
    </nav>
</div>
<myafrica-page-title pageTitle="Selected Product"></myafrica-page-title>
<div class="container">
    <div class="row">
        <div class="col-sm-12 col-md-8 text-center  mr-md-0">
            <h3 class="text-warning">{{selectedProduct.productName}}</h3>
            <img class="img-fluid" [src]="selectedImageUrl ? selectedImageUrl: defaultImageUrl"
                 [alt]="selectedProduct.productName" style="height: 350px;">
            <div class="row">
                <div class="container p-2">
                    <span *ngFor="let clothingColour of selectedProduct.colours">
                        <myafrica-product-icon class="mx-1" [clothingColour]="clothingColour"
                                               (click)="filterSizes(clothingColour)">
                        </myafrica-product-icon>
                    </span>
                </div>
            </div>
            <p class="h4 my-1">Product Info</p>
            <p>{{selectedProduct.productDescription}}</p>
        </div>
        <div class="col-sm-12 col-md-4 ">
            <myafrica-out-of-stock [product]="selectedProduct"></myafrica-out-of-stock>
            <myafrica-product-price [product]="selectedProduct"></myafrica-product-price>
            <myafrica-increase-cart-items [cartItem]="cartItem"></myafrica-increase-cart-items>
            <myafrica-add-to-basket-button [cartItem]="cartItem"></myafrica-add-to-basket-button>
            <div class="row">
                <div class="col-12">
                    <span *ngIf="selectedProduct.colours && selectedProduct.colours.length > 0"
                          class="text-dark h6 ">COLOR: </span>
                    <span *ngFor="let clothingColour of selectedProduct.colours">
                    <myafrica-clothing-colour [clothingColour]="clothingColour"
                                              (click)="filterSizes(clothingColour)"></myafrica-clothing-colour>

                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 py-2">
                    <span *ngIf="clothingSizes.length > 0" class="text-dark h6 ">SIZES: </span>
                    <span class="d-inline cursor-pointer" (click)="setSizeSelected(productSize, ind)"
                          *ngFor="let productSize of clothingSizes ;let ind = index">
                    <span class="badge"
                          [ngStyle]="{'background-color': productSize.active ? '#00b300':'white'}">
                        {{productSize.name}}
                    </span>
                </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 py-2">
                    <span class="badge badge-primary my-1 p-2 text-white h2">{{stockCount | async}}
                        : Items in stock</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 py-2">
                    <p class="">
                        Estimated delivery <span class=" text-warning">
									6 to 7 business working days</span>
                    </p>
                </div>
            </div>

            <button class=" btn btn-warning btn-block rounded-top rounded-bottom my-1" (click)="checkOut()">
                <h5>Check Out</h5>
            </button>
            <myafrica-business-snipet [business]="productBusiness"></myafrica-business-snipet>
        </div>

    </div>
</div>
<myafrica-product-history-view [htmlContent]="htmlContent"></myafrica-product-history-view>
<myafrica-page-title pageTitle="Similar Products"></myafrica-page-title>
<myafrica-products-list></myafrica-products-list>


<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
