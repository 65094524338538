<div class="container pt-md-2 mt-5 mb-5">

    <div class="container align-middle col col-sm-12 col-md-10 p-md-5">
        <div class="card shadow text-dark">
            <div class="card-header text-center h3 bg-primary text-white">
                REGISTER
            </div>
            <div class="card-body">
                <div class="alert alert-danger" role="alert"
                     *ngIf="confirmPassword.touched && password.touched && (password.value !== confirmPassword.value)">
                    The two passwords do not match
                </div>
                <form (ngSubmit)="registerUser()" #registerForm="ngForm" class="sm">
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="username">Your Name *</label>
                            <input type="text" class="form-control form-control-sm" id="username"
                                   [(ngModel)]="regDetails.userName" name="username" required #userName="ngModel"
                                   [ngClass]="{'is-invalid':userName.touched && userName.invalid
                                   ,'is-valid':userName.touched && userName.valid}"
                            >
                            <div class="text-danger" *ngIf="userName.touched && userName.invalid">Username is required
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="emailAddress">Email Address *</label>
                            <input type="email" class="form-control form-control-sm" id="emailAddress"
                                   #emailAddress="ngModel"
                                   [(ngModel)]="regDetails.email" name="emailAddress" required
                                   [ngClass]="{'is-invalid':emailAddress.touched && emailAddress.invalid
                                   , 'is-valid':emailAddress.touched && emailAddress.valid}"
                                   pattern="^\S*[@]\S*[.]\S*$">
                            <div class="text-danger" *ngIf="emailAddress.touched && emailAddress.invalid">A valid email
                                is required
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="password">Password *</label>
                            <input type="password" class="form-control form-control-sm " id="password"
                                   [ngClass]="{'is-invalid':password.touched && password.invalid,
                                   'is-valid':password.touched && password.valid}"
                                   [(ngModel)]="regDetails.password" name="password" required #password="ngModel"
                                   pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$">
                            <div *ngIf="password.touched ">
                                <p class="text-danger" *ngIf="password?.errors?.required">Password
                                    is required</p>
                                <p class="text-danger" *ngIf="password?.errors?.minlength">Password
                                    needs to be more than 8 characters.</p>
                                <p class="text-danger" *ngIf="password?.errors?.pattern">Must
                                    contain one lower &amp; uppercase letter, one special character
                                    and a number</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="pwdConfirm">Confirm Password *</label>
                            <input type="password" class="form-control form-control-sm" id="pwdConfirm"
                                   required
                                   [(ngModel)]="regDetails.confirmPwd" name="pwdConfirm" #confirmPassword="ngModel"
                                   [ngClass]="{'is-valid': confirmPassword.touched && confirmPassword.valid,
                                   'is-invalid': confirmPassword.touched && confirmPassword.invalid}"
                            >
                            <div class="text-danger" *ngIf="confirmPassword.touched && confirmPassword.invalid">Confirm
                                Password required
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="marketing"
                                       [(ngModel)]="regDetails.newsletterAccepted" name="marketing"
                                       [attr.checked]="'bg-secondary'">
                                <label class="form-check-label" for="marketing">Sign up for our newsletter</label>
                            </div>
                        </div>

                    </div>


                    <hr class="bg-warning">
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary btn-block"
                                [disabled]="!registerForm.form.valid">
                            Register
                        </button>

                    </div>
                    <hr class="bg-warning">
                    <div class="text-right pb-3">
                        <a routerLink="/login" class="text-decoration-none text-dark">Have an account? Login.</a>
                    </div>
                </form>

            </div>
        </div>

    </div>

</div>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


<!-- Modal -->
<div class="modal fade" id="termsAndConditions" data-backdrop="static" data-keyboard="false" tabindex="-1"
     aria-labelledby="termsAndConditionsLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body">
                <myafrica-terms class="m-0 p-0 col-12"></myafrica-terms>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
