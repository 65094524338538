<div class="container">
    <div class="container">
        <h1 class="text-center text-primary py-2">{{selectedBusiness.businessName}}</h1>
        <div class="row">
            <div class="jumbotron w-100 shadow-lg"
                 [ngStyle]="{'background-image': getUrl(selectedBusiness.billboardUrl), 'background-size':'cover'}">

                <img *ngIf="hasLogo(selectedBusiness); else logoMissing" [src]="selectedBusiness.logoUrl"
                     [alt]="selectedBusiness.businessName" class="shadow-lg rounded"
                     height="120">
                <button type="button" class="btn btn-warning d-sm-block float-right border-light shadow"
                        data-toggle="modal"
                        data-target="#exampleModal">
                    About Business
                </button>
            </div>
        </div>
    </div>
    <!-- Button trigger modal -->


    <!-- Modal -->
    <div class="modal fade " id="exampleModal" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-primary  text-white">
                    <h2 class="modal-title text-center mx-auto"
                        id="exampleModalLabel">{{selectedBusiness.businessName}}</h2>
                    <i class="fa fa-times text-white" data-dismiss="modal" aria-label="Close"></i>

                </div>
                <div class="modal-body text-center ">
                    <a [href]="selectedBusiness.businessDetails.businessFacebookURL" target="_blank"
                       class="d-inline"><i class="fab fa-facebook fa-2x mx-1" aria-hidden="true"></i></a>
                    <a [href]="selectedBusiness.businessDetails.businessTwitterURL" class="d-inline"
                       target="_blank"><i class="fab fa-twitter fa-2x mx-1" aria-hidden="true"></i></a>
                    <div class="row">
                        <div class="col-12 text-center">

                            <ul class="list-unstyled">
                                <li class="media">
                                    <img src="" class="mr-3 badge badge-primary text-white" alt="...">
                                    <div class="media-body">
                                        <h5 class="mt-0 mb-1">Business Info</h5>
                                        {{selectedBusiness.businessDetails.aboutBusiness}}
                                    </div>
                                </li>
                                
                                <li class="media my-4">
                                    <span class="badge badge-primary text-white">Years in Business: </span>
                                    <div class="media-body">
                                        <p class="badge badge-info text-dark">{{selectedBusiness.businessDetails.yearsInBusiness}}</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <myafrica-page-title pageTitle="Business Products"></myafrica-page-title>
    <div class="row">
        <div class="col-sm-12 col-md-3" *ngFor="let product of selectedBusiness.products">
            <myafrica-product-item [product]="product"></myafrica-product-item>
        </div>

    </div>
</div>
<section>
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col-12 col-md-3 py-3">
                <button class="btn btn-warning btn-block" routerLink="/all-products">View
                    More Products &nbsp;<i class="text-white fa fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</section>
<myafrica-page-title pageTitle="Contact Us"></myafrica-page-title>
<div class="container my-4">
    <div class="row">

        <div class="col-sm-12 col-md-6 my-3 shadow">
            <div class="row">
                <div class="container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14359.685345669526!2d28.20445959901287!3d-25.872066304628163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc238539a9dec964f!2sRoad%20Lodge%20Centurion!5e0!3m2!1sen!2sza!4v1627820124040!5m2!1sen!2sza"
                            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>

            </div>
        </div>
        <div class="col-sm-12 col-md-6 my-3 shadow">
            <div class="row">
                <div class="container ">
                    <table class="table table-info shadow rounded">
                        <thead>
                        <tr>
                            <th scope="col">Day</th>
                            <th scope="col">Opens</th>
                            <th scope="col">Closes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let day of selectedBusiness?.businessTradingHours">
                            <td *ngIf="day.openForBusiness">{{day.dayOfWeekName}}</td>
                            <td *ngIf="day.openForBusiness">{{day.openTime}} - AM</td>
                            <td *ngIf="day.openForBusiness">{{day.closeTime}} - PM</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<myafrica-page-title pageTitle="Trading Hours"></myafrica-page-title>-->

<ng-template #logoMissing>
    <img src="https://myafricaconnect.com/images/image-not-available.jpg" class="shadow rounded"
         [alt]="selectedBusiness.businessName"
         height="120">
</ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
