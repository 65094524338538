export class BusinessHour {
    id = '';
    dayOfWeek: string;
    openTime: string;
    closeTime: string;
    openForBusiness: boolean;
    businessId: string;
    links: string[];

    constructor(id: string, dayOfWeek: string, openTime: string, closeTime: string, businessId: string,
                openForBusiness: boolean
    ) {
        this.id = id;
        this.dayOfWeek = dayOfWeek;
        this.openTime = openTime;
        this.closeTime = closeTime;
        this.businessId = businessId;
        this.openForBusiness = openForBusiness;


    }

}
