import {Component, OnInit} from '@angular/core';
import {LoginService} from '../services/login.service';
import {LoginDetails} from '../model/LoginDetails';
import {NotifierService} from '../services/notifier.service';
import {HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ProfileService} from '../shared/services/profile.service';
import {ApplicationStoreService} from '../services/application-store.service';
import {LoginResponse} from '../model/LoginResponse';
import {AppIdleService} from '../shared/services/app-idle.service';
import {UserSessionManager} from '../shared/services/session-manager.service';
import {SessionScopeValues} from '../shared/SessionScopeValues';
import {UserDetails} from '../model/UserDetails';
import {UserRole} from '../model/UserRole';

@Component({
    selector: 'myafrica-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    loginDetails: LoginDetails;
    loading: boolean;
    userName: string;
    userRole: string;
    loggedInUser: UserDetails;
  
    constructor(private loginService: LoginService,
                private notifier: NotifierService,
                private router: Router,
                private profileService: ProfileService,
                private appStore: ApplicationStoreService,
                private idleAppService: AppIdleService,
                private userSessionManager: UserSessionManager) {
    }

    ngOnInit() {
        this.loginDetails = new LoginDetails();
    }

    login(loginDetails: LoginDetails) {

        this.userSessionManager.startSessionTimer();

        this.loading = true;
        this.loginService.login(loginDetails).subscribe((response: HttpResponse<LoginResponse>) => {
            if (response.body === undefined) {
                this.notifier.notify('Account does not exist, please register first!!', 'error');
                this.loading = false;
                return;
            }
    
            let stringResponseData = JSON.stringify(response.body);
            let responseData = JSON.parse(stringResponseData);
            this.userName = responseData.userName;
            sessionStorage.setItem('userDetails', stringResponseData);
            sessionStorage.setItem('userName', responseData.userName);
            sessionStorage.setItem('psswd', loginDetails.password);
            sessionStorage.setItem('moniesDue', responseData.moniesDue);
            sessionStorage.setItem('profileId', responseData.id);
            //sessionStorage.setItem('products', JSON.stringify(response.body.products));
               
            sessionStorage.setItem('businesses', JSON.stringify(responseData.businesses));
           // sessionStorage.setItem('messages', JSON.stringify(response.body.messages));
            sessionStorage.setItem('isUserLoggedIn', 'true');
            sessionStorage.setItem('loginResponse', JSON.stringify(response.body));

            this.appStore.saveToken(response.headers.get('Authorization'));
  			sessionStorage.setItem("Active-Profile-Id", response.headers.get('Active-Profile-Id'))
    		sessionStorage.setItem("Authorization", response.headers.get('Authorization'))
            this.appStore.saveRefreshToken(response.headers.get('Refresh-Token'));
            
            this.idleAppService.setUserLoggedIn(true);
            
            this.setUserNavigationOutlet();
            // if password change is required
            if (response.status === 202) {
                this.router.navigate(['/change/password']);
            } else if (response.status === 206) {
                if (this.userHasRole('Shopper')) {
                    this.router.navigate(['/shopper/agreement']);

                } else {
                    this.router.navigate(['/vendor/agreement']);
                }
            } else if (sessionStorage.getItem('returnUrl')) {
                this.router.navigate([sessionStorage.getItem('returnUrl')]);
            } else {
                if(sessionStorage.getItem(SessionScopeValues.SHOPPING_LOGIN_REQUIRED) !== null &&
                      sessionStorage.getItem(SessionScopeValues.SHOPPING_LOGIN_REQUIRED) !== undefined) {
                    sessionStorage.removeItem(SessionScopeValues.SHOPPING_LOGIN_REQUIRED);
                    if (sessionStorage.getItem(SessionScopeValues.DELIVERY_METHOD) === 'delivery') {
                        this.router.navigate(['/shopping/view-delivery-options']).then();
                    } else {
                        this.router.navigate(['/shopping/view-delivery-options']).then();
                    }
                    
                } else  if (this.userHasRole('Shopper')) {
                    this.router.navigate(['/shopperdashboard']);

                } else if (this.userHasRole('Admin Clerk')) {
                    this.router.navigate(['/adminclerkdashboard']);

                } else {
                    this.router.navigate(['/dashboard']).then();
                }
            }

            this.loading = false;

        }, error1 => {
            if (parseInt(error1.status) === 409) {
                this.notifier.notify('Account disabled. Please contact MyAfrica Support team!!', 'error');
            } else if (parseInt(error1.status, 0) === 424) {
                this.notifier.notify('Account not registered. Please click on register to apply!', 'error');
            } else if (parseInt(error1.status, 0) === 428) {
                this.notifier.notify('Account not activated. ' +
                    'Please activate account by clicking the activate link on the registration email!! ' +
                    'You can re-send the registration email by clicking on the Re-send Activation Email link  below.', 'error');
            } else {
                this.notifier.notify('Invalid login. Please try again.', 'error');
            }
            this.loading = false;
        });

    }

	 userHasRole(rolename: string) {
        this.loggedInUser = JSON.parse(sessionStorage.getItem('userDetails'));
        if(this.loggedInUser.userRoles === null) return;
     	let innerUserRole = this.loggedInUser.userRoles.filter(user => user.userRole === rolename);
     	if(innerUserRole !== null && innerUserRole.length  !== 0) {
			return true;
		}       
		return false;
	}

    setUserNavigationOutlet() {
        if (this.userHasRole('Shopper')) {
            sessionStorage.setItem('navigationOutlet', 'shopperdashboard');

        } else if (this.userHasRole('Admin Clerk')) {
            sessionStorage.setItem('navigationOutlet', 'adminclerkdashboard');

        } else {
            sessionStorage.setItem('navigationOutlet', 'dashboard');
        }
    }

    resendActivationLink(username: string, password: string) {
        const regEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!regEx.test(username)) {
            this.notifier.notify('Please fill a valid email address!!', 'warning', 'center');
            return;
        }


        const userDetails = {
            email: username,
            password,
            newPassword: password,
            confirmationPassword: password,
        };

        this.profileService.resendActivationLink(userDetails).subscribe((value: HttpResponse<any>) => {

            // this.router.navigate(['/register-success);
            this.notifier.notify('Email with instruction to activate account has been sent!!', 'success');

        }, error1 => {
            if (parseInt(error1.status, 0) === 200) {
                this.notifier.notify('Email with instruction to activate account has been sent!!', 'success');
            } else if (parseInt(error1.status, 0) === 409) {
                this.notifier.notify('Email account is not registered.!!', 'error');
            } else {
                this.notifier.notify('Email re-send was unsuccessful. Please try again later!', 'error');
            }

        });

    }

}
