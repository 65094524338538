import { Component, OnInit } from '@angular/core';
import {OrdersServiceService} from '../../shared/services/orders-service.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CustomerOrder} from '../../model/CustomerOrder';
import {CustomerOrderInvoice} from '../../model/CustomerOrderInvoice';
import {CustomerOrderItemStatusTracker} from '../../model/CustomerOrderItemStatusTracker';
import {Subscription} from 'rxjs';
import {ApplicationStore} from '../../interceptors/application-store.service';
import {Utils} from '../../shared/Utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessServiceService} from '../../shared/services/business/business-service.service';
import {Business} from '../../model/Business';
import {PaginationBusiness} from '../../model/PaginationBusiness';
import {NotifierService} from '../../services/notifier.service';


@Component({
  selector: 'myafrica-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit {

  loading = false;
  displayPrint = false;
  startDate = new Date();
  endDate: Date;
  message: string;
  businesses: Business[];
  paginationBusiness: PaginationBusiness[];
  startStringDate: string;
  endStringDate: string;
  closeResult: string;
  
  displayMessage = false;
  displaySaveButton = false;
  displayErrorMessage = false;
  displayPrintMessage = false;
  displayPrintErrorMessage = false;

  subs1: Subscription;
  subs2: Subscription;
  subs3: Subscription;
  subs4: Subscription;
  subs5: Subscription;
  
  pageTitle = 'Reports';
  businessId = 0;
  
  userRole: string;

  profileId = 0;

  constructor(private router: Router, private notifier: NotifierService,
              private ordersService: OrdersServiceService,
               private businessService: BusinessServiceService,  private appStore: ApplicationStore) { }

  ngOnInit() {
	  this.userRole = sessionStorage.getItem('userRole');
	  this.loading = true;
	  this.defaultDates();
	  this.getBusinesses();
  }
  
  getBusinesses() {
    const profileId = this.appStore.getJsonItem('profileId');
	this.profileId =  profileId;

    if(this.isAdministrator()) {
		
        
		this.subs1 = this.businessService.getAdminPaginationBusinessByName('all', 0, 1, '0', 'asc','filter').subscribe((value: PaginationBusiness[]) => {
		this.paginationBusiness = value;
		this.businesses = this.paginationBusiness[0].businesses;	 
				
		 });
	} else {
 
		this.subs1 = this.businessService.getBusinessesByProfileId(profileId).subscribe((value: Business[]) => {
			this.businesses = value;
		  }
		);
	}
 

  }
  
  isAdministrator() {
	
		let isAdmin = this.userRole === 'Administrator' || this.userRole === 'Admin Clerk';
  
			if(isAdmin) {
				this.profileId  = 0;
			}
  
		return  isAdmin;
  }
  
  defaultDates() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 10));

    this.endDate = new Date();
	this.loading = false;

  }
  
  
   printOrders() {
    this.displayMessage = false;
    this.displayErrorMessage = false;
	this.loading = true;
    this.displaySaveButton = false;
    this.startStringDate = Utils.getDateString(this.startDate);
    this.endStringDate = Utils.getDateString(this.endDate);
    this.isAdministrator();	
    
    if(parseInt(this.businessId +"") === 0) {
		this.printByDatesOnly();
	} else {
		this.printOrdersByBusinessReport();
	}

  }

  printByDatesOnly() {
	
      
          this.subs2 = this.ordersService
        .printOrdersReport(this.startStringDate, this.endStringDate, this.profileId).subscribe(value  => {
            this.displayMessage = true;
			if([value][0].byteLength > 0) {
			 // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([value], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
			this.loading = false;

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL  = window.URL.createObjectURL(newBlob);

			let tab = window.open(); 
			tab.location.href = fileURL;
			} else {
				 this.loading = false;
	     		 this.message = 'No records founds to generate report. Please try different options!!';
			     this.notifier.notify(this.message, 'error');

			}
             }, error1 => {
		    this.loading = false;
           this.message = 'Error generating report Please try again!!';
           this.notifier.notify(this.message, 'error');

	       this.displayPrintErrorMessage = true;
        });

  }

  printOrdersByBusinessReport() {
	    this.subs2 = this.ordersService
        .printOrdersByBusinessReport(this.startStringDate, this.endStringDate, this.businessId).subscribe(value  => {
            this.displayMessage = true;
			if([value][0].byteLength > 0) {
			 // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([value], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
			this.loading = false;

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const fileURL  = window.URL.createObjectURL(newBlob);

			let tab = window.open(); 
			tab.location.href = fileURL;
			} else {
				 this.loading = false;
			     this.message = 'No records founds to generate report. Please try different options!!';
				 this.notifier.notify(this.message, 'error');
			
			}
        }, error1 => {
		    this.loading = false;
           this.message = 'Error generating report Please try again!!';
           this.notifier.notify(this.message, 'error');
	       this.displayPrintErrorMessage = true;
        });

  }



	clearValues() {
		this.displayPrintMessage = false;
	    this.message  = "";
		this.displayPrintErrorMessage = false;
	}

}