<myafrica-breadcrumbs title="Invoices"></myafrica-breadcrumbs>


<div class="container mb-3">
	
	<div class="row shadow">
		<div class="container">

			<div class="row">
				<div class="col-md-4">

					<label for="invoiceNo" title="Order Number">Invoice No:</label> <input
						type="text" class="form-control" [(ngModel)]="invoiceNo"
						id="invoiceNo" />
				</div>

				<div class="col-md-4">
					<label for="startDate"
						title="Filters the invoices according to the date ordered e.g. Last 15 days=Invoices created in the last 15 days">From:</label>
					<input type="date" id="startDate"
						[ngModel]="startDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="startDate = $event">`
				</div>
				<div class="col-md-4">

					<label for="endDate">To</label> <input type="date" id="endDate"
						[ngModel]="endDate | date:'yyyy-MM-dd'" class="form-control"
						(ngModelChange)="endDate = $event">`


				</div>

			</div>
			<div class="row">
				<div class="form-group col-md-4"></div>

				<div class="form-group col-md-4">
					<button type="button" class="btn btn-success w-100"
						id="search1Button" (keyup.enter)="searchInvoices()"
						(click)="searchInvoices()">
						<i class="fa fa-search" aria-hidden="true"></i>&nbsp;&nbsp;{{
						buttonLabel }}
					</button>

				</div>
			</div>
		</div>
	</div>
</div>


<div class="container mb-3">
	<div class="row bg-warning rounded-bottom rounded-top shadow">
		<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 pull-left mt-2">
			<span *ngIf="totalOrdersCount > 0" class="text-white">
				{{((currentPage) * entryLimitCount) + 1}} - {{displayRangeLimit}} of
				{{totalOrdersCount}} </span> <span *ngIf="totalOrdersCount <= 0"> </span>
		</div>

		<div
			class="input-group col-xs-6 col-sm-6 col-md-6 col-lg-6 input-group-sm mt-2 mb-2
			justify-content-xs-end justify-content-sm-end justify-content-md-end justify-content-lg-end">
			<div class="input-group-prepend">
				<span class="input-group-text">Show</span>
			</div>
			<div class="input-group-prepend">
				<select [(ngModel)]="itemsPerPage"
					class="form-control text-secondary" style="width: 100%"
					name="itemsPerPage" (ngModelChange)="setNumberOfItemsperPage()">
					<option [value]="item.id" *ngFor="let item of itemsPerPageList">{{item.id}}</option>

				</select>
			</div>
			<div class="input-group-append">
				<span class="input-group-text">per page</span>
			</div>
		</div>
	</div>
</div>



<div class="container d-none d-md-block">

	<table class="table table-bordered table-striped">
		<thead class="thead-dark">
			<tr>
				<th>Invoice No</th>
				<th>Order No</th>
				<th>Items</th>
				<th>Invoice Date</th>
				<th>Amount</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of rows; let index = index">
		
				<td class="mw-15"
				(click)="openInvoiceItems(invoiceItemsModal, row)"><u
					class="text-anchor" style="color: blue">{{row.invoiceNumber}}
					</u>
				</td>
				<td class="mw-20 text-blue" style="cursor: pointer">
					{{row.customerOrder.orderId}}
				</td>
		
				<td class="mw-20">{{row.customerOrder.customerOrderItems.length}}</td>
				<td class="mw-20">
					<p>{{row.created_Date| date: dateFormat}}</p>
				</td>
				<td class="mw-20">
					<p>{{row.invoiceAmount | currency : 'ZAR'}}</p>
				</td>
				<td class="mw-5 text-center">
					<button type="button" class="btn btn-success w-80"
						title="Print order" (click)="printInvoiceReport(row)">
						<i class="fa fa-file"></i>&nbsp;Print Invoice
					</button>


				</td>

			</tr>
		</tbody>
	</table>

</div>

<div class="container rounded  d-block d-md-none"
	*ngFor="let row of rows; let index = index">
	<div class="row">
		<div class="col-6 bg-primary text-white">Invoice No:</div>
		<div class="mx-auto" style="cursor: pointer"
			(click)="openInvoiceItems(invoiceItemsModal, row)">
			<u class="text-anchor" style="color: blue">{{row.invoiceNumber}}</u>
		</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white">Order No:</div>
		<div class="mx-auto" 
			(click)="openInvoiceItems(invoiceItemsModal, row)">
			{{row.customerOrder.orderId}}
		</div>
	</div>
	
	<div class="row">
		<div class="col-6 bg-primary text-white">Items:</div>
		<div class="mx-auto">
			{{row.customerOrder.customerOrderItems.length}}
		</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white">Date:</div>
		<div class="mx-auto">{{row.created_Date | date: dateFormat}}</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white">Total:</div>
		<div class="mx-auto">{{row.invoiceAmount | currency : 'ZAR'}}</div>
	</div>
	<div class="row">
		<div class="col-6 bg-primary text-white"></div>
		<div class="col-6 mx-auto text-center"><button type="button" class="btn btn-success w-80"
						title="Print order" (click)="printInvoiceReport(row)">
						<i class="fa fa-file"></i>&nbsp;Print Invoice
					</button></div>
	</div>
	
</div>
<!-- /.container -->
<div class="container mt-2">
	<div class="row bg-light rounded-bottom rounded-top shadow h-20 my-2">
		<div class="col-6 mt-2">
			<span class="badge badge-success text-white"
				*ngIf="displayRangeLimit - itemsPerPage <= 0"><a
				class="disabled text-white h6">&larr; Previous</a></span> <span
				class="badge badge-success text-white"
				*ngIf="displayRangeLimit - itemsPerPage > 0"><a
				(click)="previous()"><span class="text-white h6">&larr;
						Previous</span></a></span>
		</div>

		<div class="col-6  mt-2 text-right">
			<span class="badge badge-success text-white"
				*ngIf="displayRangeLimit < totalOrdersCount"><a
				(click)="next()"><span class="paginationanchor text-white h6">Next
						&rarr;</span></a></span> <span class="badge badge-success text-white"
				*ngIf="displayRangeLimit >= totalOrdersCount"><a
				class="disabled text-white h6">Next &rarr;</a></span>

		</div>
	</div>
</div>

<ng-template #invoiceItemsModal> <!-- Mobile -->

<div class="container d-block d-md-none">
	<div class="row">
		<div
			class="col-12 modal-header bg-primary  text-white text-capitalize">
			<span id="orderNoId">Invoice No :
				{{selectedCustomerOrderInvoice.invoiceNumber}}</span>

		</div>
	</div>


	<div class="container"
		*ngFor="let itemrow of selectedCustomerOrderInvoice.customerOrder.customerOrderItems">
		<div class="row">
			<div class="col-4 bg-primary text-white">Product Name</div>

			<div class="col-8">{{ itemrow.product.productName }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Quantity</div>
			<div class="col-8">{{ itemrow.quantity }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Price</div>
			<div class="col-8">{{ itemrow.itemPrice | currency :
				itemrow.product.currency }}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Total</div>
			<div class="col-8">{{ itemrow.itemPrice * itemrow.quantity |
				currency : itemrow.product.currency}}</div>
		</div>
		<div class="row">
			<div class="col-4 bg-primary text-white">Status</div>
			<div
				*ngIf="itemrow.orderItemStatusTracker[0]; else mobileStatusMissing"
				class="col-8">
				{{itemrow.orderItemStatusTracker[0].itemStatus}}</div>
		</div>
		<ng-template #mobileStatusMissing>
		<div class="col-8">{{itemrow.orderStatus}}</div>

		</ng-template>
	
	</div>

	<hr />
	<div class="row">
		<div class="col-12">
			<button type="button" data-dismiss="modal" (click)="closeModal()"
				class="btn btn-danger">Close</button>
		</div>
	</div>
</div>


<div class="container d-none d-md-block">
	<div class="row">
		<div
			class="col-12 modal-header bg-primary  text-white text-capitalize">
			<span class="modal-title" id="exampleModalLabel">Invoice No :
				{{selectedCustomerOrderInvoice.invoiceNumber}}</span>
			<button type="button" class="close" data-dismiss="modal"
				(click)="closeModal()" aria-label="Close">
				<span aria-hidden="true" class="text-white">&times;</span>
			</button>
		</div>
	</div>
	<div class="row product-items-div bg-primary">
		<div class="card-body col-3 col-md-3  col-sm-6 text-white">Product
			Name</div>
		<div class="card-body col-1 col-md-1 text-white">Quantity</div>
		<div class="card-body col-2 col-md-2 text-white">Price</div>
		<div class="card-body col-2 col-md-2 text-white">Total</div>

		<div class="card-body col-4 col-md-3 col-sm-5 text-white">Status</div>
	</div>

	<div class="row product-items-div form-group"
		*ngFor="let itemrow of selectedCustomerOrderInvoice.customerOrder.customerOrderItems">
		<div class="col-4 col-md-3 col-sm-6 inner-div-cell">{{
			itemrow.product.productName }}</div>
		<div class="col-4 col-md-1  inner-div-cell">{{ itemrow.quantity
			}}</div>
		<div class="col-4 col-md-2  inner-div-cell">{{ itemrow.itemPrice
			| currency : itemrow.product.currency }}</div>
		<div class="col-6  col-md-2  inner-div-cell">{{
			itemrow.itemPrice * itemrow.quantity | currency :
			itemrow.product.currency}}</div>

		<div class="col-5 col-md-3  col-sm-5 inner-div-cell"
			*ngIf="itemrow.orderItemStatusTracker[0]; else statusMissing">
			{{itemrow.orderItemStatusTracker[0].itemStatus}}</div>
		<ng-template #statusMissing>
		<div class="col-5 col-md-3  col-sm-5 inner-div-cell">
			{{itemrow.orderStatus}}</div>

		</ng-template>

	</div>

	<hr />
	<div class="row">
		<div class="col-12">
			<button type="button" data-dismiss="modal" (click)="closeModal()"
				class="btn btn-danger">Close</button>
		</div>
	</div>
</div>
</ng-template>

<ng-template #displayRadioButtonsDetails let-modal>
<div class="container p7 mt-1">
	<b> Order No: {{businessName}}</b> <br /> <b>Contact No:
		{{contactNo}}</b> <br />

	<hr />
	<button type="button" data-dismiss="modal" (click)="closeModal()"
		class="btn btn-danger">Close</button>
</div>
</ng-template>
<ng-template #invoiceModal let-modal>

<div class="row modal-header m-0">
	<h2 class="form-heading">Print Invoice</h2>
</div>
<div class="modal-body">
	<form class="form-horizontal style-form" #messageform="ngForm"
		name="messageform">
		<div class="row">
			<div class="col-1">
				<label class="control-label"></label>
			</div>
			<div class="col-11">
				<textarea class="form-control input-lg" maxlength="255" required
					placeholder="Special Instructions"
					[(ngModel)]="specialInstructions" name="specialInstructions"
					rows="6" required></textarea>
			</div>
		</div>

		<div class="row">
			<div class="col-2 pull-right"></div>
			<div class="col-4 " class="success-message-bold "
				*ngIf="displayPrintMessage">{{message}}</div>
			<div class="col-4 " *ngIf="displaySaveButton"
				class="error-message-bold">{{message}}</div>

			<div class="col-2 pull-right"></div>
		</div>

		<div class="modal-footer">
			
		</div>
		<div class="modal-footer">

			<button type="button" (click)="modal.close('Save click')"
				class="btn btn-danger">Cancel</button>
		</div>
	</form>
</div>
</ng-template>
<myafrica-loader [isPageLoading]="loading"></myafrica-loader>


