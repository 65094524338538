<div class="container my-5 pb-3 shadow">
    <myafrica-page-title pageTitle="Terms & Conditions"></myafrica-page-title>

    <div class="accordion" id="accordionTerms">
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingIntro">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center" type="button" data-toggle="collapse"
                            data-target="#collapseIntro" aria-expanded="true" aria-controls="collapseIntro">
                        Introduction
                    </button>
                </h2>
            </div>

            <div id="collapseIntro" class="collapse show" aria-labelledby="headingIntro" data-parent="#accordionTerms">
                <div class="card-body">
                    <p class="text-justify text-uppercase">PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN
                        IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
                        OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, A
                        CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND
                        OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS. IN
                        PARTICULAR, BUSINESS OWNERS SHOULD UNDERSTAND HOW THE LAWS WORK IN
                        THEIR RESPECTIVE CITIES OR COUNTRIES AND FIELDS OF BUSINESS, SOME
                        CITIES OR COUNTRIES HAVE LAWS THAT RESTRICT THEIR ABILITY TO RUN A
                        BUSINESS WITHOUT A PERMIT. THESE LAWS ARE OFTEN PART OF A
                        CITY&rsquo;S OR COUNTRIES ZONING OR ADMINISTRATIVE CODES IN MANY
                        COUNTRIES BUSINESSES MUST REGISTER, GET A PERMIT, OR OBTAIN A
                        LICENSE BEFORE OPERATING OR DOING BUSINESS, SOME BUSINESSES MAY BE
                        PROHIBITED ALTOGETHER,GORVENMENTS DIFFER IN LAWS ON HOW THEY
                        INFORCE THIS LAWS, PENALTIES MAY INCLUDE FINES OR OTHER
                        ENFORCEMENTS,BUSINESSES SHOULD REVIEW LAW CONSTITUTIONS BEFORE
                        ENLISTING ON MYAFRICACONNECT</p>
                    <br>
                    <ol>
                        <li>
                            This website can be accessed at www.myafricaconnect.com
                            (&quot;the Website&quot;) and is made available by
                            MyAfricaConnect(Proprietary) Limited, registration number &quot;2015/167845/07
                            &quot; , a limited liability private company incorporated in the
                            Republic of South Africa Gauteng (&quot;the Company&quot;,
                            &quot;we&quot;, &quot;us&quot; and &quot;our&quot;)
                            <ol type="i">
                                <li>These Website Terms and Conditions (&quot;Terms and
                                    Conditions&quot;) govern the sale of Goods (as defined below) and
                                    the use of the Website.
                                </li>
                                <li>These Terms and Conditions are
                                    binding and enforceable against every person (&quot;you&quot;;
                                    &quot;your&quot; or &quot;user&quot;) that accesses or uses this
                                    Website or registers as a user as contemplated below. By using the
                                    Website and by clicking on the &quot;Register &quot; button on the
                                    Website, as may be applicable, you acknowledge that you have read
                                    and agree to be bound by these Terms and Conditions.
                                </li>
                                <li>The Website enables you to shop online for an extensive range of
                                    goods including jewellery and beauty products, books, music, toys
                                    and pet supplies ("Goods") etc..and not limited to the fore mentioned products.
                                </li>
                            </ol>
                        </li>
                    </ol>

                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingGeneral">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseGeneral" aria-expanded="false" aria-controls="collapseGeneral">
                        General
                    </button>
                </h2>
            </div>
            <div id="collapseGeneral" class="collapse" aria-labelledby="headingGeneral" data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>The Company may, in its sole discretion, at any time and for any
                            reason and without prior written notice, suspend or terminate the
                            operation of the Website or the user&rsquo;s right to use the
                            Website or any of its contents subject to us processing any orders
                            then already made by you.
                        </li>
                        <li>You may not cede, assign or
                            otherwise transfer your rights and obligations in terms of these
                            Terms and Conditions to any third party
                        </li>
                        <li>Any failure on
                            the part of you or the Company to enforce any right in terms hereof
                            shall not constitute a waiver of that right.
                        </li>
                        <li>If any
                            term or condition contained herein is declared invalid, the
                            remaining terms and conditions will remain in full force and
                            effect.
                        </li>
                        <li>No variation, addition, deletion, or agreed
                            cancellation of the Terms and Conditions will be of any force or
                            effect unless in writing and accepted by or on behalf of the
                            parties hereto.
                        </li>
                        <li>No indulgence, extension of time,
                            relaxation or latitude which any party (&quot;the grantor&quot;)
                            may show grant or allow to the other (&quot;the grantee&quot;)
                            shall constitute a waiver by the grantor of any of the
                            grantor&rsquo;s rights and the grantor shall not thereby be
                            prejudiced or stopped from exercising any of its rights against the
                            grantee which may have arisen in the past or which might arise in
                            the future.
                        </li>
                        <li>These Terms and Conditions contain the
                            whole agreement between you and the Company and no other warranty
                            or undertaking is valid, unless contained in this document between
                            the parties.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingDisclaimer">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseDisclaimer" aria-expanded="false" aria-controls="collapseDisclaimer">
                        Disclaimer
                    </button>
                </h2>
            </div>
            <div id="collapseDisclaimer" class="collapse" aria-labelledby="headingDisclaimer"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol type="A">
                        <li>The use of the Website is entirely at your own risk and you
                            assume full responsibility for any risk or loss resulting from use
                            of the Website or reliance on any information on the Website.
                        </li>
                        <li>Whilst the Company takes reasonable measures to ensure that the
                            content of the Website is accurate and complete, the Company makes
                            no representations or warranties, whether express or implied, as to
                            the quality, timeliness, operation, integrity, availability or
                            functionality of the Website or as to the accuracy, completeness or
                            reliability of any information on the Website.
                        </li>
                        <li>The
                            Company disclaims liability for any damage, loss or liability,
                            whether direct, indirect or consequential in nature, arising out of
                            or in connection with your access to or use of the Website and/or
                            any content therein unless otherwise provided by law.
                        </li>
                        <li>Although Goods sold from the Website may, under certain
                            specifically defined circumstances, be under warranty, the Website
                            itself and all information provided on the Website is provided
                            &quot;as is&quot; without warranty of any kind, either express or
                            implied, including, but not limited to, the implied warranties of
                            merchantability, fitness for a particular purpose, completeness, or
                            non-infringement, as may be allowed in law.
                        </li>
                        <li>Any views
                            or statements made or expressed on the Website are not necessarily
                            the views of the Company, its directors, employees and/or agents.
                        </li>
                        <li>In addition to the disclaimers contained elsewhere in these
                            Terms and Conditions, the Company also makes no warranty or
                            representation, whether express or implied, that the information or
                            files available on the Website are free of viruses, spyware,
                            malware, trojans, destructive materials or any other data or code
                            which is able to corrupt, destroy, compromise, disrupt, disable,
                            harm, jeopardise or otherwise impede in any manner the operation,
                            stability, security functionality or content of your computer
                            system, computer network, hardware or software in any way.
                        </li>
                        <li>You
                            accept all risk associated with the existence of such viruses,
                            destructive materials or any other data or code which is able to
                            corrupt, compromise, jeopardise, disrupt, disable, harm or
                            otherwise impede in any manner the operation or content of a
                            computer system, computer network, any handset or mobile device, or
                            your hardware or software, save where such risks arise due to the
                            gross negligence or wilful misconduct of the Company, its
                            employees, agents or authorised representatives. The Company thus
                            disclaims all liability for any damage, loss or liability of any
                            nature whatsoever arising out of or in in connection with your
                            access to or use of the Website.
                        </li>
                    </ol>

                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingPrivacy">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapsePrivacy" aria-expanded="false" aria-controls="collapsePrivacy">
                        Privacy Policy
                    </button>
                </h2>
            </div>
            <div id="collapsePrivacy" class="collapse" aria-labelledby="headingPrivacy" data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>We respect your privacy and will take reasonable measures to
                            protect it, as more fully detailed below.
                        </li>
                        <li>Should you
                            decide to register as a user on the Website, we may require you to
                            provide us with personal information which includes but is not
                            limited to -
                            <ol type="i">
                                <li>your name and surname;</li>
                                <li>your
                                    email address;
                                </li>
                                <li>your physical address;</li>
                                <li>your
                                    gender;
                                </li>
                                <li>your date
                                    of birth
                                </li>
                                <li>your mobile number; and</li>
                            </ol>
                        </li>
                        <li>Should your personal information change,
                            please inform us and provide us with updates to your personal
                            information as soon as reasonably possible to enable us to update
                            your personal information.
                        </li>
                        <li>This Website makes use of
                            &quot;cookies&quot; to automatically collect information and data
                            through the standard operation of the Internet servers.<br>
                            <h4>Cookies</h4>
                            <p>these are small text files a website can use (and which we may use) to
                                recognise repeat users, facilitate the user&rsquo;s on-going access
                                to and use of a website and allow a website to track usage
                                behaviour and compile aggregate data that will allow the website
                                operator to improve the functionality of the website and its
                                content. The type of information collected by cookies is not used
                                to personally identify you. If you do not want information
                                collected through the use of cookies, there is a simple procedure
                                in most browsers that allows you to deny or accept the cookie
                                feature. Please note that cookies may be necessary to provide you
                                with certain features available on our Website, and thus if you
                                disable the cookies on your browser you may not be able to use
                                those features, and your access to our Website will therefore be
                                limited.&nbsp;If you do accept a &quot;cookie&quot;, you thereby
                                consent to our use of any personal information collected by us
                                using that cookie subject to the provisions of clause 7.7 below.</p></li>
                        <li>You may choose to provide additional personal information to us,
                            in which event you agree to provide accurate and current
                            information, and not to impersonate or misrepresent any person or
                            entity or falsely state or otherwise misrepresent your affiliation
                            with anyone or anything.
                        </li>
                        <li>The purposes for which we will
                            use your personal information are as follows:
                            <ol>
                                <li>in
                                    relation to the sale and delivery of Goods;
                                </li>
                                <li>to contact
                                    you regarding current or new products or services (provided you
                                    have consented to receiving such marketing material);
                                </li>
                                <li>o inform you of new features, special offers and promotional
                                    competitions (provided you have consented to receiving such
                                    marketing material); and
                                </li>
                                <li>to improve your experience on
                                    our Website by, inter alia, monitoring statistical non-personal
                                    browsing habits, and to transact with us.
                                </li>
                            </ol>
                        </li>
                        <li>The Company will
                            not use your personal information for any purpose (other than as
                            stated above) without your express consent. We will not use or
                            disclose your personal information to third parties without your
                            consent, unless the use or disclosure is -
                            <ol type="i">
                                <li>required in
                                    order to comply with applicable law, order of court or legal
                                    process served on the Company; and/or
                                </li>
                                <li>disclosure is
                                    necessary to protect and defend the rights or property of the
                                    Company.
                                </li>
                            </ol>
                        </li>
                        <li>We will be entitled to disclose your personal
                            information to those of our employees and/or third party service
                            providers who assist us to interact with you via our Website or
                            email, for the sale of Goods or when delivering Goods to you, and
                            thus need to know your personal information in order to assist us
                            to communicate with you properly and efficiently. We will ensure
                            that all such employees and/or third party service providers having
                            access to your personal information are bound by appropriate and
                            legally binding confidentiality and non-use obligations in relation
                            to your personal information.
                        </li>
                        <li>We will -
                            <ol type="i">
                                <li>treat
                                    your personal information as strictly confidential;
                                </li>
                                <li>take appropriate technical and organisational measures to ensure
                                    that your personal information is kept secure and is protected
                                    against unauthorised or unlawful processing, accidental
                                    loss, destruction or damage, alteration, disclosure or access;
                                </li>
                                <li>provide you with access to your personal information to view
                                    and/or update personal details;
                                </li>
                                <li>promptly notify you if
                                    we become aware of any unauthorised use, disclosure or processing
                                    of your personal information;
                                </li>
                                <li>provide you with
                                    reasonable evidence of our compliance with our obligations under
                                    this policy on reasonable notice and request; and
                                </li>
                                <li>upon
                                    your request, promptly return or destroy any and all of your
                                    personal information in our possession or control.
                                </li>
                                <li>We will
                                    not retain your personal information longer than the period for
                                    which it was originally needed, unless we are required by law to do
                                    so, or you consent to us retaining such information for a longer
                                    period.
                                </li>
                            </ol>
                        </li>
                        <li>The Company undertakes never to sell or make
                            your personal information available to any third party unless we
                            are compelled to do so by law. In particular, in the
                            event of a fraudulent online payment, the Company reserves the
                            right to disclose relevant personal information for criminal
                            investigation purposes or in line with any other legal obligation
                            for disclosure of the personal information which may be required of
                            it. Whilst we will do all things reasonably necessary to protect
                            your rights of privacy, we cannot guarantee or accept any liability
                            whatsoever for unauthorised or unlawful disclosures of your
                            personal information, whilst in our possession, made by third
                            parties who are not subject to our control, unless such disclosure
                            is as a result of our gross negligence. If you disclose
                            your personal information to a third party, such as an entity which
                            operates a website linked to this Website or anyone other than the
                            Company, THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE,
                            HOWSOEVER ARISING, SUFFERED BY YOU AS A RESULT OF THE DISCLOSURE OF
                            SUCH INFORMATION TO THE THIRD PARTY. This is because we do not
                            regulate or control how that third party uses your personal
                            information. You should always ensure that you read the privacy
                            policy of any third party.
                        </li>
                        <li value=""><h4>Limitation of liability</h4>
                            <p>The Company cannot be held liable for any inaccurate information
                                published on the Website and/or any incorrect prices displayed on
                                the Website, save where such liability arises from the gross
                                negligence or wilful misconduct of the Company, its employees,
                                agents or authorised representatives. You are encouraged to contact
                                us to report any possible malfunctions or errors by way of email to
                                info@myafricaconnect.com.<br><br> THE COMPANY SHALL NOT BE
                                LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
                                CONSEQUENTIAL LOSS OR DAMAGES WHICH MIGHT ARISE FROM YOUR USE OF,
                                OR RELIANCE UPON, THE WEBSITE OR THE CONTENT CONTAINED IN THE
                                WEBSITE; OR YOUR INABILITY TO USE THE WEBSITE, AND/OR UNLAWFUL
                                ACTIVITY ON THE WEBSITE AND/OR ANY LINKED THIRD PARTY WEBSITE.<br><br>
                                YOU HEREBY INDEMNIFY THE COMPANY AGAINST ANY LOSS, CLAIM OR
                                DAMAGE WHICH MAY BE SUFFERED BY YOURSELF OR ANY THIRD PARTY ARISING
                                IN ANY WAY FROM YOUR USE OF THIS WEBSITE AND/OR ANY LINKED THIRD
                                PARTY WEBSITE.</p></li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingNotices">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseNotices" aria-expanded="false" aria-controls="collapseNotices">
                        Important Notice
                    </button>
                </h2>
            </div>
            <div id="collapseNotices" class="collapse" aria-labelledby="headingNotices" data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>These Terms and Conditions apply to users who are consumers for
                            purposes of the Consumer Protection Act, 68 of 2008 (&quot;the
                            CPA&quot;).
                        </li>
                        <li>These Terms and Conditions contain
                            provisions that appear in similar text and style to this clause and
                            which -
                            <ol type="i">
                                <li>may limit the risk or liability of the Company
                                    or a third party; and/or
                                </li>
                                <li>may create risk or liability
                                    for the user; and/or
                                </li>
                                <li> may compel the user to indemnify
                                    the Company or a third party; and/or
                                </li>
                                <li>serves as an
                                    acknowledgement, by the user, of a fact.
                                </li>
                            </ol>
                        </li>
                        <li>our attention
                            is drawn to these Terms and Conditions because they are important
                            and should be carefully noted.
                        </li>
                        <li>If there is any
                            provision in these Terms and Conditions that you do not understand,
                            it is your responsibility to ask the Company to explain it to you
                            before you accept the Terms and Conditions or continue using the
                            Website.
                        </li>
                        <li>Nothing in these Terms and Conditions is
                            intended or must be understood to unlawfully restrict, limit or
                            avoid any right or obligation, as the case may be, created for
                            either you or the Company in terms of the CPA.
                        </li>
                        <li>The
                            Company permits the use of this Website subject to the Terms and
                            Conditions. By using this Website in any way, you shall be deemed
                            to have accepted all the Terms and Conditions unconditionally. You
                            must not use this Website if you do not agree to the Terms and
                            Conditions.
                        </li>

                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingRegAndUse">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseRegAndUse" aria-expanded="false" aria-controls="collapseRegAndUse">
                        Registration and Use of website
                    </button>
                </h2>
            </div>
            <div id="collapseRegAndUse" class="collapse" aria-labelledby="headingRegAndUse"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>Only registered users may purchase Goods on the Website and
                            only listed businesses may sell products on the website or even
                            advertise the products on the website.
                        </li>
                        <li>To register as
                            a user, you must provide a unique username and password and provide
                            certain information and personal details to the Company.
                        </li>
                        <li>You
                            acknowledge that you will use your unique username and password to
                            access the Website and to purchase Goods.
                        </li>
                        <li>You agree and
                            warrant that your username and password shall:
                            <ol type="i">
                                <li>be used
                                    for personal use only; and
                                </li>
                                <li>not be disclosed by you to
                                    any third party.
                                </li>
                            </ol>
                        </li>
                        <li>For security purposes you agree to enter
                            the correct username and password whenever purchasing Goods,
                            failing which you will be denied access.
                        </li>
                        <li>You agree that
                            the Company will accept and process your order for Goods once the
                            correct username and password have been entered, irrespective of
                            whether the use of the username and password is unauthorised or
                            fraudulent and that you will be liable for payment of such order,
                            save where the order is cancelled by you in terms of the provisions
                            of clause 16.
                        </li>
                        <li>You agree to notify the Company immediately
                            upon becoming aware of or reasonably suspecting any unauthorised
                            access to or use of your username and password and to take steps to
                            mitigate any resultant loss or harm.
                        </li>
                        <li>By using the Website
                            you warrant that you are 18 (eighteen) years of age or older and of
                            full legal capacity. If you are under the age of 18 (eighteen) or
                            if you are not legally permitted to enter into a binding agreement,
                            then you may use the Website only with the involvement and
                            supervision of your parent or legal guardian. If your parent or
                            legal guardian supervises you and gives his/her consent, then such
                            person agrees to be bound to these Terms and Conditions and to be
                            liable and responsible for you and all your obligations under these
                            Terms and Conditions. You agree that you will not in any way use
                            any device, software or other instrument to interfere or attempt to
                            interfere with the proper working of the Website. In addition, you
                            agree that you will not in any way use any robot, spider, other
                            automatic device, or manual process to monitor, copy, distribute or
                            modify the Website or the information contained herein, without the
                            prior written consent from an authorised Company representative
                            (such consent is deemed given for standard search engine technology
                            employed by Internet search websites to direct Internet users to
                            this Website).
                        </li>
                        <li>You may not use the Website to
                            distribute material which is defamatory, offensive, contains or
                            amounts to hate speech or is otherwise unlawful.
                        </li>
                        <li>You may not in
                            any way display, publish, copy, print, post or otherwise use the
                            Website and/or the information contained therein without the
                            express prior written consent of an authorised Company
                            representative.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingAvailabilityAndTermination">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseAvailabilityAndTermination" aria-expanded="false"
                            aria-controls="collapseAvailabilityAndTermination">
                        Availability and Termination
                    </button>
                </h2>
            </div>
            <div id="collapseAvailabilityAndTermination" class="collapse"
                 aria-labelledby="headingAvailabilityAndTermination" data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>We will use reasonable endeavours to maintain the availability
                            of the Website, except during scheduled maintenance periods, and
                            reserve the right to discontinue providing the Website or any part
                            thereof with or without notice to you.
                        </li>
                        <li>The Company may
                            in its sole discretion terminate, suspend and modify this Website,
                            with or without notice to you. You agree that the Company will not
                            be liable to you in the event that it chooses to suspend, modify or
                            terminate this Website other than for processing any orders made by
                            you prior to such time and to the extent possible.
                        </li>
                        <li>If
                            you fail to comply with your obligations under these Terms and
                            Conditions, including any incident involving payment of the price
                            of an order for any Goods, and you fail to remedy such failure
                            within 7 (seven) days of notice to you by us, this may (in our sole
                            discretion) lead to a suspension of your access to the Website
                            without any prejudice to any claims for damages or otherwise that
                            we may have against you.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingSale">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseSale" aria-expanded="false" aria-controls="collapseSale">
                        Sale
                    </button>
                </h2>
            </div>
            <div id="collapseSale" class="collapse" aria-labelledby="headingSale" data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>Registered users may place orders for Goods as long as the Goods
                            are available and have not been sold out.
                        </li>
                        <li>An agreement
                            of sale between you and the Company will come into effect upon
                            completion of an online order summary on the Website, payment or
                            payment authorisation being received by the Company to the
                            satisfaction of the Company and by delivery of the Goods to you.
                        </li>
                        <li>You acknowledge that stock of all Goods on offer is limited.
                            When Goods are no longer available the Company will notify you and
                            you will be entitled to a refund of the amount paid by you for such
                            Goods. Whilst the Company will take all reasonable efforts to
                            monitor stock levels, we cannot guarantee the availability of stock
                            and we will only be liable for delivery of the Goods when the Goods
                            are available. Should the Goods no longer be available, you shall
                            be entitled to either be credited or refunded for any payments
                            already made by you.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingPayment">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapsePayment" aria-expanded="false" aria-controls="collapsePayment">
                        Payment
                    </button>
                </h2>
            </div>
            <div id="collapsePayment" class="collapse" aria-labelledby="headingPayment" data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>We are committed to providing secure online payment facilities.
                            All transactions are encrypted using appropriate encryption
                            technology.
                        </li>
                        <li>Payment can be made for Goods via -
                            <ol>
                                <li>debit card;</li>
                                <li>credit card: <small>where payment is made by
                                    credit card, we may require additional information in order to
                                    authorise and/or verify the validity of payment. In such cases we
                                    reserve the right to withhold delivery until such time as the
                                    additional information is received by us and authorisation is
                                    obtained by us for the amounts. If we do not receive authorisation
                                    your purchase of the Goods will be cancelled. You warrant that you
                                    are fully authorised to use the credit card supplied for purposes
                                    of paying the Goods. You also warrant that your credit card has
                                    sufficient available funds to cover all the costs incurred as a
                                    result of the services used on the Website;</small></li>
                                <li>direct bank: <small>
                                    deposit or electronic funds transfer: if you pay via direct bank
                                    deposit or electronic funds transfer, payment must be made within 5
                                    (five) days of placing your order. The Company will not execute the
                                    order until receiving confirmation that payment has been received;</small></li>
                            </ol>
                        </li>
                        <li></li>

                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingInstantEFT">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseInstantEFT" aria-expanded="false" aria-controls="collapseInstantEFT">
                        Instant EFT
                    </button>
                </h2>
            </div>
            <div id="collapseInstantEFT" class="collapse" aria-labelledby="headingInstantEFT"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>cash on delivery;</li>
                        <li>cheque deposits: cheque deposits
                            may be made by prior arrangement with the Company. The Company will
                            only execute the order on notification of clearance of the cheque
                            deposit.
                        </li>
                        <p class="mt-2">
                            Once you accept these Terms and Conditions,
                            you will be directed to a link to a secure site for payment of the
                            applicable purchase price for the Goods
                        </p>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingDeliveryAndGoods">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseDeliveryAndGoods" aria-expanded="false"
                            aria-controls="collapseDeliveryAndGoods">
                        Shipping and Delivery
                    </button>
                </h2>
            </div>
            <div id="collapseDeliveryAndGoods" class="collapse" aria-labelledby="headingDeliveryAndGoods"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>The Company offers 3 (three) methods of delivery of Goods to
                            you. You may elect delivery via:
                            <ul>
                                <li>courier;</li>
                                <li>post office;</li>
                                <li>self-collection.</li>
                            </ul>
                        </li>
                        <li>Please
                            see details of our delivery and shipping terms and conditions under
                            FAQ&rsquo;s: Shipping and Delivery which are incorporated herein by
                            reference
                        </li>
                        <li>We shall take all reasonable efforts to
                            accurately reflect the Goods, purchase price and delivery charges
                            of Goods on the Website. However, should there be any errors of
                            whatsoever nature on the Website (which are not due to our gross
                            negligence) we shall not be liable to make good on a transaction
                            based on any error apart from refunding any monies paid by you to
                            the Company.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingElectronicComms">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseElectronicComms" aria-expanded="false"
                            aria-controls="collapseElectronicComms">
                        Electronic communications
                    </button>
                </h2>
            </div>
            <div id="collapseElectronicComms" class="collapse" aria-labelledby="headingElectronicComms"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <p class="container pt-2">
                        1. When you visit the Website or send emails to us, you consent to
                        receiving communications from us electronically.<br>
                    </p>
                    <p>Cancellation&nbsp;and&nbsp;refund</p>
                    <p class="container pt-2">
                        You are, in terms of section 44 of the Electronic Communications
                        and Transactions Act 25 of 2002 (&quot;ECT Act&quot;) entitled to
                        cancel, without reason and without penalty, your order for the
                        Goods within 7 (seven) days of receipt of the Goods. In such an
                        event -<br> you will receive a full refund of the purchase
                        price within 30 (thirty) days of the date of your cancellation of
                        the order;<br> you undertake not to utilise the Goods;<br>
                        your agreement of purchase will be deemed to have been cancelled,
                        and<br> you will be liable in terms of section 44(2) of the
                        ECT Act for the direct costs of returning the Goods, such as
                        courier and/or shipping charges.<br> If the Goods in question
                        were procured from the &quot;Import&quot; section of our website
                        from a third party affiliate supplier, you will also be liable for
                        the direct costs incurred by us in returning the Goods to such
                        third party affiliate supplier.You should note that your right to
                        cancel your order for Goods without penalty set out in this clause,
                        shall not apply where the Goods that you have ordered are
                        newspapers, periodicals, magazines and books, or where audio or
                        video recordings or computer software were unsealed by you, or
                        where goods are made to your specifications or are clearly
                        personalised.<br> 1. The Company will deliver the Goods to you
                        as soon as reasonably possible, but no later than 30 (thirty) days
                        of receipt of your payment (&quot;Delivery Period&quot;). We will
                        notify you if we are unable to deliver the Goods during the
                        Delivery Period. You may then, within 7 (seven) days of receiving
                        such notification elect whether or not to proceed with your order
                        for the Goods or cancel your order. If you elect to cancel your
                        order, we will reimburse you with the purchase price.<br> 2.
                        The Company may, in its sole discretion, accept the cancellation of
                        an order by you, on sufficient cause shown and on a case by case
                        basis. In which event the provisions set out in this clause 16 in
                        respect of refunds and returns will similarly apply<br></p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingExchangeAndReturns">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseExchangeAndReturns" aria-expanded="false"
                            aria-controls="collapseExchangeAndReturns">
                        Exchange and returns
                    </button>
                </h2>
            </div>
            <div id="collapseExchangeAndReturns" class="collapse" aria-labelledby="headingExchangeAndReturns"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>Subject to the provisions of clause 16 above, Goods may be
                            returned only when defective, damaged or if the Goods supplied are
                            not the same Goods as what was ordered. If any defect in the Goods
                            (provided that such Goods are not by their nature not durable for
                            up to a period of 6(six) months) are discovered within a period of
                            6 (six) months after delivery, this must be reported to us as soon
                            as reasonably possible after the Goods having been delivered to you
                            and must be returned to us within a period of 6 (six) months after
                            delivery. If the Goods are not the same as what was ordered this
                            must be reported within 24 (twenty four) hours of delivery. Any
                            damage must also be immediately reported, within 24 (twenty four)
                            hours of delivery.
                        </li>
                        <li>If the Goods have been approved by
                            us in accordance with the provisions of clause 17.1 for return, we
                            will notify you and either arrange
                            <ol type="i">
                                <li>for our couriers to collect
                                    the item from you; or
                                </li>
                                <li>request that you post it back to us; or</li>
                                <li>ask you to return the Goods to our warehouse location,
                                    depending on your original order&rsquo;s shipping method. Should
                                    you need to post the Goods back to us please only send it to our
                                    postal address.
                                </li>
                            </ol>
                        </li>
                        <li>If you are returning Goods via courier
                            or post office you must package it carefully so that it does not
                            become damaged enroute.
                        </li>
                        <li> Nothing in this clause 17 or
                            the Terms and Conditions in any way limits your rights in terms of
                            section 56 read with section 55 of the CPA in any way.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingLawAndJurisdiction">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseLawAndJurisdiction" aria-expanded="false"
                            aria-controls="collapseLawAndJurisdiction">
                        Governing law and Jurisdiction
                    </button>
                </h2>
            </div>
            <div id="collapseLawAndJurisdiction" class="collapse" aria-labelledby="headingLawAndJurisdiction"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>These Terms and Conditions and our relationship and/or any
                            dispute arising from or in connection with these Terms and
                            Conditions shall be governed and interpreted in accordance with the
                            laws of the Republic of South Africa. Your continued use of the
                            Website will constitute your consent and submission to the
                            jurisdiction of the South African courts regarding all proceedings,
                            transactions, applications or the like instituted by either party
                            against the other, arising from any of these Terms and Conditions.
                        </li>
                        <li>In the event of any dispute arising between you and the Company,
                            you hereby consent to the non-exclusive jurisdiction of the High
                            Court of the Republic of South Africa
                        </li>
                        <li>notwithstanding
                            that the quantum in the action or proceedings may otherwise fall
                            below the monetary jurisdiction of that court.
                        </li>
                        <li> Nothing in this
                            clause 18 or the Terms and Conditions limit your right to approach
                            any court, tribunal or forum of competent jurisdiction in terms of
                            the CPA.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-primary text-white display-4" id="headingNoticesFinal">
                <h2 class="mb-0">
                    <button class="btn text-white display-4 btn-block text-center collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseNoticesFinal" aria-expanded="false"
                            aria-controls="collapseNoticesFinal">
                        Notices
                    </button>
                </h2>
            </div>
            <div id="collapseNoticesFinal" class="collapse" aria-labelledby="headingNoticesFinal"
                 data-parent="#accordionTerms">
                <div class="card-body">
                    <ol>
                        <li>Either party may change its nominated address to any other
                            physical address by not less than 7 days&rsquo; notice in writing
                            to the other party.
                        </li>
                        <li>Notices must be sent either by
                            hand, prepaid registered post, telefax or email and must be in
                            English. All notices sent
                            <ol type="i">
                                <li>by hand will be deemed to
                                    have been received on the date of delivery;
                                </li>
                                <li>by prepaid
                                    registered post, will be deemed to have been received 10 days after
                                    the date of posting;
                                </li>
                                <li>by telefax before 16h30 on a
                                    business day will be deemed to have been received, on the date of
                                    successful transmission of the telefax.
                                </li>
                                All telefaxes sent
                                after 16h30 or on a day which is not a business day will be deemed
                                to have been received on the following business day
                            </ol>
                        </li>

                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
