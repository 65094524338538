import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ProfileService} from '../../shared/services/profile.service';

@Component({
  selector: 'myafrica-shopper-agreement',
  templateUrl: './shopper-agreement.component.html',
  styleUrls: ['./shopper-agreement.component.scss']
})
export class ShopperAgreementComponent implements  OnInit, OnDestroy {

  termsAccepted: boolean;
  userName: string;
  buttonDisabled: boolean;
  email: string;
  subscription: Subscription;

  displayErrorMessage: boolean;
  displaySuccessMessage: boolean;
  loading = false;
  loginErrorMessage: string;
  loginSuccessMessage: string;
  userDetails: any;

  constructor( private profileService: ProfileService,
				           private router: Router, ) {
  }

  ngOnInit() {
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.userName = this.userDetails.userName;
	this.email = this.userDetails.email;

  }

  activateButton() {
  if (this.termsAccepted) {
	this.buttonDisabled = false;
	} else {
	this.buttonDisabled = true;

	}
  }

  registerUser() {

   const userDetails = {
      email: this.email
    };

   this.subscription = this.profileService.acceptSellerAgreement(this.userDetails).subscribe((value: HttpResponse<any>) => {

        this.router.navigate(['/shopperdashboard']);

    }, error1 => {


      this.loginErrorMessage = 'An error occurred activating the account. Please try again later!';

	     this.displayErrorMessage = true;
      this.displaySuccessMessage = false;

    });


  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
