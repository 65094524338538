<!-- Modal showProductOutOfStock options -->
<div class="modal" tabindex="-1" id="deliveryOptionsModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h6 class="d-inline">I will collect from the business: &nbsp;</h6>
                <p class="text-justify d-inline">When you select the &quot;I will
                    collect from the business&quot; option you
                    will be choosing the option to collect items from the business yourself</p>
                <br>
                <br>
                <h6 class="d-inline">Have it delivered: &nbsp;</h6>
                <p class="text-justify d-inline">When you
                    select the '&quot;Have it delivered&quot; option you will be choosing the option to
                    have the items delivered to
                    you. Additional charges will be incurred. Please note that this is for businesses in the Gauteng
                    province area</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="viewBigImageModal" style="z-index: 9999">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{cartItem?.product.productName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="text-danger">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <img [src]="cartItem?.image" class="img-fluid">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
