<div class="container-fluid m-0 p-0">

    <div class="container-fluid px-0 py-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-primary">
                <li class="breadcrumb-item "><a routerLink="/dashboard" class="text-white">Dashboard</a></li>
                <li class="breadcrumb-item "><a
                        [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-products']}}]"
                        skipLocationChange class="text-white">Manage Products</a></li>
                <li class="breadcrumb-item "><a
                        [routerLink]="['/dashboard', {outlets: {'dashboard': ['manage-products']}}]"
                        skipLocationChange class="text-white">Edit Product</a></li>
                <li class="breadcrumb-item text-success" aria-current="page">{{selectedProduct.productName}}</li>
            </ol>
        </nav>
    </div>


    <div class="row">
        <div class="col-lg-12 text-center">
            <h3 class="text-dark">
                {{selectedProductName}}
            </h3>
        </div>
    </div>

    <hr/>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 text-center text-md-left">
            <img class="img-thumbnail text-center mx-auto" style="min-height: 200px; max-height: 201px"
                 src="{{activatedImage ? activatedImage : selectedProduct.defaultImageUrl}}">

            <div class="container"
                 *ngIf="selectedProduct.colours && selectedProduct.colours.length > 0 ">
                <label for="colors">Supported Colors</label>
                <div id="colors">
                    <div class="d-inline-block"
                         *ngFor="let myColor of selectedProduct.colours">
                        <span *ngIf="myColor.colourName === '#999999A'" (click)="activateColorImage(myColor)"
                              class="badge mr-1 supported-colours multicolourdiv cursor-pointer" >&nbsp;</span>
                        <myafrica-product-icon *ngIf="myColor.colourName !== '#999999A'"
                                               [clothingColour]="myColor" (click)="activateColorImage(myColor)">
                        </myafrica-product-icon>
                    </div>
                </div>


            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 p-0 text-center text-md-left">
            <h4 class="selectedproduct-name">{{selectedProduct.productBusiness.businessName}}</h4>

            <div class="caption col-lg-8 col-md-8 col-sm-6 text-center text-md-left">

                <p class="text-warning">
                    {{selectedProductPrice | currency:selectedProduct.currency.concat(' ')}}</p>

                <button class="btn btn-success mr-2 mb-2" style="width: 180px"
                        *ngIf="!selectedProduct.deleted" data-toggle="modal" data-target="#exampleModal">
                    Change default Image
                </button>

                <button class="btn btn-danger mr-2 mb-2" style="width: 180px"
                        *ngIf="!selectedProduct.deleted" (click)="confirm()">
                    Delete Product
                </button>

                <button *ngIf="selectedProduct.category.clothingCategory" style="width: 180px"
                        class="btn btn-success mr-2 mb-2" (click)="addColor(addColorModal)">Add
                    a color
                </button>

                <button class="btn  btn-success" style="width: 180px"
                        *ngIf="selectedProduct.deleted" (click)="undeleteConfirm()">
                    Activate Product
                </button>
            </div>
        </div>
    </div>


    <hr/>

    <div class="container-fluid mt-md-5 px-0 mx-0 text-justify">
        <ul class="nav nav-tabs justify-content-center " id="myTab" role="tablist">
            <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
                <a class="nav-link active" id="productDetails-tab" data-toggle="tab" href="#productDetails" role="tab"
                   aria-controls="productDetails" aria-selected="true">Product Details</a>
            </li>
            <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
                <a class="nav-link" id="productColours-tab" data-toggle="tab" href="#productColours" role="tab"
                   aria-controls="productColours"
                   aria-selected="false">Product Colours</a>
            </li>
            <li class="nav-item p-0 col-sm text-center shadow" role="presentation">
                <a class="nav-link" id="productHistory-tab" data-toggle="tab" href="#productHistory" role="tab"
                   aria-controls="productHistory"
                   aria-selected="false">History behind the product</a>
            </li>
        </ul>
        <div class="tab-content py-3" id="myTabContent">
            <div class="tab-pane fade show active" id="productDetails" role="tabpanel"
                 aria-labelledby="productDetails-tab">
                <myafrica-product-details-edit></myafrica-product-details-edit>
            </div>
            <div class="tab-pane fade" id="productColours" role="tabpanel" aria-labelledby="productColours-tab">
                <div *ngIf="selectedProduct.category.clothingCategory">
                    <myafrica-product-colour-edit></myafrica-product-colour-edit>
                </div>
            </div>
            <div class="tab-pane fade" id="productHistory" role="tabpanel" aria-labelledby="productHistory-tab">
                <myafrica-edit-history [product]="selectedProduct"></myafrica-edit-history>
            </div>
        </div>
    </div>

    <!--MODALS-->
    <ng-template #addColorModal let-modal>
        <myafrica-page-title pageTitle="Product Colour"></myafrica-page-title>

        <div class="container col-12 form-inline modal-body py-2">
            <div class="container p7 mt-1 col-sm-12 col-lg-6">
                <div class="input-group mb-1">
                    <label class="col-sm-12 col-lg-5 control-label">Multi-Colour?</label>
                    <input type="checkbox" name="newsletter" class="form-check-input"
                           style="height: 20px; width: 20px;" [(ngModel)]="multiColourProduct"
                           (change)="setMultiColourProduct()"> <label
                        class="multicolourdiv text-white" style=" width: 110px">
                    Multi-Colour</label> &nbsp; &nbsp; &nbsp; &nbsp; <span
                        class="network-name info-icon" title="" style="margin-left: 5px"
                        (click)="open(displayRadioButtonsDetails)">
                <i class="fa fa-info-circle text-warning" aria-hidden="true"></i>
                </span>

                </div>

                <div class="input-group mb-1" *ngIf="!disableColorPicker">
                    <label class="col-5 control-label">Color</label>
                    <input name="color" [disabled]="disableColorPicker" [(ngModel)]="color" [(colorPicker)]="color"
                           [style.background]="color"/>
                </div>
                <br/>

                <div class="input-group mb-3" *ngFor="let elem of sizesElements">
                    <div>
                        <div class="input-group-text">
                            Size
                        </div>
                    </div>
                    <select class="form-control" name="name" [(ngModel)]="elem.name">
                        <option [value]="item.clothingSize"
                                *ngFor="let item of clothingSizeCodes">{{item.clothingSize}}</option>
                    </select>

                    <input type="number" class="form-control" name="size" [(ngModel)]="elem.count" placeholder="count">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            Items
                        </div>
                    </div>
                </div>


                <div class="input-group mb-3">
                    <button class="btn btn-success" (click)="adMoreElement()">Add
                        More Sizes
                    </button>
                </div>

            </div>
            <div class="container p7 mt-1 col-sm-12 col-lg-6 text-center">

                <div class="container-fluid">
                    <div class="input-group-text my-1 border-info" *ngIf="url else noImage">
                        <img [src]="url" class="m-auto img-fluid">
                    </div>
                </div>
                <div class="container">
                    <input type='file' class="btn-theme-large" (change)="onSelectFile($event)">
                </div>
            </div>
        </div>


        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-lg-2"></div>
                <div class="col-sm-12 col-lg-4 p-2">
                    <button class="btn btn-success btn-block" [disabled]="!url || !color"
                            (click)="submit()">Add Color
                    </button>
                </div>
                <div class="col-sm-12 col-lg-4 p-2">
                    <button type="button" data-dismiss="modal" (click)="modal.close('')"
                            class="btn btn-danger btn-block">Close
                    </button>
                </div>
                <div class="col-sm-12 col-lg-2"></div>

            </div>
        </div>

    </ng-template>


    <ng-template #displayRadioButtonsDetails let-modal>
        <div class="container p7 mt-1">
            <label class="search-label"> Multi Colour? = The field is to
                indicate whether the product is multi coloured or not</label> <label
                class="search-label"> Color = The field is a colour picker to
            select the colour of the product. If the multi coloured checkbox is
            selected this field will be hidden</label> <label class="search-label">
            Add More Sizes = Button to add more sizes for the product</label> <label
                class="search-label"> Size = The size of the product, this can
            be a number or a string. As an example the size of a shirt can be a 41
            or M for medium based on the size type used.</label> <br/> <br/>
            <button type="button" data-dismiss="modal" (click)="modal.close('')"
                    class="btn btn-danger">Close
            </button>
            <br/>

        </div>
    </ng-template>

    <ng-template #noImage>
        <div class="input-group-text text-center my-1">Image display area</div>
    </ng-template>


</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary  text-white">
                <h3 class="modal-title text-center mx-auto"
                    id="exampleModalLabel">Update Default Image</h3>
                <i class="fa fa-times text-white" data-dismiss="modal" aria-label="Close"></i>
            </div>

            <div class="modal-body">
                <img [src]="defaultUrl" height="200" class="img-fluid" (change)="updateDefaultUrl($event)"> <br/>
                <input type='file' class="pt-3" (change)="updateDefaultUrl($event)">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger my-1" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary my-1" (click)="updateDefaultImage()" data-dismiss="modal">Update Image</button>
            </div>
        </div>
    </div>
</div>

<myafrica-loader [isPageLoading]="loading"></myafrica-loader>
