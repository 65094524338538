import {Component, OnInit} from '@angular/core';
import {Business, businessWithUpdatedImages} from '../../../model/Business';
import {Product} from '../../../model/Product';
import {PaginationProduct} from '../../../model/PaginationProduct';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationStoreService} from '../../../services/application-store.service';
import {BusinessService} from '../../../services/business.service';
import {HttpResponse} from '@angular/common/http';
import {NotifierService} from '../../../services/notifier.service';
import {LoginResponse} from '../../../model/LoginResponse';

@Component({
    selector: 'myafrica-edit-business',
    templateUrl: './edit-business.component.html',
    styleUrls: ['./edit-business.component.scss'],
})
export class EditBusinessComponent implements OnInit {

    selectedBusiness: Business;
    billboard: File;
    logo: File;
    logoUrl: string | ArrayBuffer;
    billboardUrl: string | ArrayBuffer;

    itemsPerPageList = [{
        id: 12,
        name: '12',
    }, {
        id: 24,
        name: '24',
    }, {
        id: 36,
        name: '36',
    }, {
        id: 52,
        name: '52',
    }];

    entryLimitCount = 12; // entries per page
    displayEntryLimitCount = 12; // entries per page
    currentPage = 1;
    totalProducts = 0;
    allProducts = [];
    loading: boolean;
    displayRangeLimit: number;

    searchProductName: string;
    searchProductCategory: string;
    searchedProducts: Product[] = [];


    constructor(private modalService: NgbModal,
                private appStore: ApplicationStoreService,
                private businessService: BusinessService,
                private notifier: NotifierService) {
    }

    ngOnInit() {
        this.searchProductName = 'all';
        this.searchProductCategory = 'all';

        this.selectedBusiness = businessWithUpdatedImages(this.appStore.getJsonItem('selectedBusiness'));
        this.loading = true;
        this.getDefaultBusinessProducts();
    }


    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    getDefaultBusinessProducts() {
        this.currentPage = this.currentPage - 1;

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.businessId]).subscribe((value: HttpResponse<PaginationProduct[]>) => {
                this.searchedProducts = value.body[0].products;
                this.totalProducts = value.body[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    setNumberOfItemsperPage() {
        this.currentPage = 1;
        this.displayEntryLimitCount = this.entryLimitCount;
        this.getDefaultBusinessProducts();
    }

    previous() {

        this.loading = true;
        this.currentPage = this.currentPage - 1;

        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.businessId]).subscribe((value: PaginationProduct[]) => {

                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;
                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    next() {

        this.loading = true;
        this.currentPage = this.currentPage + 1;
        if (this.searchProductName === '' || this.searchProductName === undefined || this.searchProductName === null) {
            this.searchProductName = 'all';
        }

        this.businessService.getPaginationBusinessProductsByName(this.searchProductName, this.entryLimitCount, this.currentPage,
            this.searchProductCategory, [',' + this.selectedBusiness.businessId]).subscribe((value: PaginationProduct[]) => {
                this.searchedProducts = value[0].products;
                this.totalProducts = value[0].totalProductsCount;
                this.displayRangeLimit = (this.currentPage + 1) * this.entryLimitCount;

                if (this.displayRangeLimit > this.totalProducts) {
                    this.displayRangeLimit = this.totalProducts;
                }
                this.loading = false;
            }, () => {
                this.loading = false;
            },
        );
    }

    hasLogo(business: Business): boolean {
        return business.logoUrl !== undefined;
    }

    getUrl(url: string): string {
        return 'url('.concat(url).concat(')');
    }

    selectBillboard(event) {
        if (event.target.files && event.target.files[0]) {
            this.billboard = event.target.files[0];
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.billboardUrl = event.target.result;
            };
        }
    }

    selectLogo(event) {
        if (event.target.files && event.target.files[0]) {
            this.logo = event.target.files[0];
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.logoUrl = event.target.result;
            };
        }
    }


    uploadLogo() {
        this.loading = true;
        this.businessService.uploadFile(this.logo, this.selectedBusiness.id)
            .subscribe((response: HttpResponse<Business>) => {
                this.notifier.notify('Logo updated successfully!');
                this.selectedBusiness.logoUrl = response.body.logoPath;
                const loginResponse: LoginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));
                loginResponse.businesses.filter(bus => bus.id === response.body.id)
                    .forEach(business => {
                        business.logoUrl = response.body.logoPath;
                        this.logoUrl = response.body.logoPath;

                    });
                sessionStorage.setItem('loginResponse', JSON.stringify(loginResponse));
                this.loading = false;
            }, error => {
                this.notifier.notifyError('Logo update failed!');
                this.loading = false;
            });
    }

    uploadBillboard() {
        this.loading = true;
        this.businessService.uploadBillboardFile(this.billboard, this.selectedBusiness.id)
            .subscribe((response: HttpResponse<Business>) => {
                this.notifier.notify('Billboard updated successfully!');
                this.selectedBusiness.billboardUrl = response.body.billboardPath;
                const loginResponse: LoginResponse = JSON.parse(sessionStorage.getItem('loginResponse'));
                loginResponse.businesses.filter(bus => bus.id === response.body.id)
                    .forEach(business => {
                        business.billboardUrl = response.body.billboardUrl;
                        this.billboardUrl = response.body.billboardUrl;

                    });
                sessionStorage.setItem('loginResponse', JSON.stringify(loginResponse));
                this.loading = false;
            }, error => {
		        this.notifier.notifyError('Billboard update failed!');
                this.loading = false;
            });
    }

}
