import {Province} from './Province';

export class UserAddress {
    addressline1: string;
    city: string;
    zipcode: string;
    streetname: string;
    province: Province;
	provinceId: string;
	id: string;

}
